import React, { useState } from "react";
import { Provider as StyletronProvider, DebugEngine } from "styletron-react";
import { Client as Styletron } from "styletron-engine-atomic";

import { BaseProvider, LightTheme, DarkTheme } from "baseui";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import Layout from "components/Layout/Layout";
import { ThemeSwitcherProvider, THEME } from "contexts/theme/theme.provider";
import { CartProvider } from "contexts/cart/cart.provider";
import { ApolloProvider } from "@apollo/client";
import client from "api/client";
import Home from "./pages/index";
import Chat from "./pages/chat";
import Integrations from "./pages/integrations";
import Login from "./pages/login";
import Pricing from "./pages/pricing";
import Privacy from "./pages/privacy";
import Profile from "./pages/profile";
import Signup from "./pages/signup";
import NotFoundPage from "./pages/404";
import Apps from "./pages/apps";
import Todo from "./pages/apps/todo";
import Shop from "./pages/shop";
import Form from "./pages/forms";
import FirebaseCrud from "./pages/apps/firebase-crud";
import Terms from "./pages/terms";
import UIElements from "./pages/uielements";
import Charts from "./pages/charts";
import Settings from "./pages/settings";
// external css
import "@glidejs/glide/dist/css/glide.core.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "assets/css/reset.css";
import "react-flexbox-grid/dist/react-flexbox-grid.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "typeface-open-sans";
import Area from "pages/charts/area";
import Column from "pages/charts/column";
import Bar from "pages/charts/bar";
import Mixed from "pages/charts/mixed";
import Pie from "pages/charts/pie";
import Radar from "pages/charts/radar";
import ProductView from "pages/charts/product-view";
import CashFlow from "pages/charts/cash-flow";
import PageTitle from "pages/uielements/page-title";
import WidgetCard from "pages/uielements/widget-card";
import CarouselCard from "pages/uielements/carousel";
import CartProduct from "pages/uielements/cart-product";
import InstagramCard from "pages/uielements/instagram-card";
import ProductCard from "pages/uielements/product-card";
import ListGridCard from "pages/uielements/listgrid-card";
import PricingCard from "pages/uielements/pricing-card";
import Catalogs from "pages/catalogs";
import Amplify from "aws-amplify";
import awsconfig from "aws-exports";
import { AuthProvider } from "contexts/auth/auth.provider";
import { ProfileProvider } from "contexts/profile/profile.provider";

awsconfig.oauth.redirectSignIn = `${window.location.origin}`;
awsconfig.oauth.redirectSignOut = `${window.location.origin}/signout`;
Amplify.configure(awsconfig);

function App() {
  const debug =
    process.env.NODE_ENV === "production" ? void 0 : new DebugEngine();

  // 1. Create a client engine instance
  const engine = new Styletron();
  const [theme, setTheme] = React.useState(THEME.light);
  React.useEffect(() => {
    let SAVED_THEME = localStorage.getItem("theme");
    if (SAVED_THEME === null) {
      SAVED_THEME = THEME.light;
    }
    setTheme(SAVED_THEME);
  }, []);

  return (
    <AuthProvider>
      <ApolloProvider client={client}>
        <ProfileProvider>
          <StyletronProvider value={engine} debug={debug}>
            <BaseProvider
              theme={
                theme === THEME.light
                  ? { ...LightTheme, direction: "ltr" }
                  : { ...DarkTheme, direction: "ltr" }
              }
            >
              <ThemeSwitcherProvider value={{ theme, setTheme }}>
                <CartProvider>
                  <BrowserRouter>
                    <Layout>
                      <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/catalogs" element={<Catalogs />} />
                        <Route path="/apps1/todo" element={<Column />} />
                        <Route path="apps" element={<Apps />}>
                          {/* <Route path="invoice" element={<Invoice  />} /> */}
                          <Route path="todo" element={<Todo />} />
                          <Route
                            path="firebase-crud"
                            element={<FirebaseCrud />}
                          />
                        </Route>
                        <Route path="/shop" element={<Shop />} />
                        <Route path="/integration" element={<Integrations />} />
                        <Route path="/chat" element={<Chat />} />
                        <Route path="charts" element={<Outlet />}>
                          <Route path="/" element={<Charts />} />
                          <Route path="area" element={<Area />} />
                          <Route path="column" element={<Column />} />
                          <Route path="bar" element={<Bar />} />
                          <Route path="mixed" element={<Mixed />} />
                          <Route path="cash-flow" element={<CashFlow />} />
                          <Route path="pie" element={<Pie />} />
                          <Route path="radar" element={<Radar />} />
                          <Route path="product-view" element={<ProductView />} />
                        </Route>
                        <Route path="/forms" element={<Form />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/pricing" element={<Pricing />} />
                        <Route path="/privacy" element={<Privacy />} />
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/terms" element={<Terms />} />
                        <Route path="/signup" element={<Signup />} />
                        <Route path="/settings" element={<Settings />} />

                        <Route path="/uielements" element={<Outlet />}>
                          <Route path="/" element={<UIElements />} />
                          <Route path="page-title" element={<PageTitle />} />
                          <Route path="widget-card" element={<WidgetCard />} />
                          <Route path="carousel" element={<CarouselCard />} />
                          <Route path="cart-product" element={<CartProduct />} />
                          <Route
                            path="instagram-card"
                            element={<InstagramCard />}
                          />
                          <Route path="product-card" element={<ProductCard />} />
                          <Route
                            path="listgrid-card"
                            element={<ListGridCard />}
                          />
                          <Route path="pricing-card" element={<PricingCard />} />
                        </Route>
                        <Route path="*" element={<NotFoundPage />} />
                      </Routes>
                    </Layout>
                  </BrowserRouter>
                </CartProvider>
              </ThemeSwitcherProvider>
            </BaseProvider>
          </StyletronProvider>
        </ProfileProvider>
      </ApolloProvider>
    </AuthProvider>
  );
}

export default App;
