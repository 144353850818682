import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { Select } from "baseui/select";
import { Textarea } from "baseui/textarea";
import { Checkbox } from "baseui/checkbox";
import { RadioGroup, Radio } from "baseui/radio";
import { Datepicker } from "baseui/datepicker";
import { Button } from "baseui/button";
import { Block } from "baseui/block";
import { AuthContext } from "contexts/auth/auth.context";
import { ProfileContext } from "contexts/profile/profile.context";
import { Auth } from "@aws-amplify/auth";

type Props = any;

const LoginForm: React.FC<Props> = (props) => {
  const { state, dispatch } = useContext(AuthContext);
  const { profile, dispatchProfile } = useContext(ProfileContext);
  const { register, handleSubmit, setValue, errors } = useForm();
  const [select, setSelect] = useState([]);
  const [datepicker, setDatepicker] = useState([]);
  const [aboutYourself, setAboutYourself] = useState("");
  const [checked, setChecked] = useState(false);
  const [radio, setRadio] = useState("1");

  async function signIn(email, password, confirmation) {
    try {
      if (confirmation) {
        const user = await Auth.confirmSignUp(email, confirmation);
      }
      const user = await Auth.signIn(email, password);
      //dispatchProfile({ type: "LOAD_PROFILE", payload: user.attributes.email });

      return {
        isOk: true,
        user,
        //        data: { ...defaultUser, email: email },
      };
    } catch (error) {
      console.log("error signing in", error);
      if (error.code === "UserNotConfirmedException") {
        return {
          isOk: false,
          confirmRequired: true,
          message: "User Not Confirmed",
        };
      } else
        return {
          isOk: false,
          message: "Authentication failed",
        };
    }
  }

  const onSubmit = (data) => {
    console.log(`data - Login`, data);
    signIn(data.email, data.password, null);
    //dispatchProfile({ type: "LOAD_PROFILE", payload: data.email });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
      <Block marginBottom="30px">
        <FormControl
          label="Your Email"
          caption="johndoe@demo.io"
          error={errors.email && "Please enter a valid email address"}
        >
          <Input
            name="email"
            inputRef={register({
              required: true,
              pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
            })}
            overrides={{
              InputContainer: {
                style: () => {
                  return { backgroundColor: "transparent" };
                },
              },
            }}
          />
        </FormControl>
      </Block>
      <Block>
        <FormControl>
          <Input
            type="password"
            name="password"
            //value={state.newPassword}
            //onChange={handleOnChange}
            inputRef={register({ required: true, maxLength: 20 })}
            overrides={{
              InputContainer: {
                style: () => {
                  return { backgroundColor: "transparent" };
                },
              },
            }}
          />
        </FormControl>
      </Block>

      <Button type="submit">Submit</Button>
    </form>
  );
};

export default LoginForm;
