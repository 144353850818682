import React, { useContext, useState } from "react";

import { Helmet as Head } from "react-helmet";
import { Avatar } from "baseui/avatar";
import { Modal, ModalHeader, ModalBody } from "baseui/modal";
import { Button } from "baseui/button";
import Container from "../components/UiElements/Container/Container";
import Posts from "../containers/Posts/Posts";

import {
  PageTitle,
  InfoBar,
  UserAvatar,
  UserName,
  UserRole,
  Menu,
  MenuList,
  Strong,
  AlignItems,
  List,
  ListItem,
} from "../components/PageStyles/Profile.styled";
import { Block } from "baseui/block";
import profileData from "data/profile.data";
import { AuthContext } from "contexts/auth/auth.context";
import { ProfileContext } from "contexts/profile/profile.context";
import { AccountFragment, UserByEmailQuery } from "api";
const Profile: React.FC<{}> = () => {
  const [active, setActive] = useState("posts");
  const [visible, setVisible] = useState(false);
  const { state } = useContext(AuthContext);
  const { profile, dispatchProfile } = useContext(ProfileContext);

  if (!profile.user) return <div>No User</div>;

  const user = profile.user?.profile;
  //  || {
  //   name: "Unknown User",
  // }; //fix by adding index
  const accounts = profile.user?.authorizedUserByEmail?.accounts;

  const posts = profileData.posts;

  var cognitoName, cognitoImage;
  if (state.cognitoUser) {
    console.log(`state.cognitoUser`, state.cognitoUser);
    const user = state.cognitoUser.attributes;
    cognitoName = user.name;
    cognitoImage = user.picture.startsWith("http")
      ? user.picture
      : JSON.parse(user.picture).data.url;
  }

  return (
    <>
      <Head>
        <title>Profile | INST.</title>
        <meta name="Description" content="Inst profile page" />
      </Head>

      <PageTitle>
        <Container>
          <Block
            overrides={{
              Block: {
                style: {
                  "@media only screen and (max-width: 667px)": {
                    textAlign: "center",
                  },
                },
              },
            }}
          >
            <UserAvatar
              src={cognitoImage || user?.imageUrl}
              alt={user?.firstName}
            />
          </Block>

          <InfoBar>
            <Block>
              <UserName>
                {cognitoName || user?.firstName + ` ` + user?.lastName}
              </UserName>
              <UserRole>{user?.company}</UserRole>
            </Block>
            <Block>
              <UserName>Email</UserName>
              <UserRole>{user?.email}</UserRole>
            </Block>
            <Block>
              <UserName>Phone </UserName>
              <UserRole>{user?.phone}</UserRole>
            </Block>
            <Block>
              <UserName>Address</UserName>
              <UserRole>
                {user?.street +
                  " " +
                  user?.city +
                  " " +
                  user?.state +
                  " " +
                  user?.zipPostal}
              </UserRole>
            </Block>
            <Menu>
              <MenuList
                className={active === "accounts" ? "active" : ""}
                onClick={() => {
                  setActive("accounts");
                  setVisible(true);
                }}
              >
                <Strong>Partner Accounts</Strong>
              </MenuList>
            </Menu>
          </InfoBar>
        </Container>
      </PageTitle>

      <Container>
        <Posts
          data={posts}
          avatar={user?.imageUrl}
          username={user?.firstName}
        />

        <Modal
          onClose={() => {
            setVisible(false);
            setActive("posts");
          }}
          closeable
          isOpen={visible}
          animate
          size="default"
          role="dialog"
          unstable_ModalBackdropScroll={true}
          overrides={{
            Root: {
              style: () => {
                return { zIndex: 9999 };
              },
            },
          }}
        >
          {active === "accounts" && (
            <>
              <ModalHeader>Partner Accounts</ModalHeader>
              <ModalBody style={{ overflow: "hidden" }}>
                {accounts?.length > 0 ? (
                  <List className="followers-list">
                    {accounts.map((account: AccountFragment) => (
                      <ListItem key={`follower-key${account.oid}`}>
                        <AlignItems>
                          <Avatar
                            name={account.name}
                            size="scale1400"
                            src={account.logo}
                          />
                          <span style={{ margin: "0 10px" }}>
                            {account.name}
                          </span>
                        </AlignItems>
                        <Button
                          size="compact"
                          kind="secondary"
                          shape="pill"
                          onClick={() =>
                            dispatchProfile({
                              type: "SET_ACCOUNT",
                              payload: account,
                            })
                          }
                        >
                          Select
                        </Button>
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  "0 Accounts"
                )}
              </ModalBody>
            </>
          )}
        </Modal>
      </Container>
    </>
  );
};

export default Profile;
