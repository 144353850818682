import React from 'react';

type SvgIconProps = {
  src: string;
  style?: React.CSSProperties;
  CustomComp?: React.ReactNode
};

const SvgIcon = ({src, style,CustomComp }: SvgIconProps) => {
  return (
    <span
      style={style}
      className="svg-icon"
    >{CustomComp}</span>
  );
};

export default SvgIcon;
