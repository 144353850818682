import React, { Children } from "react";
import useRouter from "utils/useRouter";
import { Link } from "react-router-dom";
import Links from './NavLink.styled';

type ActiveLinkProps = {
  children: React.ReactNode;
  activeClassName: string;
  link: string;
};

const ActiveLink = ({
  children,
  activeClassName,
  link,
  ...props
}: ActiveLinkProps) => {
  const { pathname } = useRouter();
  const child: any = Children.only(children);
  const childClassName = child.props.className || "";
  const newpathname = pathname.split("/").slice(1, 2);
  const path = `/${newpathname[0]}`;

  const className =
    path === link
      ? `${childClassName} ${activeClassName}`.trim()
      : childClassName;

  return (
    <Links to={link} {...props}>
      {React.cloneElement(child, {
        className: className || null,
      })}
    </Links>
  );
};

export default ActiveLink;
