import { Helmet as Head } from "react-helmet";
import useRouter from "utils/useRouter";
import { IoLogoFacebook, IoLogoGoogle, IoLogoTwitter } from "react-icons/io";
import { Block } from "baseui/block";
import { Button } from "baseui/button";
import Container from "../components/UiElements/Container/Container";
import { useContext } from "react";
import { AuthContext } from "contexts/auth/auth.context";
import HookForm from "containers/HookForm/Form";
import LoginForm from "containers/HookForm/LoginForm";
import Auth, { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
interface LoginProps {
  onAuth?: Function;
}
const Login: React.FC<LoginProps> = ({ onAuth }) => {
  const { state, dispatch } = useContext(AuthContext);
  const facebookLogin = (e) => {
    e.preventDefault();
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Facebook,
    });
  };

  const googleLogin = (e) => {
    e.preventDefault();
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
  };

  return (
    <>
      <Head>
        <title>Login | INST.</title>
        <meta name="Description" content="Inst login page" />
      </Head>

      <Container>
        {state.currentForm === "signUp" ? (
          <HookForm />
        ) : (
          <Block
            overrides={{
              Block: {
                style: {
                  minHeight: "calc(100vh - 213px)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                },
              },
            }}
          >
            <Block
              as="h1"
              overrides={{
                Block: {
                  style: ({ $theme }) => {
                    return {
                      ...$theme.typography.font1250,
                      fontWeight: 700,
                      marginBottom: "30px",
                    };
                  },
                },
              }}
            >
              Catalogs Portal Login
            </Block>
            <LoginForm />

            <Button
              onClick={facebookLogin}
              startEnhancer={() => <IoLogoFacebook size="1.25rem" />}
              overrides={{
                BaseButton: {
                  style: ({ $theme }) => {
                    return {
                      ...$theme.typography.font250,
                      width: "100%",
                      maxWidth: "260px",
                      borderTopLeftRadius: "4px",
                      borderTopRightRadius: "4px",
                      borderBottomLeftRadius: "4px",
                      borderBottomRightRadius: "4px",
                      marginTop: "10px",
                      justifyContent: "flex-start",
                      paddingLeft: "25px",
                      paddingRight: "25px",
                      backgroundColor: "#4267B2",
                      transition: "all 0.3s ease",
                      ":hover": {
                        backgroundColor: "#4267B2",
                        opacity: 0.95,
                      },
                    };
                  },
                },
              }}
            >
              Continue with Facebook
            </Button>

            <Button
              onClick={googleLogin}
              startEnhancer={() => <IoLogoGoogle size="1.25rem" />}
              overrides={{
                BaseButton: {
                  style: ({ $theme }) => {
                    return {
                      ...$theme.typography.font250,
                      backgroundColor: "#1DA1F2",
                      width: "100%",
                      maxWidth: "260px",
                      borderTopLeftRadius: "4px",
                      borderTopRightRadius: "4px",
                      borderBottomLeftRadius: "4px",
                      borderBottomRightRadius: "4px",
                      marginTop: "10px",
                      justifyContent: "flex-start",
                      paddingLeft: "25px",
                      paddingRight: "25px",
                      ":hover": {
                        backgroundColor: "#1DA1F2",
                        opacity: 0.95,
                      },
                    };
                  },
                },
              }}
            >
              Continue with Google
            </Button>
          </Block>
        )}
      </Container>
    </>
  );
};

export default Login;
