import React from "react";
import { Link } from "react-router-dom";
import Wrapper from "./Logo.styled";

type LogoProps = {
  path: string | any;
  src: React.ReactNode;
  style?: React.CSSProperties;
  
};

const Logo:React.FC<LogoProps>=({path,src,style})=>{
  
  return (
    <Link to={path}>
      <Wrapper className="logo" style={style}>
        {src}
      </Wrapper>
    </Link>
  );
};

export default Logo;
