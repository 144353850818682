/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_identity_pool_id:
    "us-east-1:db02356f-3d4a-4409-b17c-c83f558fb4e1",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_kNC1B115Y",
  aws_user_pools_web_client_id: "3n9vdv0km8ha02s08hs2ptlf5s",
  oauth: {
    domain: "authorize-prod.auth.us-east-1.amazoncognito.com",
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: "http://localhost:3000/login/",
    redirectSignOut: "http://localhost:3000/logout/",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_mobile_analytics_app_id: "63689e0215ef497aa2a15f8574760cda",
  aws_mobile_analytics_app_region: "us-east-1",
};

export default awsmobile;
