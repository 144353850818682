import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { API } from "constant";

const link = new BatchHttpLink({ uri: API });
const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    //@ts-ignore
    dataIdFromObject: (object) => object.oid || null,
  }),
});
export default client;
