import React from "react";

import { Helmet as Head } from "react-helmet";
import Container from "../components/UiElements/Container/Container";
import NotFound from "../components/UiElements/NotFound/NotFound";

const NotFoundPage: React.FC<{}> = () => {
  return (
    <>
      <Head>
        <title>404: Not found</title>
      </Head>
      <div>
        <Container>
          <NotFound />
        </Container>
      </div>
    </>
  );
};

export default NotFoundPage;
