/************ CONSTANTS ***********/
export const CURRENCY = "$";
export const BASECDN =
  process.env.REACT_APP_CDN_URL || "https://cdn.catalogs.com";
export const PCDN = process.env.REACT_APP_PCDN_URL || BASECDN + "/s/thumb";
export const MEDIUMCDN =
  process.env.REACT_APP_MEDIUMCDN_URL || BASECDN + "/s/mjpeg";
export const FULLCDN =
  process.env.REACT_APP_FULLCDN_URL || BASECDN + "/s/ljpeg";
export const API =
  process.env.REACT_APP_API_URL || "https://gql.catalogshub.com/production";
