import React, { useContext } from "react";
import useRouter from "utils/useRouter";
import { Block } from "baseui/block";
import { Button } from "baseui/button";
import Logo from "../../UiElements/Logo/Logo";
import SvgIcon from "../../UiElements/SvgIcon/SvgIcon";
import Container from "../../UiElements/Container/Container";
import LogoSvg from "assets/images/logo.svg";
import { AuthContext } from "contexts/auth/auth.context";
type AuthHeaderType = {
  pathname?: string;
};

const AuthHeader = ({ pathname }: AuthHeaderType) => {
  const router = useRouter();
  const { state, dispatch } = useContext(AuthContext);
  return (
    <Container>
      <Block
        overrides={{
          Block: {
            style: {
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "23px 0",
            },
          },
        }}
      >
        <Logo path={pathname} src={<SvgIcon src={LogoSvg} />} />

        <Block>
          <Button
            kind="minimal"
            //disabled={pathname === "/login"}
            onClick={() => dispatch({ type: "SIGNIN" })}
            overrides={{
              BaseButton: {
                style: ({ $theme }) => {
                  return {
                    ...$theme.typography.font250,
                    ":disabled": {
                      backgroundColor: "transparent",
                    },
                  };
                },
              },
            }}
          >
            Login
          </Button>
          <Button
            // disabled={pathname === "/signup"}
            onClick={() => dispatch({ type: "SIGNUP" })}
            overrides={{
              BaseButton: {
                style: ({ $theme }) => {
                  return {
                    ...$theme.typography.font250,
                  };
                },
              },
            }}
          >
            Join Free
          </Button>
        </Block>
      </Block>
    </Container>
  );
};

export default AuthHeader;
