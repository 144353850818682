import { UserByEmailQuery } from "api";
import { createContext, Dispatch } from "react";
import { ActionType, ProfileData } from "./profile.provider";

export const INITIAL_STATE = {
  profile: null,
  authorizedUserByEmail: null,
};

export const ProfileContext = createContext<{
  profile: ProfileData;
  dispatchProfile: Dispatch<ActionType>;
}>({
  profile: {},
  dispatchProfile: () => null,
});
