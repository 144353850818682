import React from "react";
import ChartWithNoSSR from "react-apexcharts";

type ApexChartType = {
  [props: string]: any;
};

const ApexChart = (props: ApexChartType) => {
  return <ChartWithNoSSR {...props} />;
};

export default ApexChart;
