import { createContext, Dispatch } from "react";
import { ActionType, AuthData } from "./auth.provider";

export const INITIAL_STATE = {
  isAuthenticated: false,
  currentForm: "signIn",
  useAuthentication: true,
  cognitoUser: undefined,
};

export const AuthContext = createContext<{
  state: AuthData;
  dispatch: Dispatch<ActionType>;
}>({
  state: INITIAL_STATE,
  dispatch: () => null,
});
