import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A signed eight-byte integer. The upper big integer values are greater than the max value for a JavaScript number. Therefore all big integers will be output as strings and not numbers. */
  BigInt: any;
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: any;
  /** A point in time as described by the [ISO 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone. */
  Datetime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: any;
};

export type Account = {
  readonly __typename?: 'Account';
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly customSmsNumber?: Maybe<Scalars['String']>;
  readonly logo?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly login?: Maybe<Scalars['String']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly defaultTrackingCode?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['Datetime']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['BigInt']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Catalog`. */
  readonly catalogsByAccountConnection: CatalogsConnection;
  /** Reads and enables pagination through a set of `Catalog`. */
  readonly catalogsByAccount: ReadonlyArray<Catalog>;
  /** Reads and enables pagination through a set of `Contact`. */
  readonly contactsByParentConnection: ContactsConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  readonly contactsByParent: ReadonlyArray<Contact>;
  /** Reads and enables pagination through a set of `Campaign`. */
  readonly campaignsByAccountConnection: CampaignsConnection;
  /** Reads and enables pagination through a set of `Campaign`. */
  readonly campaignsByAccount: ReadonlyArray<Campaign>;
  /** Reads and enables pagination through a set of `Lead`. */
  readonly leadsByAccountConnection: LeadsConnection;
  /** Reads and enables pagination through a set of `Lead`. */
  readonly leadsByAccount: ReadonlyArray<Lead>;
  /** Reads and enables pagination through a set of `AuthorizedUserAuthorizedUsersAccountPartnerAccess`. */
  readonly authorizedUserAuthorizedUsersAccountPartnerAccessesByPartnerAccessConnection: AuthorizedUserAuthorizedUsersAccountPartnerAccessesConnection;
  /** Reads and enables pagination through a set of `AuthorizedUserAuthorizedUsersAccountPartnerAccess`. */
  readonly authorizedUserAuthorizedUsersAccountPartnerAccessesByPartnerAccess: ReadonlyArray<AuthorizedUserAuthorizedUsersAccountPartnerAccess>;
  /** Reads and enables pagination through a set of `Department`. */
  readonly departmentsByCatalogAccountAndPrimaryDepartment: AccountDepartmentsByCatalogAccountAndPrimaryDepartmentManyToManyConnection;
  /** Reads and enables pagination through a set of `Department`. */
  readonly departmentsByCatalogAccountAndPrimaryDepartmentList: ReadonlyArray<Department>;
  /** Reads and enables pagination through a set of `Version`. */
  readonly versionsByCatalogAccountAndActiveVersion: AccountVersionsByCatalogAccountAndActiveVersionManyToManyConnection;
  /** Reads and enables pagination through a set of `Version`. */
  readonly versionsByCatalogAccountAndActiveVersionList: ReadonlyArray<Version>;
  /** Reads and enables pagination through a set of `Profile`. */
  readonly profilesByLeadAccountAndProfile: AccountProfilesByLeadAccountAndProfileManyToManyConnection;
  /** Reads and enables pagination through a set of `Profile`. */
  readonly profilesByLeadAccountAndProfileList: ReadonlyArray<Profile>;
  /** Reads and enables pagination through a set of `AuthorizedUser`. */
  readonly authorizedUsersByAuthorizedUserAuthorizedUsersAccountPartnerAccessPartnerAccessAndAuthorizedUsers: AccountAuthorizedUsersByAuthorizedUserAuthorizedUsersAccountPartnerAccessPartnerAccessAndAuthorizedUsersManyToManyConnection;
  /** Reads and enables pagination through a set of `AuthorizedUser`. */
  readonly authorizedUsersByAuthorizedUserAuthorizedUsersAccountPartnerAccessPartnerAccessAndAuthorizedUsersList: ReadonlyArray<AuthorizedUser>;
};


export type AccountCatalogsByAccountConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CatalogsOrderBy>>;
  condition?: Maybe<CatalogCondition>;
  filter?: Maybe<CatalogFilter>;
};


export type AccountCatalogsByAccountArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CatalogsOrderBy>>;
  condition?: Maybe<CatalogCondition>;
  filter?: Maybe<CatalogFilter>;
};


export type AccountContactsByParentConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<ContactsOrderBy>>;
  condition?: Maybe<ContactCondition>;
  filter?: Maybe<ContactFilter>;
};


export type AccountContactsByParentArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<ContactsOrderBy>>;
  condition?: Maybe<ContactCondition>;
  filter?: Maybe<ContactFilter>;
};


export type AccountCampaignsByAccountConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CampaignsOrderBy>>;
  condition?: Maybe<CampaignCondition>;
  filter?: Maybe<CampaignFilter>;
};


export type AccountCampaignsByAccountArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CampaignsOrderBy>>;
  condition?: Maybe<CampaignCondition>;
  filter?: Maybe<CampaignFilter>;
};


export type AccountLeadsByAccountConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};


export type AccountLeadsByAccountArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};


export type AccountAuthorizedUserAuthorizedUsersAccountPartnerAccessesByPartnerAccessConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<AuthorizedUserAuthorizedUsersAccountPartnerAccessesOrderBy>>;
  condition?: Maybe<AuthorizedUserAuthorizedUsersAccountPartnerAccessCondition>;
  filter?: Maybe<AuthorizedUserAuthorizedUsersAccountPartnerAccessFilter>;
};


export type AccountAuthorizedUserAuthorizedUsersAccountPartnerAccessesByPartnerAccessArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<AuthorizedUserAuthorizedUsersAccountPartnerAccessesOrderBy>>;
  condition?: Maybe<AuthorizedUserAuthorizedUsersAccountPartnerAccessCondition>;
  filter?: Maybe<AuthorizedUserAuthorizedUsersAccountPartnerAccessFilter>;
};


export type AccountDepartmentsByCatalogAccountAndPrimaryDepartmentArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<DepartmentsOrderBy>>;
  condition?: Maybe<DepartmentCondition>;
  filter?: Maybe<DepartmentFilter>;
};


export type AccountDepartmentsByCatalogAccountAndPrimaryDepartmentListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<DepartmentsOrderBy>>;
  condition?: Maybe<DepartmentCondition>;
  filter?: Maybe<DepartmentFilter>;
};


export type AccountVersionsByCatalogAccountAndActiveVersionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<VersionsOrderBy>>;
  condition?: Maybe<VersionCondition>;
  filter?: Maybe<VersionFilter>;
};


export type AccountVersionsByCatalogAccountAndActiveVersionListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<VersionsOrderBy>>;
  condition?: Maybe<VersionCondition>;
  filter?: Maybe<VersionFilter>;
};


export type AccountProfilesByLeadAccountAndProfileArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<ProfilesOrderBy>>;
  condition?: Maybe<ProfileCondition>;
  filter?: Maybe<ProfileFilter>;
};


export type AccountProfilesByLeadAccountAndProfileListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<ProfilesOrderBy>>;
  condition?: Maybe<ProfileCondition>;
  filter?: Maybe<ProfileFilter>;
};


export type AccountAuthorizedUsersByAuthorizedUserAuthorizedUsersAccountPartnerAccessPartnerAccessAndAuthorizedUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<AuthorizedUsersOrderBy>>;
  condition?: Maybe<AuthorizedUserCondition>;
  filter?: Maybe<AuthorizedUserFilter>;
};


export type AccountAuthorizedUsersByAuthorizedUserAuthorizedUsersAccountPartnerAccessPartnerAccessAndAuthorizedUsersListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<AuthorizedUsersOrderBy>>;
  condition?: Maybe<AuthorizedUserCondition>;
  filter?: Maybe<AuthorizedUserFilter>;
};

/** A connection to a list of `AuthorizedUser` values, with data from `AuthorizedUserAuthorizedUsersAccountPartnerAccess`. */
export type AccountAuthorizedUsersByAuthorizedUserAuthorizedUsersAccountPartnerAccessPartnerAccessAndAuthorizedUsersManyToManyConnection = {
  readonly __typename?: 'AccountAuthorizedUsersByAuthorizedUserAuthorizedUsersAccountPartnerAccessPartnerAccessAndAuthorizedUsersManyToManyConnection';
  /** A list of `AuthorizedUser` objects. */
  readonly nodes: ReadonlyArray<AuthorizedUser>;
  /** A list of edges which contains the `AuthorizedUser`, info from the `AuthorizedUserAuthorizedUsersAccountPartnerAccess`, and the cursor to aid in pagination. */
  readonly edges: ReadonlyArray<AccountAuthorizedUsersByAuthorizedUserAuthorizedUsersAccountPartnerAccessPartnerAccessAndAuthorizedUsersManyToManyEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `AuthorizedUser` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `AuthorizedUser` edge in the connection, with data from `AuthorizedUserAuthorizedUsersAccountPartnerAccess`. */
export type AccountAuthorizedUsersByAuthorizedUserAuthorizedUsersAccountPartnerAccessPartnerAccessAndAuthorizedUsersManyToManyEdge = {
  readonly __typename?: 'AccountAuthorizedUsersByAuthorizedUserAuthorizedUsersAccountPartnerAccessPartnerAccessAndAuthorizedUsersManyToManyEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `AuthorizedUser` at the end of the edge. */
  readonly node: AuthorizedUser;
  /** Reads and enables pagination through a set of `AuthorizedUserAuthorizedUsersAccountPartnerAccess`. */
  readonly authorizedUserAuthorizedUsersAccountPartnerAccessesByAuthorizedUsersConnection: AuthorizedUserAuthorizedUsersAccountPartnerAccessesConnection;
  /** Reads and enables pagination through a set of `AuthorizedUserAuthorizedUsersAccountPartnerAccess`. */
  readonly authorizedUserAuthorizedUsersAccountPartnerAccessesByAuthorizedUsers: ReadonlyArray<AuthorizedUserAuthorizedUsersAccountPartnerAccess>;
};


/** A `AuthorizedUser` edge in the connection, with data from `AuthorizedUserAuthorizedUsersAccountPartnerAccess`. */
export type AccountAuthorizedUsersByAuthorizedUserAuthorizedUsersAccountPartnerAccessPartnerAccessAndAuthorizedUsersManyToManyEdgeAuthorizedUserAuthorizedUsersAccountPartnerAccessesByAuthorizedUsersConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<AuthorizedUserAuthorizedUsersAccountPartnerAccessesOrderBy>>;
  condition?: Maybe<AuthorizedUserAuthorizedUsersAccountPartnerAccessCondition>;
  filter?: Maybe<AuthorizedUserAuthorizedUsersAccountPartnerAccessFilter>;
};


/** A `AuthorizedUser` edge in the connection, with data from `AuthorizedUserAuthorizedUsersAccountPartnerAccess`. */
export type AccountAuthorizedUsersByAuthorizedUserAuthorizedUsersAccountPartnerAccessPartnerAccessAndAuthorizedUsersManyToManyEdgeAuthorizedUserAuthorizedUsersAccountPartnerAccessesByAuthorizedUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<AuthorizedUserAuthorizedUsersAccountPartnerAccessesOrderBy>>;
  condition?: Maybe<AuthorizedUserAuthorizedUsersAccountPartnerAccessCondition>;
  filter?: Maybe<AuthorizedUserAuthorizedUsersAccountPartnerAccessFilter>;
};

/** A condition to be used against `Account` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type AccountCondition = {
  /** Checks for equality with the object’s `oid` field. */
  readonly oid?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `options` field. */
  readonly options?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `imageUrl` field. */
  readonly imageUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `email` field. */
  readonly email?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `phone` field. */
  readonly phone?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `street` field. */
  readonly street?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `city` field. */
  readonly city?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `state` field. */
  readonly state?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `zipPostal` field. */
  readonly zipPostal?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `country` field. */
  readonly country?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  readonly name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `customSmsNumber` field. */
  readonly customSmsNumber?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `logo` field. */
  readonly logo?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `active` field. */
  readonly active?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `login` field. */
  readonly login?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `comments` field. */
  readonly comments?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `defaultTrackingCode` field. */
  readonly defaultTrackingCode?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdBy` field. */
  readonly createdBy?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdOn` field. */
  readonly createdOn?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `homePageUrl` field. */
  readonly homePageUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `legacyId` field. */
  readonly legacyId?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `slug` field. */
  readonly slug?: Maybe<Scalars['String']>;
};

/** A connection to a list of `Department` values, with data from `Catalog`. */
export type AccountDepartmentsByCatalogAccountAndPrimaryDepartmentManyToManyConnection = {
  readonly __typename?: 'AccountDepartmentsByCatalogAccountAndPrimaryDepartmentManyToManyConnection';
  /** A list of `Department` objects. */
  readonly nodes: ReadonlyArray<Department>;
  /** A list of edges which contains the `Department`, info from the `Catalog`, and the cursor to aid in pagination. */
  readonly edges: ReadonlyArray<AccountDepartmentsByCatalogAccountAndPrimaryDepartmentManyToManyEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Department` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Department` edge in the connection, with data from `Catalog`. */
export type AccountDepartmentsByCatalogAccountAndPrimaryDepartmentManyToManyEdge = {
  readonly __typename?: 'AccountDepartmentsByCatalogAccountAndPrimaryDepartmentManyToManyEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Department` at the end of the edge. */
  readonly node: Department;
  /** Reads and enables pagination through a set of `Catalog`. */
  readonly catalogsByPrimaryDepartmentConnection: CatalogsConnection;
  /** Reads and enables pagination through a set of `Catalog`. */
  readonly catalogsByPrimaryDepartment: ReadonlyArray<Catalog>;
};


/** A `Department` edge in the connection, with data from `Catalog`. */
export type AccountDepartmentsByCatalogAccountAndPrimaryDepartmentManyToManyEdgeCatalogsByPrimaryDepartmentConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CatalogsOrderBy>>;
  condition?: Maybe<CatalogCondition>;
  filter?: Maybe<CatalogFilter>;
};


/** A `Department` edge in the connection, with data from `Catalog`. */
export type AccountDepartmentsByCatalogAccountAndPrimaryDepartmentManyToManyEdgeCatalogsByPrimaryDepartmentArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CatalogsOrderBy>>;
  condition?: Maybe<CatalogCondition>;
  filter?: Maybe<CatalogFilter>;
};

/** A filter to be used against `Account` object types. All fields are combined with a logical ‘and.’ */
export type AccountFilter = {
  /** Filter by the object’s `oid` field. */
  readonly oid?: Maybe<UuidFilter>;
  /** Filter by the object’s `imageUrl` field. */
  readonly imageUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `email` field. */
  readonly email?: Maybe<StringFilter>;
  /** Filter by the object’s `phone` field. */
  readonly phone?: Maybe<StringFilter>;
  /** Filter by the object’s `street` field. */
  readonly street?: Maybe<StringFilter>;
  /** Filter by the object’s `city` field. */
  readonly city?: Maybe<StringFilter>;
  /** Filter by the object’s `state` field. */
  readonly state?: Maybe<StringFilter>;
  /** Filter by the object’s `zipPostal` field. */
  readonly zipPostal?: Maybe<StringFilter>;
  /** Filter by the object’s `country` field. */
  readonly country?: Maybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  readonly name?: Maybe<StringFilter>;
  /** Filter by the object’s `customSmsNumber` field. */
  readonly customSmsNumber?: Maybe<StringFilter>;
  /** Filter by the object’s `logo` field. */
  readonly logo?: Maybe<StringFilter>;
  /** Filter by the object’s `active` field. */
  readonly active?: Maybe<BooleanFilter>;
  /** Filter by the object’s `login` field. */
  readonly login?: Maybe<StringFilter>;
  /** Filter by the object’s `comments` field. */
  readonly comments?: Maybe<StringFilter>;
  /** Filter by the object’s `defaultTrackingCode` field. */
  readonly defaultTrackingCode?: Maybe<StringFilter>;
  /** Filter by the object’s `createdBy` field. */
  readonly createdBy?: Maybe<StringFilter>;
  /** Filter by the object’s `createdOn` field. */
  readonly createdOn?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `homePageUrl` field. */
  readonly homePageUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `legacyId` field. */
  readonly legacyId?: Maybe<BigIntFilter>;
  /** Filter by the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<IntFilter>;
  /** Filter by the object’s `slug` field. */
  readonly slug?: Maybe<StringFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<ReadonlyArray<AccountFilter>>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<ReadonlyArray<AccountFilter>>;
  /** Negates the expression. */
  readonly not?: Maybe<AccountFilter>;
};

/** An input for mutations affecting `Account` */
export type AccountInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly customSmsNumber?: Maybe<Scalars['String']>;
  readonly logo?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly login?: Maybe<Scalars['String']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly defaultTrackingCode?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['Datetime']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['BigInt']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly catalogsUsingOid?: Maybe<FkCatalogAccountInverseInput>;
  readonly contactsUsingOid?: Maybe<FkContactParentInverseInput>;
  readonly campaignsUsingOid?: Maybe<FkCampaignAccountInverseInput>;
  readonly leadsUsingOid?: Maybe<FkLeadAccountInverseInput>;
  readonly authorizedUserAuthorizedUsersAccountPartnerAccessesUsingOid?: Maybe<FkAuthorizedUserAuthorizedUsersAccountPartnerAccess4E22214EInverseInput>;
};

/** The fields on `account` to look up the row to update. */
export type AccountOnAuthorizedUserAuthorizedUsersAccountPartnerAccessForFkAuthorizedUserAuthorizedUsersAccountPartnerAccess4E22214EUsingPkAccountUpdate = {
  /** An object where the defined keys will be set on the `account` being updated. */
  readonly patch: UpdateAccountOnAuthorizedUserAuthorizedUsersAccountPartnerAccessForFkAuthorizedUserAuthorizedUsersAccountPartnerAccess4E22214EPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `account` to look up the row to update. */
export type AccountOnCampaignForFkCampaignAccountUsingPkAccountUpdate = {
  /** An object where the defined keys will be set on the `account` being updated. */
  readonly patch: UpdateAccountOnCampaignForFkCampaignAccountPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `account` to look up the row to update. */
export type AccountOnCatalogForFkCatalogAccountUsingPkAccountUpdate = {
  /** An object where the defined keys will be set on the `account` being updated. */
  readonly patch: UpdateAccountOnCatalogForFkCatalogAccountPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `account` to look up the row to update. */
export type AccountOnContactForFkContactParentUsingPkAccountUpdate = {
  /** An object where the defined keys will be set on the `account` being updated. */
  readonly patch: UpdateAccountOnContactForFkContactParentPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `account` to look up the row to update. */
export type AccountOnLeadForFkLeadAccountUsingPkAccountUpdate = {
  /** An object where the defined keys will be set on the `account` being updated. */
  readonly patch: UpdateAccountOnLeadForFkLeadAccountPatch;
  readonly oid: Scalars['UUID'];
};

/** Represents an update to a `Account`. Fields that are set will be updated. */
export type AccountPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly customSmsNumber?: Maybe<Scalars['String']>;
  readonly logo?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly login?: Maybe<Scalars['String']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly defaultTrackingCode?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['Datetime']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['BigInt']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly catalogsUsingOid?: Maybe<FkCatalogAccountInverseInput>;
  readonly contactsUsingOid?: Maybe<FkContactParentInverseInput>;
  readonly campaignsUsingOid?: Maybe<FkCampaignAccountInverseInput>;
  readonly leadsUsingOid?: Maybe<FkLeadAccountInverseInput>;
  readonly authorizedUserAuthorizedUsersAccountPartnerAccessesUsingOid?: Maybe<FkAuthorizedUserAuthorizedUsersAccountPartnerAccess4E22214EInverseInput>;
};

/** The fields on `account` to look up the row to connect. */
export type AccountPkAccountConnect = {
  readonly oid: Scalars['UUID'];
};

/** The fields on `account` to look up the row to delete. */
export type AccountPkAccountDelete = {
  readonly oid: Scalars['UUID'];
};

/** A connection to a list of `Profile` values, with data from `Lead`. */
export type AccountProfilesByLeadAccountAndProfileManyToManyConnection = {
  readonly __typename?: 'AccountProfilesByLeadAccountAndProfileManyToManyConnection';
  /** A list of `Profile` objects. */
  readonly nodes: ReadonlyArray<Profile>;
  /** A list of edges which contains the `Profile`, info from the `Lead`, and the cursor to aid in pagination. */
  readonly edges: ReadonlyArray<AccountProfilesByLeadAccountAndProfileManyToManyEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Profile` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Profile` edge in the connection, with data from `Lead`. */
export type AccountProfilesByLeadAccountAndProfileManyToManyEdge = {
  readonly __typename?: 'AccountProfilesByLeadAccountAndProfileManyToManyEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Profile` at the end of the edge. */
  readonly node: Profile;
  /** Reads and enables pagination through a set of `Lead`. */
  readonly leadsByProfileConnection: LeadsConnection;
  /** Reads and enables pagination through a set of `Lead`. */
  readonly leadsByProfile: ReadonlyArray<Lead>;
};


/** A `Profile` edge in the connection, with data from `Lead`. */
export type AccountProfilesByLeadAccountAndProfileManyToManyEdgeLeadsByProfileConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};


/** A `Profile` edge in the connection, with data from `Lead`. */
export type AccountProfilesByLeadAccountAndProfileManyToManyEdgeLeadsByProfileArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};

/** A connection to a list of `Version` values, with data from `Catalog`. */
export type AccountVersionsByCatalogAccountAndActiveVersionManyToManyConnection = {
  readonly __typename?: 'AccountVersionsByCatalogAccountAndActiveVersionManyToManyConnection';
  /** A list of `Version` objects. */
  readonly nodes: ReadonlyArray<Version>;
  /** A list of edges which contains the `Version`, info from the `Catalog`, and the cursor to aid in pagination. */
  readonly edges: ReadonlyArray<AccountVersionsByCatalogAccountAndActiveVersionManyToManyEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Version` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Version` edge in the connection, with data from `Catalog`. */
export type AccountVersionsByCatalogAccountAndActiveVersionManyToManyEdge = {
  readonly __typename?: 'AccountVersionsByCatalogAccountAndActiveVersionManyToManyEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Version` at the end of the edge. */
  readonly node: Version;
  /** Reads and enables pagination through a set of `Catalog`. */
  readonly catalogsByActiveVersionConnection: CatalogsConnection;
  /** Reads and enables pagination through a set of `Catalog`. */
  readonly catalogsByActiveVersion: ReadonlyArray<Catalog>;
};


/** A `Version` edge in the connection, with data from `Catalog`. */
export type AccountVersionsByCatalogAccountAndActiveVersionManyToManyEdgeCatalogsByActiveVersionConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CatalogsOrderBy>>;
  condition?: Maybe<CatalogCondition>;
  filter?: Maybe<CatalogFilter>;
};


/** A `Version` edge in the connection, with data from `Catalog`. */
export type AccountVersionsByCatalogAccountAndActiveVersionManyToManyEdgeCatalogsByActiveVersionArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CatalogsOrderBy>>;
  condition?: Maybe<CatalogCondition>;
  filter?: Maybe<CatalogFilter>;
};

/** A connection to a list of `Account` values. */
export type AccountsConnection = {
  readonly __typename?: 'AccountsConnection';
  /** A list of `Account` objects. */
  readonly nodes: ReadonlyArray<Account>;
  /** A list of edges which contains the `Account` and cursor to aid in pagination. */
  readonly edges: ReadonlyArray<AccountsEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Account` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Account` edge in the connection. */
export type AccountsEdge = {
  readonly __typename?: 'AccountsEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Account` at the end of the edge. */
  readonly node: Account;
};

/** Methods to use when ordering `Account`. */
export enum AccountsOrderBy {
  Natural = 'NATURAL',
  OidAsc = 'OID_ASC',
  OidDesc = 'OID_DESC',
  OptionsAsc = 'OPTIONS_ASC',
  OptionsDesc = 'OPTIONS_DESC',
  ImageUrlAsc = 'IMAGE_URL_ASC',
  ImageUrlDesc = 'IMAGE_URL_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  PhoneAsc = 'PHONE_ASC',
  PhoneDesc = 'PHONE_DESC',
  StreetAsc = 'STREET_ASC',
  StreetDesc = 'STREET_DESC',
  CityAsc = 'CITY_ASC',
  CityDesc = 'CITY_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  ZipPostalAsc = 'ZIP_POSTAL_ASC',
  ZipPostalDesc = 'ZIP_POSTAL_DESC',
  CountryAsc = 'COUNTRY_ASC',
  CountryDesc = 'COUNTRY_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  CustomSmsNumberAsc = 'CUSTOM_SMS_NUMBER_ASC',
  CustomSmsNumberDesc = 'CUSTOM_SMS_NUMBER_DESC',
  LogoAsc = 'LOGO_ASC',
  LogoDesc = 'LOGO_DESC',
  ActiveAsc = 'ACTIVE_ASC',
  ActiveDesc = 'ACTIVE_DESC',
  LoginAsc = 'LOGIN_ASC',
  LoginDesc = 'LOGIN_DESC',
  CommentsAsc = 'COMMENTS_ASC',
  CommentsDesc = 'COMMENTS_DESC',
  DefaultTrackingCodeAsc = 'DEFAULT_TRACKING_CODE_ASC',
  DefaultTrackingCodeDesc = 'DEFAULT_TRACKING_CODE_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  CreatedOnAsc = 'CREATED_ON_ASC',
  CreatedOnDesc = 'CREATED_ON_DESC',
  HomePageUrlAsc = 'HOME_PAGE_URL_ASC',
  HomePageUrlDesc = 'HOME_PAGE_URL_DESC',
  LegacyIdAsc = 'LEGACY_ID_ASC',
  LegacyIdDesc = 'LEGACY_ID_DESC',
  OptimisticLockFieldAsc = 'OPTIMISTIC_LOCK_FIELD_ASC',
  OptimisticLockFieldDesc = 'OPTIMISTIC_LOCK_FIELD_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type AuthorizedUser = {
  readonly __typename?: 'AuthorizedUser';
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly faceBookId?: Maybe<Scalars['String']>;
  readonly googleId?: Maybe<Scalars['String']>;
  readonly linkedInId?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  /** Reads a single `Profile` that is related to this `AuthorizedUser`. */
  readonly profileByEmail?: Maybe<Profile>;
  /** Reads and enables pagination through a set of `AuthorizedUserAuthorizedUsersAccountPartnerAccess`. */
  readonly authorizedUserAuthorizedUsersAccountPartnerAccessesByAuthorizedUsersConnection: AuthorizedUserAuthorizedUsersAccountPartnerAccessesConnection;
  /** Reads and enables pagination through a set of `AuthorizedUserAuthorizedUsersAccountPartnerAccess`. */
  readonly authorizedUserAuthorizedUsersAccountPartnerAccessesByAuthorizedUsers: ReadonlyArray<AuthorizedUserAuthorizedUsersAccountPartnerAccess>;
  /** Reads and enables pagination through a set of `Account`. */
  readonly accountsByAuthorizedUserAuthorizedUsersAccountPartnerAccessAuthorizedUsersAndPartnerAccess: AuthorizedUserAccountsByAuthorizedUserAuthorizedUsersAccountPartnerAccessAuthorizedUsersAndPartnerAccessManyToManyConnection;
  /** Reads and enables pagination through a set of `Account`. */
  readonly accountsByAuthorizedUserAuthorizedUsersAccountPartnerAccessAuthorizedUsersAndPartnerAccessList: ReadonlyArray<Account>;
};


export type AuthorizedUserAuthorizedUserAuthorizedUsersAccountPartnerAccessesByAuthorizedUsersConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<AuthorizedUserAuthorizedUsersAccountPartnerAccessesOrderBy>>;
  condition?: Maybe<AuthorizedUserAuthorizedUsersAccountPartnerAccessCondition>;
  filter?: Maybe<AuthorizedUserAuthorizedUsersAccountPartnerAccessFilter>;
};


export type AuthorizedUserAuthorizedUserAuthorizedUsersAccountPartnerAccessesByAuthorizedUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<AuthorizedUserAuthorizedUsersAccountPartnerAccessesOrderBy>>;
  condition?: Maybe<AuthorizedUserAuthorizedUsersAccountPartnerAccessCondition>;
  filter?: Maybe<AuthorizedUserAuthorizedUsersAccountPartnerAccessFilter>;
};


export type AuthorizedUserAccountsByAuthorizedUserAuthorizedUsersAccountPartnerAccessAuthorizedUsersAndPartnerAccessArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<AccountsOrderBy>>;
  condition?: Maybe<AccountCondition>;
  filter?: Maybe<AccountFilter>;
};


export type AuthorizedUserAccountsByAuthorizedUserAuthorizedUsersAccountPartnerAccessAuthorizedUsersAndPartnerAccessListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<AccountsOrderBy>>;
  condition?: Maybe<AccountCondition>;
  filter?: Maybe<AccountFilter>;
};

/** A connection to a list of `Account` values, with data from `AuthorizedUserAuthorizedUsersAccountPartnerAccess`. */
export type AuthorizedUserAccountsByAuthorizedUserAuthorizedUsersAccountPartnerAccessAuthorizedUsersAndPartnerAccessManyToManyConnection = {
  readonly __typename?: 'AuthorizedUserAccountsByAuthorizedUserAuthorizedUsersAccountPartnerAccessAuthorizedUsersAndPartnerAccessManyToManyConnection';
  /** A list of `Account` objects. */
  readonly nodes: ReadonlyArray<Account>;
  /** A list of edges which contains the `Account`, info from the `AuthorizedUserAuthorizedUsersAccountPartnerAccess`, and the cursor to aid in pagination. */
  readonly edges: ReadonlyArray<AuthorizedUserAccountsByAuthorizedUserAuthorizedUsersAccountPartnerAccessAuthorizedUsersAndPartnerAccessManyToManyEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Account` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Account` edge in the connection, with data from `AuthorizedUserAuthorizedUsersAccountPartnerAccess`. */
export type AuthorizedUserAccountsByAuthorizedUserAuthorizedUsersAccountPartnerAccessAuthorizedUsersAndPartnerAccessManyToManyEdge = {
  readonly __typename?: 'AuthorizedUserAccountsByAuthorizedUserAuthorizedUsersAccountPartnerAccessAuthorizedUsersAndPartnerAccessManyToManyEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Account` at the end of the edge. */
  readonly node: Account;
  /** Reads and enables pagination through a set of `AuthorizedUserAuthorizedUsersAccountPartnerAccess`. */
  readonly authorizedUserAuthorizedUsersAccountPartnerAccessesByPartnerAccessConnection: AuthorizedUserAuthorizedUsersAccountPartnerAccessesConnection;
  /** Reads and enables pagination through a set of `AuthorizedUserAuthorizedUsersAccountPartnerAccess`. */
  readonly authorizedUserAuthorizedUsersAccountPartnerAccessesByPartnerAccess: ReadonlyArray<AuthorizedUserAuthorizedUsersAccountPartnerAccess>;
};


/** A `Account` edge in the connection, with data from `AuthorizedUserAuthorizedUsersAccountPartnerAccess`. */
export type AuthorizedUserAccountsByAuthorizedUserAuthorizedUsersAccountPartnerAccessAuthorizedUsersAndPartnerAccessManyToManyEdgeAuthorizedUserAuthorizedUsersAccountPartnerAccessesByPartnerAccessConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<AuthorizedUserAuthorizedUsersAccountPartnerAccessesOrderBy>>;
  condition?: Maybe<AuthorizedUserAuthorizedUsersAccountPartnerAccessCondition>;
  filter?: Maybe<AuthorizedUserAuthorizedUsersAccountPartnerAccessFilter>;
};


/** A `Account` edge in the connection, with data from `AuthorizedUserAuthorizedUsersAccountPartnerAccess`. */
export type AuthorizedUserAccountsByAuthorizedUserAuthorizedUsersAccountPartnerAccessAuthorizedUsersAndPartnerAccessManyToManyEdgeAuthorizedUserAuthorizedUsersAccountPartnerAccessesByPartnerAccessArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<AuthorizedUserAuthorizedUsersAccountPartnerAccessesOrderBy>>;
  condition?: Maybe<AuthorizedUserAuthorizedUsersAccountPartnerAccessCondition>;
  filter?: Maybe<AuthorizedUserAuthorizedUsersAccountPartnerAccessFilter>;
};

/** The fields on `authorizedUser` to look up the row to connect. */
export type AuthorizedUserAuthorizedUserByEmailConnect = {
  readonly email: Scalars['String'];
};

/** The fields on `authorizedUser` to look up the row to delete. */
export type AuthorizedUserAuthorizedUserByEmailDelete = {
  readonly email: Scalars['String'];
};

export type AuthorizedUserAuthorizedUsersAccountPartnerAccess = {
  readonly __typename?: 'AuthorizedUserAuthorizedUsersAccountPartnerAccess';
  readonly partnerAccess?: Maybe<Scalars['UUID']>;
  readonly authorizedUsers?: Maybe<Scalars['UUID']>;
  readonly oid: Scalars['UUID'];
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  /** Reads a single `Account` that is related to this `AuthorizedUserAuthorizedUsersAccountPartnerAccess`. */
  readonly accountByPartnerAccess?: Maybe<Account>;
  /** Reads a single `AuthorizedUser` that is related to this `AuthorizedUserAuthorizedUsersAccountPartnerAccess`. */
  readonly authorizedUserByAuthorizedUsers?: Maybe<AuthorizedUser>;
};

/** A condition to be used against `AuthorizedUserAuthorizedUsersAccountPartnerAccess` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type AuthorizedUserAuthorizedUsersAccountPartnerAccessCondition = {
  /** Checks for equality with the object’s `partnerAccess` field. */
  readonly partnerAccess?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `authorizedUsers` field. */
  readonly authorizedUsers?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `oid` field. */
  readonly oid?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `AuthorizedUserAuthorizedUsersAccountPartnerAccess` object types. All fields are combined with a logical ‘and.’ */
export type AuthorizedUserAuthorizedUsersAccountPartnerAccessFilter = {
  /** Filter by the object’s `partnerAccess` field. */
  readonly partnerAccess?: Maybe<UuidFilter>;
  /** Filter by the object’s `authorizedUsers` field. */
  readonly authorizedUsers?: Maybe<UuidFilter>;
  /** Filter by the object’s `oid` field. */
  readonly oid?: Maybe<UuidFilter>;
  /** Filter by the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<IntFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<ReadonlyArray<AuthorizedUserAuthorizedUsersAccountPartnerAccessFilter>>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<ReadonlyArray<AuthorizedUserAuthorizedUsersAccountPartnerAccessFilter>>;
  /** Negates the expression. */
  readonly not?: Maybe<AuthorizedUserAuthorizedUsersAccountPartnerAccessFilter>;
};

/** An input for mutations affecting `AuthorizedUserAuthorizedUsersAccountPartnerAccess` */
export type AuthorizedUserAuthorizedUsersAccountPartnerAccessInput = {
  readonly partnerAccess?: Maybe<Scalars['UUID']>;
  readonly authorizedUsers?: Maybe<Scalars['UUID']>;
  readonly oid: Scalars['UUID'];
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly accountToPartnerAccess?: Maybe<FkAuthorizedUserAuthorizedUsersAccountPartnerAccess4E22214EInput>;
  readonly authorizedUserToAuthorizedUsers?: Maybe<FkAuthorizedUserAuthorizedUsersAccountPartnerAccessB343B2CfInput>;
};

/** The fields on `authorizedUserAuthorizedUsersAccountPartnerAccess` to look up the row to update. */
export type AuthorizedUserAuthorizedUsersAccountPartnerAccessOnAuthorizedUserAuthorizedUsersAccountPartnerAccessForFkAuthorizedUserAuthorizedUsersAccountPartnerAccess4E22214EUsingPkAuthorizedUserAuthorizedUsersAccountPartnerAccessUpdate = {
  /** An object where the defined keys will be set on the `authorizedUserAuthorizedUsersAccountPartnerAccess` being updated. */
  readonly patch: UpdateAuthorizedUserAuthorizedUsersAccountPartnerAccessOnAuthorizedUserAuthorizedUsersAccountPartnerAccessForFkAuthorizedUserAuthorizedUsersAccountPartnerAccess4E22214EPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `authorizedUserAuthorizedUsersAccountPartnerAccess` to look up the row to update. */
export type AuthorizedUserAuthorizedUsersAccountPartnerAccessOnAuthorizedUserAuthorizedUsersAccountPartnerAccessForFkAuthorizedUserAuthorizedUsersAccountPartnerAccessB343B2CfUsingPkAuthorizedUserAuthorizedUsersAccountPartnerAccessUpdate = {
  /** An object where the defined keys will be set on the `authorizedUserAuthorizedUsersAccountPartnerAccess` being updated. */
  readonly patch: UpdateAuthorizedUserAuthorizedUsersAccountPartnerAccessOnAuthorizedUserAuthorizedUsersAccountPartnerAccessForFkAuthorizedUserAuthorizedUsersAccountPartnerAccessB343B2CfPatch;
  readonly oid: Scalars['UUID'];
};

/** Represents an update to a `AuthorizedUserAuthorizedUsersAccountPartnerAccess`. Fields that are set will be updated. */
export type AuthorizedUserAuthorizedUsersAccountPartnerAccessPatch = {
  readonly partnerAccess?: Maybe<Scalars['UUID']>;
  readonly authorizedUsers?: Maybe<Scalars['UUID']>;
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly accountToPartnerAccess?: Maybe<FkAuthorizedUserAuthorizedUsersAccountPartnerAccess4E22214EInput>;
  readonly authorizedUserToAuthorizedUsers?: Maybe<FkAuthorizedUserAuthorizedUsersAccountPartnerAccessB343B2CfInput>;
};

/** The fields on `authorizedUserAuthorizedUsersAccountPartnerAccess` to look up the row to connect. */
export type AuthorizedUserAuthorizedUsersAccountPartnerAccessPkAuthorizedUserAuthorizedUsersAccountPartnerAccessConnect = {
  readonly oid: Scalars['UUID'];
};

/** The fields on `authorizedUserAuthorizedUsersAccountPartnerAccess` to look up the row to delete. */
export type AuthorizedUserAuthorizedUsersAccountPartnerAccessPkAuthorizedUserAuthorizedUsersAccountPartnerAccessDelete = {
  readonly oid: Scalars['UUID'];
};

/** A connection to a list of `AuthorizedUserAuthorizedUsersAccountPartnerAccess` values. */
export type AuthorizedUserAuthorizedUsersAccountPartnerAccessesConnection = {
  readonly __typename?: 'AuthorizedUserAuthorizedUsersAccountPartnerAccessesConnection';
  /** A list of `AuthorizedUserAuthorizedUsersAccountPartnerAccess` objects. */
  readonly nodes: ReadonlyArray<AuthorizedUserAuthorizedUsersAccountPartnerAccess>;
  /** A list of edges which contains the `AuthorizedUserAuthorizedUsersAccountPartnerAccess` and cursor to aid in pagination. */
  readonly edges: ReadonlyArray<AuthorizedUserAuthorizedUsersAccountPartnerAccessesEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `AuthorizedUserAuthorizedUsersAccountPartnerAccess` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `AuthorizedUserAuthorizedUsersAccountPartnerAccess` edge in the connection. */
export type AuthorizedUserAuthorizedUsersAccountPartnerAccessesEdge = {
  readonly __typename?: 'AuthorizedUserAuthorizedUsersAccountPartnerAccessesEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `AuthorizedUserAuthorizedUsersAccountPartnerAccess` at the end of the edge. */
  readonly node: AuthorizedUserAuthorizedUsersAccountPartnerAccess;
};

/** Methods to use when ordering `AuthorizedUserAuthorizedUsersAccountPartnerAccess`. */
export enum AuthorizedUserAuthorizedUsersAccountPartnerAccessesOrderBy {
  Natural = 'NATURAL',
  PartnerAccessAsc = 'PARTNER_ACCESS_ASC',
  PartnerAccessDesc = 'PARTNER_ACCESS_DESC',
  AuthorizedUsersAsc = 'AUTHORIZED_USERS_ASC',
  AuthorizedUsersDesc = 'AUTHORIZED_USERS_DESC',
  OidAsc = 'OID_ASC',
  OidDesc = 'OID_DESC',
  OptimisticLockFieldAsc = 'OPTIMISTIC_LOCK_FIELD_ASC',
  OptimisticLockFieldDesc = 'OPTIMISTIC_LOCK_FIELD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A condition to be used against `AuthorizedUser` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type AuthorizedUserCondition = {
  /** Checks for equality with the object’s `oid` field. */
  readonly oid?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `options` field. */
  readonly options?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `email` field. */
  readonly email?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `faceBookId` field. */
  readonly faceBookId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `googleId` field. */
  readonly googleId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `linkedInId` field. */
  readonly linkedInId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `AuthorizedUser` object types. All fields are combined with a logical ‘and.’ */
export type AuthorizedUserFilter = {
  /** Filter by the object’s `oid` field. */
  readonly oid?: Maybe<UuidFilter>;
  /** Filter by the object’s `email` field. */
  readonly email?: Maybe<StringFilter>;
  /** Filter by the object’s `faceBookId` field. */
  readonly faceBookId?: Maybe<StringFilter>;
  /** Filter by the object’s `googleId` field. */
  readonly googleId?: Maybe<StringFilter>;
  /** Filter by the object’s `linkedInId` field. */
  readonly linkedInId?: Maybe<StringFilter>;
  /** Filter by the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<IntFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<ReadonlyArray<AuthorizedUserFilter>>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<ReadonlyArray<AuthorizedUserFilter>>;
  /** Negates the expression. */
  readonly not?: Maybe<AuthorizedUserFilter>;
};

/** An input for mutations affecting `AuthorizedUser` */
export type AuthorizedUserInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly faceBookId?: Maybe<Scalars['String']>;
  readonly googleId?: Maybe<Scalars['String']>;
  readonly linkedInId?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly profileToEmail?: Maybe<FakePublicAuthorizedUserForeignKey0Input>;
  readonly authorizedUserAuthorizedUsersAccountPartnerAccessesUsingOid?: Maybe<FkAuthorizedUserAuthorizedUsersAccountPartnerAccessB343B2CfInverseInput>;
};

/** The fields on `authorizedUser` to look up the row to update. */
export type AuthorizedUserOnAuthorizedUserAuthorizedUsersAccountPartnerAccessForFkAuthorizedUserAuthorizedUsersAccountPartnerAccessB343B2CfUsingAuthorizedUserByEmailUpdate = {
  /** An object where the defined keys will be set on the `authorizedUser` being updated. */
  readonly patch: UpdateAuthorizedUserOnAuthorizedUserAuthorizedUsersAccountPartnerAccessForFkAuthorizedUserAuthorizedUsersAccountPartnerAccessB343B2CfPatch;
  readonly email: Scalars['String'];
};

/** The fields on `authorizedUser` to look up the row to update. */
export type AuthorizedUserOnAuthorizedUserAuthorizedUsersAccountPartnerAccessForFkAuthorizedUserAuthorizedUsersAccountPartnerAccessB343B2CfUsingPkAuthorizedUserUpdate = {
  /** An object where the defined keys will be set on the `authorizedUser` being updated. */
  readonly patch: UpdateAuthorizedUserOnAuthorizedUserAuthorizedUsersAccountPartnerAccessForFkAuthorizedUserAuthorizedUsersAccountPartnerAccessB343B2CfPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `authorizedUser` to look up the row to update. */
export type AuthorizedUserOnAuthorizedUserForFakePublicAuthorizedUserForeignKey0UsingAuthorizedUserByEmailUpdate = {
  /** An object where the defined keys will be set on the `authorizedUser` being updated. */
  readonly patch: UpdateAuthorizedUserOnAuthorizedUserForFakePublicAuthorizedUserForeignKey0Patch;
  readonly email: Scalars['String'];
};

/** The fields on `authorizedUser` to look up the row to update. */
export type AuthorizedUserOnAuthorizedUserForFakePublicAuthorizedUserForeignKey0UsingPkAuthorizedUserUpdate = {
  /** An object where the defined keys will be set on the `authorizedUser` being updated. */
  readonly patch: UpdateAuthorizedUserOnAuthorizedUserForFakePublicAuthorizedUserForeignKey0Patch;
  readonly oid: Scalars['UUID'];
};

/** Represents an update to a `AuthorizedUser`. Fields that are set will be updated. */
export type AuthorizedUserPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly faceBookId?: Maybe<Scalars['String']>;
  readonly googleId?: Maybe<Scalars['String']>;
  readonly linkedInId?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly profileToEmail?: Maybe<FakePublicAuthorizedUserForeignKey0Input>;
  readonly authorizedUserAuthorizedUsersAccountPartnerAccessesUsingOid?: Maybe<FkAuthorizedUserAuthorizedUsersAccountPartnerAccessB343B2CfInverseInput>;
};

/** The fields on `authorizedUser` to look up the row to connect. */
export type AuthorizedUserPkAuthorizedUserConnect = {
  readonly oid: Scalars['UUID'];
};

/** The fields on `authorizedUser` to look up the row to delete. */
export type AuthorizedUserPkAuthorizedUserDelete = {
  readonly oid: Scalars['UUID'];
};

/** A connection to a list of `AuthorizedUser` values. */
export type AuthorizedUsersConnection = {
  readonly __typename?: 'AuthorizedUsersConnection';
  /** A list of `AuthorizedUser` objects. */
  readonly nodes: ReadonlyArray<AuthorizedUser>;
  /** A list of edges which contains the `AuthorizedUser` and cursor to aid in pagination. */
  readonly edges: ReadonlyArray<AuthorizedUsersEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `AuthorizedUser` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `AuthorizedUser` edge in the connection. */
export type AuthorizedUsersEdge = {
  readonly __typename?: 'AuthorizedUsersEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `AuthorizedUser` at the end of the edge. */
  readonly node: AuthorizedUser;
};

/** Methods to use when ordering `AuthorizedUser`. */
export enum AuthorizedUsersOrderBy {
  Natural = 'NATURAL',
  OidAsc = 'OID_ASC',
  OidDesc = 'OID_DESC',
  OptionsAsc = 'OPTIONS_ASC',
  OptionsDesc = 'OPTIONS_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  FaceBookIdAsc = 'FACE_BOOK_ID_ASC',
  FaceBookIdDesc = 'FACE_BOOK_ID_DESC',
  GoogleIdAsc = 'GOOGLE_ID_ASC',
  GoogleIdDesc = 'GOOGLE_ID_DESC',
  LinkedInIdAsc = 'LINKED_IN_ID_ASC',
  LinkedInIdDesc = 'LINKED_IN_ID_DESC',
  OptimisticLockFieldAsc = 'OPTIMISTIC_LOCK_FIELD_ASC',
  OptimisticLockFieldDesc = 'OPTIMISTIC_LOCK_FIELD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}


/** A filter to be used against BigInt fields. All fields are combined with a logical ‘and.’ */
export type BigIntFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  readonly isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  readonly equalTo?: Maybe<Scalars['BigInt']>;
  /** Not equal to the specified value. */
  readonly notEqualTo?: Maybe<Scalars['BigInt']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  readonly distinctFrom?: Maybe<Scalars['BigInt']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  readonly notDistinctFrom?: Maybe<Scalars['BigInt']>;
  /** Included in the specified list. */
  readonly in?: Maybe<ReadonlyArray<Scalars['BigInt']>>;
  /** Not included in the specified list. */
  readonly notIn?: Maybe<ReadonlyArray<Scalars['BigInt']>>;
  /** Less than the specified value. */
  readonly lessThan?: Maybe<Scalars['BigInt']>;
  /** Less than or equal to the specified value. */
  readonly lessThanOrEqualTo?: Maybe<Scalars['BigInt']>;
  /** Greater than the specified value. */
  readonly greaterThan?: Maybe<Scalars['BigInt']>;
  /** Greater than or equal to the specified value. */
  readonly greaterThanOrEqualTo?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  readonly isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  readonly equalTo?: Maybe<Scalars['Boolean']>;
  /** Not equal to the specified value. */
  readonly notEqualTo?: Maybe<Scalars['Boolean']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  readonly distinctFrom?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  readonly notDistinctFrom?: Maybe<Scalars['Boolean']>;
  /** Included in the specified list. */
  readonly in?: Maybe<ReadonlyArray<Scalars['Boolean']>>;
  /** Not included in the specified list. */
  readonly notIn?: Maybe<ReadonlyArray<Scalars['Boolean']>>;
  /** Less than the specified value. */
  readonly lessThan?: Maybe<Scalars['Boolean']>;
  /** Less than or equal to the specified value. */
  readonly lessThanOrEqualTo?: Maybe<Scalars['Boolean']>;
  /** Greater than the specified value. */
  readonly greaterThan?: Maybe<Scalars['Boolean']>;
  /** Greater than or equal to the specified value. */
  readonly greaterThanOrEqualTo?: Maybe<Scalars['Boolean']>;
};

export type Campaign = {
  readonly __typename?: 'Campaign';
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly sender?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly distributionDate?: Maybe<Scalars['String']>;
  readonly quantity?: Maybe<Scalars['Int']>;
  readonly terms?: Maybe<Scalars['String']>;
  readonly rate?: Maybe<Scalars['Float']>;
  readonly totalCost?: Maybe<Scalars['String']>;
  readonly vendor?: Maybe<Scalars['String']>;
  readonly opens?: Maybe<Scalars['Int']>;
  readonly clicks?: Maybe<Scalars['Int']>;
  readonly dynalogViews?: Maybe<Scalars['Int']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  /** Reads a single `Account` that is related to this `Campaign`. */
  readonly accountByAccount?: Maybe<Account>;
  /** Reads and enables pagination through a set of `CampaignGroup`. */
  readonly campaignGroupsByCampaignConnection: CampaignGroupsConnection;
  /** Reads and enables pagination through a set of `CampaignGroup`. */
  readonly campaignGroupsByCampaign: ReadonlyArray<CampaignGroup>;
  /** Reads and enables pagination through a set of `Catalog`. */
  readonly catalogsByCampaignGroupCampaignAndCatalog: CampaignCatalogsByCampaignGroupCampaignAndCatalogManyToManyConnection;
  /** Reads and enables pagination through a set of `Catalog`. */
  readonly catalogsByCampaignGroupCampaignAndCatalogList: ReadonlyArray<Catalog>;
};


export type CampaignCampaignGroupsByCampaignConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CampaignGroupsOrderBy>>;
  condition?: Maybe<CampaignGroupCondition>;
  filter?: Maybe<CampaignGroupFilter>;
};


export type CampaignCampaignGroupsByCampaignArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CampaignGroupsOrderBy>>;
  condition?: Maybe<CampaignGroupCondition>;
  filter?: Maybe<CampaignGroupFilter>;
};


export type CampaignCatalogsByCampaignGroupCampaignAndCatalogArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CatalogsOrderBy>>;
  condition?: Maybe<CatalogCondition>;
  filter?: Maybe<CatalogFilter>;
};


export type CampaignCatalogsByCampaignGroupCampaignAndCatalogListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CatalogsOrderBy>>;
  condition?: Maybe<CatalogCondition>;
  filter?: Maybe<CatalogFilter>;
};

/** A connection to a list of `Catalog` values, with data from `CampaignGroup`. */
export type CampaignCatalogsByCampaignGroupCampaignAndCatalogManyToManyConnection = {
  readonly __typename?: 'CampaignCatalogsByCampaignGroupCampaignAndCatalogManyToManyConnection';
  /** A list of `Catalog` objects. */
  readonly nodes: ReadonlyArray<Catalog>;
  /** A list of edges which contains the `Catalog`, info from the `CampaignGroup`, and the cursor to aid in pagination. */
  readonly edges: ReadonlyArray<CampaignCatalogsByCampaignGroupCampaignAndCatalogManyToManyEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Catalog` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Catalog` edge in the connection, with data from `CampaignGroup`. */
export type CampaignCatalogsByCampaignGroupCampaignAndCatalogManyToManyEdge = {
  readonly __typename?: 'CampaignCatalogsByCampaignGroupCampaignAndCatalogManyToManyEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Catalog` at the end of the edge. */
  readonly node: Catalog;
  /** Reads and enables pagination through a set of `CampaignGroup`. */
  readonly campaignGroupsByCatalogConnection: CampaignGroupsConnection;
  /** Reads and enables pagination through a set of `CampaignGroup`. */
  readonly campaignGroupsByCatalog: ReadonlyArray<CampaignGroup>;
};


/** A `Catalog` edge in the connection, with data from `CampaignGroup`. */
export type CampaignCatalogsByCampaignGroupCampaignAndCatalogManyToManyEdgeCampaignGroupsByCatalogConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CampaignGroupsOrderBy>>;
  condition?: Maybe<CampaignGroupCondition>;
  filter?: Maybe<CampaignGroupFilter>;
};


/** A `Catalog` edge in the connection, with data from `CampaignGroup`. */
export type CampaignCatalogsByCampaignGroupCampaignAndCatalogManyToManyEdgeCampaignGroupsByCatalogArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CampaignGroupsOrderBy>>;
  condition?: Maybe<CampaignGroupCondition>;
  filter?: Maybe<CampaignGroupFilter>;
};

/** A condition to be used against `Campaign` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type CampaignCondition = {
  /** Checks for equality with the object’s `oid` field. */
  readonly oid?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `options` field. */
  readonly options?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `account` field. */
  readonly account?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `sender` field. */
  readonly sender?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `title` field. */
  readonly title?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `distributionDate` field. */
  readonly distributionDate?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `quantity` field. */
  readonly quantity?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `terms` field. */
  readonly terms?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `rate` field. */
  readonly rate?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `totalCost` field. */
  readonly totalCost?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `vendor` field. */
  readonly vendor?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `opens` field. */
  readonly opens?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `clicks` field. */
  readonly clicks?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `dynalogViews` field. */
  readonly dynalogViews?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `Campaign` object types. All fields are combined with a logical ‘and.’ */
export type CampaignFilter = {
  /** Filter by the object’s `oid` field. */
  readonly oid?: Maybe<UuidFilter>;
  /** Filter by the object’s `account` field. */
  readonly account?: Maybe<UuidFilter>;
  /** Filter by the object’s `sender` field. */
  readonly sender?: Maybe<StringFilter>;
  /** Filter by the object’s `title` field. */
  readonly title?: Maybe<StringFilter>;
  /** Filter by the object’s `distributionDate` field. */
  readonly distributionDate?: Maybe<StringFilter>;
  /** Filter by the object’s `quantity` field. */
  readonly quantity?: Maybe<IntFilter>;
  /** Filter by the object’s `terms` field. */
  readonly terms?: Maybe<StringFilter>;
  /** Filter by the object’s `rate` field. */
  readonly rate?: Maybe<FloatFilter>;
  /** Filter by the object’s `totalCost` field. */
  readonly totalCost?: Maybe<StringFilter>;
  /** Filter by the object’s `vendor` field. */
  readonly vendor?: Maybe<StringFilter>;
  /** Filter by the object’s `opens` field. */
  readonly opens?: Maybe<IntFilter>;
  /** Filter by the object’s `clicks` field. */
  readonly clicks?: Maybe<IntFilter>;
  /** Filter by the object’s `dynalogViews` field. */
  readonly dynalogViews?: Maybe<IntFilter>;
  /** Filter by the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<IntFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<ReadonlyArray<CampaignFilter>>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<ReadonlyArray<CampaignFilter>>;
  /** Negates the expression. */
  readonly not?: Maybe<CampaignFilter>;
};

export type CampaignGroup = {
  readonly __typename?: 'CampaignGroup';
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly campaign?: Maybe<Scalars['UUID']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  /** Reads a single `Campaign` that is related to this `CampaignGroup`. */
  readonly campaignByCampaign?: Maybe<Campaign>;
  /** Reads a single `Catalog` that is related to this `CampaignGroup`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
  /** Reads and enables pagination through a set of `CampaignUser`. */
  readonly campaignUsersByCampaignGroupConnection: CampaignUsersConnection;
  /** Reads and enables pagination through a set of `CampaignUser`. */
  readonly campaignUsersByCampaignGroup: ReadonlyArray<CampaignUser>;
  /** Reads and enables pagination through a set of `Lead`. */
  readonly leadsByCampaignUserCampaignGroupAndLead: CampaignGroupLeadsByCampaignUserCampaignGroupAndLeadManyToManyConnection;
  /** Reads and enables pagination through a set of `Lead`. */
  readonly leadsByCampaignUserCampaignGroupAndLeadList: ReadonlyArray<Lead>;
};


export type CampaignGroupCampaignUsersByCampaignGroupConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CampaignUsersOrderBy>>;
  condition?: Maybe<CampaignUserCondition>;
  filter?: Maybe<CampaignUserFilter>;
};


export type CampaignGroupCampaignUsersByCampaignGroupArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CampaignUsersOrderBy>>;
  condition?: Maybe<CampaignUserCondition>;
  filter?: Maybe<CampaignUserFilter>;
};


export type CampaignGroupLeadsByCampaignUserCampaignGroupAndLeadArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};


export type CampaignGroupLeadsByCampaignUserCampaignGroupAndLeadListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};

/** A condition to be used against `CampaignGroup` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type CampaignGroupCondition = {
  /** Checks for equality with the object’s `oid` field. */
  readonly oid?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `options` field. */
  readonly options?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `id` field. */
  readonly id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `slug` field. */
  readonly slug?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  readonly name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `campaign` field. */
  readonly campaign?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `catalog` field. */
  readonly catalog?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `targetUrl` field. */
  readonly targetUrl?: Maybe<Scalars['String']>;
};

/** A filter to be used against `CampaignGroup` object types. All fields are combined with a logical ‘and.’ */
export type CampaignGroupFilter = {
  /** Filter by the object’s `oid` field. */
  readonly oid?: Maybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  readonly id?: Maybe<IntFilter>;
  /** Filter by the object’s `slug` field. */
  readonly slug?: Maybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  readonly name?: Maybe<StringFilter>;
  /** Filter by the object’s `campaign` field. */
  readonly campaign?: Maybe<UuidFilter>;
  /** Filter by the object’s `catalog` field. */
  readonly catalog?: Maybe<UuidFilter>;
  /** Filter by the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<IntFilter>;
  /** Filter by the object’s `targetUrl` field. */
  readonly targetUrl?: Maybe<StringFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<ReadonlyArray<CampaignGroupFilter>>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<ReadonlyArray<CampaignGroupFilter>>;
  /** Negates the expression. */
  readonly not?: Maybe<CampaignGroupFilter>;
};

/** An input for mutations affecting `CampaignGroup` */
export type CampaignGroupInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly campaign?: Maybe<Scalars['UUID']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly campaignToCampaign?: Maybe<FkCampaignGroupCampaignInput>;
  readonly catalogToCatalog?: Maybe<FkCampaignGroupCatalogInput>;
  readonly campaignUsersUsingOid?: Maybe<FkCampaignUserCampaignGroupInverseInput>;
};

/** A connection to a list of `Lead` values, with data from `CampaignUser`. */
export type CampaignGroupLeadsByCampaignUserCampaignGroupAndLeadManyToManyConnection = {
  readonly __typename?: 'CampaignGroupLeadsByCampaignUserCampaignGroupAndLeadManyToManyConnection';
  /** A list of `Lead` objects. */
  readonly nodes: ReadonlyArray<Lead>;
  /** A list of edges which contains the `Lead`, info from the `CampaignUser`, and the cursor to aid in pagination. */
  readonly edges: ReadonlyArray<CampaignGroupLeadsByCampaignUserCampaignGroupAndLeadManyToManyEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Lead` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Lead` edge in the connection, with data from `CampaignUser`. */
export type CampaignGroupLeadsByCampaignUserCampaignGroupAndLeadManyToManyEdge = {
  readonly __typename?: 'CampaignGroupLeadsByCampaignUserCampaignGroupAndLeadManyToManyEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Lead` at the end of the edge. */
  readonly node: Lead;
  /** Reads and enables pagination through a set of `CampaignUser`. */
  readonly campaignUsersByLeadConnection: CampaignUsersConnection;
  /** Reads and enables pagination through a set of `CampaignUser`. */
  readonly campaignUsersByLead: ReadonlyArray<CampaignUser>;
};


/** A `Lead` edge in the connection, with data from `CampaignUser`. */
export type CampaignGroupLeadsByCampaignUserCampaignGroupAndLeadManyToManyEdgeCampaignUsersByLeadConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CampaignUsersOrderBy>>;
  condition?: Maybe<CampaignUserCondition>;
  filter?: Maybe<CampaignUserFilter>;
};


/** A `Lead` edge in the connection, with data from `CampaignUser`. */
export type CampaignGroupLeadsByCampaignUserCampaignGroupAndLeadManyToManyEdgeCampaignUsersByLeadArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CampaignUsersOrderBy>>;
  condition?: Maybe<CampaignUserCondition>;
  filter?: Maybe<CampaignUserFilter>;
};

/** The fields on `campaignGroup` to look up the row to update. */
export type CampaignGroupOnCampaignGroupForFkCampaignGroupCampaignUsingPkCampaignGroupUpdate = {
  /** An object where the defined keys will be set on the `campaignGroup` being updated. */
  readonly patch: UpdateCampaignGroupOnCampaignGroupForFkCampaignGroupCampaignPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `campaignGroup` to look up the row to update. */
export type CampaignGroupOnCampaignGroupForFkCampaignGroupCatalogUsingPkCampaignGroupUpdate = {
  /** An object where the defined keys will be set on the `campaignGroup` being updated. */
  readonly patch: UpdateCampaignGroupOnCampaignGroupForFkCampaignGroupCatalogPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `campaignGroup` to look up the row to update. */
export type CampaignGroupOnCampaignUserForFkCampaignUserCampaignGroupUsingPkCampaignGroupUpdate = {
  /** An object where the defined keys will be set on the `campaignGroup` being updated. */
  readonly patch: UpdateCampaignGroupOnCampaignUserForFkCampaignUserCampaignGroupPatch;
  readonly oid: Scalars['UUID'];
};

/** Represents an update to a `CampaignGroup`. Fields that are set will be updated. */
export type CampaignGroupPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly campaign?: Maybe<Scalars['UUID']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly campaignToCampaign?: Maybe<FkCampaignGroupCampaignInput>;
  readonly catalogToCatalog?: Maybe<FkCampaignGroupCatalogInput>;
  readonly campaignUsersUsingOid?: Maybe<FkCampaignUserCampaignGroupInverseInput>;
};

/** The fields on `campaignGroup` to look up the row to connect. */
export type CampaignGroupPkCampaignGroupConnect = {
  readonly oid: Scalars['UUID'];
};

/** The fields on `campaignGroup` to look up the row to delete. */
export type CampaignGroupPkCampaignGroupDelete = {
  readonly oid: Scalars['UUID'];
};

/** A connection to a list of `CampaignGroup` values. */
export type CampaignGroupsConnection = {
  readonly __typename?: 'CampaignGroupsConnection';
  /** A list of `CampaignGroup` objects. */
  readonly nodes: ReadonlyArray<CampaignGroup>;
  /** A list of edges which contains the `CampaignGroup` and cursor to aid in pagination. */
  readonly edges: ReadonlyArray<CampaignGroupsEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `CampaignGroup` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `CampaignGroup` edge in the connection. */
export type CampaignGroupsEdge = {
  readonly __typename?: 'CampaignGroupsEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `CampaignGroup` at the end of the edge. */
  readonly node: CampaignGroup;
};

/** Methods to use when ordering `CampaignGroup`. */
export enum CampaignGroupsOrderBy {
  Natural = 'NATURAL',
  OidAsc = 'OID_ASC',
  OidDesc = 'OID_DESC',
  OptionsAsc = 'OPTIONS_ASC',
  OptionsDesc = 'OPTIONS_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  CampaignAsc = 'CAMPAIGN_ASC',
  CampaignDesc = 'CAMPAIGN_DESC',
  CatalogAsc = 'CATALOG_ASC',
  CatalogDesc = 'CATALOG_DESC',
  OptimisticLockFieldAsc = 'OPTIMISTIC_LOCK_FIELD_ASC',
  OptimisticLockFieldDesc = 'OPTIMISTIC_LOCK_FIELD_DESC',
  TargetUrlAsc = 'TARGET_URL_ASC',
  TargetUrlDesc = 'TARGET_URL_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** An input for mutations affecting `Campaign` */
export type CampaignInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly sender?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly distributionDate?: Maybe<Scalars['String']>;
  readonly quantity?: Maybe<Scalars['Int']>;
  readonly terms?: Maybe<Scalars['String']>;
  readonly rate?: Maybe<Scalars['Float']>;
  readonly totalCost?: Maybe<Scalars['String']>;
  readonly vendor?: Maybe<Scalars['String']>;
  readonly opens?: Maybe<Scalars['Int']>;
  readonly clicks?: Maybe<Scalars['Int']>;
  readonly dynalogViews?: Maybe<Scalars['Int']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly accountToAccount?: Maybe<FkCampaignAccountInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCampaignInverseInput>;
};

/** The fields on `campaign` to look up the row to update. */
export type CampaignOnCampaignForFkCampaignAccountUsingPkCampaignUpdate = {
  /** An object where the defined keys will be set on the `campaign` being updated. */
  readonly patch: UpdateCampaignOnCampaignForFkCampaignAccountPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `campaign` to look up the row to update. */
export type CampaignOnCampaignGroupForFkCampaignGroupCampaignUsingPkCampaignUpdate = {
  /** An object where the defined keys will be set on the `campaign` being updated. */
  readonly patch: UpdateCampaignOnCampaignGroupForFkCampaignGroupCampaignPatch;
  readonly oid: Scalars['UUID'];
};

/** Represents an update to a `Campaign`. Fields that are set will be updated. */
export type CampaignPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly sender?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly distributionDate?: Maybe<Scalars['String']>;
  readonly quantity?: Maybe<Scalars['Int']>;
  readonly terms?: Maybe<Scalars['String']>;
  readonly rate?: Maybe<Scalars['Float']>;
  readonly totalCost?: Maybe<Scalars['String']>;
  readonly vendor?: Maybe<Scalars['String']>;
  readonly opens?: Maybe<Scalars['Int']>;
  readonly clicks?: Maybe<Scalars['Int']>;
  readonly dynalogViews?: Maybe<Scalars['Int']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly accountToAccount?: Maybe<FkCampaignAccountInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCampaignInverseInput>;
};

/** The fields on `campaign` to look up the row to connect. */
export type CampaignPkCampaignConnect = {
  readonly oid: Scalars['UUID'];
};

/** The fields on `campaign` to look up the row to delete. */
export type CampaignPkCampaignDelete = {
  readonly oid: Scalars['UUID'];
};

export type CampaignUser = {
  readonly __typename?: 'CampaignUser';
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly lead?: Maybe<Scalars['UUID']>;
  readonly campaignGroup?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  /** Reads a single `Lead` that is related to this `CampaignUser`. */
  readonly leadByLead?: Maybe<Lead>;
  /** Reads a single `CampaignGroup` that is related to this `CampaignUser`. */
  readonly campaignGroupByCampaignGroup?: Maybe<CampaignGroup>;
};

/** A condition to be used against `CampaignUser` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type CampaignUserCondition = {
  /** Checks for equality with the object’s `oid` field. */
  readonly oid?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `options` field. */
  readonly options?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `lead` field. */
  readonly lead?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `campaignGroup` field. */
  readonly campaignGroup?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `CampaignUser` object types. All fields are combined with a logical ‘and.’ */
export type CampaignUserFilter = {
  /** Filter by the object’s `oid` field. */
  readonly oid?: Maybe<UuidFilter>;
  /** Filter by the object’s `lead` field. */
  readonly lead?: Maybe<UuidFilter>;
  /** Filter by the object’s `campaignGroup` field. */
  readonly campaignGroup?: Maybe<UuidFilter>;
  /** Filter by the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<IntFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<ReadonlyArray<CampaignUserFilter>>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<ReadonlyArray<CampaignUserFilter>>;
  /** Negates the expression. */
  readonly not?: Maybe<CampaignUserFilter>;
};

/** An input for mutations affecting `CampaignUser` */
export type CampaignUserInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly lead?: Maybe<Scalars['UUID']>;
  readonly campaignGroup?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly leadToLead?: Maybe<FkCampaignUserLeadInput>;
  readonly campaignGroupToCampaignGroup?: Maybe<FkCampaignUserCampaignGroupInput>;
};

/** The fields on `campaignUser` to look up the row to update. */
export type CampaignUserOnCampaignUserForFkCampaignUserCampaignGroupUsingPkCampaignUserUpdate = {
  /** An object where the defined keys will be set on the `campaignUser` being updated. */
  readonly patch: UpdateCampaignUserOnCampaignUserForFkCampaignUserCampaignGroupPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `campaignUser` to look up the row to update. */
export type CampaignUserOnCampaignUserForFkCampaignUserLeadUsingPkCampaignUserUpdate = {
  /** An object where the defined keys will be set on the `campaignUser` being updated. */
  readonly patch: UpdateCampaignUserOnCampaignUserForFkCampaignUserLeadPatch;
  readonly oid: Scalars['UUID'];
};

/** Represents an update to a `CampaignUser`. Fields that are set will be updated. */
export type CampaignUserPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly lead?: Maybe<Scalars['UUID']>;
  readonly campaignGroup?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly leadToLead?: Maybe<FkCampaignUserLeadInput>;
  readonly campaignGroupToCampaignGroup?: Maybe<FkCampaignUserCampaignGroupInput>;
};

/** The fields on `campaignUser` to look up the row to connect. */
export type CampaignUserPkCampaignUserConnect = {
  readonly oid: Scalars['UUID'];
};

/** The fields on `campaignUser` to look up the row to delete. */
export type CampaignUserPkCampaignUserDelete = {
  readonly oid: Scalars['UUID'];
};

/** A connection to a list of `CampaignUser` values. */
export type CampaignUsersConnection = {
  readonly __typename?: 'CampaignUsersConnection';
  /** A list of `CampaignUser` objects. */
  readonly nodes: ReadonlyArray<CampaignUser>;
  /** A list of edges which contains the `CampaignUser` and cursor to aid in pagination. */
  readonly edges: ReadonlyArray<CampaignUsersEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `CampaignUser` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `CampaignUser` edge in the connection. */
export type CampaignUsersEdge = {
  readonly __typename?: 'CampaignUsersEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `CampaignUser` at the end of the edge. */
  readonly node: CampaignUser;
};

/** Methods to use when ordering `CampaignUser`. */
export enum CampaignUsersOrderBy {
  Natural = 'NATURAL',
  OidAsc = 'OID_ASC',
  OidDesc = 'OID_DESC',
  OptionsAsc = 'OPTIONS_ASC',
  OptionsDesc = 'OPTIONS_DESC',
  LeadAsc = 'LEAD_ASC',
  LeadDesc = 'LEAD_DESC',
  CampaignGroupAsc = 'CAMPAIGN_GROUP_ASC',
  CampaignGroupDesc = 'CAMPAIGN_GROUP_DESC',
  OptimisticLockFieldAsc = 'OPTIMISTIC_LOCK_FIELD_ASC',
  OptimisticLockFieldDesc = 'OPTIMISTIC_LOCK_FIELD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A connection to a list of `Campaign` values. */
export type CampaignsConnection = {
  readonly __typename?: 'CampaignsConnection';
  /** A list of `Campaign` objects. */
  readonly nodes: ReadonlyArray<Campaign>;
  /** A list of edges which contains the `Campaign` and cursor to aid in pagination. */
  readonly edges: ReadonlyArray<CampaignsEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Campaign` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Campaign` edge in the connection. */
export type CampaignsEdge = {
  readonly __typename?: 'CampaignsEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Campaign` at the end of the edge. */
  readonly node: Campaign;
};

/** Methods to use when ordering `Campaign`. */
export enum CampaignsOrderBy {
  Natural = 'NATURAL',
  OidAsc = 'OID_ASC',
  OidDesc = 'OID_DESC',
  OptionsAsc = 'OPTIONS_ASC',
  OptionsDesc = 'OPTIONS_DESC',
  AccountAsc = 'ACCOUNT_ASC',
  AccountDesc = 'ACCOUNT_DESC',
  SenderAsc = 'SENDER_ASC',
  SenderDesc = 'SENDER_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  DistributionDateAsc = 'DISTRIBUTION_DATE_ASC',
  DistributionDateDesc = 'DISTRIBUTION_DATE_DESC',
  QuantityAsc = 'QUANTITY_ASC',
  QuantityDesc = 'QUANTITY_DESC',
  TermsAsc = 'TERMS_ASC',
  TermsDesc = 'TERMS_DESC',
  RateAsc = 'RATE_ASC',
  RateDesc = 'RATE_DESC',
  TotalCostAsc = 'TOTAL_COST_ASC',
  TotalCostDesc = 'TOTAL_COST_DESC',
  VendorAsc = 'VENDOR_ASC',
  VendorDesc = 'VENDOR_DESC',
  OpensAsc = 'OPENS_ASC',
  OpensDesc = 'OPENS_DESC',
  ClicksAsc = 'CLICKS_ASC',
  ClicksDesc = 'CLICKS_DESC',
  DynalogViewsAsc = 'DYNALOG_VIEWS_ASC',
  DynalogViewsDesc = 'DYNALOG_VIEWS_DESC',
  OptimisticLockFieldAsc = 'OPTIMISTIC_LOCK_FIELD_ASC',
  OptimisticLockFieldDesc = 'OPTIMISTIC_LOCK_FIELD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type Carousel = {
  readonly __typename?: 'Carousel';
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly showBySlug?: Maybe<Scalars['String']>;
  readonly showOnHomePage?: Maybe<Scalars['Boolean']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly rankOrder?: Maybe<Scalars['Int']>;
  readonly headLine?: Maybe<Scalars['String']>;
  readonly subText?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly showOffers?: Maybe<Scalars['Int']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaKeys?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `CarouselListing`. */
  readonly carouselListingsByCarouselConnection: CarouselListingsConnection;
  /** Reads and enables pagination through a set of `CarouselListing`. */
  readonly carouselListingsByCarousel: ReadonlyArray<CarouselListing>;
  /** Reads and enables pagination through a set of `Offer`. */
  readonly offersByCarouselListingCarouselAndOffer: CarouselOffersByCarouselListingCarouselAndOfferManyToManyConnection;
  /** Reads and enables pagination through a set of `Offer`. */
  readonly offersByCarouselListingCarouselAndOfferList: ReadonlyArray<Offer>;
  /** Reads and enables pagination through a set of `Catalog`. */
  readonly catalogsByCarouselListingCarouselAndCatalog: CarouselCatalogsByCarouselListingCarouselAndCatalogManyToManyConnection;
  /** Reads and enables pagination through a set of `Catalog`. */
  readonly catalogsByCarouselListingCarouselAndCatalogList: ReadonlyArray<Catalog>;
};


export type CarouselCarouselListingsByCarouselConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CarouselListingsOrderBy>>;
  condition?: Maybe<CarouselListingCondition>;
  filter?: Maybe<CarouselListingFilter>;
};


export type CarouselCarouselListingsByCarouselArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CarouselListingsOrderBy>>;
  condition?: Maybe<CarouselListingCondition>;
  filter?: Maybe<CarouselListingFilter>;
};


export type CarouselOffersByCarouselListingCarouselAndOfferArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<OffersOrderBy>>;
  condition?: Maybe<OfferCondition>;
  filter?: Maybe<OfferFilter>;
};


export type CarouselOffersByCarouselListingCarouselAndOfferListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<OffersOrderBy>>;
  condition?: Maybe<OfferCondition>;
  filter?: Maybe<OfferFilter>;
};


export type CarouselCatalogsByCarouselListingCarouselAndCatalogArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CatalogsOrderBy>>;
  condition?: Maybe<CatalogCondition>;
  filter?: Maybe<CatalogFilter>;
};


export type CarouselCatalogsByCarouselListingCarouselAndCatalogListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CatalogsOrderBy>>;
  condition?: Maybe<CatalogCondition>;
  filter?: Maybe<CatalogFilter>;
};

/** A connection to a list of `Catalog` values, with data from `CarouselListing`. */
export type CarouselCatalogsByCarouselListingCarouselAndCatalogManyToManyConnection = {
  readonly __typename?: 'CarouselCatalogsByCarouselListingCarouselAndCatalogManyToManyConnection';
  /** A list of `Catalog` objects. */
  readonly nodes: ReadonlyArray<Catalog>;
  /** A list of edges which contains the `Catalog`, info from the `CarouselListing`, and the cursor to aid in pagination. */
  readonly edges: ReadonlyArray<CarouselCatalogsByCarouselListingCarouselAndCatalogManyToManyEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Catalog` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Catalog` edge in the connection, with data from `CarouselListing`. */
export type CarouselCatalogsByCarouselListingCarouselAndCatalogManyToManyEdge = {
  readonly __typename?: 'CarouselCatalogsByCarouselListingCarouselAndCatalogManyToManyEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Catalog` at the end of the edge. */
  readonly node: Catalog;
  /** Reads and enables pagination through a set of `CarouselListing`. */
  readonly carouselListingsByCatalogConnection: CarouselListingsConnection;
  /** Reads and enables pagination through a set of `CarouselListing`. */
  readonly carouselListingsByCatalog: ReadonlyArray<CarouselListing>;
};


/** A `Catalog` edge in the connection, with data from `CarouselListing`. */
export type CarouselCatalogsByCarouselListingCarouselAndCatalogManyToManyEdgeCarouselListingsByCatalogConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CarouselListingsOrderBy>>;
  condition?: Maybe<CarouselListingCondition>;
  filter?: Maybe<CarouselListingFilter>;
};


/** A `Catalog` edge in the connection, with data from `CarouselListing`. */
export type CarouselCatalogsByCarouselListingCarouselAndCatalogManyToManyEdgeCarouselListingsByCatalogArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CarouselListingsOrderBy>>;
  condition?: Maybe<CarouselListingCondition>;
  filter?: Maybe<CarouselListingFilter>;
};

/** A condition to be used against `Carousel` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type CarouselCondition = {
  /** Checks for equality with the object’s `oid` field. */
  readonly oid?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `options` field. */
  readonly options?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `showBySlug` field. */
  readonly showBySlug?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `showOnHomePage` field. */
  readonly showOnHomePage?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `active` field. */
  readonly active?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `rankOrder` field. */
  readonly rankOrder?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `headLine` field. */
  readonly headLine?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `subText` field. */
  readonly subText?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `showOffers` field. */
  readonly showOffers?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `metaTitle` field. */
  readonly metaTitle?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `metaKeys` field. */
  readonly metaKeys?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `metaDescription` field. */
  readonly metaDescription?: Maybe<Scalars['String']>;
};

/** A filter to be used against `Carousel` object types. All fields are combined with a logical ‘and.’ */
export type CarouselFilter = {
  /** Filter by the object’s `oid` field. */
  readonly oid?: Maybe<UuidFilter>;
  /** Filter by the object’s `showBySlug` field. */
  readonly showBySlug?: Maybe<StringFilter>;
  /** Filter by the object’s `showOnHomePage` field. */
  readonly showOnHomePage?: Maybe<BooleanFilter>;
  /** Filter by the object’s `active` field. */
  readonly active?: Maybe<BooleanFilter>;
  /** Filter by the object’s `rankOrder` field. */
  readonly rankOrder?: Maybe<IntFilter>;
  /** Filter by the object’s `headLine` field. */
  readonly headLine?: Maybe<StringFilter>;
  /** Filter by the object’s `subText` field. */
  readonly subText?: Maybe<StringFilter>;
  /** Filter by the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<IntFilter>;
  /** Filter by the object’s `showOffers` field. */
  readonly showOffers?: Maybe<IntFilter>;
  /** Filter by the object’s `metaTitle` field. */
  readonly metaTitle?: Maybe<StringFilter>;
  /** Filter by the object’s `metaKeys` field. */
  readonly metaKeys?: Maybe<StringFilter>;
  /** Filter by the object’s `metaDescription` field. */
  readonly metaDescription?: Maybe<StringFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<ReadonlyArray<CarouselFilter>>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<ReadonlyArray<CarouselFilter>>;
  /** Negates the expression. */
  readonly not?: Maybe<CarouselFilter>;
};

/** An input for mutations affecting `Carousel` */
export type CarouselInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly showBySlug?: Maybe<Scalars['String']>;
  readonly showOnHomePage?: Maybe<Scalars['Boolean']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly rankOrder?: Maybe<Scalars['Int']>;
  readonly headLine?: Maybe<Scalars['String']>;
  readonly subText?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly showOffers?: Maybe<Scalars['Int']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaKeys?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingCarouselInverseInput>;
};

export type CarouselListing = {
  readonly __typename?: 'CarouselListing';
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly carousel?: Maybe<Scalars['UUID']>;
  readonly rankOrder?: Maybe<Scalars['Int']>;
  readonly offer?: Maybe<Scalars['UUID']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  /** Reads a single `Carousel` that is related to this `CarouselListing`. */
  readonly carouselByCarousel?: Maybe<Carousel>;
  /** Reads a single `Offer` that is related to this `CarouselListing`. */
  readonly offerByOffer?: Maybe<Offer>;
  /** Reads a single `Catalog` that is related to this `CarouselListing`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
};

/** A condition to be used against `CarouselListing` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type CarouselListingCondition = {
  /** Checks for equality with the object’s `oid` field. */
  readonly oid?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `options` field. */
  readonly options?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `carousel` field. */
  readonly carousel?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `rankOrder` field. */
  readonly rankOrder?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `offer` field. */
  readonly offer?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `catalog` field. */
  readonly catalog?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `CarouselListing` object types. All fields are combined with a logical ‘and.’ */
export type CarouselListingFilter = {
  /** Filter by the object’s `oid` field. */
  readonly oid?: Maybe<UuidFilter>;
  /** Filter by the object’s `carousel` field. */
  readonly carousel?: Maybe<UuidFilter>;
  /** Filter by the object’s `rankOrder` field. */
  readonly rankOrder?: Maybe<IntFilter>;
  /** Filter by the object’s `offer` field. */
  readonly offer?: Maybe<UuidFilter>;
  /** Filter by the object’s `catalog` field. */
  readonly catalog?: Maybe<UuidFilter>;
  /** Filter by the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<IntFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<ReadonlyArray<CarouselListingFilter>>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<ReadonlyArray<CarouselListingFilter>>;
  /** Negates the expression. */
  readonly not?: Maybe<CarouselListingFilter>;
};

/** An input for mutations affecting `CarouselListing` */
export type CarouselListingInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly carousel?: Maybe<Scalars['UUID']>;
  readonly rankOrder?: Maybe<Scalars['Int']>;
  readonly offer?: Maybe<Scalars['UUID']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly carouselToCarousel?: Maybe<FkCarouselListingCarouselInput>;
  readonly offerToOffer?: Maybe<FkCarouselListingOfferInput>;
  readonly catalogToCatalog?: Maybe<FkCarouselListingCatalogInput>;
};

/** The fields on `carouselListing` to look up the row to update. */
export type CarouselListingOnCarouselListingForFkCarouselListingCarouselUsingPkCarouselListingUpdate = {
  /** An object where the defined keys will be set on the `carouselListing` being updated. */
  readonly patch: UpdateCarouselListingOnCarouselListingForFkCarouselListingCarouselPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `carouselListing` to look up the row to update. */
export type CarouselListingOnCarouselListingForFkCarouselListingCatalogUsingPkCarouselListingUpdate = {
  /** An object where the defined keys will be set on the `carouselListing` being updated. */
  readonly patch: UpdateCarouselListingOnCarouselListingForFkCarouselListingCatalogPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `carouselListing` to look up the row to update. */
export type CarouselListingOnCarouselListingForFkCarouselListingOfferUsingPkCarouselListingUpdate = {
  /** An object where the defined keys will be set on the `carouselListing` being updated. */
  readonly patch: UpdateCarouselListingOnCarouselListingForFkCarouselListingOfferPatch;
  readonly oid: Scalars['UUID'];
};

/** Represents an update to a `CarouselListing`. Fields that are set will be updated. */
export type CarouselListingPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly carousel?: Maybe<Scalars['UUID']>;
  readonly rankOrder?: Maybe<Scalars['Int']>;
  readonly offer?: Maybe<Scalars['UUID']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly carouselToCarousel?: Maybe<FkCarouselListingCarouselInput>;
  readonly offerToOffer?: Maybe<FkCarouselListingOfferInput>;
  readonly catalogToCatalog?: Maybe<FkCarouselListingCatalogInput>;
};

/** The fields on `carouselListing` to look up the row to connect. */
export type CarouselListingPkCarouselListingConnect = {
  readonly oid: Scalars['UUID'];
};

/** The fields on `carouselListing` to look up the row to delete. */
export type CarouselListingPkCarouselListingDelete = {
  readonly oid: Scalars['UUID'];
};

/** A connection to a list of `CarouselListing` values. */
export type CarouselListingsConnection = {
  readonly __typename?: 'CarouselListingsConnection';
  /** A list of `CarouselListing` objects. */
  readonly nodes: ReadonlyArray<CarouselListing>;
  /** A list of edges which contains the `CarouselListing` and cursor to aid in pagination. */
  readonly edges: ReadonlyArray<CarouselListingsEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `CarouselListing` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `CarouselListing` edge in the connection. */
export type CarouselListingsEdge = {
  readonly __typename?: 'CarouselListingsEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `CarouselListing` at the end of the edge. */
  readonly node: CarouselListing;
};

/** Methods to use when ordering `CarouselListing`. */
export enum CarouselListingsOrderBy {
  Natural = 'NATURAL',
  OidAsc = 'OID_ASC',
  OidDesc = 'OID_DESC',
  OptionsAsc = 'OPTIONS_ASC',
  OptionsDesc = 'OPTIONS_DESC',
  CarouselAsc = 'CAROUSEL_ASC',
  CarouselDesc = 'CAROUSEL_DESC',
  RankOrderAsc = 'RANK_ORDER_ASC',
  RankOrderDesc = 'RANK_ORDER_DESC',
  OfferAsc = 'OFFER_ASC',
  OfferDesc = 'OFFER_DESC',
  CatalogAsc = 'CATALOG_ASC',
  CatalogDesc = 'CATALOG_DESC',
  OptimisticLockFieldAsc = 'OPTIMISTIC_LOCK_FIELD_ASC',
  OptimisticLockFieldDesc = 'OPTIMISTIC_LOCK_FIELD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A connection to a list of `Offer` values, with data from `CarouselListing`. */
export type CarouselOffersByCarouselListingCarouselAndOfferManyToManyConnection = {
  readonly __typename?: 'CarouselOffersByCarouselListingCarouselAndOfferManyToManyConnection';
  /** A list of `Offer` objects. */
  readonly nodes: ReadonlyArray<Offer>;
  /** A list of edges which contains the `Offer`, info from the `CarouselListing`, and the cursor to aid in pagination. */
  readonly edges: ReadonlyArray<CarouselOffersByCarouselListingCarouselAndOfferManyToManyEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Offer` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Offer` edge in the connection, with data from `CarouselListing`. */
export type CarouselOffersByCarouselListingCarouselAndOfferManyToManyEdge = {
  readonly __typename?: 'CarouselOffersByCarouselListingCarouselAndOfferManyToManyEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Offer` at the end of the edge. */
  readonly node: Offer;
  /** Reads and enables pagination through a set of `CarouselListing`. */
  readonly carouselListingsByOfferConnection: CarouselListingsConnection;
  /** Reads and enables pagination through a set of `CarouselListing`. */
  readonly carouselListingsByOffer: ReadonlyArray<CarouselListing>;
};


/** A `Offer` edge in the connection, with data from `CarouselListing`. */
export type CarouselOffersByCarouselListingCarouselAndOfferManyToManyEdgeCarouselListingsByOfferConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CarouselListingsOrderBy>>;
  condition?: Maybe<CarouselListingCondition>;
  filter?: Maybe<CarouselListingFilter>;
};


/** A `Offer` edge in the connection, with data from `CarouselListing`. */
export type CarouselOffersByCarouselListingCarouselAndOfferManyToManyEdgeCarouselListingsByOfferArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CarouselListingsOrderBy>>;
  condition?: Maybe<CarouselListingCondition>;
  filter?: Maybe<CarouselListingFilter>;
};

/** The fields on `carousel` to look up the row to update. */
export type CarouselOnCarouselListingForFkCarouselListingCarouselUsingPkCarouselUpdate = {
  /** An object where the defined keys will be set on the `carousel` being updated. */
  readonly patch: UpdateCarouselOnCarouselListingForFkCarouselListingCarouselPatch;
  readonly oid: Scalars['UUID'];
};

/** Represents an update to a `Carousel`. Fields that are set will be updated. */
export type CarouselPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly showBySlug?: Maybe<Scalars['String']>;
  readonly showOnHomePage?: Maybe<Scalars['Boolean']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly rankOrder?: Maybe<Scalars['Int']>;
  readonly headLine?: Maybe<Scalars['String']>;
  readonly subText?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly showOffers?: Maybe<Scalars['Int']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaKeys?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingCarouselInverseInput>;
};

/** The fields on `carousel` to look up the row to connect. */
export type CarouselPkCarouselConnect = {
  readonly oid: Scalars['UUID'];
};

/** The fields on `carousel` to look up the row to delete. */
export type CarouselPkCarouselDelete = {
  readonly oid: Scalars['UUID'];
};

/** A connection to a list of `Carousel` values. */
export type CarouselsConnection = {
  readonly __typename?: 'CarouselsConnection';
  /** A list of `Carousel` objects. */
  readonly nodes: ReadonlyArray<Carousel>;
  /** A list of edges which contains the `Carousel` and cursor to aid in pagination. */
  readonly edges: ReadonlyArray<CarouselsEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Carousel` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Carousel` edge in the connection. */
export type CarouselsEdge = {
  readonly __typename?: 'CarouselsEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Carousel` at the end of the edge. */
  readonly node: Carousel;
};

/** Methods to use when ordering `Carousel`. */
export enum CarouselsOrderBy {
  Natural = 'NATURAL',
  OidAsc = 'OID_ASC',
  OidDesc = 'OID_DESC',
  OptionsAsc = 'OPTIONS_ASC',
  OptionsDesc = 'OPTIONS_DESC',
  ShowBySlugAsc = 'SHOW_BY_SLUG_ASC',
  ShowBySlugDesc = 'SHOW_BY_SLUG_DESC',
  ShowOnHomePageAsc = 'SHOW_ON_HOME_PAGE_ASC',
  ShowOnHomePageDesc = 'SHOW_ON_HOME_PAGE_DESC',
  ActiveAsc = 'ACTIVE_ASC',
  ActiveDesc = 'ACTIVE_DESC',
  RankOrderAsc = 'RANK_ORDER_ASC',
  RankOrderDesc = 'RANK_ORDER_DESC',
  HeadLineAsc = 'HEAD_LINE_ASC',
  HeadLineDesc = 'HEAD_LINE_DESC',
  SubTextAsc = 'SUB_TEXT_ASC',
  SubTextDesc = 'SUB_TEXT_DESC',
  OptimisticLockFieldAsc = 'OPTIMISTIC_LOCK_FIELD_ASC',
  OptimisticLockFieldDesc = 'OPTIMISTIC_LOCK_FIELD_DESC',
  ShowOffersAsc = 'SHOW_OFFERS_ASC',
  ShowOffersDesc = 'SHOW_OFFERS_DESC',
  MetaTitleAsc = 'META_TITLE_ASC',
  MetaTitleDesc = 'META_TITLE_DESC',
  MetaKeysAsc = 'META_KEYS_ASC',
  MetaKeysDesc = 'META_KEYS_DESC',
  MetaDescriptionAsc = 'META_DESCRIPTION_ASC',
  MetaDescriptionDesc = 'META_DESCRIPTION_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type Catalog = {
  readonly __typename?: 'Catalog';
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly cover?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly catalogFormat?: Maybe<Scalars['String']>;
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly leadCharge?: Maybe<Scalars['Float']>;
  readonly linkCharge?: Maybe<Scalars['Float']>;
  readonly digitalFee?: Maybe<Scalars['Float']>;
  readonly listingFee?: Maybe<Scalars['Float']>;
  readonly dateExpired?: Maybe<Scalars['Datetime']>;
  readonly trackingCode?: Maybe<Scalars['String']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly showCategoryLinks?: Maybe<Scalars['Boolean']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Datetime']>;
  readonly offerCopy?: Maybe<Scalars['String']>;
  readonly introCopy?: Maybe<Scalars['String']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  readonly blurb?: Maybe<Scalars['String']>;
  readonly featureCopy?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly keywords?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly longDescription?: Maybe<Scalars['String']>;
  readonly class?: Maybe<Scalars['String']>;
  readonly searchAppendCode?: Maybe<Scalars['String']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly primaryDepartment?: Maybe<Scalars['UUID']>;
  readonly isDigital?: Maybe<Scalars['Boolean']>;
  readonly merchantId?: Maybe<Scalars['BigInt']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly autoActivate?: Maybe<Scalars['Boolean']>;
  readonly groupHubspotId?: Maybe<Scalars['String']>;
  readonly customSubdomain?: Maybe<Scalars['String']>;
  readonly text2Disclaimer?: Maybe<Scalars['String']>;
  readonly activeVersion?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly collectFullAddress?: Maybe<Scalars['Boolean']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly freeShipping?: Maybe<Scalars['Boolean']>;
  readonly mailOrder?: Maybe<Scalars['Boolean']>;
  readonly collectLeadQr?: Maybe<Scalars['Boolean']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly hideAds?: Maybe<Scalars['Boolean']>;
  /** Reads a single `Account` that is related to this `Catalog`. */
  readonly accountByAccount?: Maybe<Account>;
  /** Reads a single `Department` that is related to this `Catalog`. */
  readonly departmentByPrimaryDepartment?: Maybe<Department>;
  /** Reads a single `Version` that is related to this `Catalog`. */
  readonly versionByActiveVersion?: Maybe<Version>;
  /** Reads and enables pagination through a set of `DepartmentDepartmentsCatalogListing`. */
  readonly departmentDepartmentsCatalogListingsByListingsConnection: DepartmentDepartmentsCatalogListingsConnection;
  /** Reads and enables pagination through a set of `DepartmentDepartmentsCatalogListing`. */
  readonly departmentDepartmentsCatalogListingsByListings: ReadonlyArray<DepartmentDepartmentsCatalogListing>;
  /** Reads and enables pagination through a set of `Version`. */
  readonly versionsByCatalogConnection: VersionsConnection;
  /** Reads and enables pagination through a set of `Version`. */
  readonly versionsByCatalog: ReadonlyArray<Version>;
  /** Reads and enables pagination through a set of `Offer`. */
  readonly offersByCatalogConnection: OffersConnection;
  /** Reads and enables pagination through a set of `Offer`. */
  readonly offersByCatalog: ReadonlyArray<Offer>;
  /** Reads and enables pagination through a set of `Category`. */
  readonly categoriesByCatalogConnection: CategoriesConnection;
  /** Reads and enables pagination through a set of `Category`. */
  readonly categoriesByCatalog: ReadonlyArray<Category>;
  /** Reads and enables pagination through a set of `Feed`. */
  readonly feedsByCatalogConnection: FeedsConnection;
  /** Reads and enables pagination through a set of `Feed`. */
  readonly feedsByCatalog: ReadonlyArray<Feed>;
  /** Reads and enables pagination through a set of `LinkOut`. */
  readonly linkOutsByCatalogConnection: LinkOutsConnection;
  /** Reads and enables pagination through a set of `LinkOut`. */
  readonly linkOutsByCatalog: ReadonlyArray<LinkOut>;
  /** Reads and enables pagination through a set of `Subscription`. */
  readonly subscriptionsByCatalogConnection: SubscriptionsConnection;
  /** Reads and enables pagination through a set of `Subscription`. */
  readonly subscriptionsByCatalog: ReadonlyArray<Subscription>;
  /** Reads and enables pagination through a set of `Product`. */
  readonly productsByCatalogConnection: ProductsConnection;
  /** Reads and enables pagination through a set of `Product`. */
  readonly productsByCatalog: ReadonlyArray<Product>;
  /** Reads and enables pagination through a set of `CampaignGroup`. */
  readonly campaignGroupsByCatalogConnection: CampaignGroupsConnection;
  /** Reads and enables pagination through a set of `CampaignGroup`. */
  readonly campaignGroupsByCatalog: ReadonlyArray<CampaignGroup>;
  /** Reads and enables pagination through a set of `CarouselListing`. */
  readonly carouselListingsByCatalogConnection: CarouselListingsConnection;
  /** Reads and enables pagination through a set of `CarouselListing`. */
  readonly carouselListingsByCatalog: ReadonlyArray<CarouselListing>;
  /** Reads and enables pagination through a set of `CategoryByCatalog`. */
  readonly categoryByCatalogsByCatalogConnection: CategoryByCatalogsConnection;
  /** Reads and enables pagination through a set of `CategoryByCatalog`. */
  readonly categoryByCatalogsByCatalog: ReadonlyArray<CategoryByCatalog>;
  /** Reads and enables pagination through a set of `Department`. */
  readonly departmentsByDepartmentDepartmentsCatalogListingListingsAndDepartments: CatalogDepartmentsByDepartmentDepartmentsCatalogListingListingsAndDepartmentsManyToManyConnection;
  /** Reads and enables pagination through a set of `Department`. */
  readonly departmentsByDepartmentDepartmentsCatalogListingListingsAndDepartmentsList: ReadonlyArray<Department>;
  /** Reads and enables pagination through a set of `Profile`. */
  readonly profilesByLinkOutCatalogAndCustomer: CatalogProfilesByLinkOutCatalogAndCustomerManyToManyConnection;
  /** Reads and enables pagination through a set of `Profile`. */
  readonly profilesByLinkOutCatalogAndCustomerList: ReadonlyArray<Profile>;
  /** Reads and enables pagination through a set of `Profile`. */
  readonly profilesBySubscriptionCatalogAndCustomer: CatalogProfilesBySubscriptionCatalogAndCustomerManyToManyConnection;
  /** Reads and enables pagination through a set of `Profile`. */
  readonly profilesBySubscriptionCatalogAndCustomerList: ReadonlyArray<Profile>;
  /** Reads and enables pagination through a set of `Campaign`. */
  readonly campaignsByCampaignGroupCatalogAndCampaign: CatalogCampaignsByCampaignGroupCatalogAndCampaignManyToManyConnection;
  /** Reads and enables pagination through a set of `Campaign`. */
  readonly campaignsByCampaignGroupCatalogAndCampaignList: ReadonlyArray<Campaign>;
  /** Reads and enables pagination through a set of `Carousel`. */
  readonly carouselsByCarouselListingCatalogAndCarousel: CatalogCarouselsByCarouselListingCatalogAndCarouselManyToManyConnection;
  /** Reads and enables pagination through a set of `Carousel`. */
  readonly carouselsByCarouselListingCatalogAndCarouselList: ReadonlyArray<Carousel>;
  /** Reads and enables pagination through a set of `Offer`. */
  readonly offersByCarouselListingCatalogAndOffer: CatalogOffersByCarouselListingCatalogAndOfferManyToManyConnection;
  /** Reads and enables pagination through a set of `Offer`. */
  readonly offersByCarouselListingCatalogAndOfferList: ReadonlyArray<Offer>;
};


export type CatalogDepartmentDepartmentsCatalogListingsByListingsConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<DepartmentDepartmentsCatalogListingsOrderBy>>;
  condition?: Maybe<DepartmentDepartmentsCatalogListingCondition>;
  filter?: Maybe<DepartmentDepartmentsCatalogListingFilter>;
};


export type CatalogDepartmentDepartmentsCatalogListingsByListingsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<DepartmentDepartmentsCatalogListingsOrderBy>>;
  condition?: Maybe<DepartmentDepartmentsCatalogListingCondition>;
  filter?: Maybe<DepartmentDepartmentsCatalogListingFilter>;
};


export type CatalogVersionsByCatalogConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<VersionsOrderBy>>;
  condition?: Maybe<VersionCondition>;
  filter?: Maybe<VersionFilter>;
};


export type CatalogVersionsByCatalogArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<VersionsOrderBy>>;
  condition?: Maybe<VersionCondition>;
  filter?: Maybe<VersionFilter>;
};


export type CatalogOffersByCatalogConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<OffersOrderBy>>;
  condition?: Maybe<OfferCondition>;
  filter?: Maybe<OfferFilter>;
};


export type CatalogOffersByCatalogArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<OffersOrderBy>>;
  condition?: Maybe<OfferCondition>;
  filter?: Maybe<OfferFilter>;
};


export type CatalogCategoriesByCatalogConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CategoriesOrderBy>>;
  condition?: Maybe<CategoryCondition>;
  filter?: Maybe<CategoryFilter>;
};


export type CatalogCategoriesByCatalogArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CategoriesOrderBy>>;
  condition?: Maybe<CategoryCondition>;
  filter?: Maybe<CategoryFilter>;
};


export type CatalogFeedsByCatalogConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<FeedsOrderBy>>;
  condition?: Maybe<FeedCondition>;
  filter?: Maybe<FeedFilter>;
};


export type CatalogFeedsByCatalogArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<FeedsOrderBy>>;
  condition?: Maybe<FeedCondition>;
  filter?: Maybe<FeedFilter>;
};


export type CatalogLinkOutsByCatalogConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<LinkOutsOrderBy>>;
  condition?: Maybe<LinkOutCondition>;
  filter?: Maybe<LinkOutFilter>;
};


export type CatalogLinkOutsByCatalogArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<LinkOutsOrderBy>>;
  condition?: Maybe<LinkOutCondition>;
  filter?: Maybe<LinkOutFilter>;
};


export type CatalogSubscriptionsByCatalogConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<SubscriptionsOrderBy>>;
  condition?: Maybe<SubscriptionCondition>;
  filter?: Maybe<SubscriptionFilter>;
};


export type CatalogSubscriptionsByCatalogArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<SubscriptionsOrderBy>>;
  condition?: Maybe<SubscriptionCondition>;
  filter?: Maybe<SubscriptionFilter>;
};


export type CatalogProductsByCatalogConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<ProductsOrderBy>>;
  condition?: Maybe<ProductCondition>;
  filter?: Maybe<ProductFilter>;
};


export type CatalogProductsByCatalogArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<ProductsOrderBy>>;
  condition?: Maybe<ProductCondition>;
  filter?: Maybe<ProductFilter>;
};


export type CatalogCampaignGroupsByCatalogConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CampaignGroupsOrderBy>>;
  condition?: Maybe<CampaignGroupCondition>;
  filter?: Maybe<CampaignGroupFilter>;
};


export type CatalogCampaignGroupsByCatalogArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CampaignGroupsOrderBy>>;
  condition?: Maybe<CampaignGroupCondition>;
  filter?: Maybe<CampaignGroupFilter>;
};


export type CatalogCarouselListingsByCatalogConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CarouselListingsOrderBy>>;
  condition?: Maybe<CarouselListingCondition>;
  filter?: Maybe<CarouselListingFilter>;
};


export type CatalogCarouselListingsByCatalogArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CarouselListingsOrderBy>>;
  condition?: Maybe<CarouselListingCondition>;
  filter?: Maybe<CarouselListingFilter>;
};


export type CatalogCategoryByCatalogsByCatalogConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CategoryByCatalogsOrderBy>>;
  condition?: Maybe<CategoryByCatalogCondition>;
  filter?: Maybe<CategoryByCatalogFilter>;
};


export type CatalogCategoryByCatalogsByCatalogArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CategoryByCatalogsOrderBy>>;
  condition?: Maybe<CategoryByCatalogCondition>;
  filter?: Maybe<CategoryByCatalogFilter>;
};


export type CatalogDepartmentsByDepartmentDepartmentsCatalogListingListingsAndDepartmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<DepartmentsOrderBy>>;
  condition?: Maybe<DepartmentCondition>;
  filter?: Maybe<DepartmentFilter>;
};


export type CatalogDepartmentsByDepartmentDepartmentsCatalogListingListingsAndDepartmentsListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<DepartmentsOrderBy>>;
  condition?: Maybe<DepartmentCondition>;
  filter?: Maybe<DepartmentFilter>;
};


export type CatalogProfilesByLinkOutCatalogAndCustomerArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<ProfilesOrderBy>>;
  condition?: Maybe<ProfileCondition>;
  filter?: Maybe<ProfileFilter>;
};


export type CatalogProfilesByLinkOutCatalogAndCustomerListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<ProfilesOrderBy>>;
  condition?: Maybe<ProfileCondition>;
  filter?: Maybe<ProfileFilter>;
};


export type CatalogProfilesBySubscriptionCatalogAndCustomerArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<ProfilesOrderBy>>;
  condition?: Maybe<ProfileCondition>;
  filter?: Maybe<ProfileFilter>;
};


export type CatalogProfilesBySubscriptionCatalogAndCustomerListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<ProfilesOrderBy>>;
  condition?: Maybe<ProfileCondition>;
  filter?: Maybe<ProfileFilter>;
};


export type CatalogCampaignsByCampaignGroupCatalogAndCampaignArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CampaignsOrderBy>>;
  condition?: Maybe<CampaignCondition>;
  filter?: Maybe<CampaignFilter>;
};


export type CatalogCampaignsByCampaignGroupCatalogAndCampaignListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CampaignsOrderBy>>;
  condition?: Maybe<CampaignCondition>;
  filter?: Maybe<CampaignFilter>;
};


export type CatalogCarouselsByCarouselListingCatalogAndCarouselArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CarouselsOrderBy>>;
  condition?: Maybe<CarouselCondition>;
  filter?: Maybe<CarouselFilter>;
};


export type CatalogCarouselsByCarouselListingCatalogAndCarouselListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CarouselsOrderBy>>;
  condition?: Maybe<CarouselCondition>;
  filter?: Maybe<CarouselFilter>;
};


export type CatalogOffersByCarouselListingCatalogAndOfferArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<OffersOrderBy>>;
  condition?: Maybe<OfferCondition>;
  filter?: Maybe<OfferFilter>;
};


export type CatalogOffersByCarouselListingCatalogAndOfferListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<OffersOrderBy>>;
  condition?: Maybe<OfferCondition>;
  filter?: Maybe<OfferFilter>;
};

/** A connection to a list of `Campaign` values, with data from `CampaignGroup`. */
export type CatalogCampaignsByCampaignGroupCatalogAndCampaignManyToManyConnection = {
  readonly __typename?: 'CatalogCampaignsByCampaignGroupCatalogAndCampaignManyToManyConnection';
  /** A list of `Campaign` objects. */
  readonly nodes: ReadonlyArray<Campaign>;
  /** A list of edges which contains the `Campaign`, info from the `CampaignGroup`, and the cursor to aid in pagination. */
  readonly edges: ReadonlyArray<CatalogCampaignsByCampaignGroupCatalogAndCampaignManyToManyEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Campaign` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Campaign` edge in the connection, with data from `CampaignGroup`. */
export type CatalogCampaignsByCampaignGroupCatalogAndCampaignManyToManyEdge = {
  readonly __typename?: 'CatalogCampaignsByCampaignGroupCatalogAndCampaignManyToManyEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Campaign` at the end of the edge. */
  readonly node: Campaign;
  /** Reads and enables pagination through a set of `CampaignGroup`. */
  readonly campaignGroupsByCampaignConnection: CampaignGroupsConnection;
  /** Reads and enables pagination through a set of `CampaignGroup`. */
  readonly campaignGroupsByCampaign: ReadonlyArray<CampaignGroup>;
};


/** A `Campaign` edge in the connection, with data from `CampaignGroup`. */
export type CatalogCampaignsByCampaignGroupCatalogAndCampaignManyToManyEdgeCampaignGroupsByCampaignConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CampaignGroupsOrderBy>>;
  condition?: Maybe<CampaignGroupCondition>;
  filter?: Maybe<CampaignGroupFilter>;
};


/** A `Campaign` edge in the connection, with data from `CampaignGroup`. */
export type CatalogCampaignsByCampaignGroupCatalogAndCampaignManyToManyEdgeCampaignGroupsByCampaignArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CampaignGroupsOrderBy>>;
  condition?: Maybe<CampaignGroupCondition>;
  filter?: Maybe<CampaignGroupFilter>;
};

/** A connection to a list of `Carousel` values, with data from `CarouselListing`. */
export type CatalogCarouselsByCarouselListingCatalogAndCarouselManyToManyConnection = {
  readonly __typename?: 'CatalogCarouselsByCarouselListingCatalogAndCarouselManyToManyConnection';
  /** A list of `Carousel` objects. */
  readonly nodes: ReadonlyArray<Carousel>;
  /** A list of edges which contains the `Carousel`, info from the `CarouselListing`, and the cursor to aid in pagination. */
  readonly edges: ReadonlyArray<CatalogCarouselsByCarouselListingCatalogAndCarouselManyToManyEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Carousel` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Carousel` edge in the connection, with data from `CarouselListing`. */
export type CatalogCarouselsByCarouselListingCatalogAndCarouselManyToManyEdge = {
  readonly __typename?: 'CatalogCarouselsByCarouselListingCatalogAndCarouselManyToManyEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Carousel` at the end of the edge. */
  readonly node: Carousel;
  /** Reads and enables pagination through a set of `CarouselListing`. */
  readonly carouselListingsByCarouselConnection: CarouselListingsConnection;
  /** Reads and enables pagination through a set of `CarouselListing`. */
  readonly carouselListingsByCarousel: ReadonlyArray<CarouselListing>;
};


/** A `Carousel` edge in the connection, with data from `CarouselListing`. */
export type CatalogCarouselsByCarouselListingCatalogAndCarouselManyToManyEdgeCarouselListingsByCarouselConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CarouselListingsOrderBy>>;
  condition?: Maybe<CarouselListingCondition>;
  filter?: Maybe<CarouselListingFilter>;
};


/** A `Carousel` edge in the connection, with data from `CarouselListing`. */
export type CatalogCarouselsByCarouselListingCatalogAndCarouselManyToManyEdgeCarouselListingsByCarouselArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CarouselListingsOrderBy>>;
  condition?: Maybe<CarouselListingCondition>;
  filter?: Maybe<CarouselListingFilter>;
};

/** The fields on `catalog` to look up the row to connect. */
export type CatalogCatalogByIdConnect = {
  readonly id: Scalars['Int'];
};

/** The fields on `catalog` to look up the row to delete. */
export type CatalogCatalogByIdDelete = {
  readonly id: Scalars['Int'];
};

/** A condition to be used against `Catalog` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type CatalogCondition = {
  /** Checks for equality with the object’s `oid` field. */
  readonly oid?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `options` field. */
  readonly options?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `id` field. */
  readonly id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `slug` field. */
  readonly slug?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `legacyId` field. */
  readonly legacyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `title` field. */
  readonly title?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  readonly name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `cover` field. */
  readonly cover?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `status` field. */
  readonly status?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `active` field. */
  readonly active?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `catalogFormat` field. */
  readonly catalogFormat?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `linkUrl` field. */
  readonly linkUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `leadCharge` field. */
  readonly leadCharge?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `linkCharge` field. */
  readonly linkCharge?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `digitalFee` field. */
  readonly digitalFee?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `listingFee` field. */
  readonly listingFee?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `dateExpired` field. */
  readonly dateExpired?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `trackingCode` field. */
  readonly trackingCode?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `heading` field. */
  readonly heading?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `showCategoryLinks` field. */
  readonly showCategoryLinks?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `comments` field. */
  readonly comments?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `startDate` field. */
  readonly startDate?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `offerCopy` field. */
  readonly offerCopy?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `introCopy` field. */
  readonly introCopy?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `metaTitle` field. */
  readonly metaTitle?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `metaDescription` field. */
  readonly metaDescription?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `blurb` field. */
  readonly blurb?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `featureCopy` field. */
  readonly featureCopy?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `altText` field. */
  readonly altText?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `keywords` field. */
  readonly keywords?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `description` field. */
  readonly description?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `longDescription` field. */
  readonly longDescription?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `class` field. */
  readonly class?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `searchAppendCode` field. */
  readonly searchAppendCode?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `account` field. */
  readonly account?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `primaryDepartment` field. */
  readonly primaryDepartment?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `isDigital` field. */
  readonly isDigital?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `merchantId` field. */
  readonly merchantId?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `label` field. */
  readonly label?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `autoActivate` field. */
  readonly autoActivate?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `groupHubspotId` field. */
  readonly groupHubspotId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `customSubdomain` field. */
  readonly customSubdomain?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `text2Disclaimer` field. */
  readonly text2Disclaimer?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `activeVersion` field. */
  readonly activeVersion?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `collectFullAddress` field. */
  readonly collectFullAddress?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `targetUrl` field. */
  readonly targetUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `freeShipping` field. */
  readonly freeShipping?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `mailOrder` field. */
  readonly mailOrder?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `collectLeadQr` field. */
  readonly collectLeadQr?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `homePageUrl` field. */
  readonly homePageUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `hideAds` field. */
  readonly hideAds?: Maybe<Scalars['Boolean']>;
};

/** A connection to a list of `Department` values, with data from `DepartmentDepartmentsCatalogListing`. */
export type CatalogDepartmentsByDepartmentDepartmentsCatalogListingListingsAndDepartmentsManyToManyConnection = {
  readonly __typename?: 'CatalogDepartmentsByDepartmentDepartmentsCatalogListingListingsAndDepartmentsManyToManyConnection';
  /** A list of `Department` objects. */
  readonly nodes: ReadonlyArray<Department>;
  /** A list of edges which contains the `Department`, info from the `DepartmentDepartmentsCatalogListing`, and the cursor to aid in pagination. */
  readonly edges: ReadonlyArray<CatalogDepartmentsByDepartmentDepartmentsCatalogListingListingsAndDepartmentsManyToManyEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Department` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Department` edge in the connection, with data from `DepartmentDepartmentsCatalogListing`. */
export type CatalogDepartmentsByDepartmentDepartmentsCatalogListingListingsAndDepartmentsManyToManyEdge = {
  readonly __typename?: 'CatalogDepartmentsByDepartmentDepartmentsCatalogListingListingsAndDepartmentsManyToManyEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Department` at the end of the edge. */
  readonly node: Department;
  /** Reads and enables pagination through a set of `DepartmentDepartmentsCatalogListing`. */
  readonly departmentDepartmentsCatalogListingsByDepartmentsConnection: DepartmentDepartmentsCatalogListingsConnection;
  /** Reads and enables pagination through a set of `DepartmentDepartmentsCatalogListing`. */
  readonly departmentDepartmentsCatalogListingsByDepartments: ReadonlyArray<DepartmentDepartmentsCatalogListing>;
};


/** A `Department` edge in the connection, with data from `DepartmentDepartmentsCatalogListing`. */
export type CatalogDepartmentsByDepartmentDepartmentsCatalogListingListingsAndDepartmentsManyToManyEdgeDepartmentDepartmentsCatalogListingsByDepartmentsConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<DepartmentDepartmentsCatalogListingsOrderBy>>;
  condition?: Maybe<DepartmentDepartmentsCatalogListingCondition>;
  filter?: Maybe<DepartmentDepartmentsCatalogListingFilter>;
};


/** A `Department` edge in the connection, with data from `DepartmentDepartmentsCatalogListing`. */
export type CatalogDepartmentsByDepartmentDepartmentsCatalogListingListingsAndDepartmentsManyToManyEdgeDepartmentDepartmentsCatalogListingsByDepartmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<DepartmentDepartmentsCatalogListingsOrderBy>>;
  condition?: Maybe<DepartmentDepartmentsCatalogListingCondition>;
  filter?: Maybe<DepartmentDepartmentsCatalogListingFilter>;
};

/** A filter to be used against `Catalog` object types. All fields are combined with a logical ‘and.’ */
export type CatalogFilter = {
  /** Filter by the object’s `oid` field. */
  readonly oid?: Maybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  readonly id?: Maybe<IntFilter>;
  /** Filter by the object’s `slug` field. */
  readonly slug?: Maybe<StringFilter>;
  /** Filter by the object’s `legacyId` field. */
  readonly legacyId?: Maybe<IntFilter>;
  /** Filter by the object’s `title` field. */
  readonly title?: Maybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  readonly name?: Maybe<StringFilter>;
  /** Filter by the object’s `cover` field. */
  readonly cover?: Maybe<StringFilter>;
  /** Filter by the object’s `status` field. */
  readonly status?: Maybe<IntFilter>;
  /** Filter by the object’s `active` field. */
  readonly active?: Maybe<BooleanFilter>;
  /** Filter by the object’s `catalogFormat` field. */
  readonly catalogFormat?: Maybe<StringFilter>;
  /** Filter by the object’s `linkUrl` field. */
  readonly linkUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `leadCharge` field. */
  readonly leadCharge?: Maybe<FloatFilter>;
  /** Filter by the object’s `linkCharge` field. */
  readonly linkCharge?: Maybe<FloatFilter>;
  /** Filter by the object’s `digitalFee` field. */
  readonly digitalFee?: Maybe<FloatFilter>;
  /** Filter by the object’s `listingFee` field. */
  readonly listingFee?: Maybe<FloatFilter>;
  /** Filter by the object’s `dateExpired` field. */
  readonly dateExpired?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `trackingCode` field. */
  readonly trackingCode?: Maybe<StringFilter>;
  /** Filter by the object’s `heading` field. */
  readonly heading?: Maybe<StringFilter>;
  /** Filter by the object’s `showCategoryLinks` field. */
  readonly showCategoryLinks?: Maybe<BooleanFilter>;
  /** Filter by the object’s `comments` field. */
  readonly comments?: Maybe<StringFilter>;
  /** Filter by the object’s `startDate` field. */
  readonly startDate?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `offerCopy` field. */
  readonly offerCopy?: Maybe<StringFilter>;
  /** Filter by the object’s `introCopy` field. */
  readonly introCopy?: Maybe<StringFilter>;
  /** Filter by the object’s `metaTitle` field. */
  readonly metaTitle?: Maybe<StringFilter>;
  /** Filter by the object’s `metaDescription` field. */
  readonly metaDescription?: Maybe<StringFilter>;
  /** Filter by the object’s `blurb` field. */
  readonly blurb?: Maybe<StringFilter>;
  /** Filter by the object’s `featureCopy` field. */
  readonly featureCopy?: Maybe<StringFilter>;
  /** Filter by the object’s `altText` field. */
  readonly altText?: Maybe<StringFilter>;
  /** Filter by the object’s `keywords` field. */
  readonly keywords?: Maybe<StringFilter>;
  /** Filter by the object’s `description` field. */
  readonly description?: Maybe<StringFilter>;
  /** Filter by the object’s `longDescription` field. */
  readonly longDescription?: Maybe<StringFilter>;
  /** Filter by the object’s `class` field. */
  readonly class?: Maybe<StringFilter>;
  /** Filter by the object’s `searchAppendCode` field. */
  readonly searchAppendCode?: Maybe<StringFilter>;
  /** Filter by the object’s `account` field. */
  readonly account?: Maybe<UuidFilter>;
  /** Filter by the object’s `primaryDepartment` field. */
  readonly primaryDepartment?: Maybe<UuidFilter>;
  /** Filter by the object’s `isDigital` field. */
  readonly isDigital?: Maybe<BooleanFilter>;
  /** Filter by the object’s `merchantId` field. */
  readonly merchantId?: Maybe<BigIntFilter>;
  /** Filter by the object’s `label` field. */
  readonly label?: Maybe<StringFilter>;
  /** Filter by the object’s `autoActivate` field. */
  readonly autoActivate?: Maybe<BooleanFilter>;
  /** Filter by the object’s `groupHubspotId` field. */
  readonly groupHubspotId?: Maybe<StringFilter>;
  /** Filter by the object’s `customSubdomain` field. */
  readonly customSubdomain?: Maybe<StringFilter>;
  /** Filter by the object’s `text2Disclaimer` field. */
  readonly text2Disclaimer?: Maybe<StringFilter>;
  /** Filter by the object’s `activeVersion` field. */
  readonly activeVersion?: Maybe<UuidFilter>;
  /** Filter by the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<IntFilter>;
  /** Filter by the object’s `collectFullAddress` field. */
  readonly collectFullAddress?: Maybe<BooleanFilter>;
  /** Filter by the object’s `targetUrl` field. */
  readonly targetUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `freeShipping` field. */
  readonly freeShipping?: Maybe<BooleanFilter>;
  /** Filter by the object’s `mailOrder` field. */
  readonly mailOrder?: Maybe<BooleanFilter>;
  /** Filter by the object’s `collectLeadQr` field. */
  readonly collectLeadQr?: Maybe<BooleanFilter>;
  /** Filter by the object’s `homePageUrl` field. */
  readonly homePageUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `hideAds` field. */
  readonly hideAds?: Maybe<BooleanFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<ReadonlyArray<CatalogFilter>>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<ReadonlyArray<CatalogFilter>>;
  /** Negates the expression. */
  readonly not?: Maybe<CatalogFilter>;
};

/** An input for mutations affecting `Catalog` */
export type CatalogInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly cover?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly catalogFormat?: Maybe<Scalars['String']>;
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly leadCharge?: Maybe<Scalars['Float']>;
  readonly linkCharge?: Maybe<Scalars['Float']>;
  readonly digitalFee?: Maybe<Scalars['Float']>;
  readonly listingFee?: Maybe<Scalars['Float']>;
  readonly dateExpired?: Maybe<Scalars['Datetime']>;
  readonly trackingCode?: Maybe<Scalars['String']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly showCategoryLinks?: Maybe<Scalars['Boolean']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Datetime']>;
  readonly offerCopy?: Maybe<Scalars['String']>;
  readonly introCopy?: Maybe<Scalars['String']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  readonly blurb?: Maybe<Scalars['String']>;
  readonly featureCopy?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly keywords?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly longDescription?: Maybe<Scalars['String']>;
  readonly class?: Maybe<Scalars['String']>;
  readonly searchAppendCode?: Maybe<Scalars['String']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly primaryDepartment?: Maybe<Scalars['UUID']>;
  readonly isDigital?: Maybe<Scalars['Boolean']>;
  readonly merchantId?: Maybe<Scalars['BigInt']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly autoActivate?: Maybe<Scalars['Boolean']>;
  readonly groupHubspotId?: Maybe<Scalars['String']>;
  readonly customSubdomain?: Maybe<Scalars['String']>;
  readonly text2Disclaimer?: Maybe<Scalars['String']>;
  readonly activeVersion?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly collectFullAddress?: Maybe<Scalars['Boolean']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly freeShipping?: Maybe<Scalars['Boolean']>;
  readonly mailOrder?: Maybe<Scalars['Boolean']>;
  readonly collectLeadQr?: Maybe<Scalars['Boolean']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly hideAds?: Maybe<Scalars['Boolean']>;
  readonly accountToAccount?: Maybe<FkCatalogAccountInput>;
  readonly departmentToPrimaryDepartment?: Maybe<FkCatalogPrimaryDepartmentInput>;
  readonly versionToActiveVersion?: Maybe<FkCatalogActiveVersionInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInverseInput>;
  readonly versionsUsingOid?: Maybe<FkVersionCatalogInverseInput>;
  readonly offersUsingOid?: Maybe<FkOfferCatalogInverseInput>;
  readonly categoriesUsingOid?: Maybe<FkCategoryCatalogInverseInput>;
  readonly feedsUsingOid?: Maybe<FkFeedCatalogInverseInput>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCatalogInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCatalogInverseInput>;
  readonly productsUsingOid?: Maybe<FkProductCatalogInverseInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCatalogInverseInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingCatalogInverseInput>;
  readonly categoryByCatalogsUsingOid?: Maybe<FakePublicCategoryByCatalogForeignKey0InverseInput>;
};

/** A connection to a list of `Offer` values, with data from `CarouselListing`. */
export type CatalogOffersByCarouselListingCatalogAndOfferManyToManyConnection = {
  readonly __typename?: 'CatalogOffersByCarouselListingCatalogAndOfferManyToManyConnection';
  /** A list of `Offer` objects. */
  readonly nodes: ReadonlyArray<Offer>;
  /** A list of edges which contains the `Offer`, info from the `CarouselListing`, and the cursor to aid in pagination. */
  readonly edges: ReadonlyArray<CatalogOffersByCarouselListingCatalogAndOfferManyToManyEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Offer` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Offer` edge in the connection, with data from `CarouselListing`. */
export type CatalogOffersByCarouselListingCatalogAndOfferManyToManyEdge = {
  readonly __typename?: 'CatalogOffersByCarouselListingCatalogAndOfferManyToManyEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Offer` at the end of the edge. */
  readonly node: Offer;
  /** Reads and enables pagination through a set of `CarouselListing`. */
  readonly carouselListingsByOfferConnection: CarouselListingsConnection;
  /** Reads and enables pagination through a set of `CarouselListing`. */
  readonly carouselListingsByOffer: ReadonlyArray<CarouselListing>;
};


/** A `Offer` edge in the connection, with data from `CarouselListing`. */
export type CatalogOffersByCarouselListingCatalogAndOfferManyToManyEdgeCarouselListingsByOfferConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CarouselListingsOrderBy>>;
  condition?: Maybe<CarouselListingCondition>;
  filter?: Maybe<CarouselListingFilter>;
};


/** A `Offer` edge in the connection, with data from `CarouselListing`. */
export type CatalogOffersByCarouselListingCatalogAndOfferManyToManyEdgeCarouselListingsByOfferArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CarouselListingsOrderBy>>;
  condition?: Maybe<CarouselListingCondition>;
  filter?: Maybe<CarouselListingFilter>;
};

/** The fields on `catalog` to look up the row to update. */
export type CatalogOnCampaignGroupForFkCampaignGroupCatalogUsingCatalogByIdUpdate = {
  /** An object where the defined keys will be set on the `catalog` being updated. */
  readonly patch: UpdateCatalogOnCampaignGroupForFkCampaignGroupCatalogPatch;
  readonly id: Scalars['Int'];
};

/** The fields on `catalog` to look up the row to update. */
export type CatalogOnCampaignGroupForFkCampaignGroupCatalogUsingPkCatalogUpdate = {
  /** An object where the defined keys will be set on the `catalog` being updated. */
  readonly patch: UpdateCatalogOnCampaignGroupForFkCampaignGroupCatalogPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `catalog` to look up the row to update. */
export type CatalogOnCarouselListingForFkCarouselListingCatalogUsingCatalogByIdUpdate = {
  /** An object where the defined keys will be set on the `catalog` being updated. */
  readonly patch: UpdateCatalogOnCarouselListingForFkCarouselListingCatalogPatch;
  readonly id: Scalars['Int'];
};

/** The fields on `catalog` to look up the row to update. */
export type CatalogOnCarouselListingForFkCarouselListingCatalogUsingPkCatalogUpdate = {
  /** An object where the defined keys will be set on the `catalog` being updated. */
  readonly patch: UpdateCatalogOnCarouselListingForFkCarouselListingCatalogPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `catalog` to look up the row to update. */
export type CatalogOnCatalogForFkCatalogAccountUsingCatalogByIdUpdate = {
  /** An object where the defined keys will be set on the `catalog` being updated. */
  readonly patch: UpdateCatalogOnCatalogForFkCatalogAccountPatch;
  readonly id: Scalars['Int'];
};

/** The fields on `catalog` to look up the row to update. */
export type CatalogOnCatalogForFkCatalogAccountUsingPkCatalogUpdate = {
  /** An object where the defined keys will be set on the `catalog` being updated. */
  readonly patch: UpdateCatalogOnCatalogForFkCatalogAccountPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `catalog` to look up the row to update. */
export type CatalogOnCatalogForFkCatalogActiveVersionUsingCatalogByIdUpdate = {
  /** An object where the defined keys will be set on the `catalog` being updated. */
  readonly patch: UpdateCatalogOnCatalogForFkCatalogActiveVersionPatch;
  readonly id: Scalars['Int'];
};

/** The fields on `catalog` to look up the row to update. */
export type CatalogOnCatalogForFkCatalogActiveVersionUsingPkCatalogUpdate = {
  /** An object where the defined keys will be set on the `catalog` being updated. */
  readonly patch: UpdateCatalogOnCatalogForFkCatalogActiveVersionPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `catalog` to look up the row to update. */
export type CatalogOnCatalogForFkCatalogPrimaryDepartmentUsingCatalogByIdUpdate = {
  /** An object where the defined keys will be set on the `catalog` being updated. */
  readonly patch: UpdateCatalogOnCatalogForFkCatalogPrimaryDepartmentPatch;
  readonly id: Scalars['Int'];
};

/** The fields on `catalog` to look up the row to update. */
export type CatalogOnCatalogForFkCatalogPrimaryDepartmentUsingPkCatalogUpdate = {
  /** An object where the defined keys will be set on the `catalog` being updated. */
  readonly patch: UpdateCatalogOnCatalogForFkCatalogPrimaryDepartmentPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `catalog` to look up the row to update. */
export type CatalogOnCategoryByCatalogForFakePublicCategoryByCatalogForeignKey0UsingCatalogByIdUpdate = {
  /** An object where the defined keys will be set on the `catalog` being updated. */
  readonly patch: UpdateCatalogOnCategoryByCatalogForFakePublicCategoryByCatalogForeignKey0Patch;
  readonly id: Scalars['Int'];
};

/** The fields on `catalog` to look up the row to update. */
export type CatalogOnCategoryByCatalogForFakePublicCategoryByCatalogForeignKey0UsingPkCatalogUpdate = {
  /** An object where the defined keys will be set on the `catalog` being updated. */
  readonly patch: UpdateCatalogOnCategoryByCatalogForFakePublicCategoryByCatalogForeignKey0Patch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `catalog` to look up the row to update. */
export type CatalogOnCategoryForFkCategoryCatalogUsingCatalogByIdUpdate = {
  /** An object where the defined keys will be set on the `catalog` being updated. */
  readonly patch: UpdateCatalogOnCategoryForFkCategoryCatalogPatch;
  readonly id: Scalars['Int'];
};

/** The fields on `catalog` to look up the row to update. */
export type CatalogOnCategoryForFkCategoryCatalogUsingPkCatalogUpdate = {
  /** An object where the defined keys will be set on the `catalog` being updated. */
  readonly patch: UpdateCatalogOnCategoryForFkCategoryCatalogPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `catalog` to look up the row to update. */
export type CatalogOnDepartmentDepartmentsCatalogListingForFkDepartmentDepartmentsCatalogListingsListingsUsingCatalogByIdUpdate = {
  /** An object where the defined keys will be set on the `catalog` being updated. */
  readonly patch: UpdateCatalogOnDepartmentDepartmentsCatalogListingForFkDepartmentDepartmentsCatalogListingsListingsPatch;
  readonly id: Scalars['Int'];
};

/** The fields on `catalog` to look up the row to update. */
export type CatalogOnDepartmentDepartmentsCatalogListingForFkDepartmentDepartmentsCatalogListingsListingsUsingPkCatalogUpdate = {
  /** An object where the defined keys will be set on the `catalog` being updated. */
  readonly patch: UpdateCatalogOnDepartmentDepartmentsCatalogListingForFkDepartmentDepartmentsCatalogListingsListingsPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `catalog` to look up the row to update. */
export type CatalogOnFeedForFkFeedCatalogUsingCatalogByIdUpdate = {
  /** An object where the defined keys will be set on the `catalog` being updated. */
  readonly patch: UpdateCatalogOnFeedForFkFeedCatalogPatch;
  readonly id: Scalars['Int'];
};

/** The fields on `catalog` to look up the row to update. */
export type CatalogOnFeedForFkFeedCatalogUsingPkCatalogUpdate = {
  /** An object where the defined keys will be set on the `catalog` being updated. */
  readonly patch: UpdateCatalogOnFeedForFkFeedCatalogPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `catalog` to look up the row to update. */
export type CatalogOnLinkOutForFkLinkOutCatalogUsingCatalogByIdUpdate = {
  /** An object where the defined keys will be set on the `catalog` being updated. */
  readonly patch: UpdateCatalogOnLinkOutForFkLinkOutCatalogPatch;
  readonly id: Scalars['Int'];
};

/** The fields on `catalog` to look up the row to update. */
export type CatalogOnLinkOutForFkLinkOutCatalogUsingPkCatalogUpdate = {
  /** An object where the defined keys will be set on the `catalog` being updated. */
  readonly patch: UpdateCatalogOnLinkOutForFkLinkOutCatalogPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `catalog` to look up the row to update. */
export type CatalogOnOfferForFkOfferCatalogUsingCatalogByIdUpdate = {
  /** An object where the defined keys will be set on the `catalog` being updated. */
  readonly patch: UpdateCatalogOnOfferForFkOfferCatalogPatch;
  readonly id: Scalars['Int'];
};

/** The fields on `catalog` to look up the row to update. */
export type CatalogOnOfferForFkOfferCatalogUsingPkCatalogUpdate = {
  /** An object where the defined keys will be set on the `catalog` being updated. */
  readonly patch: UpdateCatalogOnOfferForFkOfferCatalogPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `catalog` to look up the row to update. */
export type CatalogOnProductForFkProductCatalogUsingCatalogByIdUpdate = {
  /** An object where the defined keys will be set on the `catalog` being updated. */
  readonly patch: UpdateCatalogOnProductForFkProductCatalogPatch;
  readonly id: Scalars['Int'];
};

/** The fields on `catalog` to look up the row to update. */
export type CatalogOnProductForFkProductCatalogUsingPkCatalogUpdate = {
  /** An object where the defined keys will be set on the `catalog` being updated. */
  readonly patch: UpdateCatalogOnProductForFkProductCatalogPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `catalog` to look up the row to update. */
export type CatalogOnSubscriptionForFkSubscriptionCatalogUsingCatalogByIdUpdate = {
  /** An object where the defined keys will be set on the `catalog` being updated. */
  readonly patch: UpdateCatalogOnSubscriptionForFkSubscriptionCatalogPatch;
  readonly id: Scalars['Int'];
};

/** The fields on `catalog` to look up the row to update. */
export type CatalogOnSubscriptionForFkSubscriptionCatalogUsingPkCatalogUpdate = {
  /** An object where the defined keys will be set on the `catalog` being updated. */
  readonly patch: UpdateCatalogOnSubscriptionForFkSubscriptionCatalogPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `catalog` to look up the row to update. */
export type CatalogOnVersionForFkVersionCatalogUsingCatalogByIdUpdate = {
  /** An object where the defined keys will be set on the `catalog` being updated. */
  readonly patch: UpdateCatalogOnVersionForFkVersionCatalogPatch;
  readonly id: Scalars['Int'];
};

/** The fields on `catalog` to look up the row to update. */
export type CatalogOnVersionForFkVersionCatalogUsingPkCatalogUpdate = {
  /** An object where the defined keys will be set on the `catalog` being updated. */
  readonly patch: UpdateCatalogOnVersionForFkVersionCatalogPatch;
  readonly oid: Scalars['UUID'];
};

/** Represents an update to a `Catalog`. Fields that are set will be updated. */
export type CatalogPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly cover?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly catalogFormat?: Maybe<Scalars['String']>;
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly leadCharge?: Maybe<Scalars['Float']>;
  readonly linkCharge?: Maybe<Scalars['Float']>;
  readonly digitalFee?: Maybe<Scalars['Float']>;
  readonly listingFee?: Maybe<Scalars['Float']>;
  readonly dateExpired?: Maybe<Scalars['Datetime']>;
  readonly trackingCode?: Maybe<Scalars['String']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly showCategoryLinks?: Maybe<Scalars['Boolean']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Datetime']>;
  readonly offerCopy?: Maybe<Scalars['String']>;
  readonly introCopy?: Maybe<Scalars['String']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  readonly blurb?: Maybe<Scalars['String']>;
  readonly featureCopy?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly keywords?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly longDescription?: Maybe<Scalars['String']>;
  readonly class?: Maybe<Scalars['String']>;
  readonly searchAppendCode?: Maybe<Scalars['String']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly primaryDepartment?: Maybe<Scalars['UUID']>;
  readonly isDigital?: Maybe<Scalars['Boolean']>;
  readonly merchantId?: Maybe<Scalars['BigInt']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly autoActivate?: Maybe<Scalars['Boolean']>;
  readonly groupHubspotId?: Maybe<Scalars['String']>;
  readonly customSubdomain?: Maybe<Scalars['String']>;
  readonly text2Disclaimer?: Maybe<Scalars['String']>;
  readonly activeVersion?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly collectFullAddress?: Maybe<Scalars['Boolean']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly freeShipping?: Maybe<Scalars['Boolean']>;
  readonly mailOrder?: Maybe<Scalars['Boolean']>;
  readonly collectLeadQr?: Maybe<Scalars['Boolean']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly hideAds?: Maybe<Scalars['Boolean']>;
  readonly accountToAccount?: Maybe<FkCatalogAccountInput>;
  readonly departmentToPrimaryDepartment?: Maybe<FkCatalogPrimaryDepartmentInput>;
  readonly versionToActiveVersion?: Maybe<FkCatalogActiveVersionInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInverseInput>;
  readonly versionsUsingOid?: Maybe<FkVersionCatalogInverseInput>;
  readonly offersUsingOid?: Maybe<FkOfferCatalogInverseInput>;
  readonly categoriesUsingOid?: Maybe<FkCategoryCatalogInverseInput>;
  readonly feedsUsingOid?: Maybe<FkFeedCatalogInverseInput>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCatalogInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCatalogInverseInput>;
  readonly productsUsingOid?: Maybe<FkProductCatalogInverseInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCatalogInverseInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingCatalogInverseInput>;
  readonly categoryByCatalogsUsingOid?: Maybe<FakePublicCategoryByCatalogForeignKey0InverseInput>;
};

/** The fields on `catalog` to look up the row to connect. */
export type CatalogPkCatalogConnect = {
  readonly oid: Scalars['UUID'];
};

/** The fields on `catalog` to look up the row to delete. */
export type CatalogPkCatalogDelete = {
  readonly oid: Scalars['UUID'];
};

/** A connection to a list of `Profile` values, with data from `LinkOut`. */
export type CatalogProfilesByLinkOutCatalogAndCustomerManyToManyConnection = {
  readonly __typename?: 'CatalogProfilesByLinkOutCatalogAndCustomerManyToManyConnection';
  /** A list of `Profile` objects. */
  readonly nodes: ReadonlyArray<Profile>;
  /** A list of edges which contains the `Profile`, info from the `LinkOut`, and the cursor to aid in pagination. */
  readonly edges: ReadonlyArray<CatalogProfilesByLinkOutCatalogAndCustomerManyToManyEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Profile` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Profile` edge in the connection, with data from `LinkOut`. */
export type CatalogProfilesByLinkOutCatalogAndCustomerManyToManyEdge = {
  readonly __typename?: 'CatalogProfilesByLinkOutCatalogAndCustomerManyToManyEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Profile` at the end of the edge. */
  readonly node: Profile;
  /** Reads and enables pagination through a set of `LinkOut`. */
  readonly linkOutsByCustomerConnection: LinkOutsConnection;
  /** Reads and enables pagination through a set of `LinkOut`. */
  readonly linkOutsByCustomer: ReadonlyArray<LinkOut>;
};


/** A `Profile` edge in the connection, with data from `LinkOut`. */
export type CatalogProfilesByLinkOutCatalogAndCustomerManyToManyEdgeLinkOutsByCustomerConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<LinkOutsOrderBy>>;
  condition?: Maybe<LinkOutCondition>;
  filter?: Maybe<LinkOutFilter>;
};


/** A `Profile` edge in the connection, with data from `LinkOut`. */
export type CatalogProfilesByLinkOutCatalogAndCustomerManyToManyEdgeLinkOutsByCustomerArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<LinkOutsOrderBy>>;
  condition?: Maybe<LinkOutCondition>;
  filter?: Maybe<LinkOutFilter>;
};

/** A connection to a list of `Profile` values, with data from `Subscription`. */
export type CatalogProfilesBySubscriptionCatalogAndCustomerManyToManyConnection = {
  readonly __typename?: 'CatalogProfilesBySubscriptionCatalogAndCustomerManyToManyConnection';
  /** A list of `Profile` objects. */
  readonly nodes: ReadonlyArray<Profile>;
  /** A list of edges which contains the `Profile`, info from the `Subscription`, and the cursor to aid in pagination. */
  readonly edges: ReadonlyArray<CatalogProfilesBySubscriptionCatalogAndCustomerManyToManyEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Profile` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Profile` edge in the connection, with data from `Subscription`. */
export type CatalogProfilesBySubscriptionCatalogAndCustomerManyToManyEdge = {
  readonly __typename?: 'CatalogProfilesBySubscriptionCatalogAndCustomerManyToManyEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Profile` at the end of the edge. */
  readonly node: Profile;
  /** Reads and enables pagination through a set of `Subscription`. */
  readonly subscriptionsByCustomerConnection: SubscriptionsConnection;
  /** Reads and enables pagination through a set of `Subscription`. */
  readonly subscriptionsByCustomer: ReadonlyArray<Subscription>;
};


/** A `Profile` edge in the connection, with data from `Subscription`. */
export type CatalogProfilesBySubscriptionCatalogAndCustomerManyToManyEdgeSubscriptionsByCustomerConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<SubscriptionsOrderBy>>;
  condition?: Maybe<SubscriptionCondition>;
  filter?: Maybe<SubscriptionFilter>;
};


/** A `Profile` edge in the connection, with data from `Subscription`. */
export type CatalogProfilesBySubscriptionCatalogAndCustomerManyToManyEdgeSubscriptionsByCustomerArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<SubscriptionsOrderBy>>;
  condition?: Maybe<SubscriptionCondition>;
  filter?: Maybe<SubscriptionFilter>;
};

export type CatalogSearch = {
  readonly __typename?: 'CatalogSearch';
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly primaryDepartment?: Maybe<Scalars['UUID']>;
  readonly textsearch?: Maybe<Scalars['String']>;
};

/** A condition to be used against `CatalogSearch` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type CatalogSearchCondition = {
  /** Checks for equality with the object’s `oid` field. */
  readonly oid?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `primaryDepartment` field. */
  readonly primaryDepartment?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `textsearch` field. */
  readonly textsearch?: Maybe<Scalars['String']>;
};

/** A filter to be used against `CatalogSearch` object types. All fields are combined with a logical ‘and.’ */
export type CatalogSearchFilter = {
  /** Filter by the object’s `oid` field. */
  readonly oid?: Maybe<UuidFilter>;
  /** Filter by the object’s `primaryDepartment` field. */
  readonly primaryDepartment?: Maybe<UuidFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<ReadonlyArray<CatalogSearchFilter>>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<ReadonlyArray<CatalogSearchFilter>>;
  /** Negates the expression. */
  readonly not?: Maybe<CatalogSearchFilter>;
};

export type CatalogSearchLive = {
  readonly __typename?: 'CatalogSearchLive';
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly primaryDepartment?: Maybe<Scalars['UUID']>;
  readonly textsearch?: Maybe<Scalars['String']>;
};

/** A condition to be used against `CatalogSearchLive` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type CatalogSearchLiveCondition = {
  /** Checks for equality with the object’s `oid` field. */
  readonly oid?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `primaryDepartment` field. */
  readonly primaryDepartment?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `textsearch` field. */
  readonly textsearch?: Maybe<Scalars['String']>;
};

/** A filter to be used against `CatalogSearchLive` object types. All fields are combined with a logical ‘and.’ */
export type CatalogSearchLiveFilter = {
  /** Filter by the object’s `oid` field. */
  readonly oid?: Maybe<UuidFilter>;
  /** Filter by the object’s `primaryDepartment` field. */
  readonly primaryDepartment?: Maybe<UuidFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<ReadonlyArray<CatalogSearchLiveFilter>>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<ReadonlyArray<CatalogSearchLiveFilter>>;
  /** Negates the expression. */
  readonly not?: Maybe<CatalogSearchLiveFilter>;
};

/** An input for mutations affecting `CatalogSearchLive` */
export type CatalogSearchLiveInput = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly primaryDepartment?: Maybe<Scalars['UUID']>;
  readonly textsearch?: Maybe<Scalars['String']>;
};

/** A connection to a list of `CatalogSearchLive` values. */
export type CatalogSearchLivesConnection = {
  readonly __typename?: 'CatalogSearchLivesConnection';
  /** A list of `CatalogSearchLive` objects. */
  readonly nodes: ReadonlyArray<CatalogSearchLive>;
  /** A list of edges which contains the `CatalogSearchLive` and cursor to aid in pagination. */
  readonly edges: ReadonlyArray<CatalogSearchLivesEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `CatalogSearchLive` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `CatalogSearchLive` edge in the connection. */
export type CatalogSearchLivesEdge = {
  readonly __typename?: 'CatalogSearchLivesEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `CatalogSearchLive` at the end of the edge. */
  readonly node: CatalogSearchLive;
};

/** Methods to use when ordering `CatalogSearchLive`. */
export enum CatalogSearchLivesOrderBy {
  Natural = 'NATURAL',
  OidAsc = 'OID_ASC',
  OidDesc = 'OID_DESC',
  PrimaryDepartmentAsc = 'PRIMARY_DEPARTMENT_ASC',
  PrimaryDepartmentDesc = 'PRIMARY_DEPARTMENT_DESC',
  TextsearchAsc = 'TEXTSEARCH_ASC',
  TextsearchDesc = 'TEXTSEARCH_DESC'
}

/** A connection to a list of `CatalogSearch` values. */
export type CatalogSearchesConnection = {
  readonly __typename?: 'CatalogSearchesConnection';
  /** A list of `CatalogSearch` objects. */
  readonly nodes: ReadonlyArray<CatalogSearch>;
  /** A list of edges which contains the `CatalogSearch` and cursor to aid in pagination. */
  readonly edges: ReadonlyArray<CatalogSearchesEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `CatalogSearch` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `CatalogSearch` edge in the connection. */
export type CatalogSearchesEdge = {
  readonly __typename?: 'CatalogSearchesEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `CatalogSearch` at the end of the edge. */
  readonly node: CatalogSearch;
};

/** Methods to use when ordering `CatalogSearch`. */
export enum CatalogSearchesOrderBy {
  Natural = 'NATURAL',
  OidAsc = 'OID_ASC',
  OidDesc = 'OID_DESC',
  PrimaryDepartmentAsc = 'PRIMARY_DEPARTMENT_ASC',
  PrimaryDepartmentDesc = 'PRIMARY_DEPARTMENT_DESC',
  TextsearchAsc = 'TEXTSEARCH_ASC',
  TextsearchDesc = 'TEXTSEARCH_DESC'
}

/** A connection to a list of `Catalog` values. */
export type CatalogsConnection = {
  readonly __typename?: 'CatalogsConnection';
  /** A list of `Catalog` objects. */
  readonly nodes: ReadonlyArray<Catalog>;
  /** A list of edges which contains the `Catalog` and cursor to aid in pagination. */
  readonly edges: ReadonlyArray<CatalogsEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Catalog` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Catalog` edge in the connection. */
export type CatalogsEdge = {
  readonly __typename?: 'CatalogsEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Catalog` at the end of the edge. */
  readonly node: Catalog;
};

/** Methods to use when ordering `Catalog`. */
export enum CatalogsOrderBy {
  Natural = 'NATURAL',
  OidAsc = 'OID_ASC',
  OidDesc = 'OID_DESC',
  OptionsAsc = 'OPTIONS_ASC',
  OptionsDesc = 'OPTIONS_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  LegacyIdAsc = 'LEGACY_ID_ASC',
  LegacyIdDesc = 'LEGACY_ID_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  CoverAsc = 'COVER_ASC',
  CoverDesc = 'COVER_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  ActiveAsc = 'ACTIVE_ASC',
  ActiveDesc = 'ACTIVE_DESC',
  CatalogFormatAsc = 'CATALOG_FORMAT_ASC',
  CatalogFormatDesc = 'CATALOG_FORMAT_DESC',
  LinkUrlAsc = 'LINK_URL_ASC',
  LinkUrlDesc = 'LINK_URL_DESC',
  LeadChargeAsc = 'LEAD_CHARGE_ASC',
  LeadChargeDesc = 'LEAD_CHARGE_DESC',
  LinkChargeAsc = 'LINK_CHARGE_ASC',
  LinkChargeDesc = 'LINK_CHARGE_DESC',
  DigitalFeeAsc = 'DIGITAL_FEE_ASC',
  DigitalFeeDesc = 'DIGITAL_FEE_DESC',
  ListingFeeAsc = 'LISTING_FEE_ASC',
  ListingFeeDesc = 'LISTING_FEE_DESC',
  DateExpiredAsc = 'DATE_EXPIRED_ASC',
  DateExpiredDesc = 'DATE_EXPIRED_DESC',
  TrackingCodeAsc = 'TRACKING_CODE_ASC',
  TrackingCodeDesc = 'TRACKING_CODE_DESC',
  HeadingAsc = 'HEADING_ASC',
  HeadingDesc = 'HEADING_DESC',
  ShowCategoryLinksAsc = 'SHOW_CATEGORY_LINKS_ASC',
  ShowCategoryLinksDesc = 'SHOW_CATEGORY_LINKS_DESC',
  CommentsAsc = 'COMMENTS_ASC',
  CommentsDesc = 'COMMENTS_DESC',
  StartDateAsc = 'START_DATE_ASC',
  StartDateDesc = 'START_DATE_DESC',
  OfferCopyAsc = 'OFFER_COPY_ASC',
  OfferCopyDesc = 'OFFER_COPY_DESC',
  IntroCopyAsc = 'INTRO_COPY_ASC',
  IntroCopyDesc = 'INTRO_COPY_DESC',
  MetaTitleAsc = 'META_TITLE_ASC',
  MetaTitleDesc = 'META_TITLE_DESC',
  MetaDescriptionAsc = 'META_DESCRIPTION_ASC',
  MetaDescriptionDesc = 'META_DESCRIPTION_DESC',
  BlurbAsc = 'BLURB_ASC',
  BlurbDesc = 'BLURB_DESC',
  FeatureCopyAsc = 'FEATURE_COPY_ASC',
  FeatureCopyDesc = 'FEATURE_COPY_DESC',
  AltTextAsc = 'ALT_TEXT_ASC',
  AltTextDesc = 'ALT_TEXT_DESC',
  KeywordsAsc = 'KEYWORDS_ASC',
  KeywordsDesc = 'KEYWORDS_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  LongDescriptionAsc = 'LONG_DESCRIPTION_ASC',
  LongDescriptionDesc = 'LONG_DESCRIPTION_DESC',
  ClassAsc = 'CLASS_ASC',
  ClassDesc = 'CLASS_DESC',
  SearchAppendCodeAsc = 'SEARCH_APPEND_CODE_ASC',
  SearchAppendCodeDesc = 'SEARCH_APPEND_CODE_DESC',
  AccountAsc = 'ACCOUNT_ASC',
  AccountDesc = 'ACCOUNT_DESC',
  PrimaryDepartmentAsc = 'PRIMARY_DEPARTMENT_ASC',
  PrimaryDepartmentDesc = 'PRIMARY_DEPARTMENT_DESC',
  IsDigitalAsc = 'IS_DIGITAL_ASC',
  IsDigitalDesc = 'IS_DIGITAL_DESC',
  MerchantIdAsc = 'MERCHANT_ID_ASC',
  MerchantIdDesc = 'MERCHANT_ID_DESC',
  LabelAsc = 'LABEL_ASC',
  LabelDesc = 'LABEL_DESC',
  AutoActivateAsc = 'AUTO_ACTIVATE_ASC',
  AutoActivateDesc = 'AUTO_ACTIVATE_DESC',
  GroupHubspotIdAsc = 'GROUP_HUBSPOT_ID_ASC',
  GroupHubspotIdDesc = 'GROUP_HUBSPOT_ID_DESC',
  CustomSubdomainAsc = 'CUSTOM_SUBDOMAIN_ASC',
  CustomSubdomainDesc = 'CUSTOM_SUBDOMAIN_DESC',
  Text2DisclaimerAsc = 'TEXT2DISCLAIMER_ASC',
  Text2DisclaimerDesc = 'TEXT2DISCLAIMER_DESC',
  ActiveVersionAsc = 'ACTIVE_VERSION_ASC',
  ActiveVersionDesc = 'ACTIVE_VERSION_DESC',
  OptimisticLockFieldAsc = 'OPTIMISTIC_LOCK_FIELD_ASC',
  OptimisticLockFieldDesc = 'OPTIMISTIC_LOCK_FIELD_DESC',
  CollectFullAddressAsc = 'COLLECT_FULL_ADDRESS_ASC',
  CollectFullAddressDesc = 'COLLECT_FULL_ADDRESS_DESC',
  TargetUrlAsc = 'TARGET_URL_ASC',
  TargetUrlDesc = 'TARGET_URL_DESC',
  FreeShippingAsc = 'FREE_SHIPPING_ASC',
  FreeShippingDesc = 'FREE_SHIPPING_DESC',
  MailOrderAsc = 'MAIL_ORDER_ASC',
  MailOrderDesc = 'MAIL_ORDER_DESC',
  CollectLeadQrAsc = 'COLLECT_LEAD_QR_ASC',
  CollectLeadQrDesc = 'COLLECT_LEAD_QR_DESC',
  HomePageUrlAsc = 'HOME_PAGE_URL_ASC',
  HomePageUrlDesc = 'HOME_PAGE_URL_DESC',
  HideAdsAsc = 'HIDE_ADS_ASC',
  HideAdsDesc = 'HIDE_ADS_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A connection to a list of `Category` values. */
export type CategoriesConnection = {
  readonly __typename?: 'CategoriesConnection';
  /** A list of `Category` objects. */
  readonly nodes: ReadonlyArray<Category>;
  /** A list of edges which contains the `Category` and cursor to aid in pagination. */
  readonly edges: ReadonlyArray<CategoriesEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Category` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Category` edge in the connection. */
export type CategoriesEdge = {
  readonly __typename?: 'CategoriesEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Category` at the end of the edge. */
  readonly node: Category;
};

/** Methods to use when ordering `Category`. */
export enum CategoriesOrderBy {
  Natural = 'NATURAL',
  OidAsc = 'OID_ASC',
  OidDesc = 'OID_DESC',
  OptionsAsc = 'OPTIONS_ASC',
  OptionsDesc = 'OPTIONS_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  FeaturedAsc = 'FEATURED_ASC',
  FeaturedDesc = 'FEATURED_DESC',
  ImageAsc = 'IMAGE_ASC',
  ImageDesc = 'IMAGE_DESC',
  ActiveAsc = 'ACTIVE_ASC',
  ActiveDesc = 'ACTIVE_DESC',
  LinkoutUrlAsc = 'LINKOUT_URL_ASC',
  LinkoutUrlDesc = 'LINKOUT_URL_DESC',
  ProductsPerPageAsc = 'PRODUCTS_PER_PAGE_ASC',
  ProductsPerPageDesc = 'PRODUCTS_PER_PAGE_DESC',
  ImageOrientationAsc = 'IMAGE_ORIENTATION_ASC',
  ImageOrientationDesc = 'IMAGE_ORIENTATION_DESC',
  ProductBorderAsc = 'PRODUCT_BORDER_ASC',
  ProductBorderDesc = 'PRODUCT_BORDER_DESC',
  AnimatedAsc = 'ANIMATED_ASC',
  AnimatedDesc = 'ANIMATED_DESC',
  CatalogAsc = 'CATALOG_ASC',
  CatalogDesc = 'CATALOG_DESC',
  RankOrderAsc = 'RANK_ORDER_ASC',
  RankOrderDesc = 'RANK_ORDER_DESC',
  OptimisticLockFieldAsc = 'OPTIMISTIC_LOCK_FIELD_ASC',
  OptimisticLockFieldDesc = 'OPTIMISTIC_LOCK_FIELD_DESC',
  TargetUrlAsc = 'TARGET_URL_ASC',
  TargetUrlDesc = 'TARGET_URL_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type Category = {
  readonly __typename?: 'Category';
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly featured?: Maybe<Scalars['Int']>;
  readonly image?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly linkoutUrl?: Maybe<Scalars['String']>;
  readonly productsPerPage?: Maybe<Scalars['Int']>;
  readonly imageOrientation?: Maybe<Scalars['Boolean']>;
  readonly productBorder?: Maybe<Scalars['Boolean']>;
  readonly animated?: Maybe<Scalars['Boolean']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly rankOrder?: Maybe<Scalars['Int']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  /** Reads a single `Catalog` that is related to this `Category`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
  /** Reads and enables pagination through a set of `SubCategory`. */
  readonly subCategoriesByCategoryConnection: SubCategoriesConnection;
  /** Reads and enables pagination through a set of `SubCategory`. */
  readonly subCategoriesByCategory: ReadonlyArray<SubCategory>;
  /** Reads and enables pagination through a set of `SubProduct`. */
  readonly subProductsByMainAndCatalogConnection: SubProductsConnection;
  /** Reads and enables pagination through a set of `SubProduct`. */
  readonly subProductsByMainAndCatalog: ReadonlyArray<SubProduct>;
};


export type CategorySubCategoriesByCategoryConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<SubCategoriesOrderBy>>;
  condition?: Maybe<SubCategoryCondition>;
  filter?: Maybe<SubCategoryFilter>;
};


export type CategorySubCategoriesByCategoryArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<SubCategoriesOrderBy>>;
  condition?: Maybe<SubCategoryCondition>;
  filter?: Maybe<SubCategoryFilter>;
};


export type CategorySubProductsByMainAndCatalogConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<SubProductsOrderBy>>;
  condition?: Maybe<SubProductCondition>;
  filter?: Maybe<SubProductFilter>;
};


export type CategorySubProductsByMainAndCatalogArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<SubProductsOrderBy>>;
  condition?: Maybe<SubProductCondition>;
  filter?: Maybe<SubProductFilter>;
};

export type CategoryByCatalog = {
  readonly __typename?: 'CategoryByCatalog';
  readonly category?: Maybe<Scalars['String']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly main?: Maybe<Scalars['String']>;
  readonly sub?: Maybe<Scalars['String']>;
  /** Reads a single `Catalog` that is related to this `CategoryByCatalog`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
  /** Reads and enables pagination through a set of `Product`. */
  readonly productsByCategoryAndCatalogConnection: ProductsConnection;
  /** Reads and enables pagination through a set of `Product`. */
  readonly productsByCategoryAndCatalog: ReadonlyArray<Product>;
};


export type CategoryByCatalogProductsByCategoryAndCatalogConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<ProductsOrderBy>>;
  condition?: Maybe<ProductCondition>;
  filter?: Maybe<ProductFilter>;
};


export type CategoryByCatalogProductsByCategoryAndCatalogArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<ProductsOrderBy>>;
  condition?: Maybe<ProductCondition>;
  filter?: Maybe<ProductFilter>;
};

/** A condition to be used against `CategoryByCatalog` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type CategoryByCatalogCondition = {
  /** Checks for equality with the object’s `category` field. */
  readonly category?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `catalog` field. */
  readonly catalog?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `main` field. */
  readonly main?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `sub` field. */
  readonly sub?: Maybe<Scalars['String']>;
};

/** A filter to be used against `CategoryByCatalog` object types. All fields are combined with a logical ‘and.’ */
export type CategoryByCatalogFilter = {
  /** Filter by the object’s `category` field. */
  readonly category?: Maybe<StringFilter>;
  /** Filter by the object’s `catalog` field. */
  readonly catalog?: Maybe<UuidFilter>;
  /** Filter by the object’s `main` field. */
  readonly main?: Maybe<StringFilter>;
  /** Filter by the object’s `sub` field. */
  readonly sub?: Maybe<StringFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<ReadonlyArray<CategoryByCatalogFilter>>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<ReadonlyArray<CategoryByCatalogFilter>>;
  /** Negates the expression. */
  readonly not?: Maybe<CategoryByCatalogFilter>;
};

/** A connection to a list of `CategoryByCatalog` values. */
export type CategoryByCatalogsConnection = {
  readonly __typename?: 'CategoryByCatalogsConnection';
  /** A list of `CategoryByCatalog` objects. */
  readonly nodes: ReadonlyArray<CategoryByCatalog>;
  /** A list of edges which contains the `CategoryByCatalog` and cursor to aid in pagination. */
  readonly edges: ReadonlyArray<CategoryByCatalogsEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `CategoryByCatalog` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `CategoryByCatalog` edge in the connection. */
export type CategoryByCatalogsEdge = {
  readonly __typename?: 'CategoryByCatalogsEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `CategoryByCatalog` at the end of the edge. */
  readonly node: CategoryByCatalog;
};

/** Methods to use when ordering `CategoryByCatalog`. */
export enum CategoryByCatalogsOrderBy {
  Natural = 'NATURAL',
  CategoryAsc = 'CATEGORY_ASC',
  CategoryDesc = 'CATEGORY_DESC',
  CatalogAsc = 'CATALOG_ASC',
  CatalogDesc = 'CATALOG_DESC',
  MainAsc = 'MAIN_ASC',
  MainDesc = 'MAIN_DESC',
  SubAsc = 'SUB_ASC',
  SubDesc = 'SUB_DESC'
}

/** The fields on `category` to look up the row to connect. */
export type CategoryCategoryByIdConnect = {
  readonly id: Scalars['Int'];
};

/** The fields on `category` to look up the row to delete. */
export type CategoryCategoryByIdDelete = {
  readonly id: Scalars['Int'];
};

/** A condition to be used against `Category` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type CategoryCondition = {
  /** Checks for equality with the object’s `oid` field. */
  readonly oid?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `options` field. */
  readonly options?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `id` field. */
  readonly id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `slug` field. */
  readonly slug?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  readonly name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `featured` field. */
  readonly featured?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `image` field. */
  readonly image?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `active` field. */
  readonly active?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `linkoutUrl` field. */
  readonly linkoutUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `productsPerPage` field. */
  readonly productsPerPage?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `imageOrientation` field. */
  readonly imageOrientation?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `productBorder` field. */
  readonly productBorder?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `animated` field. */
  readonly animated?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `catalog` field. */
  readonly catalog?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `rankOrder` field. */
  readonly rankOrder?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `targetUrl` field. */
  readonly targetUrl?: Maybe<Scalars['String']>;
};

/** A filter to be used against `Category` object types. All fields are combined with a logical ‘and.’ */
export type CategoryFilter = {
  /** Filter by the object’s `oid` field. */
  readonly oid?: Maybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  readonly id?: Maybe<IntFilter>;
  /** Filter by the object’s `slug` field. */
  readonly slug?: Maybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  readonly name?: Maybe<StringFilter>;
  /** Filter by the object’s `featured` field. */
  readonly featured?: Maybe<IntFilter>;
  /** Filter by the object’s `image` field. */
  readonly image?: Maybe<StringFilter>;
  /** Filter by the object’s `active` field. */
  readonly active?: Maybe<BooleanFilter>;
  /** Filter by the object’s `linkoutUrl` field. */
  readonly linkoutUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `productsPerPage` field. */
  readonly productsPerPage?: Maybe<IntFilter>;
  /** Filter by the object’s `imageOrientation` field. */
  readonly imageOrientation?: Maybe<BooleanFilter>;
  /** Filter by the object’s `productBorder` field. */
  readonly productBorder?: Maybe<BooleanFilter>;
  /** Filter by the object’s `animated` field. */
  readonly animated?: Maybe<BooleanFilter>;
  /** Filter by the object’s `catalog` field. */
  readonly catalog?: Maybe<UuidFilter>;
  /** Filter by the object’s `rankOrder` field. */
  readonly rankOrder?: Maybe<IntFilter>;
  /** Filter by the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<IntFilter>;
  /** Filter by the object’s `targetUrl` field. */
  readonly targetUrl?: Maybe<StringFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<ReadonlyArray<CategoryFilter>>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<ReadonlyArray<CategoryFilter>>;
  /** Negates the expression. */
  readonly not?: Maybe<CategoryFilter>;
};

/** An input for mutations affecting `Category` */
export type CategoryInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly featured?: Maybe<Scalars['Int']>;
  readonly image?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly linkoutUrl?: Maybe<Scalars['String']>;
  readonly productsPerPage?: Maybe<Scalars['Int']>;
  readonly imageOrientation?: Maybe<Scalars['Boolean']>;
  readonly productBorder?: Maybe<Scalars['Boolean']>;
  readonly animated?: Maybe<Scalars['Boolean']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly rankOrder?: Maybe<Scalars['Int']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly catalogToCatalog?: Maybe<FkCategoryCatalogInput>;
  readonly subCategoriesUsingOid?: Maybe<FkSubCategoryCategoryInverseInput>;
  readonly subProductsUsingNameAndCatalog?: Maybe<FakePublicSubProductsForeignKey0InverseInput>;
};

/** The fields on `category` to look up the row to update. */
export type CategoryOnCategoryForFkCategoryCatalogUsingCategoryByIdUpdate = {
  /** An object where the defined keys will be set on the `category` being updated. */
  readonly patch: UpdateCategoryOnCategoryForFkCategoryCatalogPatch;
  readonly id: Scalars['Int'];
};

/** The fields on `category` to look up the row to update. */
export type CategoryOnCategoryForFkCategoryCatalogUsingPkCategoryUpdate = {
  /** An object where the defined keys will be set on the `category` being updated. */
  readonly patch: UpdateCategoryOnCategoryForFkCategoryCatalogPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `category` to look up the row to update. */
export type CategoryOnSubCategoryForFkSubCategoryCategoryUsingCategoryByIdUpdate = {
  /** An object where the defined keys will be set on the `category` being updated. */
  readonly patch: UpdateCategoryOnSubCategoryForFkSubCategoryCategoryPatch;
  readonly id: Scalars['Int'];
};

/** The fields on `category` to look up the row to update. */
export type CategoryOnSubCategoryForFkSubCategoryCategoryUsingPkCategoryUpdate = {
  /** An object where the defined keys will be set on the `category` being updated. */
  readonly patch: UpdateCategoryOnSubCategoryForFkSubCategoryCategoryPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `category` to look up the row to update. */
export type CategoryOnSubProductForFakePublicSubProductsForeignKey0UsingCategoryByIdUpdate = {
  /** An object where the defined keys will be set on the `category` being updated. */
  readonly patch: UpdateCategoryOnSubProductForFakePublicSubProductsForeignKey0Patch;
  readonly id: Scalars['Int'];
};

/** The fields on `category` to look up the row to update. */
export type CategoryOnSubProductForFakePublicSubProductsForeignKey0UsingPkCategoryUpdate = {
  /** An object where the defined keys will be set on the `category` being updated. */
  readonly patch: UpdateCategoryOnSubProductForFakePublicSubProductsForeignKey0Patch;
  readonly oid: Scalars['UUID'];
};

/** Represents an update to a `Category`. Fields that are set will be updated. */
export type CategoryPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly featured?: Maybe<Scalars['Int']>;
  readonly image?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly linkoutUrl?: Maybe<Scalars['String']>;
  readonly productsPerPage?: Maybe<Scalars['Int']>;
  readonly imageOrientation?: Maybe<Scalars['Boolean']>;
  readonly productBorder?: Maybe<Scalars['Boolean']>;
  readonly animated?: Maybe<Scalars['Boolean']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly rankOrder?: Maybe<Scalars['Int']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly catalogToCatalog?: Maybe<FkCategoryCatalogInput>;
  readonly subCategoriesUsingOid?: Maybe<FkSubCategoryCategoryInverseInput>;
  readonly subProductsUsingNameAndCatalog?: Maybe<FakePublicSubProductsForeignKey0InverseInput>;
};

/** The fields on `category` to look up the row to connect. */
export type CategoryPkCategoryConnect = {
  readonly oid: Scalars['UUID'];
};

/** The fields on `category` to look up the row to delete. */
export type CategoryPkCategoryDelete = {
  readonly oid: Scalars['UUID'];
};

export type Contact = {
  readonly __typename?: 'Contact';
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly company?: Maybe<Scalars['String']>;
  readonly parent?: Maybe<Scalars['UUID']>;
  readonly appProfile?: Maybe<Scalars['Boolean']>;
  readonly dateCreated?: Maybe<Scalars['Datetime']>;
  readonly oldId?: Maybe<Scalars['Int']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  /** Reads a single `Account` that is related to this `Contact`. */
  readonly accountByParent?: Maybe<Account>;
};

/** A condition to be used against `Contact` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ContactCondition = {
  /** Checks for equality with the object’s `oid` field. */
  readonly oid?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `options` field. */
  readonly options?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `imageUrl` field. */
  readonly imageUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `email` field. */
  readonly email?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `phone` field. */
  readonly phone?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `street` field. */
  readonly street?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `city` field. */
  readonly city?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `state` field. */
  readonly state?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `zipPostal` field. */
  readonly zipPostal?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `country` field. */
  readonly country?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `title` field. */
  readonly title?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `firstName` field. */
  readonly firstName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `lastName` field. */
  readonly lastName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `company` field. */
  readonly company?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `parent` field. */
  readonly parent?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `appProfile` field. */
  readonly appProfile?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `dateCreated` field. */
  readonly dateCreated?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `oldId` field. */
  readonly oldId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `Contact` object types. All fields are combined with a logical ‘and.’ */
export type ContactFilter = {
  /** Filter by the object’s `oid` field. */
  readonly oid?: Maybe<UuidFilter>;
  /** Filter by the object’s `imageUrl` field. */
  readonly imageUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `email` field. */
  readonly email?: Maybe<StringFilter>;
  /** Filter by the object’s `phone` field. */
  readonly phone?: Maybe<StringFilter>;
  /** Filter by the object’s `street` field. */
  readonly street?: Maybe<StringFilter>;
  /** Filter by the object’s `city` field. */
  readonly city?: Maybe<StringFilter>;
  /** Filter by the object’s `state` field. */
  readonly state?: Maybe<StringFilter>;
  /** Filter by the object’s `zipPostal` field. */
  readonly zipPostal?: Maybe<StringFilter>;
  /** Filter by the object’s `country` field. */
  readonly country?: Maybe<StringFilter>;
  /** Filter by the object’s `title` field. */
  readonly title?: Maybe<StringFilter>;
  /** Filter by the object’s `firstName` field. */
  readonly firstName?: Maybe<StringFilter>;
  /** Filter by the object’s `lastName` field. */
  readonly lastName?: Maybe<StringFilter>;
  /** Filter by the object’s `company` field. */
  readonly company?: Maybe<StringFilter>;
  /** Filter by the object’s `parent` field. */
  readonly parent?: Maybe<UuidFilter>;
  /** Filter by the object’s `appProfile` field. */
  readonly appProfile?: Maybe<BooleanFilter>;
  /** Filter by the object’s `dateCreated` field. */
  readonly dateCreated?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `oldId` field. */
  readonly oldId?: Maybe<IntFilter>;
  /** Filter by the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<IntFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<ReadonlyArray<ContactFilter>>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<ReadonlyArray<ContactFilter>>;
  /** Negates the expression. */
  readonly not?: Maybe<ContactFilter>;
};

/** An input for mutations affecting `Contact` */
export type ContactInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly company?: Maybe<Scalars['String']>;
  readonly parent?: Maybe<Scalars['UUID']>;
  readonly appProfile?: Maybe<Scalars['Boolean']>;
  readonly dateCreated?: Maybe<Scalars['Datetime']>;
  readonly oldId?: Maybe<Scalars['Int']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly accountToParent?: Maybe<FkContactParentInput>;
};

/** The fields on `contact` to look up the row to update. */
export type ContactOnContactForFkContactParentUsingPkContactUpdate = {
  /** An object where the defined keys will be set on the `contact` being updated. */
  readonly patch: UpdateContactOnContactForFkContactParentPatch;
  readonly oid: Scalars['UUID'];
};

/** Represents an update to a `Contact`. Fields that are set will be updated. */
export type ContactPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly company?: Maybe<Scalars['String']>;
  readonly parent?: Maybe<Scalars['UUID']>;
  readonly appProfile?: Maybe<Scalars['Boolean']>;
  readonly dateCreated?: Maybe<Scalars['Datetime']>;
  readonly oldId?: Maybe<Scalars['Int']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly accountToParent?: Maybe<FkContactParentInput>;
};

/** The fields on `contact` to look up the row to connect. */
export type ContactPkContactConnect = {
  readonly oid: Scalars['UUID'];
};

/** The fields on `contact` to look up the row to delete. */
export type ContactPkContactDelete = {
  readonly oid: Scalars['UUID'];
};

/** A connection to a list of `Contact` values. */
export type ContactsConnection = {
  readonly __typename?: 'ContactsConnection';
  /** A list of `Contact` objects. */
  readonly nodes: ReadonlyArray<Contact>;
  /** A list of edges which contains the `Contact` and cursor to aid in pagination. */
  readonly edges: ReadonlyArray<ContactsEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Contact` edge in the connection. */
export type ContactsEdge = {
  readonly __typename?: 'ContactsEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Contact` at the end of the edge. */
  readonly node: Contact;
};

/** Methods to use when ordering `Contact`. */
export enum ContactsOrderBy {
  Natural = 'NATURAL',
  OidAsc = 'OID_ASC',
  OidDesc = 'OID_DESC',
  OptionsAsc = 'OPTIONS_ASC',
  OptionsDesc = 'OPTIONS_DESC',
  ImageUrlAsc = 'IMAGE_URL_ASC',
  ImageUrlDesc = 'IMAGE_URL_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  PhoneAsc = 'PHONE_ASC',
  PhoneDesc = 'PHONE_DESC',
  StreetAsc = 'STREET_ASC',
  StreetDesc = 'STREET_DESC',
  CityAsc = 'CITY_ASC',
  CityDesc = 'CITY_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  ZipPostalAsc = 'ZIP_POSTAL_ASC',
  ZipPostalDesc = 'ZIP_POSTAL_DESC',
  CountryAsc = 'COUNTRY_ASC',
  CountryDesc = 'COUNTRY_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  CompanyAsc = 'COMPANY_ASC',
  CompanyDesc = 'COMPANY_DESC',
  ParentAsc = 'PARENT_ASC',
  ParentDesc = 'PARENT_DESC',
  AppProfileAsc = 'APP_PROFILE_ASC',
  AppProfileDesc = 'APP_PROFILE_DESC',
  DateCreatedAsc = 'DATE_CREATED_ASC',
  DateCreatedDesc = 'DATE_CREATED_DESC',
  OldIdAsc = 'OLD_ID_ASC',
  OldIdDesc = 'OLD_ID_DESC',
  OptimisticLockFieldAsc = 'OPTIMISTIC_LOCK_FIELD_ASC',
  OptimisticLockFieldDesc = 'OPTIMISTIC_LOCK_FIELD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** All input for the create `Account` mutation. */
export type CreateAccountInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Account` to be created by this mutation. */
  readonly account: AccountInput;
};

/** The output of our create `Account` mutation. */
export type CreateAccountPayload = {
  readonly __typename?: 'CreateAccountPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Account` that was created by this mutation. */
  readonly account?: Maybe<Account>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** An edge for our `Account`. May be used by Relay 1. */
  readonly accountEdge?: Maybe<AccountsEdge>;
};


/** The output of our create `Account` mutation. */
export type CreateAccountPayloadAccountEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<AccountsOrderBy>>;
};

/** All input for the create `AuthorizedUserAuthorizedUsersAccountPartnerAccess` mutation. */
export type CreateAuthorizedUserAuthorizedUsersAccountPartnerAccessInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `AuthorizedUserAuthorizedUsersAccountPartnerAccess` to be created by this mutation. */
  readonly authorizedUserAuthorizedUsersAccountPartnerAccess: AuthorizedUserAuthorizedUsersAccountPartnerAccessInput;
};

/** The output of our create `AuthorizedUserAuthorizedUsersAccountPartnerAccess` mutation. */
export type CreateAuthorizedUserAuthorizedUsersAccountPartnerAccessPayload = {
  readonly __typename?: 'CreateAuthorizedUserAuthorizedUsersAccountPartnerAccessPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `AuthorizedUserAuthorizedUsersAccountPartnerAccess` that was created by this mutation. */
  readonly authorizedUserAuthorizedUsersAccountPartnerAccess?: Maybe<AuthorizedUserAuthorizedUsersAccountPartnerAccess>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Account` that is related to this `AuthorizedUserAuthorizedUsersAccountPartnerAccess`. */
  readonly accountByPartnerAccess?: Maybe<Account>;
  /** Reads a single `AuthorizedUser` that is related to this `AuthorizedUserAuthorizedUsersAccountPartnerAccess`. */
  readonly authorizedUserByAuthorizedUsers?: Maybe<AuthorizedUser>;
  /** An edge for our `AuthorizedUserAuthorizedUsersAccountPartnerAccess`. May be used by Relay 1. */
  readonly authorizedUserAuthorizedUsersAccountPartnerAccessEdge?: Maybe<AuthorizedUserAuthorizedUsersAccountPartnerAccessesEdge>;
};


/** The output of our create `AuthorizedUserAuthorizedUsersAccountPartnerAccess` mutation. */
export type CreateAuthorizedUserAuthorizedUsersAccountPartnerAccessPayloadAuthorizedUserAuthorizedUsersAccountPartnerAccessEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<AuthorizedUserAuthorizedUsersAccountPartnerAccessesOrderBy>>;
};

/** All input for the create `AuthorizedUser` mutation. */
export type CreateAuthorizedUserInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `AuthorizedUser` to be created by this mutation. */
  readonly authorizedUser: AuthorizedUserInput;
};

/** The output of our create `AuthorizedUser` mutation. */
export type CreateAuthorizedUserPayload = {
  readonly __typename?: 'CreateAuthorizedUserPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `AuthorizedUser` that was created by this mutation. */
  readonly authorizedUser?: Maybe<AuthorizedUser>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Profile` that is related to this `AuthorizedUser`. */
  readonly profileByEmail?: Maybe<Profile>;
  /** An edge for our `AuthorizedUser`. May be used by Relay 1. */
  readonly authorizedUserEdge?: Maybe<AuthorizedUsersEdge>;
};


/** The output of our create `AuthorizedUser` mutation. */
export type CreateAuthorizedUserPayloadAuthorizedUserEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<AuthorizedUsersOrderBy>>;
};

/** All input for the create `CampaignGroup` mutation. */
export type CreateCampaignGroupInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `CampaignGroup` to be created by this mutation. */
  readonly campaignGroup: CampaignGroupInput;
};

/** The output of our create `CampaignGroup` mutation. */
export type CreateCampaignGroupPayload = {
  readonly __typename?: 'CreateCampaignGroupPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `CampaignGroup` that was created by this mutation. */
  readonly campaignGroup?: Maybe<CampaignGroup>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Campaign` that is related to this `CampaignGroup`. */
  readonly campaignByCampaign?: Maybe<Campaign>;
  /** Reads a single `Catalog` that is related to this `CampaignGroup`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
  /** An edge for our `CampaignGroup`. May be used by Relay 1. */
  readonly campaignGroupEdge?: Maybe<CampaignGroupsEdge>;
};


/** The output of our create `CampaignGroup` mutation. */
export type CreateCampaignGroupPayloadCampaignGroupEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<CampaignGroupsOrderBy>>;
};

/** All input for the create `Campaign` mutation. */
export type CreateCampaignInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Campaign` to be created by this mutation. */
  readonly campaign: CampaignInput;
};

/** The output of our create `Campaign` mutation. */
export type CreateCampaignPayload = {
  readonly __typename?: 'CreateCampaignPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Campaign` that was created by this mutation. */
  readonly campaign?: Maybe<Campaign>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Account` that is related to this `Campaign`. */
  readonly accountByAccount?: Maybe<Account>;
  /** An edge for our `Campaign`. May be used by Relay 1. */
  readonly campaignEdge?: Maybe<CampaignsEdge>;
};


/** The output of our create `Campaign` mutation. */
export type CreateCampaignPayloadCampaignEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<CampaignsOrderBy>>;
};

/** All input for the create `CampaignUser` mutation. */
export type CreateCampaignUserInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `CampaignUser` to be created by this mutation. */
  readonly campaignUser: CampaignUserInput;
};

/** The output of our create `CampaignUser` mutation. */
export type CreateCampaignUserPayload = {
  readonly __typename?: 'CreateCampaignUserPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `CampaignUser` that was created by this mutation. */
  readonly campaignUser?: Maybe<CampaignUser>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `CampaignUser`. */
  readonly leadByLead?: Maybe<Lead>;
  /** Reads a single `CampaignGroup` that is related to this `CampaignUser`. */
  readonly campaignGroupByCampaignGroup?: Maybe<CampaignGroup>;
  /** An edge for our `CampaignUser`. May be used by Relay 1. */
  readonly campaignUserEdge?: Maybe<CampaignUsersEdge>;
};


/** The output of our create `CampaignUser` mutation. */
export type CreateCampaignUserPayloadCampaignUserEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<CampaignUsersOrderBy>>;
};

/** All input for the create `Carousel` mutation. */
export type CreateCarouselInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Carousel` to be created by this mutation. */
  readonly carousel: CarouselInput;
};

/** All input for the create `CarouselListing` mutation. */
export type CreateCarouselListingInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `CarouselListing` to be created by this mutation. */
  readonly carouselListing: CarouselListingInput;
};

/** The output of our create `CarouselListing` mutation. */
export type CreateCarouselListingPayload = {
  readonly __typename?: 'CreateCarouselListingPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `CarouselListing` that was created by this mutation. */
  readonly carouselListing?: Maybe<CarouselListing>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Carousel` that is related to this `CarouselListing`. */
  readonly carouselByCarousel?: Maybe<Carousel>;
  /** Reads a single `Offer` that is related to this `CarouselListing`. */
  readonly offerByOffer?: Maybe<Offer>;
  /** Reads a single `Catalog` that is related to this `CarouselListing`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
  /** An edge for our `CarouselListing`. May be used by Relay 1. */
  readonly carouselListingEdge?: Maybe<CarouselListingsEdge>;
};


/** The output of our create `CarouselListing` mutation. */
export type CreateCarouselListingPayloadCarouselListingEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<CarouselListingsOrderBy>>;
};

/** The output of our create `Carousel` mutation. */
export type CreateCarouselPayload = {
  readonly __typename?: 'CreateCarouselPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Carousel` that was created by this mutation. */
  readonly carousel?: Maybe<Carousel>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** An edge for our `Carousel`. May be used by Relay 1. */
  readonly carouselEdge?: Maybe<CarouselsEdge>;
};


/** The output of our create `Carousel` mutation. */
export type CreateCarouselPayloadCarouselEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<CarouselsOrderBy>>;
};

/** All input for the create `Catalog` mutation. */
export type CreateCatalogInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Catalog` to be created by this mutation. */
  readonly catalog: CatalogInput;
};

/** The output of our create `Catalog` mutation. */
export type CreateCatalogPayload = {
  readonly __typename?: 'CreateCatalogPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Catalog` that was created by this mutation. */
  readonly catalog?: Maybe<Catalog>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Account` that is related to this `Catalog`. */
  readonly accountByAccount?: Maybe<Account>;
  /** Reads a single `Department` that is related to this `Catalog`. */
  readonly departmentByPrimaryDepartment?: Maybe<Department>;
  /** Reads a single `Version` that is related to this `Catalog`. */
  readonly versionByActiveVersion?: Maybe<Version>;
  /** An edge for our `Catalog`. May be used by Relay 1. */
  readonly catalogEdge?: Maybe<CatalogsEdge>;
};


/** The output of our create `Catalog` mutation. */
export type CreateCatalogPayloadCatalogEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<CatalogsOrderBy>>;
};

/** All input for the create `CatalogSearchLive` mutation. */
export type CreateCatalogSearchLiveInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `CatalogSearchLive` to be created by this mutation. */
  readonly catalogSearchLive: CatalogSearchLiveInput;
};

/** The output of our create `CatalogSearchLive` mutation. */
export type CreateCatalogSearchLivePayload = {
  readonly __typename?: 'CreateCatalogSearchLivePayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `CatalogSearchLive` that was created by this mutation. */
  readonly catalogSearchLive?: Maybe<CatalogSearchLive>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** An edge for our `CatalogSearchLive`. May be used by Relay 1. */
  readonly catalogSearchLiveEdge?: Maybe<CatalogSearchLivesEdge>;
};


/** The output of our create `CatalogSearchLive` mutation. */
export type CreateCatalogSearchLivePayloadCatalogSearchLiveEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<CatalogSearchLivesOrderBy>>;
};

/** All input for the create `Category` mutation. */
export type CreateCategoryInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Category` to be created by this mutation. */
  readonly category: CategoryInput;
};

/** The output of our create `Category` mutation. */
export type CreateCategoryPayload = {
  readonly __typename?: 'CreateCategoryPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Category` that was created by this mutation. */
  readonly category?: Maybe<Category>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Catalog` that is related to this `Category`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
  /** An edge for our `Category`. May be used by Relay 1. */
  readonly categoryEdge?: Maybe<CategoriesEdge>;
};


/** The output of our create `Category` mutation. */
export type CreateCategoryPayloadCategoryEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<CategoriesOrderBy>>;
};

/** All input for the create `Contact` mutation. */
export type CreateContactInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Contact` to be created by this mutation. */
  readonly contact: ContactInput;
};

/** The output of our create `Contact` mutation. */
export type CreateContactPayload = {
  readonly __typename?: 'CreateContactPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Contact` that was created by this mutation. */
  readonly contact?: Maybe<Contact>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Account` that is related to this `Contact`. */
  readonly accountByParent?: Maybe<Account>;
  /** An edge for our `Contact`. May be used by Relay 1. */
  readonly contactEdge?: Maybe<ContactsEdge>;
};


/** The output of our create `Contact` mutation. */
export type CreateContactPayloadContactEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<ContactsOrderBy>>;
};

/** All input for the create `DepartmentDepartmentsCatalogListing` mutation. */
export type CreateDepartmentDepartmentsCatalogListingInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `DepartmentDepartmentsCatalogListing` to be created by this mutation. */
  readonly departmentDepartmentsCatalogListing: DepartmentDepartmentsCatalogListingInput;
};

/** The output of our create `DepartmentDepartmentsCatalogListing` mutation. */
export type CreateDepartmentDepartmentsCatalogListingPayload = {
  readonly __typename?: 'CreateDepartmentDepartmentsCatalogListingPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `DepartmentDepartmentsCatalogListing` that was created by this mutation. */
  readonly departmentDepartmentsCatalogListing?: Maybe<DepartmentDepartmentsCatalogListing>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Catalog` that is related to this `DepartmentDepartmentsCatalogListing`. */
  readonly catalogByListings?: Maybe<Catalog>;
  /** Reads a single `Department` that is related to this `DepartmentDepartmentsCatalogListing`. */
  readonly departmentByDepartments?: Maybe<Department>;
  /** An edge for our `DepartmentDepartmentsCatalogListing`. May be used by Relay 1. */
  readonly departmentDepartmentsCatalogListingEdge?: Maybe<DepartmentDepartmentsCatalogListingsEdge>;
};


/** The output of our create `DepartmentDepartmentsCatalogListing` mutation. */
export type CreateDepartmentDepartmentsCatalogListingPayloadDepartmentDepartmentsCatalogListingEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<DepartmentDepartmentsCatalogListingsOrderBy>>;
};

/** All input for the create `Department` mutation. */
export type CreateDepartmentInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Department` to be created by this mutation. */
  readonly department: DepartmentInput;
};

/** The output of our create `Department` mutation. */
export type CreateDepartmentPayload = {
  readonly __typename?: 'CreateDepartmentPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Department` that was created by this mutation. */
  readonly department?: Maybe<Department>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Department` that is related to this `Department`. */
  readonly departmentByParentDepartment?: Maybe<Department>;
  /** An edge for our `Department`. May be used by Relay 1. */
  readonly departmentEdge?: Maybe<DepartmentsEdge>;
};


/** The output of our create `Department` mutation. */
export type CreateDepartmentPayloadDepartmentEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<DepartmentsOrderBy>>;
};

/** All input for the create `FeedHistory` mutation. */
export type CreateFeedHistoryInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `FeedHistory` to be created by this mutation. */
  readonly feedHistory: FeedHistoryInput;
};

/** The output of our create `FeedHistory` mutation. */
export type CreateFeedHistoryPayload = {
  readonly __typename?: 'CreateFeedHistoryPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `FeedHistory` that was created by this mutation. */
  readonly feedHistory?: Maybe<FeedHistory>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Feed` that is related to this `FeedHistory`. */
  readonly feedByFeed?: Maybe<Feed>;
  /** An edge for our `FeedHistory`. May be used by Relay 1. */
  readonly feedHistoryEdge?: Maybe<FeedHistoriesEdge>;
};


/** The output of our create `FeedHistory` mutation. */
export type CreateFeedHistoryPayloadFeedHistoryEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<FeedHistoriesOrderBy>>;
};

/** All input for the create `Feed` mutation. */
export type CreateFeedInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Feed` to be created by this mutation. */
  readonly feed: FeedInput;
};

/** The output of our create `Feed` mutation. */
export type CreateFeedPayload = {
  readonly __typename?: 'CreateFeedPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Feed` that was created by this mutation. */
  readonly feed?: Maybe<Feed>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Catalog` that is related to this `Feed`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
  /** An edge for our `Feed`. May be used by Relay 1. */
  readonly feedEdge?: Maybe<FeedsEdge>;
};


/** The output of our create `Feed` mutation. */
export type CreateFeedPayloadFeedEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<FeedsOrderBy>>;
};

/** All input for the create `Lead` mutation. */
export type CreateLeadInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Lead` to be created by this mutation. */
  readonly lead: LeadInput;
};

/** The output of our create `Lead` mutation. */
export type CreateLeadPayload = {
  readonly __typename?: 'CreateLeadPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Lead` that was created by this mutation. */
  readonly lead?: Maybe<Lead>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Account` that is related to this `Lead`. */
  readonly accountByAccount?: Maybe<Account>;
  /** Reads a single `Profile` that is related to this `Lead`. */
  readonly profileByProfile?: Maybe<Profile>;
  /** An edge for our `Lead`. May be used by Relay 1. */
  readonly leadEdge?: Maybe<LeadsEdge>;
};


/** The output of our create `Lead` mutation. */
export type CreateLeadPayloadLeadEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<LeadsOrderBy>>;
};

/** All input for the create `LinkOut` mutation. */
export type CreateLinkOutInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `LinkOut` to be created by this mutation. */
  readonly linkOut: LinkOutInput;
};

/** The output of our create `LinkOut` mutation. */
export type CreateLinkOutPayload = {
  readonly __typename?: 'CreateLinkOutPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `LinkOut` that was created by this mutation. */
  readonly linkOut?: Maybe<LinkOut>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Profile` that is related to this `LinkOut`. */
  readonly profileByCustomer?: Maybe<Profile>;
  /** Reads a single `Catalog` that is related to this `LinkOut`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
  /** An edge for our `LinkOut`. May be used by Relay 1. */
  readonly linkOutEdge?: Maybe<LinkOutsEdge>;
};


/** The output of our create `LinkOut` mutation. */
export type CreateLinkOutPayloadLinkOutEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<LinkOutsOrderBy>>;
};

/** All input for the create `NavigationItem` mutation. */
export type CreateNavigationItemInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `NavigationItem` to be created by this mutation. */
  readonly navigationItem: NavigationItemInput;
};

/** The output of our create `NavigationItem` mutation. */
export type CreateNavigationItemPayload = {
  readonly __typename?: 'CreateNavigationItemPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `NavigationItem` that was created by this mutation. */
  readonly navigationItem?: Maybe<NavigationItem>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Department` that is related to this `NavigationItem`. */
  readonly departmentByDepartment?: Maybe<Department>;
  /** An edge for our `NavigationItem`. May be used by Relay 1. */
  readonly navigationItemEdge?: Maybe<NavigationItemsEdge>;
};


/** The output of our create `NavigationItem` mutation. */
export type CreateNavigationItemPayloadNavigationItemEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<NavigationItemsOrderBy>>;
};

/** All input for the create `Offer` mutation. */
export type CreateOfferInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Offer` to be created by this mutation. */
  readonly offer: OfferInput;
};

/** The output of our create `Offer` mutation. */
export type CreateOfferPayload = {
  readonly __typename?: 'CreateOfferPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Offer` that was created by this mutation. */
  readonly offer?: Maybe<Offer>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Catalog` that is related to this `Offer`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
  /** An edge for our `Offer`. May be used by Relay 1. */
  readonly offerEdge?: Maybe<OffersEdge>;
};


/** The output of our create `Offer` mutation. */
export type CreateOfferPayloadOfferEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<OffersOrderBy>>;
};

/** All input for the create `Order` mutation. */
export type CreateOrderInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Order` to be created by this mutation. */
  readonly order: OrderInput;
};

/** The output of our create `Order` mutation. */
export type CreateOrderPayload = {
  readonly __typename?: 'CreateOrderPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Order` that was created by this mutation. */
  readonly order?: Maybe<Order>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** An edge for our `Order`. May be used by Relay 1. */
  readonly orderEdge?: Maybe<OrdersEdge>;
};


/** The output of our create `Order` mutation. */
export type CreateOrderPayloadOrderEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<OrdersOrderBy>>;
};

/** All input for the create `OrderProduct` mutation. */
export type CreateOrderProductInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `OrderProduct` to be created by this mutation. */
  readonly orderProduct: OrderProductInput;
};

/** The output of our create `OrderProduct` mutation. */
export type CreateOrderProductPayload = {
  readonly __typename?: 'CreateOrderProductPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `OrderProduct` that was created by this mutation. */
  readonly orderProduct?: Maybe<OrderProduct>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Order` that is related to this `OrderProduct`. */
  readonly orderByOrder?: Maybe<Order>;
  /** An edge for our `OrderProduct`. May be used by Relay 1. */
  readonly orderProductEdge?: Maybe<OrderProductsEdge>;
};


/** The output of our create `OrderProduct` mutation. */
export type CreateOrderProductPayloadOrderProductEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<OrderProductsOrderBy>>;
};

/** All input for the create `Page` mutation. */
export type CreatePageInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Page` to be created by this mutation. */
  readonly page: PageInput;
};

/** The output of our create `Page` mutation. */
export type CreatePagePayload = {
  readonly __typename?: 'CreatePagePayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Page` that was created by this mutation. */
  readonly page?: Maybe<Page>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Version` that is related to this `Page`. */
  readonly versionByVersion?: Maybe<Version>;
  /** An edge for our `Page`. May be used by Relay 1. */
  readonly pageEdge?: Maybe<PagesEdge>;
};


/** The output of our create `Page` mutation. */
export type CreatePagePayloadPageEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<PagesOrderBy>>;
};

/** All input for the create `Product` mutation. */
export type CreateProductInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Product` to be created by this mutation. */
  readonly product: ProductInput;
};

/** The output of our create `Product` mutation. */
export type CreateProductPayload = {
  readonly __typename?: 'CreateProductPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Product` that was created by this mutation. */
  readonly product?: Maybe<Product>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Catalog` that is related to this `Product`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
  /** Reads a single `CategoryByCatalog` that is related to this `Product`. */
  readonly categoryByCatalogByCategoryAndCatalog?: Maybe<CategoryByCatalog>;
  /** An edge for our `Product`. May be used by Relay 1. */
  readonly productEdge?: Maybe<ProductsEdge>;
};


/** The output of our create `Product` mutation. */
export type CreateProductPayloadProductEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<ProductsOrderBy>>;
};

/** All input for the create `Profile` mutation. */
export type CreateProfileInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Profile` to be created by this mutation. */
  readonly profile: ProfileInput;
};

/** The output of our create `Profile` mutation. */
export type CreateProfilePayload = {
  readonly __typename?: 'CreateProfilePayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Profile` that was created by this mutation. */
  readonly profile?: Maybe<Profile>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** An edge for our `Profile`. May be used by Relay 1. */
  readonly profileEdge?: Maybe<ProfilesEdge>;
};


/** The output of our create `Profile` mutation. */
export type CreateProfilePayloadProfileEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<ProfilesOrderBy>>;
};

/** All input for the create `SubCategory` mutation. */
export type CreateSubCategoryInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `SubCategory` to be created by this mutation. */
  readonly subCategory: SubCategoryInput;
};

/** The output of our create `SubCategory` mutation. */
export type CreateSubCategoryPayload = {
  readonly __typename?: 'CreateSubCategoryPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `SubCategory` that was created by this mutation. */
  readonly subCategory?: Maybe<SubCategory>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Category` that is related to this `SubCategory`. */
  readonly categoryByCategory?: Maybe<Category>;
  /** An edge for our `SubCategory`. May be used by Relay 1. */
  readonly subCategoryEdge?: Maybe<SubCategoriesEdge>;
};


/** The output of our create `SubCategory` mutation. */
export type CreateSubCategoryPayloadSubCategoryEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<SubCategoriesOrderBy>>;
};

/** All input for the create `Subscription` mutation. */
export type CreateSubscriptionInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Subscription` to be created by this mutation. */
  readonly subscription: SubscriptionInput;
};

/** The output of our create `Subscription` mutation. */
export type CreateSubscriptionPayload = {
  readonly __typename?: 'CreateSubscriptionPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Subscription` that was created by this mutation. */
  readonly subscription?: Maybe<Subscription>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Profile` that is related to this `Subscription`. */
  readonly profileByCustomer?: Maybe<Profile>;
  /** Reads a single `Catalog` that is related to this `Subscription`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
  /** An edge for our `Subscription`. May be used by Relay 1. */
  readonly subscriptionEdge?: Maybe<SubscriptionsEdge>;
};


/** The output of our create `Subscription` mutation. */
export type CreateSubscriptionPayloadSubscriptionEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<SubscriptionsOrderBy>>;
};

/** All input for the create `Version` mutation. */
export type CreateVersionInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Version` to be created by this mutation. */
  readonly version: VersionInput;
};

/** The output of our create `Version` mutation. */
export type CreateVersionPayload = {
  readonly __typename?: 'CreateVersionPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Version` that was created by this mutation. */
  readonly version?: Maybe<Version>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Catalog` that is related to this `Version`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
  /** An edge for our `Version`. May be used by Relay 1. */
  readonly versionEdge?: Maybe<VersionsEdge>;
};


/** The output of our create `Version` mutation. */
export type CreateVersionPayloadVersionEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<VersionsOrderBy>>;
};



/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  readonly isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  readonly equalTo?: Maybe<Scalars['Datetime']>;
  /** Not equal to the specified value. */
  readonly notEqualTo?: Maybe<Scalars['Datetime']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  readonly distinctFrom?: Maybe<Scalars['Datetime']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  readonly notDistinctFrom?: Maybe<Scalars['Datetime']>;
  /** Included in the specified list. */
  readonly in?: Maybe<ReadonlyArray<Scalars['Datetime']>>;
  /** Not included in the specified list. */
  readonly notIn?: Maybe<ReadonlyArray<Scalars['Datetime']>>;
  /** Less than the specified value. */
  readonly lessThan?: Maybe<Scalars['Datetime']>;
  /** Less than or equal to the specified value. */
  readonly lessThanOrEqualTo?: Maybe<Scalars['Datetime']>;
  /** Greater than the specified value. */
  readonly greaterThan?: Maybe<Scalars['Datetime']>;
  /** Greater than or equal to the specified value. */
  readonly greaterThanOrEqualTo?: Maybe<Scalars['Datetime']>;
};

/** All input for the `deleteAccount` mutation. */
export type DeleteAccountInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly oid: Scalars['UUID'];
};

/** The output of our delete `Account` mutation. */
export type DeleteAccountPayload = {
  readonly __typename?: 'DeleteAccountPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Account` that was deleted by this mutation. */
  readonly account?: Maybe<Account>;
  readonly deletedAccountNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** An edge for our `Account`. May be used by Relay 1. */
  readonly accountEdge?: Maybe<AccountsEdge>;
};


/** The output of our delete `Account` mutation. */
export type DeleteAccountPayloadAccountEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<AccountsOrderBy>>;
};

/** All input for the `deleteAuthorizedUserAuthorizedUsersAccountPartnerAccess` mutation. */
export type DeleteAuthorizedUserAuthorizedUsersAccountPartnerAccessInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly oid: Scalars['UUID'];
};

/** The output of our delete `AuthorizedUserAuthorizedUsersAccountPartnerAccess` mutation. */
export type DeleteAuthorizedUserAuthorizedUsersAccountPartnerAccessPayload = {
  readonly __typename?: 'DeleteAuthorizedUserAuthorizedUsersAccountPartnerAccessPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `AuthorizedUserAuthorizedUsersAccountPartnerAccess` that was deleted by this mutation. */
  readonly authorizedUserAuthorizedUsersAccountPartnerAccess?: Maybe<AuthorizedUserAuthorizedUsersAccountPartnerAccess>;
  readonly deletedAuthorizedUserAuthorizedUsersAccountPartnerAccessNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Account` that is related to this `AuthorizedUserAuthorizedUsersAccountPartnerAccess`. */
  readonly accountByPartnerAccess?: Maybe<Account>;
  /** Reads a single `AuthorizedUser` that is related to this `AuthorizedUserAuthorizedUsersAccountPartnerAccess`. */
  readonly authorizedUserByAuthorizedUsers?: Maybe<AuthorizedUser>;
  /** An edge for our `AuthorizedUserAuthorizedUsersAccountPartnerAccess`. May be used by Relay 1. */
  readonly authorizedUserAuthorizedUsersAccountPartnerAccessEdge?: Maybe<AuthorizedUserAuthorizedUsersAccountPartnerAccessesEdge>;
};


/** The output of our delete `AuthorizedUserAuthorizedUsersAccountPartnerAccess` mutation. */
export type DeleteAuthorizedUserAuthorizedUsersAccountPartnerAccessPayloadAuthorizedUserAuthorizedUsersAccountPartnerAccessEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<AuthorizedUserAuthorizedUsersAccountPartnerAccessesOrderBy>>;
};

/** All input for the `deleteAuthorizedUserByEmail` mutation. */
export type DeleteAuthorizedUserByEmailInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly email: Scalars['String'];
};

/** All input for the `deleteAuthorizedUser` mutation. */
export type DeleteAuthorizedUserInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly oid: Scalars['UUID'];
};

/** The output of our delete `AuthorizedUser` mutation. */
export type DeleteAuthorizedUserPayload = {
  readonly __typename?: 'DeleteAuthorizedUserPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `AuthorizedUser` that was deleted by this mutation. */
  readonly authorizedUser?: Maybe<AuthorizedUser>;
  readonly deletedAuthorizedUserNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Profile` that is related to this `AuthorizedUser`. */
  readonly profileByEmail?: Maybe<Profile>;
  /** An edge for our `AuthorizedUser`. May be used by Relay 1. */
  readonly authorizedUserEdge?: Maybe<AuthorizedUsersEdge>;
};


/** The output of our delete `AuthorizedUser` mutation. */
export type DeleteAuthorizedUserPayloadAuthorizedUserEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<AuthorizedUsersOrderBy>>;
};

/** All input for the `deleteCampaignGroup` mutation. */
export type DeleteCampaignGroupInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly oid: Scalars['UUID'];
};

/** The output of our delete `CampaignGroup` mutation. */
export type DeleteCampaignGroupPayload = {
  readonly __typename?: 'DeleteCampaignGroupPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `CampaignGroup` that was deleted by this mutation. */
  readonly campaignGroup?: Maybe<CampaignGroup>;
  readonly deletedCampaignGroupNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Campaign` that is related to this `CampaignGroup`. */
  readonly campaignByCampaign?: Maybe<Campaign>;
  /** Reads a single `Catalog` that is related to this `CampaignGroup`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
  /** An edge for our `CampaignGroup`. May be used by Relay 1. */
  readonly campaignGroupEdge?: Maybe<CampaignGroupsEdge>;
};


/** The output of our delete `CampaignGroup` mutation. */
export type DeleteCampaignGroupPayloadCampaignGroupEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<CampaignGroupsOrderBy>>;
};

/** All input for the `deleteCampaign` mutation. */
export type DeleteCampaignInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly oid: Scalars['UUID'];
};

/** The output of our delete `Campaign` mutation. */
export type DeleteCampaignPayload = {
  readonly __typename?: 'DeleteCampaignPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Campaign` that was deleted by this mutation. */
  readonly campaign?: Maybe<Campaign>;
  readonly deletedCampaignNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Account` that is related to this `Campaign`. */
  readonly accountByAccount?: Maybe<Account>;
  /** An edge for our `Campaign`. May be used by Relay 1. */
  readonly campaignEdge?: Maybe<CampaignsEdge>;
};


/** The output of our delete `Campaign` mutation. */
export type DeleteCampaignPayloadCampaignEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<CampaignsOrderBy>>;
};

/** All input for the `deleteCampaignUser` mutation. */
export type DeleteCampaignUserInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly oid: Scalars['UUID'];
};

/** The output of our delete `CampaignUser` mutation. */
export type DeleteCampaignUserPayload = {
  readonly __typename?: 'DeleteCampaignUserPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `CampaignUser` that was deleted by this mutation. */
  readonly campaignUser?: Maybe<CampaignUser>;
  readonly deletedCampaignUserNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `CampaignUser`. */
  readonly leadByLead?: Maybe<Lead>;
  /** Reads a single `CampaignGroup` that is related to this `CampaignUser`. */
  readonly campaignGroupByCampaignGroup?: Maybe<CampaignGroup>;
  /** An edge for our `CampaignUser`. May be used by Relay 1. */
  readonly campaignUserEdge?: Maybe<CampaignUsersEdge>;
};


/** The output of our delete `CampaignUser` mutation. */
export type DeleteCampaignUserPayloadCampaignUserEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<CampaignUsersOrderBy>>;
};

/** All input for the `deleteCarousel` mutation. */
export type DeleteCarouselInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly oid: Scalars['UUID'];
};

/** All input for the `deleteCarouselListing` mutation. */
export type DeleteCarouselListingInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly oid: Scalars['UUID'];
};

/** The output of our delete `CarouselListing` mutation. */
export type DeleteCarouselListingPayload = {
  readonly __typename?: 'DeleteCarouselListingPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `CarouselListing` that was deleted by this mutation. */
  readonly carouselListing?: Maybe<CarouselListing>;
  readonly deletedCarouselListingNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Carousel` that is related to this `CarouselListing`. */
  readonly carouselByCarousel?: Maybe<Carousel>;
  /** Reads a single `Offer` that is related to this `CarouselListing`. */
  readonly offerByOffer?: Maybe<Offer>;
  /** Reads a single `Catalog` that is related to this `CarouselListing`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
  /** An edge for our `CarouselListing`. May be used by Relay 1. */
  readonly carouselListingEdge?: Maybe<CarouselListingsEdge>;
};


/** The output of our delete `CarouselListing` mutation. */
export type DeleteCarouselListingPayloadCarouselListingEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<CarouselListingsOrderBy>>;
};

/** The output of our delete `Carousel` mutation. */
export type DeleteCarouselPayload = {
  readonly __typename?: 'DeleteCarouselPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Carousel` that was deleted by this mutation. */
  readonly carousel?: Maybe<Carousel>;
  readonly deletedCarouselNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** An edge for our `Carousel`. May be used by Relay 1. */
  readonly carouselEdge?: Maybe<CarouselsEdge>;
};


/** The output of our delete `Carousel` mutation. */
export type DeleteCarouselPayloadCarouselEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<CarouselsOrderBy>>;
};

/** All input for the `deleteCatalogById` mutation. */
export type DeleteCatalogByIdInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
};

/** All input for the `deleteCatalog` mutation. */
export type DeleteCatalogInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly oid: Scalars['UUID'];
};

/** The output of our delete `Catalog` mutation. */
export type DeleteCatalogPayload = {
  readonly __typename?: 'DeleteCatalogPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Catalog` that was deleted by this mutation. */
  readonly catalog?: Maybe<Catalog>;
  readonly deletedCatalogNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Account` that is related to this `Catalog`. */
  readonly accountByAccount?: Maybe<Account>;
  /** Reads a single `Department` that is related to this `Catalog`. */
  readonly departmentByPrimaryDepartment?: Maybe<Department>;
  /** Reads a single `Version` that is related to this `Catalog`. */
  readonly versionByActiveVersion?: Maybe<Version>;
  /** An edge for our `Catalog`. May be used by Relay 1. */
  readonly catalogEdge?: Maybe<CatalogsEdge>;
};


/** The output of our delete `Catalog` mutation. */
export type DeleteCatalogPayloadCatalogEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<CatalogsOrderBy>>;
};

/** All input for the `deleteCategoryById` mutation. */
export type DeleteCategoryByIdInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
};

/** All input for the `deleteCategory` mutation. */
export type DeleteCategoryInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly oid: Scalars['UUID'];
};

/** The output of our delete `Category` mutation. */
export type DeleteCategoryPayload = {
  readonly __typename?: 'DeleteCategoryPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Category` that was deleted by this mutation. */
  readonly category?: Maybe<Category>;
  readonly deletedCategoryNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Catalog` that is related to this `Category`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
  /** An edge for our `Category`. May be used by Relay 1. */
  readonly categoryEdge?: Maybe<CategoriesEdge>;
};


/** The output of our delete `Category` mutation. */
export type DeleteCategoryPayloadCategoryEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<CategoriesOrderBy>>;
};

/** All input for the `deleteContact` mutation. */
export type DeleteContactInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly oid: Scalars['UUID'];
};

/** The output of our delete `Contact` mutation. */
export type DeleteContactPayload = {
  readonly __typename?: 'DeleteContactPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Contact` that was deleted by this mutation. */
  readonly contact?: Maybe<Contact>;
  readonly deletedContactNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Account` that is related to this `Contact`. */
  readonly accountByParent?: Maybe<Account>;
  /** An edge for our `Contact`. May be used by Relay 1. */
  readonly contactEdge?: Maybe<ContactsEdge>;
};


/** The output of our delete `Contact` mutation. */
export type DeleteContactPayloadContactEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<ContactsOrderBy>>;
};

/** All input for the `deleteDepartmentDepartmentsCatalogListing` mutation. */
export type DeleteDepartmentDepartmentsCatalogListingInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly oid: Scalars['UUID'];
};

/** The output of our delete `DepartmentDepartmentsCatalogListing` mutation. */
export type DeleteDepartmentDepartmentsCatalogListingPayload = {
  readonly __typename?: 'DeleteDepartmentDepartmentsCatalogListingPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `DepartmentDepartmentsCatalogListing` that was deleted by this mutation. */
  readonly departmentDepartmentsCatalogListing?: Maybe<DepartmentDepartmentsCatalogListing>;
  readonly deletedDepartmentDepartmentsCatalogListingNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Catalog` that is related to this `DepartmentDepartmentsCatalogListing`. */
  readonly catalogByListings?: Maybe<Catalog>;
  /** Reads a single `Department` that is related to this `DepartmentDepartmentsCatalogListing`. */
  readonly departmentByDepartments?: Maybe<Department>;
  /** An edge for our `DepartmentDepartmentsCatalogListing`. May be used by Relay 1. */
  readonly departmentDepartmentsCatalogListingEdge?: Maybe<DepartmentDepartmentsCatalogListingsEdge>;
};


/** The output of our delete `DepartmentDepartmentsCatalogListing` mutation. */
export type DeleteDepartmentDepartmentsCatalogListingPayloadDepartmentDepartmentsCatalogListingEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<DepartmentDepartmentsCatalogListingsOrderBy>>;
};

/** All input for the `deleteDepartment` mutation. */
export type DeleteDepartmentInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly oid: Scalars['UUID'];
};

/** The output of our delete `Department` mutation. */
export type DeleteDepartmentPayload = {
  readonly __typename?: 'DeleteDepartmentPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Department` that was deleted by this mutation. */
  readonly department?: Maybe<Department>;
  readonly deletedDepartmentNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Department` that is related to this `Department`. */
  readonly departmentByParentDepartment?: Maybe<Department>;
  /** An edge for our `Department`. May be used by Relay 1. */
  readonly departmentEdge?: Maybe<DepartmentsEdge>;
};


/** The output of our delete `Department` mutation. */
export type DeleteDepartmentPayloadDepartmentEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<DepartmentsOrderBy>>;
};

/** All input for the `deleteFeedHistory` mutation. */
export type DeleteFeedHistoryInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly oid: Scalars['UUID'];
};

/** The output of our delete `FeedHistory` mutation. */
export type DeleteFeedHistoryPayload = {
  readonly __typename?: 'DeleteFeedHistoryPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `FeedHistory` that was deleted by this mutation. */
  readonly feedHistory?: Maybe<FeedHistory>;
  readonly deletedFeedHistoryNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Feed` that is related to this `FeedHistory`. */
  readonly feedByFeed?: Maybe<Feed>;
  /** An edge for our `FeedHistory`. May be used by Relay 1. */
  readonly feedHistoryEdge?: Maybe<FeedHistoriesEdge>;
};


/** The output of our delete `FeedHistory` mutation. */
export type DeleteFeedHistoryPayloadFeedHistoryEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<FeedHistoriesOrderBy>>;
};

/** All input for the `deleteFeed` mutation. */
export type DeleteFeedInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly oid: Scalars['UUID'];
};

/** The output of our delete `Feed` mutation. */
export type DeleteFeedPayload = {
  readonly __typename?: 'DeleteFeedPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Feed` that was deleted by this mutation. */
  readonly feed?: Maybe<Feed>;
  readonly deletedFeedNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Catalog` that is related to this `Feed`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
  /** An edge for our `Feed`. May be used by Relay 1. */
  readonly feedEdge?: Maybe<FeedsEdge>;
};


/** The output of our delete `Feed` mutation. */
export type DeleteFeedPayloadFeedEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<FeedsOrderBy>>;
};

/** All input for the `deleteLead` mutation. */
export type DeleteLeadInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly oid: Scalars['UUID'];
};

/** The output of our delete `Lead` mutation. */
export type DeleteLeadPayload = {
  readonly __typename?: 'DeleteLeadPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Lead` that was deleted by this mutation. */
  readonly lead?: Maybe<Lead>;
  readonly deletedLeadNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Account` that is related to this `Lead`. */
  readonly accountByAccount?: Maybe<Account>;
  /** Reads a single `Profile` that is related to this `Lead`. */
  readonly profileByProfile?: Maybe<Profile>;
  /** An edge for our `Lead`. May be used by Relay 1. */
  readonly leadEdge?: Maybe<LeadsEdge>;
};


/** The output of our delete `Lead` mutation. */
export type DeleteLeadPayloadLeadEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<LeadsOrderBy>>;
};

/** All input for the `deleteLinkOut` mutation. */
export type DeleteLinkOutInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly oid: Scalars['UUID'];
};

/** The output of our delete `LinkOut` mutation. */
export type DeleteLinkOutPayload = {
  readonly __typename?: 'DeleteLinkOutPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `LinkOut` that was deleted by this mutation. */
  readonly linkOut?: Maybe<LinkOut>;
  readonly deletedLinkOutNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Profile` that is related to this `LinkOut`. */
  readonly profileByCustomer?: Maybe<Profile>;
  /** Reads a single `Catalog` that is related to this `LinkOut`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
  /** An edge for our `LinkOut`. May be used by Relay 1. */
  readonly linkOutEdge?: Maybe<LinkOutsEdge>;
};


/** The output of our delete `LinkOut` mutation. */
export type DeleteLinkOutPayloadLinkOutEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<LinkOutsOrderBy>>;
};

/** All input for the `deleteNavigationItem` mutation. */
export type DeleteNavigationItemInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly oid: Scalars['UUID'];
};

/** The output of our delete `NavigationItem` mutation. */
export type DeleteNavigationItemPayload = {
  readonly __typename?: 'DeleteNavigationItemPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `NavigationItem` that was deleted by this mutation. */
  readonly navigationItem?: Maybe<NavigationItem>;
  readonly deletedNavigationItemNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Department` that is related to this `NavigationItem`. */
  readonly departmentByDepartment?: Maybe<Department>;
  /** An edge for our `NavigationItem`. May be used by Relay 1. */
  readonly navigationItemEdge?: Maybe<NavigationItemsEdge>;
};


/** The output of our delete `NavigationItem` mutation. */
export type DeleteNavigationItemPayloadNavigationItemEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<NavigationItemsOrderBy>>;
};

/** All input for the `deleteOffer` mutation. */
export type DeleteOfferInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly oid: Scalars['UUID'];
};

/** The output of our delete `Offer` mutation. */
export type DeleteOfferPayload = {
  readonly __typename?: 'DeleteOfferPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Offer` that was deleted by this mutation. */
  readonly offer?: Maybe<Offer>;
  readonly deletedOfferNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Catalog` that is related to this `Offer`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
  /** An edge for our `Offer`. May be used by Relay 1. */
  readonly offerEdge?: Maybe<OffersEdge>;
};


/** The output of our delete `Offer` mutation. */
export type DeleteOfferPayloadOfferEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<OffersOrderBy>>;
};

/** All input for the `deleteOrder` mutation. */
export type DeleteOrderInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly oid: Scalars['UUID'];
};

/** The output of our delete `Order` mutation. */
export type DeleteOrderPayload = {
  readonly __typename?: 'DeleteOrderPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Order` that was deleted by this mutation. */
  readonly order?: Maybe<Order>;
  readonly deletedOrderNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** An edge for our `Order`. May be used by Relay 1. */
  readonly orderEdge?: Maybe<OrdersEdge>;
};


/** The output of our delete `Order` mutation. */
export type DeleteOrderPayloadOrderEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<OrdersOrderBy>>;
};

/** All input for the `deleteOrderProduct` mutation. */
export type DeleteOrderProductInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly oid: Scalars['UUID'];
};

/** The output of our delete `OrderProduct` mutation. */
export type DeleteOrderProductPayload = {
  readonly __typename?: 'DeleteOrderProductPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `OrderProduct` that was deleted by this mutation. */
  readonly orderProduct?: Maybe<OrderProduct>;
  readonly deletedOrderProductNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Order` that is related to this `OrderProduct`. */
  readonly orderByOrder?: Maybe<Order>;
  /** An edge for our `OrderProduct`. May be used by Relay 1. */
  readonly orderProductEdge?: Maybe<OrderProductsEdge>;
};


/** The output of our delete `OrderProduct` mutation. */
export type DeleteOrderProductPayloadOrderProductEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<OrderProductsOrderBy>>;
};

/** All input for the `deletePage` mutation. */
export type DeletePageInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly oid: Scalars['UUID'];
};

/** The output of our delete `Page` mutation. */
export type DeletePagePayload = {
  readonly __typename?: 'DeletePagePayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Page` that was deleted by this mutation. */
  readonly page?: Maybe<Page>;
  readonly deletedPageNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Version` that is related to this `Page`. */
  readonly versionByVersion?: Maybe<Version>;
  /** An edge for our `Page`. May be used by Relay 1. */
  readonly pageEdge?: Maybe<PagesEdge>;
};


/** The output of our delete `Page` mutation. */
export type DeletePagePayloadPageEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<PagesOrderBy>>;
};

/** All input for the `deleteProduct` mutation. */
export type DeleteProductInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly oid: Scalars['UUID'];
};

/** The output of our delete `Product` mutation. */
export type DeleteProductPayload = {
  readonly __typename?: 'DeleteProductPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Product` that was deleted by this mutation. */
  readonly product?: Maybe<Product>;
  readonly deletedProductNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Catalog` that is related to this `Product`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
  /** Reads a single `CategoryByCatalog` that is related to this `Product`. */
  readonly categoryByCatalogByCategoryAndCatalog?: Maybe<CategoryByCatalog>;
  /** An edge for our `Product`. May be used by Relay 1. */
  readonly productEdge?: Maybe<ProductsEdge>;
};


/** The output of our delete `Product` mutation. */
export type DeleteProductPayloadProductEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<ProductsOrderBy>>;
};

/** All input for the `deleteProfileByEmail` mutation. */
export type DeleteProfileByEmailInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly email: Scalars['String'];
};

/** All input for the `deleteProfile` mutation. */
export type DeleteProfileInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly oid: Scalars['UUID'];
};

/** The output of our delete `Profile` mutation. */
export type DeleteProfilePayload = {
  readonly __typename?: 'DeleteProfilePayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Profile` that was deleted by this mutation. */
  readonly profile?: Maybe<Profile>;
  readonly deletedProfileNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** An edge for our `Profile`. May be used by Relay 1. */
  readonly profileEdge?: Maybe<ProfilesEdge>;
};


/** The output of our delete `Profile` mutation. */
export type DeleteProfilePayloadProfileEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<ProfilesOrderBy>>;
};

/** All input for the `deleteSubCategoryById` mutation. */
export type DeleteSubCategoryByIdInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
};

/** All input for the `deleteSubCategory` mutation. */
export type DeleteSubCategoryInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly oid: Scalars['UUID'];
};

/** The output of our delete `SubCategory` mutation. */
export type DeleteSubCategoryPayload = {
  readonly __typename?: 'DeleteSubCategoryPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `SubCategory` that was deleted by this mutation. */
  readonly subCategory?: Maybe<SubCategory>;
  readonly deletedSubCategoryNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Category` that is related to this `SubCategory`. */
  readonly categoryByCategory?: Maybe<Category>;
  /** An edge for our `SubCategory`. May be used by Relay 1. */
  readonly subCategoryEdge?: Maybe<SubCategoriesEdge>;
};


/** The output of our delete `SubCategory` mutation. */
export type DeleteSubCategoryPayloadSubCategoryEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<SubCategoriesOrderBy>>;
};

/** All input for the `deleteSubscription` mutation. */
export type DeleteSubscriptionInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly oid: Scalars['UUID'];
};

/** The output of our delete `Subscription` mutation. */
export type DeleteSubscriptionPayload = {
  readonly __typename?: 'DeleteSubscriptionPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Subscription` that was deleted by this mutation. */
  readonly subscription?: Maybe<Subscription>;
  readonly deletedSubscriptionNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Profile` that is related to this `Subscription`. */
  readonly profileByCustomer?: Maybe<Profile>;
  /** Reads a single `Catalog` that is related to this `Subscription`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
  /** An edge for our `Subscription`. May be used by Relay 1. */
  readonly subscriptionEdge?: Maybe<SubscriptionsEdge>;
};


/** The output of our delete `Subscription` mutation. */
export type DeleteSubscriptionPayloadSubscriptionEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<SubscriptionsOrderBy>>;
};

/** All input for the `deleteVersionById` mutation. */
export type DeleteVersionByIdInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
};

/** All input for the `deleteVersion` mutation. */
export type DeleteVersionInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  readonly oid: Scalars['UUID'];
};

/** The output of our delete `Version` mutation. */
export type DeleteVersionPayload = {
  readonly __typename?: 'DeleteVersionPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Version` that was deleted by this mutation. */
  readonly version?: Maybe<Version>;
  readonly deletedVersionNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Catalog` that is related to this `Version`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
  /** An edge for our `Version`. May be used by Relay 1. */
  readonly versionEdge?: Maybe<VersionsEdge>;
};


/** The output of our delete `Version` mutation. */
export type DeleteVersionPayloadVersionEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<VersionsOrderBy>>;
};

export type Department = {
  readonly __typename?: 'Department';
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly departmentId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metakeys?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly searchText?: Maybe<Scalars['String']>;
  readonly advice?: Maybe<Scalars['String']>;
  readonly hiwId?: Maybe<Scalars['Int']>;
  readonly metaTitleArticle?: Maybe<Scalars['String']>;
  readonly metaKeysArticle?: Maybe<Scalars['String']>;
  readonly metaDescrArticle?: Maybe<Scalars['String']>;
  readonly metaKeysProduct?: Maybe<Scalars['String']>;
  readonly articleDept?: Maybe<Scalars['Int']>;
  readonly parentDepartment?: Maybe<Scalars['UUID']>;
  readonly deptType?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly showArticlesByDepartment?: Maybe<Scalars['Boolean']>;
  readonly showArticlesByTag?: Maybe<Scalars['String']>;
  readonly showOffers?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  /** Reads a single `Department` that is related to this `Department`. */
  readonly departmentByParentDepartment?: Maybe<Department>;
  /** Reads and enables pagination through a set of `Catalog`. */
  readonly catalogsByPrimaryDepartmentConnection: CatalogsConnection;
  /** Reads and enables pagination through a set of `Catalog`. */
  readonly catalogsByPrimaryDepartment: ReadonlyArray<Catalog>;
  /** Reads and enables pagination through a set of `Department`. */
  readonly departmentsByParentDepartmentConnection: DepartmentsConnection;
  /** Reads and enables pagination through a set of `Department`. */
  readonly departmentsByParentDepartment: ReadonlyArray<Department>;
  /** Reads and enables pagination through a set of `DepartmentDepartmentsCatalogListing`. */
  readonly departmentDepartmentsCatalogListingsByDepartmentsConnection: DepartmentDepartmentsCatalogListingsConnection;
  /** Reads and enables pagination through a set of `DepartmentDepartmentsCatalogListing`. */
  readonly departmentDepartmentsCatalogListingsByDepartments: ReadonlyArray<DepartmentDepartmentsCatalogListing>;
  /** Reads and enables pagination through a set of `NavigationItem`. */
  readonly navigationItemsByDepartmentConnection: NavigationItemsConnection;
  /** Reads and enables pagination through a set of `NavigationItem`. */
  readonly navigationItemsByDepartment: ReadonlyArray<NavigationItem>;
  /** Reads and enables pagination through a set of `Account`. */
  readonly accountsByCatalogPrimaryDepartmentAndAccount: DepartmentAccountsByCatalogPrimaryDepartmentAndAccountManyToManyConnection;
  /** Reads and enables pagination through a set of `Account`. */
  readonly accountsByCatalogPrimaryDepartmentAndAccountList: ReadonlyArray<Account>;
  /** Reads and enables pagination through a set of `Version`. */
  readonly versionsByCatalogPrimaryDepartmentAndActiveVersion: DepartmentVersionsByCatalogPrimaryDepartmentAndActiveVersionManyToManyConnection;
  /** Reads and enables pagination through a set of `Version`. */
  readonly versionsByCatalogPrimaryDepartmentAndActiveVersionList: ReadonlyArray<Version>;
  /** Reads and enables pagination through a set of `Catalog`. */
  readonly catalogsByDepartmentDepartmentsCatalogListingDepartmentsAndListings: DepartmentCatalogsByDepartmentDepartmentsCatalogListingDepartmentsAndListingsManyToManyConnection;
  /** Reads and enables pagination through a set of `Catalog`. */
  readonly catalogsByDepartmentDepartmentsCatalogListingDepartmentsAndListingsList: ReadonlyArray<Catalog>;
};


export type DepartmentCatalogsByPrimaryDepartmentConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CatalogsOrderBy>>;
  condition?: Maybe<CatalogCondition>;
  filter?: Maybe<CatalogFilter>;
};


export type DepartmentCatalogsByPrimaryDepartmentArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CatalogsOrderBy>>;
  condition?: Maybe<CatalogCondition>;
  filter?: Maybe<CatalogFilter>;
};


export type DepartmentDepartmentsByParentDepartmentConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<DepartmentsOrderBy>>;
  condition?: Maybe<DepartmentCondition>;
  filter?: Maybe<DepartmentFilter>;
};


export type DepartmentDepartmentsByParentDepartmentArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<DepartmentsOrderBy>>;
  condition?: Maybe<DepartmentCondition>;
  filter?: Maybe<DepartmentFilter>;
};


export type DepartmentDepartmentDepartmentsCatalogListingsByDepartmentsConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<DepartmentDepartmentsCatalogListingsOrderBy>>;
  condition?: Maybe<DepartmentDepartmentsCatalogListingCondition>;
  filter?: Maybe<DepartmentDepartmentsCatalogListingFilter>;
};


export type DepartmentDepartmentDepartmentsCatalogListingsByDepartmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<DepartmentDepartmentsCatalogListingsOrderBy>>;
  condition?: Maybe<DepartmentDepartmentsCatalogListingCondition>;
  filter?: Maybe<DepartmentDepartmentsCatalogListingFilter>;
};


export type DepartmentNavigationItemsByDepartmentConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<NavigationItemsOrderBy>>;
  condition?: Maybe<NavigationItemCondition>;
  filter?: Maybe<NavigationItemFilter>;
};


export type DepartmentNavigationItemsByDepartmentArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<NavigationItemsOrderBy>>;
  condition?: Maybe<NavigationItemCondition>;
  filter?: Maybe<NavigationItemFilter>;
};


export type DepartmentAccountsByCatalogPrimaryDepartmentAndAccountArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<AccountsOrderBy>>;
  condition?: Maybe<AccountCondition>;
  filter?: Maybe<AccountFilter>;
};


export type DepartmentAccountsByCatalogPrimaryDepartmentAndAccountListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<AccountsOrderBy>>;
  condition?: Maybe<AccountCondition>;
  filter?: Maybe<AccountFilter>;
};


export type DepartmentVersionsByCatalogPrimaryDepartmentAndActiveVersionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<VersionsOrderBy>>;
  condition?: Maybe<VersionCondition>;
  filter?: Maybe<VersionFilter>;
};


export type DepartmentVersionsByCatalogPrimaryDepartmentAndActiveVersionListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<VersionsOrderBy>>;
  condition?: Maybe<VersionCondition>;
  filter?: Maybe<VersionFilter>;
};


export type DepartmentCatalogsByDepartmentDepartmentsCatalogListingDepartmentsAndListingsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CatalogsOrderBy>>;
  condition?: Maybe<CatalogCondition>;
  filter?: Maybe<CatalogFilter>;
};


export type DepartmentCatalogsByDepartmentDepartmentsCatalogListingDepartmentsAndListingsListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CatalogsOrderBy>>;
  condition?: Maybe<CatalogCondition>;
  filter?: Maybe<CatalogFilter>;
};

/** A connection to a list of `Account` values, with data from `Catalog`. */
export type DepartmentAccountsByCatalogPrimaryDepartmentAndAccountManyToManyConnection = {
  readonly __typename?: 'DepartmentAccountsByCatalogPrimaryDepartmentAndAccountManyToManyConnection';
  /** A list of `Account` objects. */
  readonly nodes: ReadonlyArray<Account>;
  /** A list of edges which contains the `Account`, info from the `Catalog`, and the cursor to aid in pagination. */
  readonly edges: ReadonlyArray<DepartmentAccountsByCatalogPrimaryDepartmentAndAccountManyToManyEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Account` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Account` edge in the connection, with data from `Catalog`. */
export type DepartmentAccountsByCatalogPrimaryDepartmentAndAccountManyToManyEdge = {
  readonly __typename?: 'DepartmentAccountsByCatalogPrimaryDepartmentAndAccountManyToManyEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Account` at the end of the edge. */
  readonly node: Account;
  /** Reads and enables pagination through a set of `Catalog`. */
  readonly catalogsByAccountConnection: CatalogsConnection;
  /** Reads and enables pagination through a set of `Catalog`. */
  readonly catalogsByAccount: ReadonlyArray<Catalog>;
};


/** A `Account` edge in the connection, with data from `Catalog`. */
export type DepartmentAccountsByCatalogPrimaryDepartmentAndAccountManyToManyEdgeCatalogsByAccountConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CatalogsOrderBy>>;
  condition?: Maybe<CatalogCondition>;
  filter?: Maybe<CatalogFilter>;
};


/** A `Account` edge in the connection, with data from `Catalog`. */
export type DepartmentAccountsByCatalogPrimaryDepartmentAndAccountManyToManyEdgeCatalogsByAccountArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CatalogsOrderBy>>;
  condition?: Maybe<CatalogCondition>;
  filter?: Maybe<CatalogFilter>;
};

/** A connection to a list of `Catalog` values, with data from `DepartmentDepartmentsCatalogListing`. */
export type DepartmentCatalogsByDepartmentDepartmentsCatalogListingDepartmentsAndListingsManyToManyConnection = {
  readonly __typename?: 'DepartmentCatalogsByDepartmentDepartmentsCatalogListingDepartmentsAndListingsManyToManyConnection';
  /** A list of `Catalog` objects. */
  readonly nodes: ReadonlyArray<Catalog>;
  /** A list of edges which contains the `Catalog`, info from the `DepartmentDepartmentsCatalogListing`, and the cursor to aid in pagination. */
  readonly edges: ReadonlyArray<DepartmentCatalogsByDepartmentDepartmentsCatalogListingDepartmentsAndListingsManyToManyEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Catalog` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Catalog` edge in the connection, with data from `DepartmentDepartmentsCatalogListing`. */
export type DepartmentCatalogsByDepartmentDepartmentsCatalogListingDepartmentsAndListingsManyToManyEdge = {
  readonly __typename?: 'DepartmentCatalogsByDepartmentDepartmentsCatalogListingDepartmentsAndListingsManyToManyEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Catalog` at the end of the edge. */
  readonly node: Catalog;
  /** Reads and enables pagination through a set of `DepartmentDepartmentsCatalogListing`. */
  readonly departmentDepartmentsCatalogListingsByListingsConnection: DepartmentDepartmentsCatalogListingsConnection;
  /** Reads and enables pagination through a set of `DepartmentDepartmentsCatalogListing`. */
  readonly departmentDepartmentsCatalogListingsByListings: ReadonlyArray<DepartmentDepartmentsCatalogListing>;
};


/** A `Catalog` edge in the connection, with data from `DepartmentDepartmentsCatalogListing`. */
export type DepartmentCatalogsByDepartmentDepartmentsCatalogListingDepartmentsAndListingsManyToManyEdgeDepartmentDepartmentsCatalogListingsByListingsConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<DepartmentDepartmentsCatalogListingsOrderBy>>;
  condition?: Maybe<DepartmentDepartmentsCatalogListingCondition>;
  filter?: Maybe<DepartmentDepartmentsCatalogListingFilter>;
};


/** A `Catalog` edge in the connection, with data from `DepartmentDepartmentsCatalogListing`. */
export type DepartmentCatalogsByDepartmentDepartmentsCatalogListingDepartmentsAndListingsManyToManyEdgeDepartmentDepartmentsCatalogListingsByListingsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<DepartmentDepartmentsCatalogListingsOrderBy>>;
  condition?: Maybe<DepartmentDepartmentsCatalogListingCondition>;
  filter?: Maybe<DepartmentDepartmentsCatalogListingFilter>;
};

/** A condition to be used against `Department` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type DepartmentCondition = {
  /** Checks for equality with the object’s `oid` field. */
  readonly oid?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `options` field. */
  readonly options?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `id` field. */
  readonly id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `slug` field. */
  readonly slug?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `departmentId` field. */
  readonly departmentId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `title` field. */
  readonly title?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `active` field. */
  readonly active?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `metaTitle` field. */
  readonly metaTitle?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `metakeys` field. */
  readonly metakeys?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `description` field. */
  readonly description?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `altText` field. */
  readonly altText?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `searchText` field. */
  readonly searchText?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `advice` field. */
  readonly advice?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `hiwId` field. */
  readonly hiwId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `metaTitleArticle` field. */
  readonly metaTitleArticle?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `metaKeysArticle` field. */
  readonly metaKeysArticle?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `metaDescrArticle` field. */
  readonly metaDescrArticle?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `metaKeysProduct` field. */
  readonly metaKeysProduct?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `articleDept` field. */
  readonly articleDept?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `parentDepartment` field. */
  readonly parentDepartment?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `deptType` field. */
  readonly deptType?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `showArticlesByDepartment` field. */
  readonly showArticlesByDepartment?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `showArticlesByTag` field. */
  readonly showArticlesByTag?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `showOffers` field. */
  readonly showOffers?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `targetUrl` field. */
  readonly targetUrl?: Maybe<Scalars['String']>;
};

export type DepartmentDepartmentsCatalogListing = {
  readonly __typename?: 'DepartmentDepartmentsCatalogListing';
  readonly listings?: Maybe<Scalars['UUID']>;
  readonly departments?: Maybe<Scalars['UUID']>;
  readonly oid: Scalars['UUID'];
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  /** Reads a single `Catalog` that is related to this `DepartmentDepartmentsCatalogListing`. */
  readonly catalogByListings?: Maybe<Catalog>;
  /** Reads a single `Department` that is related to this `DepartmentDepartmentsCatalogListing`. */
  readonly departmentByDepartments?: Maybe<Department>;
};

/** A condition to be used against `DepartmentDepartmentsCatalogListing` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type DepartmentDepartmentsCatalogListingCondition = {
  /** Checks for equality with the object’s `listings` field. */
  readonly listings?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `departments` field. */
  readonly departments?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `oid` field. */
  readonly oid?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `DepartmentDepartmentsCatalogListing` object types. All fields are combined with a logical ‘and.’ */
export type DepartmentDepartmentsCatalogListingFilter = {
  /** Filter by the object’s `listings` field. */
  readonly listings?: Maybe<UuidFilter>;
  /** Filter by the object’s `departments` field. */
  readonly departments?: Maybe<UuidFilter>;
  /** Filter by the object’s `oid` field. */
  readonly oid?: Maybe<UuidFilter>;
  /** Filter by the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<IntFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<ReadonlyArray<DepartmentDepartmentsCatalogListingFilter>>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<ReadonlyArray<DepartmentDepartmentsCatalogListingFilter>>;
  /** Negates the expression. */
  readonly not?: Maybe<DepartmentDepartmentsCatalogListingFilter>;
};

/** An input for mutations affecting `DepartmentDepartmentsCatalogListing` */
export type DepartmentDepartmentsCatalogListingInput = {
  readonly listings?: Maybe<Scalars['UUID']>;
  readonly departments?: Maybe<Scalars['UUID']>;
  readonly oid: Scalars['UUID'];
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly catalogToListings?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInput>;
  readonly departmentToDepartments?: Maybe<FkDepartmentDepartmentsCatalogListingsDepartmentsInput>;
};

/** The fields on `departmentDepartmentsCatalogListing` to look up the row to update. */
export type DepartmentDepartmentsCatalogListingOnDepartmentDepartmentsCatalogListingForFkDepartmentDepartmentsCatalogListingsDepartmentsUsingPkDepartmentDepartmentsCatalogListingsUpdate = {
  /** An object where the defined keys will be set on the `departmentDepartmentsCatalogListing` being updated. */
  readonly patch: UpdateDepartmentDepartmentsCatalogListingOnDepartmentDepartmentsCatalogListingForFkDepartmentDepartmentsCatalogListingsDepartmentsPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `departmentDepartmentsCatalogListing` to look up the row to update. */
export type DepartmentDepartmentsCatalogListingOnDepartmentDepartmentsCatalogListingForFkDepartmentDepartmentsCatalogListingsListingsUsingPkDepartmentDepartmentsCatalogListingsUpdate = {
  /** An object where the defined keys will be set on the `departmentDepartmentsCatalogListing` being updated. */
  readonly patch: UpdateDepartmentDepartmentsCatalogListingOnDepartmentDepartmentsCatalogListingForFkDepartmentDepartmentsCatalogListingsListingsPatch;
  readonly oid: Scalars['UUID'];
};

/** Represents an update to a `DepartmentDepartmentsCatalogListing`. Fields that are set will be updated. */
export type DepartmentDepartmentsCatalogListingPatch = {
  readonly listings?: Maybe<Scalars['UUID']>;
  readonly departments?: Maybe<Scalars['UUID']>;
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly catalogToListings?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInput>;
  readonly departmentToDepartments?: Maybe<FkDepartmentDepartmentsCatalogListingsDepartmentsInput>;
};

/** The fields on `departmentDepartmentsCatalogListing` to look up the row to connect. */
export type DepartmentDepartmentsCatalogListingPkDepartmentDepartmentsCatalogListingsConnect = {
  readonly oid: Scalars['UUID'];
};

/** The fields on `departmentDepartmentsCatalogListing` to look up the row to delete. */
export type DepartmentDepartmentsCatalogListingPkDepartmentDepartmentsCatalogListingsDelete = {
  readonly oid: Scalars['UUID'];
};

/** A connection to a list of `DepartmentDepartmentsCatalogListing` values. */
export type DepartmentDepartmentsCatalogListingsConnection = {
  readonly __typename?: 'DepartmentDepartmentsCatalogListingsConnection';
  /** A list of `DepartmentDepartmentsCatalogListing` objects. */
  readonly nodes: ReadonlyArray<DepartmentDepartmentsCatalogListing>;
  /** A list of edges which contains the `DepartmentDepartmentsCatalogListing` and cursor to aid in pagination. */
  readonly edges: ReadonlyArray<DepartmentDepartmentsCatalogListingsEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `DepartmentDepartmentsCatalogListing` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `DepartmentDepartmentsCatalogListing` edge in the connection. */
export type DepartmentDepartmentsCatalogListingsEdge = {
  readonly __typename?: 'DepartmentDepartmentsCatalogListingsEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `DepartmentDepartmentsCatalogListing` at the end of the edge. */
  readonly node: DepartmentDepartmentsCatalogListing;
};

/** Methods to use when ordering `DepartmentDepartmentsCatalogListing`. */
export enum DepartmentDepartmentsCatalogListingsOrderBy {
  Natural = 'NATURAL',
  ListingsAsc = 'LISTINGS_ASC',
  ListingsDesc = 'LISTINGS_DESC',
  DepartmentsAsc = 'DEPARTMENTS_ASC',
  DepartmentsDesc = 'DEPARTMENTS_DESC',
  OidAsc = 'OID_ASC',
  OidDesc = 'OID_DESC',
  OptimisticLockFieldAsc = 'OPTIMISTIC_LOCK_FIELD_ASC',
  OptimisticLockFieldDesc = 'OPTIMISTIC_LOCK_FIELD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A filter to be used against `Department` object types. All fields are combined with a logical ‘and.’ */
export type DepartmentFilter = {
  /** Filter by the object’s `oid` field. */
  readonly oid?: Maybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  readonly id?: Maybe<IntFilter>;
  /** Filter by the object’s `slug` field. */
  readonly slug?: Maybe<StringFilter>;
  /** Filter by the object’s `departmentId` field. */
  readonly departmentId?: Maybe<IntFilter>;
  /** Filter by the object’s `title` field. */
  readonly title?: Maybe<StringFilter>;
  /** Filter by the object’s `active` field. */
  readonly active?: Maybe<BooleanFilter>;
  /** Filter by the object’s `metaTitle` field. */
  readonly metaTitle?: Maybe<StringFilter>;
  /** Filter by the object’s `metakeys` field. */
  readonly metakeys?: Maybe<StringFilter>;
  /** Filter by the object’s `description` field. */
  readonly description?: Maybe<StringFilter>;
  /** Filter by the object’s `altText` field. */
  readonly altText?: Maybe<StringFilter>;
  /** Filter by the object’s `searchText` field. */
  readonly searchText?: Maybe<StringFilter>;
  /** Filter by the object’s `advice` field. */
  readonly advice?: Maybe<StringFilter>;
  /** Filter by the object’s `hiwId` field. */
  readonly hiwId?: Maybe<IntFilter>;
  /** Filter by the object’s `metaTitleArticle` field. */
  readonly metaTitleArticle?: Maybe<StringFilter>;
  /** Filter by the object’s `metaKeysArticle` field. */
  readonly metaKeysArticle?: Maybe<StringFilter>;
  /** Filter by the object’s `metaDescrArticle` field. */
  readonly metaDescrArticle?: Maybe<StringFilter>;
  /** Filter by the object’s `metaKeysProduct` field. */
  readonly metaKeysProduct?: Maybe<StringFilter>;
  /** Filter by the object’s `articleDept` field. */
  readonly articleDept?: Maybe<IntFilter>;
  /** Filter by the object’s `parentDepartment` field. */
  readonly parentDepartment?: Maybe<UuidFilter>;
  /** Filter by the object’s `deptType` field. */
  readonly deptType?: Maybe<StringFilter>;
  /** Filter by the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<IntFilter>;
  /** Filter by the object’s `showArticlesByDepartment` field. */
  readonly showArticlesByDepartment?: Maybe<BooleanFilter>;
  /** Filter by the object’s `showArticlesByTag` field. */
  readonly showArticlesByTag?: Maybe<StringFilter>;
  /** Filter by the object’s `showOffers` field. */
  readonly showOffers?: Maybe<IntFilter>;
  /** Filter by the object’s `targetUrl` field. */
  readonly targetUrl?: Maybe<StringFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<ReadonlyArray<DepartmentFilter>>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<ReadonlyArray<DepartmentFilter>>;
  /** Negates the expression. */
  readonly not?: Maybe<DepartmentFilter>;
};

/** An input for mutations affecting `Department` */
export type DepartmentInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly departmentId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metakeys?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly searchText?: Maybe<Scalars['String']>;
  readonly advice?: Maybe<Scalars['String']>;
  readonly hiwId?: Maybe<Scalars['Int']>;
  readonly metaTitleArticle?: Maybe<Scalars['String']>;
  readonly metaKeysArticle?: Maybe<Scalars['String']>;
  readonly metaDescrArticle?: Maybe<Scalars['String']>;
  readonly metaKeysProduct?: Maybe<Scalars['String']>;
  readonly articleDept?: Maybe<Scalars['Int']>;
  readonly parentDepartment?: Maybe<Scalars['UUID']>;
  readonly deptType?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly showArticlesByDepartment?: Maybe<Scalars['Boolean']>;
  readonly showArticlesByTag?: Maybe<Scalars['String']>;
  readonly showOffers?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly departmentToParentDepartment?: Maybe<FkDepartmentParentDepartmentInput>;
  readonly catalogsUsingOid?: Maybe<FkCatalogPrimaryDepartmentInverseInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsDepartmentsInverseInput>;
  readonly navigationItemsUsingOid?: Maybe<FkNavigationItemDepartmentInverseInput>;
};

/** The fields on `department` to look up the row to update. */
export type DepartmentOnCatalogForFkCatalogPrimaryDepartmentUsingPkDepartmentUpdate = {
  /** An object where the defined keys will be set on the `department` being updated. */
  readonly patch: UpdateDepartmentOnCatalogForFkCatalogPrimaryDepartmentPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `department` to look up the row to update. */
export type DepartmentOnDepartmentDepartmentsCatalogListingForFkDepartmentDepartmentsCatalogListingsDepartmentsUsingPkDepartmentUpdate = {
  /** An object where the defined keys will be set on the `department` being updated. */
  readonly patch: UpdateDepartmentOnDepartmentDepartmentsCatalogListingForFkDepartmentDepartmentsCatalogListingsDepartmentsPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `department` to look up the row to update. */
export type DepartmentOnDepartmentForFkDepartmentParentDepartmentUsingPkDepartmentUpdate = {
  /** An object where the defined keys will be set on the `department` being updated. */
  readonly patch: UpdateDepartmentOnDepartmentForFkDepartmentParentDepartmentPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `department` to look up the row to update. */
export type DepartmentOnNavigationItemForFkNavigationItemDepartmentUsingPkDepartmentUpdate = {
  /** An object where the defined keys will be set on the `department` being updated. */
  readonly patch: UpdateDepartmentOnNavigationItemForFkNavigationItemDepartmentPatch;
  readonly oid: Scalars['UUID'];
};

/** Represents an update to a `Department`. Fields that are set will be updated. */
export type DepartmentPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly departmentId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metakeys?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly searchText?: Maybe<Scalars['String']>;
  readonly advice?: Maybe<Scalars['String']>;
  readonly hiwId?: Maybe<Scalars['Int']>;
  readonly metaTitleArticle?: Maybe<Scalars['String']>;
  readonly metaKeysArticle?: Maybe<Scalars['String']>;
  readonly metaDescrArticle?: Maybe<Scalars['String']>;
  readonly metaKeysProduct?: Maybe<Scalars['String']>;
  readonly articleDept?: Maybe<Scalars['Int']>;
  readonly parentDepartment?: Maybe<Scalars['UUID']>;
  readonly deptType?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly showArticlesByDepartment?: Maybe<Scalars['Boolean']>;
  readonly showArticlesByTag?: Maybe<Scalars['String']>;
  readonly showOffers?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly departmentToParentDepartment?: Maybe<FkDepartmentParentDepartmentInput>;
  readonly catalogsUsingOid?: Maybe<FkCatalogPrimaryDepartmentInverseInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsDepartmentsInverseInput>;
  readonly navigationItemsUsingOid?: Maybe<FkNavigationItemDepartmentInverseInput>;
};

/** The fields on `department` to look up the row to connect. */
export type DepartmentPkDepartmentConnect = {
  readonly oid: Scalars['UUID'];
};

/** The fields on `department` to look up the row to delete. */
export type DepartmentPkDepartmentDelete = {
  readonly oid: Scalars['UUID'];
};

/** A connection to a list of `Version` values, with data from `Catalog`. */
export type DepartmentVersionsByCatalogPrimaryDepartmentAndActiveVersionManyToManyConnection = {
  readonly __typename?: 'DepartmentVersionsByCatalogPrimaryDepartmentAndActiveVersionManyToManyConnection';
  /** A list of `Version` objects. */
  readonly nodes: ReadonlyArray<Version>;
  /** A list of edges which contains the `Version`, info from the `Catalog`, and the cursor to aid in pagination. */
  readonly edges: ReadonlyArray<DepartmentVersionsByCatalogPrimaryDepartmentAndActiveVersionManyToManyEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Version` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Version` edge in the connection, with data from `Catalog`. */
export type DepartmentVersionsByCatalogPrimaryDepartmentAndActiveVersionManyToManyEdge = {
  readonly __typename?: 'DepartmentVersionsByCatalogPrimaryDepartmentAndActiveVersionManyToManyEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Version` at the end of the edge. */
  readonly node: Version;
  /** Reads and enables pagination through a set of `Catalog`. */
  readonly catalogsByActiveVersionConnection: CatalogsConnection;
  /** Reads and enables pagination through a set of `Catalog`. */
  readonly catalogsByActiveVersion: ReadonlyArray<Catalog>;
};


/** A `Version` edge in the connection, with data from `Catalog`. */
export type DepartmentVersionsByCatalogPrimaryDepartmentAndActiveVersionManyToManyEdgeCatalogsByActiveVersionConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CatalogsOrderBy>>;
  condition?: Maybe<CatalogCondition>;
  filter?: Maybe<CatalogFilter>;
};


/** A `Version` edge in the connection, with data from `Catalog`. */
export type DepartmentVersionsByCatalogPrimaryDepartmentAndActiveVersionManyToManyEdgeCatalogsByActiveVersionArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CatalogsOrderBy>>;
  condition?: Maybe<CatalogCondition>;
  filter?: Maybe<CatalogFilter>;
};

/** A connection to a list of `Department` values. */
export type DepartmentsConnection = {
  readonly __typename?: 'DepartmentsConnection';
  /** A list of `Department` objects. */
  readonly nodes: ReadonlyArray<Department>;
  /** A list of edges which contains the `Department` and cursor to aid in pagination. */
  readonly edges: ReadonlyArray<DepartmentsEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Department` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Department` edge in the connection. */
export type DepartmentsEdge = {
  readonly __typename?: 'DepartmentsEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Department` at the end of the edge. */
  readonly node: Department;
};

/** Methods to use when ordering `Department`. */
export enum DepartmentsOrderBy {
  Natural = 'NATURAL',
  OidAsc = 'OID_ASC',
  OidDesc = 'OID_DESC',
  OptionsAsc = 'OPTIONS_ASC',
  OptionsDesc = 'OPTIONS_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  DepartmentIdAsc = 'DEPARTMENT_ID_ASC',
  DepartmentIdDesc = 'DEPARTMENT_ID_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  ActiveAsc = 'ACTIVE_ASC',
  ActiveDesc = 'ACTIVE_DESC',
  MetaTitleAsc = 'META_TITLE_ASC',
  MetaTitleDesc = 'META_TITLE_DESC',
  MetakeysAsc = 'METAKEYS_ASC',
  MetakeysDesc = 'METAKEYS_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  AltTextAsc = 'ALT_TEXT_ASC',
  AltTextDesc = 'ALT_TEXT_DESC',
  SearchTextAsc = 'SEARCH_TEXT_ASC',
  SearchTextDesc = 'SEARCH_TEXT_DESC',
  AdviceAsc = 'ADVICE_ASC',
  AdviceDesc = 'ADVICE_DESC',
  HiwIdAsc = 'HIW_ID_ASC',
  HiwIdDesc = 'HIW_ID_DESC',
  MetaTitleArticleAsc = 'META_TITLE_ARTICLE_ASC',
  MetaTitleArticleDesc = 'META_TITLE_ARTICLE_DESC',
  MetaKeysArticleAsc = 'META_KEYS_ARTICLE_ASC',
  MetaKeysArticleDesc = 'META_KEYS_ARTICLE_DESC',
  MetaDescrArticleAsc = 'META_DESCR_ARTICLE_ASC',
  MetaDescrArticleDesc = 'META_DESCR_ARTICLE_DESC',
  MetaKeysProductAsc = 'META_KEYS_PRODUCT_ASC',
  MetaKeysProductDesc = 'META_KEYS_PRODUCT_DESC',
  ArticleDeptAsc = 'ARTICLE_DEPT_ASC',
  ArticleDeptDesc = 'ARTICLE_DEPT_DESC',
  ParentDepartmentAsc = 'PARENT_DEPARTMENT_ASC',
  ParentDepartmentDesc = 'PARENT_DEPARTMENT_DESC',
  DeptTypeAsc = 'DEPT_TYPE_ASC',
  DeptTypeDesc = 'DEPT_TYPE_DESC',
  OptimisticLockFieldAsc = 'OPTIMISTIC_LOCK_FIELD_ASC',
  OptimisticLockFieldDesc = 'OPTIMISTIC_LOCK_FIELD_DESC',
  ShowArticlesByDepartmentAsc = 'SHOW_ARTICLES_BY_DEPARTMENT_ASC',
  ShowArticlesByDepartmentDesc = 'SHOW_ARTICLES_BY_DEPARTMENT_DESC',
  ShowArticlesByTagAsc = 'SHOW_ARTICLES_BY_TAG_ASC',
  ShowArticlesByTagDesc = 'SHOW_ARTICLES_BY_TAG_DESC',
  ShowOffersAsc = 'SHOW_OFFERS_ASC',
  ShowOffersDesc = 'SHOW_OFFERS_DESC',
  TargetUrlAsc = 'TARGET_URL_ASC',
  TargetUrlDesc = 'TARGET_URL_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** The `authorizedUser` to be created by this mutation. */
export type FakePublicAuthorizedUserForeignKey0AuthorizedUserCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly faceBookId?: Maybe<Scalars['String']>;
  readonly googleId?: Maybe<Scalars['String']>;
  readonly linkedInId?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly profileToEmail?: Maybe<FakePublicAuthorizedUserForeignKey0Input>;
  readonly authorizedUserAuthorizedUsersAccountPartnerAccessesUsingOid?: Maybe<FkAuthorizedUserAuthorizedUsersAccountPartnerAccessB343B2CfInverseInput>;
};

/** Input for the nested mutation of `profile` in the `AuthorizedUserInput` mutation. */
export type FakePublicAuthorizedUserForeignKey0Input = {
  /** The primary key(s) for `profile` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ProfilePkProfileConnect>;
  /** The primary key(s) for `profile` for the far side of the relationship. */
  readonly connectByEmail?: Maybe<ProfileIEmailProfileConnect>;
  /** The primary key(s) for `profile` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ProfilePkProfileDelete>;
  /** The primary key(s) for `profile` for the far side of the relationship. */
  readonly deleteByEmail?: Maybe<ProfileIEmailProfileDelete>;
  /** The primary key(s) and patch data for `profile` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ProfileOnAuthorizedUserForFakePublicAuthorizedUserForeignKey0UsingPkProfileUpdate>;
  /** The primary key(s) and patch data for `profile` for the far side of the relationship. */
  readonly updateByEmail?: Maybe<ProfileOnAuthorizedUserForFakePublicAuthorizedUserForeignKey0UsingIEmailProfileUpdate>;
  /** A `ProfileInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FakePublicAuthorizedUserForeignKey0ProfileCreateInput>;
};

/** Input for the nested mutation of `authorizedUser` in the `ProfileInput` mutation. */
export type FakePublicAuthorizedUserForeignKey0InverseInput = {
  /** Flag indicating whether all other `authorizedUser` records that match this relationship should be removed. */
  readonly deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `authorizedUser` for the far side of the relationship. */
  readonly connectByOid?: Maybe<AuthorizedUserPkAuthorizedUserConnect>;
  /** The primary key(s) for `authorizedUser` for the far side of the relationship. */
  readonly connectByEmail?: Maybe<AuthorizedUserAuthorizedUserByEmailConnect>;
  /** The primary key(s) for `authorizedUser` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<AuthorizedUserPkAuthorizedUserDelete>;
  /** The primary key(s) for `authorizedUser` for the far side of the relationship. */
  readonly deleteByEmail?: Maybe<AuthorizedUserAuthorizedUserByEmailDelete>;
  /** The primary key(s) and patch data for `authorizedUser` for the far side of the relationship. */
  readonly updateByOid?: Maybe<AuthorizedUserOnAuthorizedUserForFakePublicAuthorizedUserForeignKey0UsingPkAuthorizedUserUpdate>;
  /** The primary key(s) and patch data for `authorizedUser` for the far side of the relationship. */
  readonly updateByEmail?: Maybe<AuthorizedUserOnAuthorizedUserForFakePublicAuthorizedUserForeignKey0UsingAuthorizedUserByEmailUpdate>;
  /** A `AuthorizedUserInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FakePublicAuthorizedUserForeignKey0AuthorizedUserCreateInput>>;
};

/** The `profile` to be created by this mutation. */
export type FakePublicAuthorizedUserForeignKey0ProfileCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly company?: Maybe<Scalars['String']>;
  readonly dateCreated?: Maybe<Scalars['Datetime']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCustomerInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCustomerInverseInput>;
  readonly leadsUsingOid?: Maybe<FkLeadProfileInverseInput>;
  readonly authorizedUserUsingEmail?: Maybe<FakePublicAuthorizedUserForeignKey0InverseInput>;
};

/** The `catalog` to be created by this mutation. */
export type FakePublicCategoryByCatalogForeignKey0CatalogCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly cover?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly catalogFormat?: Maybe<Scalars['String']>;
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly leadCharge?: Maybe<Scalars['Float']>;
  readonly linkCharge?: Maybe<Scalars['Float']>;
  readonly digitalFee?: Maybe<Scalars['Float']>;
  readonly listingFee?: Maybe<Scalars['Float']>;
  readonly dateExpired?: Maybe<Scalars['Datetime']>;
  readonly trackingCode?: Maybe<Scalars['String']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly showCategoryLinks?: Maybe<Scalars['Boolean']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Datetime']>;
  readonly offerCopy?: Maybe<Scalars['String']>;
  readonly introCopy?: Maybe<Scalars['String']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  readonly blurb?: Maybe<Scalars['String']>;
  readonly featureCopy?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly keywords?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly longDescription?: Maybe<Scalars['String']>;
  readonly class?: Maybe<Scalars['String']>;
  readonly searchAppendCode?: Maybe<Scalars['String']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly primaryDepartment?: Maybe<Scalars['UUID']>;
  readonly isDigital?: Maybe<Scalars['Boolean']>;
  readonly merchantId?: Maybe<Scalars['BigInt']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly autoActivate?: Maybe<Scalars['Boolean']>;
  readonly groupHubspotId?: Maybe<Scalars['String']>;
  readonly customSubdomain?: Maybe<Scalars['String']>;
  readonly text2Disclaimer?: Maybe<Scalars['String']>;
  readonly activeVersion?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly collectFullAddress?: Maybe<Scalars['Boolean']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly freeShipping?: Maybe<Scalars['Boolean']>;
  readonly mailOrder?: Maybe<Scalars['Boolean']>;
  readonly collectLeadQr?: Maybe<Scalars['Boolean']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly hideAds?: Maybe<Scalars['Boolean']>;
  readonly accountToAccount?: Maybe<FkCatalogAccountInput>;
  readonly departmentToPrimaryDepartment?: Maybe<FkCatalogPrimaryDepartmentInput>;
  readonly versionToActiveVersion?: Maybe<FkCatalogActiveVersionInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInverseInput>;
  readonly versionsUsingOid?: Maybe<FkVersionCatalogInverseInput>;
  readonly offersUsingOid?: Maybe<FkOfferCatalogInverseInput>;
  readonly categoriesUsingOid?: Maybe<FkCategoryCatalogInverseInput>;
  readonly feedsUsingOid?: Maybe<FkFeedCatalogInverseInput>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCatalogInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCatalogInverseInput>;
  readonly productsUsingOid?: Maybe<FkProductCatalogInverseInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCatalogInverseInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingCatalogInverseInput>;
  readonly categoryByCatalogsUsingOid?: Maybe<FakePublicCategoryByCatalogForeignKey0InverseInput>;
};

/** The `categoryByCatalog` to be created by this mutation. */
export type FakePublicCategoryByCatalogForeignKey0CategoryByCatalogCreateInput = {
  readonly category?: Maybe<Scalars['String']>;
  readonly main?: Maybe<Scalars['String']>;
  readonly sub?: Maybe<Scalars['String']>;
  readonly catalogToCatalog?: Maybe<FakePublicCategoryByCatalogForeignKey0Input>;
  readonly productsUsingCategoryAndCatalog?: Maybe<FakePublicProductForeignKey0InverseInput>;
};

/** Input for the nested mutation of `catalog` in the `CategoryByCatalogInput` mutation. */
export type FakePublicCategoryByCatalogForeignKey0Input = {
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly connectByOid?: Maybe<CatalogPkCatalogConnect>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly connectById?: Maybe<CatalogCatalogByIdConnect>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<CatalogPkCatalogDelete>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly deleteById?: Maybe<CatalogCatalogByIdDelete>;
  /** The primary key(s) and patch data for `catalog` for the far side of the relationship. */
  readonly updateByOid?: Maybe<CatalogOnCategoryByCatalogForFakePublicCategoryByCatalogForeignKey0UsingPkCatalogUpdate>;
  /** The primary key(s) and patch data for `catalog` for the far side of the relationship. */
  readonly updateById?: Maybe<CatalogOnCategoryByCatalogForFakePublicCategoryByCatalogForeignKey0UsingCatalogByIdUpdate>;
  /** A `CatalogInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FakePublicCategoryByCatalogForeignKey0CatalogCreateInput>;
};

/** Input for the nested mutation of `categoryByCatalog` in the `CatalogInput` mutation. */
export type FakePublicCategoryByCatalogForeignKey0InverseInput = {
  /** A `CategoryByCatalogInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FakePublicCategoryByCatalogForeignKey0CategoryByCatalogCreateInput>>;
};

/** The `categoryByCatalog` to be created by this mutation. */
export type FakePublicProductForeignKey0CategoryByCatalogCreateInput = {
  readonly main?: Maybe<Scalars['String']>;
  readonly sub?: Maybe<Scalars['String']>;
  readonly catalogToCatalog?: Maybe<FakePublicCategoryByCatalogForeignKey0Input>;
  readonly productsUsingCategoryAndCatalog?: Maybe<FakePublicProductForeignKey0InverseInput>;
};

/** Input for the nested mutation of `categoryByCatalog` in the `ProductInput` mutation. */
export type FakePublicProductForeignKey0Input = {
  /** A `CategoryByCatalogInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FakePublicProductForeignKey0CategoryByCatalogCreateInput>;
};

/** Input for the nested mutation of `product` in the `CategoryByCatalogInput` mutation. */
export type FakePublicProductForeignKey0InverseInput = {
  /** Flag indicating whether all other `product` records that match this relationship should be removed. */
  readonly deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `product` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ReadonlyArray<ProductPkProductConnect>>;
  /** The primary key(s) for `product` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ReadonlyArray<ProductPkProductDelete>>;
  /** The primary key(s) and patch data for `product` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ReadonlyArray<ProductOnProductForFakePublicProductForeignKey0UsingPkProductUpdate>>;
  /** A `ProductInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FakePublicProductForeignKey0ProductCreateInput>>;
};

/** The `product` to be created by this mutation. */
export type FakePublicProductForeignKey0ProductCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly brand?: Maybe<Scalars['String']>;
  readonly color?: Maybe<Scalars['String']>;
  readonly sku?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
  readonly tags?: Maybe<Scalars['String']>;
  readonly price?: Maybe<Scalars['Float']>;
  readonly dateEntered?: Maybe<Scalars['Datetime']>;
  readonly srcOriginal?: Maybe<Scalars['String']>;
  readonly additionalImages?: Maybe<Scalars['JSON']>;
  readonly additionalFields?: Maybe<Scalars['JSON']>;
  readonly changeHistory?: Maybe<Scalars['JSON']>;
  readonly dateModified?: Maybe<Scalars['Datetime']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly subCategory?: Maybe<Scalars['String']>;
  readonly salePrice?: Maybe<Scalars['Float']>;
  readonly expired?: Maybe<Scalars['Boolean']>;
  readonly src?: Maybe<Scalars['String']>;
  readonly rotated?: Maybe<Scalars['Boolean']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly catalogToCatalog?: Maybe<FkProductCatalogInput>;
  readonly categoryByCatalogToCategoryAndCatalog?: Maybe<FakePublicProductForeignKey0Input>;
};

/** The `category` to be created by this mutation. */
export type FakePublicSubProductsForeignKey0CategoryCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly featured?: Maybe<Scalars['Int']>;
  readonly image?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly linkoutUrl?: Maybe<Scalars['String']>;
  readonly productsPerPage?: Maybe<Scalars['Int']>;
  readonly imageOrientation?: Maybe<Scalars['Boolean']>;
  readonly productBorder?: Maybe<Scalars['Boolean']>;
  readonly animated?: Maybe<Scalars['Boolean']>;
  readonly rankOrder?: Maybe<Scalars['Int']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly catalogToCatalog?: Maybe<FkCategoryCatalogInput>;
  readonly subCategoriesUsingOid?: Maybe<FkSubCategoryCategoryInverseInput>;
  readonly subProductsUsingNameAndCatalog?: Maybe<FakePublicSubProductsForeignKey0InverseInput>;
};

/** Input for the nested mutation of `category` in the `SubProductInput` mutation. */
export type FakePublicSubProductsForeignKey0Input = {
  /** The primary key(s) for `category` for the far side of the relationship. */
  readonly connectByOid?: Maybe<CategoryPkCategoryConnect>;
  /** The primary key(s) for `category` for the far side of the relationship. */
  readonly connectById?: Maybe<CategoryCategoryByIdConnect>;
  /** The primary key(s) for `category` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<CategoryPkCategoryDelete>;
  /** The primary key(s) for `category` for the far side of the relationship. */
  readonly deleteById?: Maybe<CategoryCategoryByIdDelete>;
  /** The primary key(s) and patch data for `category` for the far side of the relationship. */
  readonly updateByOid?: Maybe<CategoryOnSubProductForFakePublicSubProductsForeignKey0UsingPkCategoryUpdate>;
  /** The primary key(s) and patch data for `category` for the far side of the relationship. */
  readonly updateById?: Maybe<CategoryOnSubProductForFakePublicSubProductsForeignKey0UsingCategoryByIdUpdate>;
  /** A `CategoryInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FakePublicSubProductsForeignKey0CategoryCreateInput>;
};

/** Input for the nested mutation of `subProduct` in the `CategoryInput` mutation. */
export type FakePublicSubProductsForeignKey0InverseInput = {
  /** A `SubProductInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FakePublicSubProductsForeignKey0SubProductsCreateInput>>;
};

/** The `subProduct` to be created by this mutation. */
export type FakePublicSubProductsForeignKey0SubProductsCreateInput = {
  readonly sub?: Maybe<Scalars['String']>;
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly brand?: Maybe<Scalars['String']>;
  readonly color?: Maybe<Scalars['String']>;
  readonly sku?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
  readonly tags?: Maybe<Scalars['String']>;
  readonly price?: Maybe<Scalars['Float']>;
  readonly dateEntered?: Maybe<Scalars['Datetime']>;
  readonly srcOriginal?: Maybe<Scalars['String']>;
  readonly additionalImages?: Maybe<Scalars['JSON']>;
  readonly additionalFields?: Maybe<Scalars['JSON']>;
  readonly changeHistory?: Maybe<Scalars['JSON']>;
  readonly dateModified?: Maybe<Scalars['Datetime']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly subCategory?: Maybe<Scalars['String']>;
  readonly salePrice?: Maybe<Scalars['Float']>;
  readonly category?: Maybe<Scalars['String']>;
  readonly expired?: Maybe<Scalars['Boolean']>;
  readonly src?: Maybe<Scalars['String']>;
  readonly rotated?: Maybe<Scalars['Boolean']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly categoryToMainAndCatalog?: Maybe<FakePublicSubProductsForeignKey0Input>;
};

export type Feed = {
  readonly __typename?: 'Feed';
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly feedType?: Maybe<Scalars['String']>;
  readonly feedMap?: Maybe<Scalars['String']>;
  readonly productMap?: Maybe<Scalars['JSON']>;
  readonly dateModified?: Maybe<Scalars['Datetime']>;
  readonly autoUpdate?: Maybe<Scalars['Boolean']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  /** Reads a single `Catalog` that is related to this `Feed`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
  /** Reads and enables pagination through a set of `FeedHistory`. */
  readonly feedHistoriesByFeedConnection: FeedHistoriesConnection;
  /** Reads and enables pagination through a set of `FeedHistory`. */
  readonly feedHistoriesByFeed: ReadonlyArray<FeedHistory>;
};


export type FeedFeedHistoriesByFeedConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<FeedHistoriesOrderBy>>;
  condition?: Maybe<FeedHistoryCondition>;
  filter?: Maybe<FeedHistoryFilter>;
};


export type FeedFeedHistoriesByFeedArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<FeedHistoriesOrderBy>>;
  condition?: Maybe<FeedHistoryCondition>;
  filter?: Maybe<FeedHistoryFilter>;
};

/** A condition to be used against `Feed` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type FeedCondition = {
  /** Checks for equality with the object’s `oid` field. */
  readonly oid?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `options` field. */
  readonly options?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `catalog` field. */
  readonly catalog?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `feedType` field. */
  readonly feedType?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `feedMap` field. */
  readonly feedMap?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `productMap` field. */
  readonly productMap?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `dateModified` field. */
  readonly dateModified?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `autoUpdate` field. */
  readonly autoUpdate?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `Feed` object types. All fields are combined with a logical ‘and.’ */
export type FeedFilter = {
  /** Filter by the object’s `oid` field. */
  readonly oid?: Maybe<UuidFilter>;
  /** Filter by the object’s `catalog` field. */
  readonly catalog?: Maybe<UuidFilter>;
  /** Filter by the object’s `feedType` field. */
  readonly feedType?: Maybe<StringFilter>;
  /** Filter by the object’s `feedMap` field. */
  readonly feedMap?: Maybe<StringFilter>;
  /** Filter by the object’s `dateModified` field. */
  readonly dateModified?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `autoUpdate` field. */
  readonly autoUpdate?: Maybe<BooleanFilter>;
  /** Filter by the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<IntFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<ReadonlyArray<FeedFilter>>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<ReadonlyArray<FeedFilter>>;
  /** Negates the expression. */
  readonly not?: Maybe<FeedFilter>;
};

/** A connection to a list of `FeedHistory` values. */
export type FeedHistoriesConnection = {
  readonly __typename?: 'FeedHistoriesConnection';
  /** A list of `FeedHistory` objects. */
  readonly nodes: ReadonlyArray<FeedHistory>;
  /** A list of edges which contains the `FeedHistory` and cursor to aid in pagination. */
  readonly edges: ReadonlyArray<FeedHistoriesEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `FeedHistory` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `FeedHistory` edge in the connection. */
export type FeedHistoriesEdge = {
  readonly __typename?: 'FeedHistoriesEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `FeedHistory` at the end of the edge. */
  readonly node: FeedHistory;
};

/** Methods to use when ordering `FeedHistory`. */
export enum FeedHistoriesOrderBy {
  Natural = 'NATURAL',
  OidAsc = 'OID_ASC',
  OidDesc = 'OID_DESC',
  OptionsAsc = 'OPTIONS_ASC',
  OptionsDesc = 'OPTIONS_DESC',
  FeedAsc = 'FEED_ASC',
  FeedDesc = 'FEED_DESC',
  PreUpdateStatsAsc = 'PRE_UPDATE_STATS_ASC',
  PreUpdateStatsDesc = 'PRE_UPDATE_STATS_DESC',
  PostUpdateStatsAsc = 'POST_UPDATE_STATS_ASC',
  PostUpdateStatsDesc = 'POST_UPDATE_STATS_DESC',
  FeedPathAsc = 'FEED_PATH_ASC',
  FeedPathDesc = 'FEED_PATH_DESC',
  FeedJsonAsc = 'FEED_JSON_ASC',
  FeedJsonDesc = 'FEED_JSON_DESC',
  OptimisticLockFieldAsc = 'OPTIMISTIC_LOCK_FIELD_ASC',
  OptimisticLockFieldDesc = 'OPTIMISTIC_LOCK_FIELD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type FeedHistory = {
  readonly __typename?: 'FeedHistory';
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly feed?: Maybe<Scalars['UUID']>;
  readonly preUpdateStats?: Maybe<Scalars['JSON']>;
  readonly postUpdateStats?: Maybe<Scalars['JSON']>;
  readonly feedPath?: Maybe<Scalars['String']>;
  readonly feedJson?: Maybe<Scalars['JSON']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  /** Reads a single `Feed` that is related to this `FeedHistory`. */
  readonly feedByFeed?: Maybe<Feed>;
};

/** A condition to be used against `FeedHistory` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type FeedHistoryCondition = {
  /** Checks for equality with the object’s `oid` field. */
  readonly oid?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `options` field. */
  readonly options?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `feed` field. */
  readonly feed?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `preUpdateStats` field. */
  readonly preUpdateStats?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `postUpdateStats` field. */
  readonly postUpdateStats?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `feedPath` field. */
  readonly feedPath?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `feedJson` field. */
  readonly feedJson?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `FeedHistory` object types. All fields are combined with a logical ‘and.’ */
export type FeedHistoryFilter = {
  /** Filter by the object’s `oid` field. */
  readonly oid?: Maybe<UuidFilter>;
  /** Filter by the object’s `feed` field. */
  readonly feed?: Maybe<UuidFilter>;
  /** Filter by the object’s `feedPath` field. */
  readonly feedPath?: Maybe<StringFilter>;
  /** Filter by the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<IntFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<ReadonlyArray<FeedHistoryFilter>>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<ReadonlyArray<FeedHistoryFilter>>;
  /** Negates the expression. */
  readonly not?: Maybe<FeedHistoryFilter>;
};

/** An input for mutations affecting `FeedHistory` */
export type FeedHistoryInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly feed?: Maybe<Scalars['UUID']>;
  readonly preUpdateStats?: Maybe<Scalars['JSON']>;
  readonly postUpdateStats?: Maybe<Scalars['JSON']>;
  readonly feedPath?: Maybe<Scalars['String']>;
  readonly feedJson?: Maybe<Scalars['JSON']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly feedToFeed?: Maybe<FkFeedHistoryFeedInput>;
};

/** The fields on `feedHistory` to look up the row to update. */
export type FeedHistoryOnFeedHistoryForFkFeedHistoryFeedUsingPkFeedHistoryUpdate = {
  /** An object where the defined keys will be set on the `feedHistory` being updated. */
  readonly patch: UpdateFeedHistoryOnFeedHistoryForFkFeedHistoryFeedPatch;
  readonly oid: Scalars['UUID'];
};

/** Represents an update to a `FeedHistory`. Fields that are set will be updated. */
export type FeedHistoryPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly feed?: Maybe<Scalars['UUID']>;
  readonly preUpdateStats?: Maybe<Scalars['JSON']>;
  readonly postUpdateStats?: Maybe<Scalars['JSON']>;
  readonly feedPath?: Maybe<Scalars['String']>;
  readonly feedJson?: Maybe<Scalars['JSON']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly feedToFeed?: Maybe<FkFeedHistoryFeedInput>;
};

/** The fields on `feedHistory` to look up the row to connect. */
export type FeedHistoryPkFeedHistoryConnect = {
  readonly oid: Scalars['UUID'];
};

/** The fields on `feedHistory` to look up the row to delete. */
export type FeedHistoryPkFeedHistoryDelete = {
  readonly oid: Scalars['UUID'];
};

/** An input for mutations affecting `Feed` */
export type FeedInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly feedType?: Maybe<Scalars['String']>;
  readonly feedMap?: Maybe<Scalars['String']>;
  readonly productMap?: Maybe<Scalars['JSON']>;
  readonly dateModified?: Maybe<Scalars['Datetime']>;
  readonly autoUpdate?: Maybe<Scalars['Boolean']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly catalogToCatalog?: Maybe<FkFeedCatalogInput>;
  readonly feedHistoriesUsingOid?: Maybe<FkFeedHistoryFeedInverseInput>;
};

/** The fields on `feed` to look up the row to update. */
export type FeedOnFeedForFkFeedCatalogUsingPkFeedUpdate = {
  /** An object where the defined keys will be set on the `feed` being updated. */
  readonly patch: UpdateFeedOnFeedForFkFeedCatalogPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `feed` to look up the row to update. */
export type FeedOnFeedHistoryForFkFeedHistoryFeedUsingPkFeedUpdate = {
  /** An object where the defined keys will be set on the `feed` being updated. */
  readonly patch: UpdateFeedOnFeedHistoryForFkFeedHistoryFeedPatch;
  readonly oid: Scalars['UUID'];
};

/** Represents an update to a `Feed`. Fields that are set will be updated. */
export type FeedPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly feedType?: Maybe<Scalars['String']>;
  readonly feedMap?: Maybe<Scalars['String']>;
  readonly productMap?: Maybe<Scalars['JSON']>;
  readonly dateModified?: Maybe<Scalars['Datetime']>;
  readonly autoUpdate?: Maybe<Scalars['Boolean']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly catalogToCatalog?: Maybe<FkFeedCatalogInput>;
  readonly feedHistoriesUsingOid?: Maybe<FkFeedHistoryFeedInverseInput>;
};

/** The fields on `feed` to look up the row to connect. */
export type FeedPkFeedConnect = {
  readonly oid: Scalars['UUID'];
};

/** The fields on `feed` to look up the row to delete. */
export type FeedPkFeedDelete = {
  readonly oid: Scalars['UUID'];
};

/** A connection to a list of `Feed` values. */
export type FeedsConnection = {
  readonly __typename?: 'FeedsConnection';
  /** A list of `Feed` objects. */
  readonly nodes: ReadonlyArray<Feed>;
  /** A list of edges which contains the `Feed` and cursor to aid in pagination. */
  readonly edges: ReadonlyArray<FeedsEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Feed` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Feed` edge in the connection. */
export type FeedsEdge = {
  readonly __typename?: 'FeedsEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Feed` at the end of the edge. */
  readonly node: Feed;
};

/** Methods to use when ordering `Feed`. */
export enum FeedsOrderBy {
  Natural = 'NATURAL',
  OidAsc = 'OID_ASC',
  OidDesc = 'OID_DESC',
  OptionsAsc = 'OPTIONS_ASC',
  OptionsDesc = 'OPTIONS_DESC',
  CatalogAsc = 'CATALOG_ASC',
  CatalogDesc = 'CATALOG_DESC',
  FeedTypeAsc = 'FEED_TYPE_ASC',
  FeedTypeDesc = 'FEED_TYPE_DESC',
  FeedMapAsc = 'FEED_MAP_ASC',
  FeedMapDesc = 'FEED_MAP_DESC',
  ProductMapAsc = 'PRODUCT_MAP_ASC',
  ProductMapDesc = 'PRODUCT_MAP_DESC',
  DateModifiedAsc = 'DATE_MODIFIED_ASC',
  DateModifiedDesc = 'DATE_MODIFIED_DESC',
  AutoUpdateAsc = 'AUTO_UPDATE_ASC',
  AutoUpdateDesc = 'AUTO_UPDATE_DESC',
  OptimisticLockFieldAsc = 'OPTIMISTIC_LOCK_FIELD_ASC',
  OptimisticLockFieldDesc = 'OPTIMISTIC_LOCK_FIELD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** The `account` to be created by this mutation. */
export type FkAuthorizedUserAuthorizedUsersAccountPartnerAccess4E22214EAccountCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly customSmsNumber?: Maybe<Scalars['String']>;
  readonly logo?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly login?: Maybe<Scalars['String']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly defaultTrackingCode?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['Datetime']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['BigInt']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly catalogsUsingOid?: Maybe<FkCatalogAccountInverseInput>;
  readonly contactsUsingOid?: Maybe<FkContactParentInverseInput>;
  readonly campaignsUsingOid?: Maybe<FkCampaignAccountInverseInput>;
  readonly leadsUsingOid?: Maybe<FkLeadAccountInverseInput>;
  readonly authorizedUserAuthorizedUsersAccountPartnerAccessesUsingOid?: Maybe<FkAuthorizedUserAuthorizedUsersAccountPartnerAccess4E22214EInverseInput>;
};

/** The `authorizedUserAuthorizedUsersAccountPartnerAccess` to be created by this mutation. */
export type FkAuthorizedUserAuthorizedUsersAccountPartnerAccess4E22214EAuthorizedUserAuthorizedUsersAccountPartnerAccessCreateInput = {
  readonly authorizedUsers?: Maybe<Scalars['UUID']>;
  readonly oid: Scalars['UUID'];
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly accountToPartnerAccess?: Maybe<FkAuthorizedUserAuthorizedUsersAccountPartnerAccess4E22214EInput>;
  readonly authorizedUserToAuthorizedUsers?: Maybe<FkAuthorizedUserAuthorizedUsersAccountPartnerAccessB343B2CfInput>;
};

/** Input for the nested mutation of `account` in the `AuthorizedUserAuthorizedUsersAccountPartnerAccessInput` mutation. */
export type FkAuthorizedUserAuthorizedUsersAccountPartnerAccess4E22214EInput = {
  /** The primary key(s) for `account` for the far side of the relationship. */
  readonly connectByOid?: Maybe<AccountPkAccountConnect>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<AccountPkAccountDelete>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  readonly updateByOid?: Maybe<AccountOnAuthorizedUserAuthorizedUsersAccountPartnerAccessForFkAuthorizedUserAuthorizedUsersAccountPartnerAccess4E22214EUsingPkAccountUpdate>;
  /** A `AccountInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FkAuthorizedUserAuthorizedUsersAccountPartnerAccess4E22214EAccountCreateInput>;
};

/** Input for the nested mutation of `authorizedUserAuthorizedUsersAccountPartnerAccess` in the `AccountInput` mutation. */
export type FkAuthorizedUserAuthorizedUsersAccountPartnerAccess4E22214EInverseInput = {
  /** Flag indicating whether all other `authorizedUserAuthorizedUsersAccountPartnerAccess` records that match this relationship should be removed. */
  readonly deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `authorizedUserAuthorizedUsersAccountPartnerAccess` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ReadonlyArray<AuthorizedUserAuthorizedUsersAccountPartnerAccessPkAuthorizedUserAuthorizedUsersAccountPartnerAccessConnect>>;
  /** The primary key(s) for `authorizedUserAuthorizedUsersAccountPartnerAccess` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ReadonlyArray<AuthorizedUserAuthorizedUsersAccountPartnerAccessPkAuthorizedUserAuthorizedUsersAccountPartnerAccessDelete>>;
  /** The primary key(s) and patch data for `authorizedUserAuthorizedUsersAccountPartnerAccess` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ReadonlyArray<AuthorizedUserAuthorizedUsersAccountPartnerAccessOnAuthorizedUserAuthorizedUsersAccountPartnerAccessForFkAuthorizedUserAuthorizedUsersAccountPartnerAccess4E22214EUsingPkAuthorizedUserAuthorizedUsersAccountPartnerAccessUpdate>>;
  /** A `AuthorizedUserAuthorizedUsersAccountPartnerAccessInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FkAuthorizedUserAuthorizedUsersAccountPartnerAccess4E22214EAuthorizedUserAuthorizedUsersAccountPartnerAccessCreateInput>>;
};

/** The `authorizedUserAuthorizedUsersAccountPartnerAccess` to be created by this mutation. */
export type FkAuthorizedUserAuthorizedUsersAccountPartnerAccessB343B2CfAuthorizedUserAuthorizedUsersAccountPartnerAccessCreateInput = {
  readonly partnerAccess?: Maybe<Scalars['UUID']>;
  readonly oid: Scalars['UUID'];
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly accountToPartnerAccess?: Maybe<FkAuthorizedUserAuthorizedUsersAccountPartnerAccess4E22214EInput>;
  readonly authorizedUserToAuthorizedUsers?: Maybe<FkAuthorizedUserAuthorizedUsersAccountPartnerAccessB343B2CfInput>;
};

/** The `authorizedUser` to be created by this mutation. */
export type FkAuthorizedUserAuthorizedUsersAccountPartnerAccessB343B2CfAuthorizedUserCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly faceBookId?: Maybe<Scalars['String']>;
  readonly googleId?: Maybe<Scalars['String']>;
  readonly linkedInId?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly profileToEmail?: Maybe<FakePublicAuthorizedUserForeignKey0Input>;
  readonly authorizedUserAuthorizedUsersAccountPartnerAccessesUsingOid?: Maybe<FkAuthorizedUserAuthorizedUsersAccountPartnerAccessB343B2CfInverseInput>;
};

/** Input for the nested mutation of `authorizedUser` in the `AuthorizedUserAuthorizedUsersAccountPartnerAccessInput` mutation. */
export type FkAuthorizedUserAuthorizedUsersAccountPartnerAccessB343B2CfInput = {
  /** The primary key(s) for `authorizedUser` for the far side of the relationship. */
  readonly connectByOid?: Maybe<AuthorizedUserPkAuthorizedUserConnect>;
  /** The primary key(s) for `authorizedUser` for the far side of the relationship. */
  readonly connectByEmail?: Maybe<AuthorizedUserAuthorizedUserByEmailConnect>;
  /** The primary key(s) for `authorizedUser` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<AuthorizedUserPkAuthorizedUserDelete>;
  /** The primary key(s) for `authorizedUser` for the far side of the relationship. */
  readonly deleteByEmail?: Maybe<AuthorizedUserAuthorizedUserByEmailDelete>;
  /** The primary key(s) and patch data for `authorizedUser` for the far side of the relationship. */
  readonly updateByOid?: Maybe<AuthorizedUserOnAuthorizedUserAuthorizedUsersAccountPartnerAccessForFkAuthorizedUserAuthorizedUsersAccountPartnerAccessB343B2CfUsingPkAuthorizedUserUpdate>;
  /** The primary key(s) and patch data for `authorizedUser` for the far side of the relationship. */
  readonly updateByEmail?: Maybe<AuthorizedUserOnAuthorizedUserAuthorizedUsersAccountPartnerAccessForFkAuthorizedUserAuthorizedUsersAccountPartnerAccessB343B2CfUsingAuthorizedUserByEmailUpdate>;
  /** A `AuthorizedUserInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FkAuthorizedUserAuthorizedUsersAccountPartnerAccessB343B2CfAuthorizedUserCreateInput>;
};

/** Input for the nested mutation of `authorizedUserAuthorizedUsersAccountPartnerAccess` in the `AuthorizedUserInput` mutation. */
export type FkAuthorizedUserAuthorizedUsersAccountPartnerAccessB343B2CfInverseInput = {
  /** Flag indicating whether all other `authorizedUserAuthorizedUsersAccountPartnerAccess` records that match this relationship should be removed. */
  readonly deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `authorizedUserAuthorizedUsersAccountPartnerAccess` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ReadonlyArray<AuthorizedUserAuthorizedUsersAccountPartnerAccessPkAuthorizedUserAuthorizedUsersAccountPartnerAccessConnect>>;
  /** The primary key(s) for `authorizedUserAuthorizedUsersAccountPartnerAccess` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ReadonlyArray<AuthorizedUserAuthorizedUsersAccountPartnerAccessPkAuthorizedUserAuthorizedUsersAccountPartnerAccessDelete>>;
  /** The primary key(s) and patch data for `authorizedUserAuthorizedUsersAccountPartnerAccess` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ReadonlyArray<AuthorizedUserAuthorizedUsersAccountPartnerAccessOnAuthorizedUserAuthorizedUsersAccountPartnerAccessForFkAuthorizedUserAuthorizedUsersAccountPartnerAccessB343B2CfUsingPkAuthorizedUserAuthorizedUsersAccountPartnerAccessUpdate>>;
  /** A `AuthorizedUserAuthorizedUsersAccountPartnerAccessInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FkAuthorizedUserAuthorizedUsersAccountPartnerAccessB343B2CfAuthorizedUserAuthorizedUsersAccountPartnerAccessCreateInput>>;
};

/** The `account` to be created by this mutation. */
export type FkCampaignAccountAccountCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly customSmsNumber?: Maybe<Scalars['String']>;
  readonly logo?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly login?: Maybe<Scalars['String']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly defaultTrackingCode?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['Datetime']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['BigInt']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly catalogsUsingOid?: Maybe<FkCatalogAccountInverseInput>;
  readonly contactsUsingOid?: Maybe<FkContactParentInverseInput>;
  readonly campaignsUsingOid?: Maybe<FkCampaignAccountInverseInput>;
  readonly leadsUsingOid?: Maybe<FkLeadAccountInverseInput>;
  readonly authorizedUserAuthorizedUsersAccountPartnerAccessesUsingOid?: Maybe<FkAuthorizedUserAuthorizedUsersAccountPartnerAccess4E22214EInverseInput>;
};

/** The `campaign` to be created by this mutation. */
export type FkCampaignAccountCampaignCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly sender?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly distributionDate?: Maybe<Scalars['String']>;
  readonly quantity?: Maybe<Scalars['Int']>;
  readonly terms?: Maybe<Scalars['String']>;
  readonly rate?: Maybe<Scalars['Float']>;
  readonly totalCost?: Maybe<Scalars['String']>;
  readonly vendor?: Maybe<Scalars['String']>;
  readonly opens?: Maybe<Scalars['Int']>;
  readonly clicks?: Maybe<Scalars['Int']>;
  readonly dynalogViews?: Maybe<Scalars['Int']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly accountToAccount?: Maybe<FkCampaignAccountInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCampaignInverseInput>;
};

/** Input for the nested mutation of `account` in the `CampaignInput` mutation. */
export type FkCampaignAccountInput = {
  /** The primary key(s) for `account` for the far side of the relationship. */
  readonly connectByOid?: Maybe<AccountPkAccountConnect>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<AccountPkAccountDelete>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  readonly updateByOid?: Maybe<AccountOnCampaignForFkCampaignAccountUsingPkAccountUpdate>;
  /** A `AccountInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FkCampaignAccountAccountCreateInput>;
};

/** Input for the nested mutation of `campaign` in the `AccountInput` mutation. */
export type FkCampaignAccountInverseInput = {
  /** Flag indicating whether all other `campaign` records that match this relationship should be removed. */
  readonly deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `campaign` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ReadonlyArray<CampaignPkCampaignConnect>>;
  /** The primary key(s) for `campaign` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ReadonlyArray<CampaignPkCampaignDelete>>;
  /** The primary key(s) and patch data for `campaign` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ReadonlyArray<CampaignOnCampaignForFkCampaignAccountUsingPkCampaignUpdate>>;
  /** A `CampaignInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FkCampaignAccountCampaignCreateInput>>;
};

/** The `campaign` to be created by this mutation. */
export type FkCampaignGroupCampaignCampaignCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly sender?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly distributionDate?: Maybe<Scalars['String']>;
  readonly quantity?: Maybe<Scalars['Int']>;
  readonly terms?: Maybe<Scalars['String']>;
  readonly rate?: Maybe<Scalars['Float']>;
  readonly totalCost?: Maybe<Scalars['String']>;
  readonly vendor?: Maybe<Scalars['String']>;
  readonly opens?: Maybe<Scalars['Int']>;
  readonly clicks?: Maybe<Scalars['Int']>;
  readonly dynalogViews?: Maybe<Scalars['Int']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly accountToAccount?: Maybe<FkCampaignAccountInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCampaignInverseInput>;
};

/** The `campaignGroup` to be created by this mutation. */
export type FkCampaignGroupCampaignCampaignGroupCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly campaignToCampaign?: Maybe<FkCampaignGroupCampaignInput>;
  readonly catalogToCatalog?: Maybe<FkCampaignGroupCatalogInput>;
  readonly campaignUsersUsingOid?: Maybe<FkCampaignUserCampaignGroupInverseInput>;
};

/** Input for the nested mutation of `campaign` in the `CampaignGroupInput` mutation. */
export type FkCampaignGroupCampaignInput = {
  /** The primary key(s) for `campaign` for the far side of the relationship. */
  readonly connectByOid?: Maybe<CampaignPkCampaignConnect>;
  /** The primary key(s) for `campaign` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<CampaignPkCampaignDelete>;
  /** The primary key(s) and patch data for `campaign` for the far side of the relationship. */
  readonly updateByOid?: Maybe<CampaignOnCampaignGroupForFkCampaignGroupCampaignUsingPkCampaignUpdate>;
  /** A `CampaignInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FkCampaignGroupCampaignCampaignCreateInput>;
};

/** Input for the nested mutation of `campaignGroup` in the `CampaignInput` mutation. */
export type FkCampaignGroupCampaignInverseInput = {
  /** Flag indicating whether all other `campaignGroup` records that match this relationship should be removed. */
  readonly deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `campaignGroup` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ReadonlyArray<CampaignGroupPkCampaignGroupConnect>>;
  /** The primary key(s) for `campaignGroup` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ReadonlyArray<CampaignGroupPkCampaignGroupDelete>>;
  /** The primary key(s) and patch data for `campaignGroup` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ReadonlyArray<CampaignGroupOnCampaignGroupForFkCampaignGroupCampaignUsingPkCampaignGroupUpdate>>;
  /** A `CampaignGroupInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FkCampaignGroupCampaignCampaignGroupCreateInput>>;
};

/** The `campaignGroup` to be created by this mutation. */
export type FkCampaignGroupCatalogCampaignGroupCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly campaign?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly campaignToCampaign?: Maybe<FkCampaignGroupCampaignInput>;
  readonly catalogToCatalog?: Maybe<FkCampaignGroupCatalogInput>;
  readonly campaignUsersUsingOid?: Maybe<FkCampaignUserCampaignGroupInverseInput>;
};

/** The `catalog` to be created by this mutation. */
export type FkCampaignGroupCatalogCatalogCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly cover?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly catalogFormat?: Maybe<Scalars['String']>;
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly leadCharge?: Maybe<Scalars['Float']>;
  readonly linkCharge?: Maybe<Scalars['Float']>;
  readonly digitalFee?: Maybe<Scalars['Float']>;
  readonly listingFee?: Maybe<Scalars['Float']>;
  readonly dateExpired?: Maybe<Scalars['Datetime']>;
  readonly trackingCode?: Maybe<Scalars['String']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly showCategoryLinks?: Maybe<Scalars['Boolean']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Datetime']>;
  readonly offerCopy?: Maybe<Scalars['String']>;
  readonly introCopy?: Maybe<Scalars['String']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  readonly blurb?: Maybe<Scalars['String']>;
  readonly featureCopy?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly keywords?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly longDescription?: Maybe<Scalars['String']>;
  readonly class?: Maybe<Scalars['String']>;
  readonly searchAppendCode?: Maybe<Scalars['String']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly primaryDepartment?: Maybe<Scalars['UUID']>;
  readonly isDigital?: Maybe<Scalars['Boolean']>;
  readonly merchantId?: Maybe<Scalars['BigInt']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly autoActivate?: Maybe<Scalars['Boolean']>;
  readonly groupHubspotId?: Maybe<Scalars['String']>;
  readonly customSubdomain?: Maybe<Scalars['String']>;
  readonly text2Disclaimer?: Maybe<Scalars['String']>;
  readonly activeVersion?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly collectFullAddress?: Maybe<Scalars['Boolean']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly freeShipping?: Maybe<Scalars['Boolean']>;
  readonly mailOrder?: Maybe<Scalars['Boolean']>;
  readonly collectLeadQr?: Maybe<Scalars['Boolean']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly hideAds?: Maybe<Scalars['Boolean']>;
  readonly accountToAccount?: Maybe<FkCatalogAccountInput>;
  readonly departmentToPrimaryDepartment?: Maybe<FkCatalogPrimaryDepartmentInput>;
  readonly versionToActiveVersion?: Maybe<FkCatalogActiveVersionInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInverseInput>;
  readonly versionsUsingOid?: Maybe<FkVersionCatalogInverseInput>;
  readonly offersUsingOid?: Maybe<FkOfferCatalogInverseInput>;
  readonly categoriesUsingOid?: Maybe<FkCategoryCatalogInverseInput>;
  readonly feedsUsingOid?: Maybe<FkFeedCatalogInverseInput>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCatalogInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCatalogInverseInput>;
  readonly productsUsingOid?: Maybe<FkProductCatalogInverseInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCatalogInverseInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingCatalogInverseInput>;
  readonly categoryByCatalogsUsingOid?: Maybe<FakePublicCategoryByCatalogForeignKey0InverseInput>;
};

/** Input for the nested mutation of `catalog` in the `CampaignGroupInput` mutation. */
export type FkCampaignGroupCatalogInput = {
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly connectByOid?: Maybe<CatalogPkCatalogConnect>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly connectById?: Maybe<CatalogCatalogByIdConnect>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<CatalogPkCatalogDelete>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly deleteById?: Maybe<CatalogCatalogByIdDelete>;
  /** The primary key(s) and patch data for `catalog` for the far side of the relationship. */
  readonly updateByOid?: Maybe<CatalogOnCampaignGroupForFkCampaignGroupCatalogUsingPkCatalogUpdate>;
  /** The primary key(s) and patch data for `catalog` for the far side of the relationship. */
  readonly updateById?: Maybe<CatalogOnCampaignGroupForFkCampaignGroupCatalogUsingCatalogByIdUpdate>;
  /** A `CatalogInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FkCampaignGroupCatalogCatalogCreateInput>;
};

/** Input for the nested mutation of `campaignGroup` in the `CatalogInput` mutation. */
export type FkCampaignGroupCatalogInverseInput = {
  /** Flag indicating whether all other `campaignGroup` records that match this relationship should be removed. */
  readonly deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `campaignGroup` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ReadonlyArray<CampaignGroupPkCampaignGroupConnect>>;
  /** The primary key(s) for `campaignGroup` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ReadonlyArray<CampaignGroupPkCampaignGroupDelete>>;
  /** The primary key(s) and patch data for `campaignGroup` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ReadonlyArray<CampaignGroupOnCampaignGroupForFkCampaignGroupCatalogUsingPkCampaignGroupUpdate>>;
  /** A `CampaignGroupInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FkCampaignGroupCatalogCampaignGroupCreateInput>>;
};

/** The `campaignGroup` to be created by this mutation. */
export type FkCampaignUserCampaignGroupCampaignGroupCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly campaign?: Maybe<Scalars['UUID']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly campaignToCampaign?: Maybe<FkCampaignGroupCampaignInput>;
  readonly catalogToCatalog?: Maybe<FkCampaignGroupCatalogInput>;
  readonly campaignUsersUsingOid?: Maybe<FkCampaignUserCampaignGroupInverseInput>;
};

/** The `campaignUser` to be created by this mutation. */
export type FkCampaignUserCampaignGroupCampaignUserCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly lead?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly leadToLead?: Maybe<FkCampaignUserLeadInput>;
  readonly campaignGroupToCampaignGroup?: Maybe<FkCampaignUserCampaignGroupInput>;
};

/** Input for the nested mutation of `campaignGroup` in the `CampaignUserInput` mutation. */
export type FkCampaignUserCampaignGroupInput = {
  /** The primary key(s) for `campaignGroup` for the far side of the relationship. */
  readonly connectByOid?: Maybe<CampaignGroupPkCampaignGroupConnect>;
  /** The primary key(s) for `campaignGroup` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<CampaignGroupPkCampaignGroupDelete>;
  /** The primary key(s) and patch data for `campaignGroup` for the far side of the relationship. */
  readonly updateByOid?: Maybe<CampaignGroupOnCampaignUserForFkCampaignUserCampaignGroupUsingPkCampaignGroupUpdate>;
  /** A `CampaignGroupInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FkCampaignUserCampaignGroupCampaignGroupCreateInput>;
};

/** Input for the nested mutation of `campaignUser` in the `CampaignGroupInput` mutation. */
export type FkCampaignUserCampaignGroupInverseInput = {
  /** Flag indicating whether all other `campaignUser` records that match this relationship should be removed. */
  readonly deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `campaignUser` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ReadonlyArray<CampaignUserPkCampaignUserConnect>>;
  /** The primary key(s) for `campaignUser` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ReadonlyArray<CampaignUserPkCampaignUserDelete>>;
  /** The primary key(s) and patch data for `campaignUser` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ReadonlyArray<CampaignUserOnCampaignUserForFkCampaignUserCampaignGroupUsingPkCampaignUserUpdate>>;
  /** A `CampaignUserInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FkCampaignUserCampaignGroupCampaignUserCreateInput>>;
};

/** The `campaignUser` to be created by this mutation. */
export type FkCampaignUserLeadCampaignUserCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly campaignGroup?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly leadToLead?: Maybe<FkCampaignUserLeadInput>;
  readonly campaignGroupToCampaignGroup?: Maybe<FkCampaignUserCampaignGroupInput>;
};

/** Input for the nested mutation of `lead` in the `CampaignUserInput` mutation. */
export type FkCampaignUserLeadInput = {
  /** The primary key(s) for `lead` for the far side of the relationship. */
  readonly connectByOid?: Maybe<LeadPkLeadConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<LeadPkLeadDelete>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  readonly updateByOid?: Maybe<LeadOnCampaignUserForFkCampaignUserLeadUsingPkLeadUpdate>;
  /** A `LeadInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FkCampaignUserLeadLeadCreateInput>;
};

/** Input for the nested mutation of `campaignUser` in the `LeadInput` mutation. */
export type FkCampaignUserLeadInverseInput = {
  /** Flag indicating whether all other `campaignUser` records that match this relationship should be removed. */
  readonly deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `campaignUser` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ReadonlyArray<CampaignUserPkCampaignUserConnect>>;
  /** The primary key(s) for `campaignUser` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ReadonlyArray<CampaignUserPkCampaignUserDelete>>;
  /** The primary key(s) and patch data for `campaignUser` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ReadonlyArray<CampaignUserOnCampaignUserForFkCampaignUserLeadUsingPkCampaignUserUpdate>>;
  /** A `CampaignUserInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FkCampaignUserLeadCampaignUserCreateInput>>;
};

/** The `lead` to be created by this mutation. */
export type FkCampaignUserLeadLeadCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly company?: Maybe<Scalars['String']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly dateCollected?: Maybe<Scalars['Datetime']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly profile?: Maybe<Scalars['UUID']>;
  readonly catalogId?: Maybe<Scalars['Int']>;
  readonly versionId?: Maybe<Scalars['Int']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly accountToAccount?: Maybe<FkLeadAccountInput>;
  readonly profileToProfile?: Maybe<FkLeadProfileInput>;
  readonly campaignUsersUsingOid?: Maybe<FkCampaignUserLeadInverseInput>;
};

/** The `carousel` to be created by this mutation. */
export type FkCarouselListingCarouselCarouselCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly showBySlug?: Maybe<Scalars['String']>;
  readonly showOnHomePage?: Maybe<Scalars['Boolean']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly rankOrder?: Maybe<Scalars['Int']>;
  readonly headLine?: Maybe<Scalars['String']>;
  readonly subText?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly showOffers?: Maybe<Scalars['Int']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaKeys?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingCarouselInverseInput>;
};

/** The `carouselListing` to be created by this mutation. */
export type FkCarouselListingCarouselCarouselListingCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly rankOrder?: Maybe<Scalars['Int']>;
  readonly offer?: Maybe<Scalars['UUID']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly carouselToCarousel?: Maybe<FkCarouselListingCarouselInput>;
  readonly offerToOffer?: Maybe<FkCarouselListingOfferInput>;
  readonly catalogToCatalog?: Maybe<FkCarouselListingCatalogInput>;
};

/** Input for the nested mutation of `carousel` in the `CarouselListingInput` mutation. */
export type FkCarouselListingCarouselInput = {
  /** The primary key(s) for `carousel` for the far side of the relationship. */
  readonly connectByOid?: Maybe<CarouselPkCarouselConnect>;
  /** The primary key(s) for `carousel` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<CarouselPkCarouselDelete>;
  /** The primary key(s) and patch data for `carousel` for the far side of the relationship. */
  readonly updateByOid?: Maybe<CarouselOnCarouselListingForFkCarouselListingCarouselUsingPkCarouselUpdate>;
  /** A `CarouselInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FkCarouselListingCarouselCarouselCreateInput>;
};

/** Input for the nested mutation of `carouselListing` in the `CarouselInput` mutation. */
export type FkCarouselListingCarouselInverseInput = {
  /** Flag indicating whether all other `carouselListing` records that match this relationship should be removed. */
  readonly deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `carouselListing` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ReadonlyArray<CarouselListingPkCarouselListingConnect>>;
  /** The primary key(s) for `carouselListing` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ReadonlyArray<CarouselListingPkCarouselListingDelete>>;
  /** The primary key(s) and patch data for `carouselListing` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ReadonlyArray<CarouselListingOnCarouselListingForFkCarouselListingCarouselUsingPkCarouselListingUpdate>>;
  /** A `CarouselListingInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FkCarouselListingCarouselCarouselListingCreateInput>>;
};

/** The `carouselListing` to be created by this mutation. */
export type FkCarouselListingCatalogCarouselListingCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly carousel?: Maybe<Scalars['UUID']>;
  readonly rankOrder?: Maybe<Scalars['Int']>;
  readonly offer?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly carouselToCarousel?: Maybe<FkCarouselListingCarouselInput>;
  readonly offerToOffer?: Maybe<FkCarouselListingOfferInput>;
  readonly catalogToCatalog?: Maybe<FkCarouselListingCatalogInput>;
};

/** The `catalog` to be created by this mutation. */
export type FkCarouselListingCatalogCatalogCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly cover?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly catalogFormat?: Maybe<Scalars['String']>;
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly leadCharge?: Maybe<Scalars['Float']>;
  readonly linkCharge?: Maybe<Scalars['Float']>;
  readonly digitalFee?: Maybe<Scalars['Float']>;
  readonly listingFee?: Maybe<Scalars['Float']>;
  readonly dateExpired?: Maybe<Scalars['Datetime']>;
  readonly trackingCode?: Maybe<Scalars['String']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly showCategoryLinks?: Maybe<Scalars['Boolean']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Datetime']>;
  readonly offerCopy?: Maybe<Scalars['String']>;
  readonly introCopy?: Maybe<Scalars['String']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  readonly blurb?: Maybe<Scalars['String']>;
  readonly featureCopy?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly keywords?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly longDescription?: Maybe<Scalars['String']>;
  readonly class?: Maybe<Scalars['String']>;
  readonly searchAppendCode?: Maybe<Scalars['String']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly primaryDepartment?: Maybe<Scalars['UUID']>;
  readonly isDigital?: Maybe<Scalars['Boolean']>;
  readonly merchantId?: Maybe<Scalars['BigInt']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly autoActivate?: Maybe<Scalars['Boolean']>;
  readonly groupHubspotId?: Maybe<Scalars['String']>;
  readonly customSubdomain?: Maybe<Scalars['String']>;
  readonly text2Disclaimer?: Maybe<Scalars['String']>;
  readonly activeVersion?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly collectFullAddress?: Maybe<Scalars['Boolean']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly freeShipping?: Maybe<Scalars['Boolean']>;
  readonly mailOrder?: Maybe<Scalars['Boolean']>;
  readonly collectLeadQr?: Maybe<Scalars['Boolean']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly hideAds?: Maybe<Scalars['Boolean']>;
  readonly accountToAccount?: Maybe<FkCatalogAccountInput>;
  readonly departmentToPrimaryDepartment?: Maybe<FkCatalogPrimaryDepartmentInput>;
  readonly versionToActiveVersion?: Maybe<FkCatalogActiveVersionInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInverseInput>;
  readonly versionsUsingOid?: Maybe<FkVersionCatalogInverseInput>;
  readonly offersUsingOid?: Maybe<FkOfferCatalogInverseInput>;
  readonly categoriesUsingOid?: Maybe<FkCategoryCatalogInverseInput>;
  readonly feedsUsingOid?: Maybe<FkFeedCatalogInverseInput>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCatalogInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCatalogInverseInput>;
  readonly productsUsingOid?: Maybe<FkProductCatalogInverseInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCatalogInverseInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingCatalogInverseInput>;
  readonly categoryByCatalogsUsingOid?: Maybe<FakePublicCategoryByCatalogForeignKey0InverseInput>;
};

/** Input for the nested mutation of `catalog` in the `CarouselListingInput` mutation. */
export type FkCarouselListingCatalogInput = {
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly connectByOid?: Maybe<CatalogPkCatalogConnect>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly connectById?: Maybe<CatalogCatalogByIdConnect>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<CatalogPkCatalogDelete>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly deleteById?: Maybe<CatalogCatalogByIdDelete>;
  /** The primary key(s) and patch data for `catalog` for the far side of the relationship. */
  readonly updateByOid?: Maybe<CatalogOnCarouselListingForFkCarouselListingCatalogUsingPkCatalogUpdate>;
  /** The primary key(s) and patch data for `catalog` for the far side of the relationship. */
  readonly updateById?: Maybe<CatalogOnCarouselListingForFkCarouselListingCatalogUsingCatalogByIdUpdate>;
  /** A `CatalogInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FkCarouselListingCatalogCatalogCreateInput>;
};

/** Input for the nested mutation of `carouselListing` in the `CatalogInput` mutation. */
export type FkCarouselListingCatalogInverseInput = {
  /** Flag indicating whether all other `carouselListing` records that match this relationship should be removed. */
  readonly deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `carouselListing` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ReadonlyArray<CarouselListingPkCarouselListingConnect>>;
  /** The primary key(s) for `carouselListing` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ReadonlyArray<CarouselListingPkCarouselListingDelete>>;
  /** The primary key(s) and patch data for `carouselListing` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ReadonlyArray<CarouselListingOnCarouselListingForFkCarouselListingCatalogUsingPkCarouselListingUpdate>>;
  /** A `CarouselListingInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FkCarouselListingCatalogCarouselListingCreateInput>>;
};

/** The `carouselListing` to be created by this mutation. */
export type FkCarouselListingOfferCarouselListingCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly carousel?: Maybe<Scalars['UUID']>;
  readonly rankOrder?: Maybe<Scalars['Int']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly carouselToCarousel?: Maybe<FkCarouselListingCarouselInput>;
  readonly offerToOffer?: Maybe<FkCarouselListingOfferInput>;
  readonly catalogToCatalog?: Maybe<FkCarouselListingCatalogInput>;
};

/** Input for the nested mutation of `offer` in the `CarouselListingInput` mutation. */
export type FkCarouselListingOfferInput = {
  /** The primary key(s) for `offer` for the far side of the relationship. */
  readonly connectByOid?: Maybe<OfferPkOfferConnect>;
  /** The primary key(s) for `offer` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<OfferPkOfferDelete>;
  /** The primary key(s) and patch data for `offer` for the far side of the relationship. */
  readonly updateByOid?: Maybe<OfferOnCarouselListingForFkCarouselListingOfferUsingPkOfferUpdate>;
  /** A `OfferInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FkCarouselListingOfferOfferCreateInput>;
};

/** Input for the nested mutation of `carouselListing` in the `OfferInput` mutation. */
export type FkCarouselListingOfferInverseInput = {
  /** Flag indicating whether all other `carouselListing` records that match this relationship should be removed. */
  readonly deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `carouselListing` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ReadonlyArray<CarouselListingPkCarouselListingConnect>>;
  /** The primary key(s) for `carouselListing` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ReadonlyArray<CarouselListingPkCarouselListingDelete>>;
  /** The primary key(s) and patch data for `carouselListing` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ReadonlyArray<CarouselListingOnCarouselListingForFkCarouselListingOfferUsingPkCarouselListingUpdate>>;
  /** A `CarouselListingInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FkCarouselListingOfferCarouselListingCreateInput>>;
};

/** The `offer` to be created by this mutation. */
export type FkCarouselListingOfferOfferCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly image?: Maybe<Scalars['String']>;
  readonly discountInPercent?: Maybe<Scalars['Float']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly certificate?: Maybe<Scalars['String']>;
  readonly expiryDate?: Maybe<Scalars['Datetime']>;
  readonly value?: Maybe<Scalars['String']>;
  readonly disclaimer?: Maybe<Scalars['String']>;
  readonly burst?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly paid?: Maybe<Scalars['Boolean']>;
  readonly catalogToCatalog?: Maybe<FkOfferCatalogInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingOfferInverseInput>;
};

/** The `account` to be created by this mutation. */
export type FkCatalogAccountAccountCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly customSmsNumber?: Maybe<Scalars['String']>;
  readonly logo?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly login?: Maybe<Scalars['String']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly defaultTrackingCode?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['Datetime']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['BigInt']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly catalogsUsingOid?: Maybe<FkCatalogAccountInverseInput>;
  readonly contactsUsingOid?: Maybe<FkContactParentInverseInput>;
  readonly campaignsUsingOid?: Maybe<FkCampaignAccountInverseInput>;
  readonly leadsUsingOid?: Maybe<FkLeadAccountInverseInput>;
  readonly authorizedUserAuthorizedUsersAccountPartnerAccessesUsingOid?: Maybe<FkAuthorizedUserAuthorizedUsersAccountPartnerAccess4E22214EInverseInput>;
};

/** The `catalog` to be created by this mutation. */
export type FkCatalogAccountCatalogCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly cover?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly catalogFormat?: Maybe<Scalars['String']>;
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly leadCharge?: Maybe<Scalars['Float']>;
  readonly linkCharge?: Maybe<Scalars['Float']>;
  readonly digitalFee?: Maybe<Scalars['Float']>;
  readonly listingFee?: Maybe<Scalars['Float']>;
  readonly dateExpired?: Maybe<Scalars['Datetime']>;
  readonly trackingCode?: Maybe<Scalars['String']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly showCategoryLinks?: Maybe<Scalars['Boolean']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Datetime']>;
  readonly offerCopy?: Maybe<Scalars['String']>;
  readonly introCopy?: Maybe<Scalars['String']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  readonly blurb?: Maybe<Scalars['String']>;
  readonly featureCopy?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly keywords?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly longDescription?: Maybe<Scalars['String']>;
  readonly class?: Maybe<Scalars['String']>;
  readonly searchAppendCode?: Maybe<Scalars['String']>;
  readonly primaryDepartment?: Maybe<Scalars['UUID']>;
  readonly isDigital?: Maybe<Scalars['Boolean']>;
  readonly merchantId?: Maybe<Scalars['BigInt']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly autoActivate?: Maybe<Scalars['Boolean']>;
  readonly groupHubspotId?: Maybe<Scalars['String']>;
  readonly customSubdomain?: Maybe<Scalars['String']>;
  readonly text2Disclaimer?: Maybe<Scalars['String']>;
  readonly activeVersion?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly collectFullAddress?: Maybe<Scalars['Boolean']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly freeShipping?: Maybe<Scalars['Boolean']>;
  readonly mailOrder?: Maybe<Scalars['Boolean']>;
  readonly collectLeadQr?: Maybe<Scalars['Boolean']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly hideAds?: Maybe<Scalars['Boolean']>;
  readonly accountToAccount?: Maybe<FkCatalogAccountInput>;
  readonly departmentToPrimaryDepartment?: Maybe<FkCatalogPrimaryDepartmentInput>;
  readonly versionToActiveVersion?: Maybe<FkCatalogActiveVersionInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInverseInput>;
  readonly versionsUsingOid?: Maybe<FkVersionCatalogInverseInput>;
  readonly offersUsingOid?: Maybe<FkOfferCatalogInverseInput>;
  readonly categoriesUsingOid?: Maybe<FkCategoryCatalogInverseInput>;
  readonly feedsUsingOid?: Maybe<FkFeedCatalogInverseInput>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCatalogInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCatalogInverseInput>;
  readonly productsUsingOid?: Maybe<FkProductCatalogInverseInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCatalogInverseInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingCatalogInverseInput>;
  readonly categoryByCatalogsUsingOid?: Maybe<FakePublicCategoryByCatalogForeignKey0InverseInput>;
};

/** Input for the nested mutation of `account` in the `CatalogInput` mutation. */
export type FkCatalogAccountInput = {
  /** The primary key(s) for `account` for the far side of the relationship. */
  readonly connectByOid?: Maybe<AccountPkAccountConnect>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<AccountPkAccountDelete>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  readonly updateByOid?: Maybe<AccountOnCatalogForFkCatalogAccountUsingPkAccountUpdate>;
  /** A `AccountInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FkCatalogAccountAccountCreateInput>;
};

/** Input for the nested mutation of `catalog` in the `AccountInput` mutation. */
export type FkCatalogAccountInverseInput = {
  /** Flag indicating whether all other `catalog` records that match this relationship should be removed. */
  readonly deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ReadonlyArray<CatalogPkCatalogConnect>>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly connectById?: Maybe<ReadonlyArray<CatalogCatalogByIdConnect>>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ReadonlyArray<CatalogPkCatalogDelete>>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly deleteById?: Maybe<ReadonlyArray<CatalogCatalogByIdDelete>>;
  /** The primary key(s) and patch data for `catalog` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ReadonlyArray<CatalogOnCatalogForFkCatalogAccountUsingPkCatalogUpdate>>;
  /** The primary key(s) and patch data for `catalog` for the far side of the relationship. */
  readonly updateById?: Maybe<ReadonlyArray<CatalogOnCatalogForFkCatalogAccountUsingCatalogByIdUpdate>>;
  /** A `CatalogInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FkCatalogAccountCatalogCreateInput>>;
};

/** The `catalog` to be created by this mutation. */
export type FkCatalogActiveVersionCatalogCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly cover?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly catalogFormat?: Maybe<Scalars['String']>;
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly leadCharge?: Maybe<Scalars['Float']>;
  readonly linkCharge?: Maybe<Scalars['Float']>;
  readonly digitalFee?: Maybe<Scalars['Float']>;
  readonly listingFee?: Maybe<Scalars['Float']>;
  readonly dateExpired?: Maybe<Scalars['Datetime']>;
  readonly trackingCode?: Maybe<Scalars['String']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly showCategoryLinks?: Maybe<Scalars['Boolean']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Datetime']>;
  readonly offerCopy?: Maybe<Scalars['String']>;
  readonly introCopy?: Maybe<Scalars['String']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  readonly blurb?: Maybe<Scalars['String']>;
  readonly featureCopy?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly keywords?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly longDescription?: Maybe<Scalars['String']>;
  readonly class?: Maybe<Scalars['String']>;
  readonly searchAppendCode?: Maybe<Scalars['String']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly primaryDepartment?: Maybe<Scalars['UUID']>;
  readonly isDigital?: Maybe<Scalars['Boolean']>;
  readonly merchantId?: Maybe<Scalars['BigInt']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly autoActivate?: Maybe<Scalars['Boolean']>;
  readonly groupHubspotId?: Maybe<Scalars['String']>;
  readonly customSubdomain?: Maybe<Scalars['String']>;
  readonly text2Disclaimer?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly collectFullAddress?: Maybe<Scalars['Boolean']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly freeShipping?: Maybe<Scalars['Boolean']>;
  readonly mailOrder?: Maybe<Scalars['Boolean']>;
  readonly collectLeadQr?: Maybe<Scalars['Boolean']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly hideAds?: Maybe<Scalars['Boolean']>;
  readonly accountToAccount?: Maybe<FkCatalogAccountInput>;
  readonly departmentToPrimaryDepartment?: Maybe<FkCatalogPrimaryDepartmentInput>;
  readonly versionToActiveVersion?: Maybe<FkCatalogActiveVersionInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInverseInput>;
  readonly versionsUsingOid?: Maybe<FkVersionCatalogInverseInput>;
  readonly offersUsingOid?: Maybe<FkOfferCatalogInverseInput>;
  readonly categoriesUsingOid?: Maybe<FkCategoryCatalogInverseInput>;
  readonly feedsUsingOid?: Maybe<FkFeedCatalogInverseInput>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCatalogInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCatalogInverseInput>;
  readonly productsUsingOid?: Maybe<FkProductCatalogInverseInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCatalogInverseInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingCatalogInverseInput>;
  readonly categoryByCatalogsUsingOid?: Maybe<FakePublicCategoryByCatalogForeignKey0InverseInput>;
};

/** Input for the nested mutation of `version` in the `CatalogInput` mutation. */
export type FkCatalogActiveVersionInput = {
  /** The primary key(s) for `version` for the far side of the relationship. */
  readonly connectByOid?: Maybe<VersionPkVersionConnect>;
  /** The primary key(s) for `version` for the far side of the relationship. */
  readonly connectById?: Maybe<VersionVersionByIdConnect>;
  /** The primary key(s) for `version` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<VersionPkVersionDelete>;
  /** The primary key(s) for `version` for the far side of the relationship. */
  readonly deleteById?: Maybe<VersionVersionByIdDelete>;
  /** The primary key(s) and patch data for `version` for the far side of the relationship. */
  readonly updateByOid?: Maybe<VersionOnCatalogForFkCatalogActiveVersionUsingPkVersionUpdate>;
  /** The primary key(s) and patch data for `version` for the far side of the relationship. */
  readonly updateById?: Maybe<VersionOnCatalogForFkCatalogActiveVersionUsingVersionByIdUpdate>;
  /** A `VersionInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FkCatalogActiveVersionVersionCreateInput>;
};

/** Input for the nested mutation of `catalog` in the `VersionInput` mutation. */
export type FkCatalogActiveVersionInverseInput = {
  /** Flag indicating whether all other `catalog` records that match this relationship should be removed. */
  readonly deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ReadonlyArray<CatalogPkCatalogConnect>>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly connectById?: Maybe<ReadonlyArray<CatalogCatalogByIdConnect>>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ReadonlyArray<CatalogPkCatalogDelete>>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly deleteById?: Maybe<ReadonlyArray<CatalogCatalogByIdDelete>>;
  /** The primary key(s) and patch data for `catalog` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ReadonlyArray<CatalogOnCatalogForFkCatalogActiveVersionUsingPkCatalogUpdate>>;
  /** The primary key(s) and patch data for `catalog` for the far side of the relationship. */
  readonly updateById?: Maybe<ReadonlyArray<CatalogOnCatalogForFkCatalogActiveVersionUsingCatalogByIdUpdate>>;
  /** A `CatalogInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FkCatalogActiveVersionCatalogCreateInput>>;
};

/** The `version` to be created by this mutation. */
export type FkCatalogActiveVersionVersionCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly coverUrl?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly coverMeta?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
  readonly dateCreated?: Maybe<Scalars['Datetime']>;
  readonly dateModified?: Maybe<Scalars['Datetime']>;
  readonly versionNo?: Maybe<Scalars['Int']>;
  readonly autoProcess?: Maybe<Scalars['Boolean']>;
  readonly contactUrl?: Maybe<Scalars['String']>;
  readonly enableCategoryCatalogs?: Maybe<Scalars['Boolean']>;
  readonly language?: Maybe<Scalars['String']>;
  readonly currencySymbol?: Maybe<Scalars['String']>;
  readonly leadZapEmail?: Maybe<Scalars['String']>;
  readonly agentId?: Maybe<Scalars['String']>;
  readonly shortName?: Maybe<Scalars['String']>;
  readonly isFtp?: Maybe<Scalars['Boolean']>;
  readonly catalogCloneId?: Maybe<Scalars['BigInt']>;
  readonly feedAlertNotifcation?: Maybe<Scalars['Datetime']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly order?: Maybe<Scalars['JSON']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly catalogToCatalog?: Maybe<FkVersionCatalogInput>;
  readonly catalogsUsingOid?: Maybe<FkCatalogActiveVersionInverseInput>;
  readonly pagesUsingOid?: Maybe<FkPageVersionInverseInput>;
};

/** The `catalog` to be created by this mutation. */
export type FkCatalogPrimaryDepartmentCatalogCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly cover?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly catalogFormat?: Maybe<Scalars['String']>;
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly leadCharge?: Maybe<Scalars['Float']>;
  readonly linkCharge?: Maybe<Scalars['Float']>;
  readonly digitalFee?: Maybe<Scalars['Float']>;
  readonly listingFee?: Maybe<Scalars['Float']>;
  readonly dateExpired?: Maybe<Scalars['Datetime']>;
  readonly trackingCode?: Maybe<Scalars['String']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly showCategoryLinks?: Maybe<Scalars['Boolean']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Datetime']>;
  readonly offerCopy?: Maybe<Scalars['String']>;
  readonly introCopy?: Maybe<Scalars['String']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  readonly blurb?: Maybe<Scalars['String']>;
  readonly featureCopy?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly keywords?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly longDescription?: Maybe<Scalars['String']>;
  readonly class?: Maybe<Scalars['String']>;
  readonly searchAppendCode?: Maybe<Scalars['String']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly isDigital?: Maybe<Scalars['Boolean']>;
  readonly merchantId?: Maybe<Scalars['BigInt']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly autoActivate?: Maybe<Scalars['Boolean']>;
  readonly groupHubspotId?: Maybe<Scalars['String']>;
  readonly customSubdomain?: Maybe<Scalars['String']>;
  readonly text2Disclaimer?: Maybe<Scalars['String']>;
  readonly activeVersion?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly collectFullAddress?: Maybe<Scalars['Boolean']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly freeShipping?: Maybe<Scalars['Boolean']>;
  readonly mailOrder?: Maybe<Scalars['Boolean']>;
  readonly collectLeadQr?: Maybe<Scalars['Boolean']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly hideAds?: Maybe<Scalars['Boolean']>;
  readonly accountToAccount?: Maybe<FkCatalogAccountInput>;
  readonly departmentToPrimaryDepartment?: Maybe<FkCatalogPrimaryDepartmentInput>;
  readonly versionToActiveVersion?: Maybe<FkCatalogActiveVersionInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInverseInput>;
  readonly versionsUsingOid?: Maybe<FkVersionCatalogInverseInput>;
  readonly offersUsingOid?: Maybe<FkOfferCatalogInverseInput>;
  readonly categoriesUsingOid?: Maybe<FkCategoryCatalogInverseInput>;
  readonly feedsUsingOid?: Maybe<FkFeedCatalogInverseInput>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCatalogInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCatalogInverseInput>;
  readonly productsUsingOid?: Maybe<FkProductCatalogInverseInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCatalogInverseInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingCatalogInverseInput>;
  readonly categoryByCatalogsUsingOid?: Maybe<FakePublicCategoryByCatalogForeignKey0InverseInput>;
};

/** The `department` to be created by this mutation. */
export type FkCatalogPrimaryDepartmentDepartmentCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly departmentId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metakeys?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly searchText?: Maybe<Scalars['String']>;
  readonly advice?: Maybe<Scalars['String']>;
  readonly hiwId?: Maybe<Scalars['Int']>;
  readonly metaTitleArticle?: Maybe<Scalars['String']>;
  readonly metaKeysArticle?: Maybe<Scalars['String']>;
  readonly metaDescrArticle?: Maybe<Scalars['String']>;
  readonly metaKeysProduct?: Maybe<Scalars['String']>;
  readonly articleDept?: Maybe<Scalars['Int']>;
  readonly parentDepartment?: Maybe<Scalars['UUID']>;
  readonly deptType?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly showArticlesByDepartment?: Maybe<Scalars['Boolean']>;
  readonly showArticlesByTag?: Maybe<Scalars['String']>;
  readonly showOffers?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly departmentToParentDepartment?: Maybe<FkDepartmentParentDepartmentInput>;
  readonly catalogsUsingOid?: Maybe<FkCatalogPrimaryDepartmentInverseInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsDepartmentsInverseInput>;
  readonly navigationItemsUsingOid?: Maybe<FkNavigationItemDepartmentInverseInput>;
};

/** Input for the nested mutation of `department` in the `CatalogInput` mutation. */
export type FkCatalogPrimaryDepartmentInput = {
  /** The primary key(s) for `department` for the far side of the relationship. */
  readonly connectByOid?: Maybe<DepartmentPkDepartmentConnect>;
  /** The primary key(s) for `department` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<DepartmentPkDepartmentDelete>;
  /** The primary key(s) and patch data for `department` for the far side of the relationship. */
  readonly updateByOid?: Maybe<DepartmentOnCatalogForFkCatalogPrimaryDepartmentUsingPkDepartmentUpdate>;
  /** A `DepartmentInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FkCatalogPrimaryDepartmentDepartmentCreateInput>;
};

/** Input for the nested mutation of `catalog` in the `DepartmentInput` mutation. */
export type FkCatalogPrimaryDepartmentInverseInput = {
  /** Flag indicating whether all other `catalog` records that match this relationship should be removed. */
  readonly deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ReadonlyArray<CatalogPkCatalogConnect>>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly connectById?: Maybe<ReadonlyArray<CatalogCatalogByIdConnect>>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ReadonlyArray<CatalogPkCatalogDelete>>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly deleteById?: Maybe<ReadonlyArray<CatalogCatalogByIdDelete>>;
  /** The primary key(s) and patch data for `catalog` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ReadonlyArray<CatalogOnCatalogForFkCatalogPrimaryDepartmentUsingPkCatalogUpdate>>;
  /** The primary key(s) and patch data for `catalog` for the far side of the relationship. */
  readonly updateById?: Maybe<ReadonlyArray<CatalogOnCatalogForFkCatalogPrimaryDepartmentUsingCatalogByIdUpdate>>;
  /** A `CatalogInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FkCatalogPrimaryDepartmentCatalogCreateInput>>;
};

/** The `catalog` to be created by this mutation. */
export type FkCategoryCatalogCatalogCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly cover?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly catalogFormat?: Maybe<Scalars['String']>;
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly leadCharge?: Maybe<Scalars['Float']>;
  readonly linkCharge?: Maybe<Scalars['Float']>;
  readonly digitalFee?: Maybe<Scalars['Float']>;
  readonly listingFee?: Maybe<Scalars['Float']>;
  readonly dateExpired?: Maybe<Scalars['Datetime']>;
  readonly trackingCode?: Maybe<Scalars['String']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly showCategoryLinks?: Maybe<Scalars['Boolean']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Datetime']>;
  readonly offerCopy?: Maybe<Scalars['String']>;
  readonly introCopy?: Maybe<Scalars['String']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  readonly blurb?: Maybe<Scalars['String']>;
  readonly featureCopy?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly keywords?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly longDescription?: Maybe<Scalars['String']>;
  readonly class?: Maybe<Scalars['String']>;
  readonly searchAppendCode?: Maybe<Scalars['String']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly primaryDepartment?: Maybe<Scalars['UUID']>;
  readonly isDigital?: Maybe<Scalars['Boolean']>;
  readonly merchantId?: Maybe<Scalars['BigInt']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly autoActivate?: Maybe<Scalars['Boolean']>;
  readonly groupHubspotId?: Maybe<Scalars['String']>;
  readonly customSubdomain?: Maybe<Scalars['String']>;
  readonly text2Disclaimer?: Maybe<Scalars['String']>;
  readonly activeVersion?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly collectFullAddress?: Maybe<Scalars['Boolean']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly freeShipping?: Maybe<Scalars['Boolean']>;
  readonly mailOrder?: Maybe<Scalars['Boolean']>;
  readonly collectLeadQr?: Maybe<Scalars['Boolean']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly hideAds?: Maybe<Scalars['Boolean']>;
  readonly accountToAccount?: Maybe<FkCatalogAccountInput>;
  readonly departmentToPrimaryDepartment?: Maybe<FkCatalogPrimaryDepartmentInput>;
  readonly versionToActiveVersion?: Maybe<FkCatalogActiveVersionInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInverseInput>;
  readonly versionsUsingOid?: Maybe<FkVersionCatalogInverseInput>;
  readonly offersUsingOid?: Maybe<FkOfferCatalogInverseInput>;
  readonly categoriesUsingOid?: Maybe<FkCategoryCatalogInverseInput>;
  readonly feedsUsingOid?: Maybe<FkFeedCatalogInverseInput>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCatalogInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCatalogInverseInput>;
  readonly productsUsingOid?: Maybe<FkProductCatalogInverseInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCatalogInverseInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingCatalogInverseInput>;
  readonly categoryByCatalogsUsingOid?: Maybe<FakePublicCategoryByCatalogForeignKey0InverseInput>;
};

/** The `category` to be created by this mutation. */
export type FkCategoryCatalogCategoryCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly featured?: Maybe<Scalars['Int']>;
  readonly image?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly linkoutUrl?: Maybe<Scalars['String']>;
  readonly productsPerPage?: Maybe<Scalars['Int']>;
  readonly imageOrientation?: Maybe<Scalars['Boolean']>;
  readonly productBorder?: Maybe<Scalars['Boolean']>;
  readonly animated?: Maybe<Scalars['Boolean']>;
  readonly rankOrder?: Maybe<Scalars['Int']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly catalogToCatalog?: Maybe<FkCategoryCatalogInput>;
  readonly subCategoriesUsingOid?: Maybe<FkSubCategoryCategoryInverseInput>;
  readonly subProductsUsingNameAndCatalog?: Maybe<FakePublicSubProductsForeignKey0InverseInput>;
};

/** Input for the nested mutation of `catalog` in the `CategoryInput` mutation. */
export type FkCategoryCatalogInput = {
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly connectByOid?: Maybe<CatalogPkCatalogConnect>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly connectById?: Maybe<CatalogCatalogByIdConnect>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<CatalogPkCatalogDelete>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly deleteById?: Maybe<CatalogCatalogByIdDelete>;
  /** The primary key(s) and patch data for `catalog` for the far side of the relationship. */
  readonly updateByOid?: Maybe<CatalogOnCategoryForFkCategoryCatalogUsingPkCatalogUpdate>;
  /** The primary key(s) and patch data for `catalog` for the far side of the relationship. */
  readonly updateById?: Maybe<CatalogOnCategoryForFkCategoryCatalogUsingCatalogByIdUpdate>;
  /** A `CatalogInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FkCategoryCatalogCatalogCreateInput>;
};

/** Input for the nested mutation of `category` in the `CatalogInput` mutation. */
export type FkCategoryCatalogInverseInput = {
  /** Flag indicating whether all other `category` records that match this relationship should be removed. */
  readonly deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `category` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ReadonlyArray<CategoryPkCategoryConnect>>;
  /** The primary key(s) for `category` for the far side of the relationship. */
  readonly connectById?: Maybe<ReadonlyArray<CategoryCategoryByIdConnect>>;
  /** The primary key(s) for `category` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ReadonlyArray<CategoryPkCategoryDelete>>;
  /** The primary key(s) for `category` for the far side of the relationship. */
  readonly deleteById?: Maybe<ReadonlyArray<CategoryCategoryByIdDelete>>;
  /** The primary key(s) and patch data for `category` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ReadonlyArray<CategoryOnCategoryForFkCategoryCatalogUsingPkCategoryUpdate>>;
  /** The primary key(s) and patch data for `category` for the far side of the relationship. */
  readonly updateById?: Maybe<ReadonlyArray<CategoryOnCategoryForFkCategoryCatalogUsingCategoryByIdUpdate>>;
  /** A `CategoryInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FkCategoryCatalogCategoryCreateInput>>;
};

/** The `account` to be created by this mutation. */
export type FkContactParentAccountCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly customSmsNumber?: Maybe<Scalars['String']>;
  readonly logo?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly login?: Maybe<Scalars['String']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly defaultTrackingCode?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['Datetime']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['BigInt']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly catalogsUsingOid?: Maybe<FkCatalogAccountInverseInput>;
  readonly contactsUsingOid?: Maybe<FkContactParentInverseInput>;
  readonly campaignsUsingOid?: Maybe<FkCampaignAccountInverseInput>;
  readonly leadsUsingOid?: Maybe<FkLeadAccountInverseInput>;
  readonly authorizedUserAuthorizedUsersAccountPartnerAccessesUsingOid?: Maybe<FkAuthorizedUserAuthorizedUsersAccountPartnerAccess4E22214EInverseInput>;
};

/** The `contact` to be created by this mutation. */
export type FkContactParentContactCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly company?: Maybe<Scalars['String']>;
  readonly appProfile?: Maybe<Scalars['Boolean']>;
  readonly dateCreated?: Maybe<Scalars['Datetime']>;
  readonly oldId?: Maybe<Scalars['Int']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly accountToParent?: Maybe<FkContactParentInput>;
};

/** Input for the nested mutation of `account` in the `ContactInput` mutation. */
export type FkContactParentInput = {
  /** The primary key(s) for `account` for the far side of the relationship. */
  readonly connectByOid?: Maybe<AccountPkAccountConnect>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<AccountPkAccountDelete>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  readonly updateByOid?: Maybe<AccountOnContactForFkContactParentUsingPkAccountUpdate>;
  /** A `AccountInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FkContactParentAccountCreateInput>;
};

/** Input for the nested mutation of `contact` in the `AccountInput` mutation. */
export type FkContactParentInverseInput = {
  /** Flag indicating whether all other `contact` records that match this relationship should be removed. */
  readonly deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `contact` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ReadonlyArray<ContactPkContactConnect>>;
  /** The primary key(s) for `contact` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ReadonlyArray<ContactPkContactDelete>>;
  /** The primary key(s) and patch data for `contact` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ReadonlyArray<ContactOnContactForFkContactParentUsingPkContactUpdate>>;
  /** A `ContactInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FkContactParentContactCreateInput>>;
};

/** The `department` to be created by this mutation. */
export type FkDepartmentDepartmentsCatalogListingsDepartmentsDepartmentCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly departmentId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metakeys?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly searchText?: Maybe<Scalars['String']>;
  readonly advice?: Maybe<Scalars['String']>;
  readonly hiwId?: Maybe<Scalars['Int']>;
  readonly metaTitleArticle?: Maybe<Scalars['String']>;
  readonly metaKeysArticle?: Maybe<Scalars['String']>;
  readonly metaDescrArticle?: Maybe<Scalars['String']>;
  readonly metaKeysProduct?: Maybe<Scalars['String']>;
  readonly articleDept?: Maybe<Scalars['Int']>;
  readonly parentDepartment?: Maybe<Scalars['UUID']>;
  readonly deptType?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly showArticlesByDepartment?: Maybe<Scalars['Boolean']>;
  readonly showArticlesByTag?: Maybe<Scalars['String']>;
  readonly showOffers?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly departmentToParentDepartment?: Maybe<FkDepartmentParentDepartmentInput>;
  readonly catalogsUsingOid?: Maybe<FkCatalogPrimaryDepartmentInverseInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsDepartmentsInverseInput>;
  readonly navigationItemsUsingOid?: Maybe<FkNavigationItemDepartmentInverseInput>;
};

/** The `departmentDepartmentsCatalogListing` to be created by this mutation. */
export type FkDepartmentDepartmentsCatalogListingsDepartmentsDepartmentDepartmentsCatalogListingsCreateInput = {
  readonly listings?: Maybe<Scalars['UUID']>;
  readonly oid: Scalars['UUID'];
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly catalogToListings?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInput>;
  readonly departmentToDepartments?: Maybe<FkDepartmentDepartmentsCatalogListingsDepartmentsInput>;
};

/** Input for the nested mutation of `department` in the `DepartmentDepartmentsCatalogListingInput` mutation. */
export type FkDepartmentDepartmentsCatalogListingsDepartmentsInput = {
  /** The primary key(s) for `department` for the far side of the relationship. */
  readonly connectByOid?: Maybe<DepartmentPkDepartmentConnect>;
  /** The primary key(s) for `department` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<DepartmentPkDepartmentDelete>;
  /** The primary key(s) and patch data for `department` for the far side of the relationship. */
  readonly updateByOid?: Maybe<DepartmentOnDepartmentDepartmentsCatalogListingForFkDepartmentDepartmentsCatalogListingsDepartmentsUsingPkDepartmentUpdate>;
  /** A `DepartmentInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FkDepartmentDepartmentsCatalogListingsDepartmentsDepartmentCreateInput>;
};

/** Input for the nested mutation of `departmentDepartmentsCatalogListing` in the `DepartmentInput` mutation. */
export type FkDepartmentDepartmentsCatalogListingsDepartmentsInverseInput = {
  /** Flag indicating whether all other `departmentDepartmentsCatalogListing` records that match this relationship should be removed. */
  readonly deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `departmentDepartmentsCatalogListing` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ReadonlyArray<DepartmentDepartmentsCatalogListingPkDepartmentDepartmentsCatalogListingsConnect>>;
  /** The primary key(s) for `departmentDepartmentsCatalogListing` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ReadonlyArray<DepartmentDepartmentsCatalogListingPkDepartmentDepartmentsCatalogListingsDelete>>;
  /** The primary key(s) and patch data for `departmentDepartmentsCatalogListing` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ReadonlyArray<DepartmentDepartmentsCatalogListingOnDepartmentDepartmentsCatalogListingForFkDepartmentDepartmentsCatalogListingsDepartmentsUsingPkDepartmentDepartmentsCatalogListingsUpdate>>;
  /** A `DepartmentDepartmentsCatalogListingInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FkDepartmentDepartmentsCatalogListingsDepartmentsDepartmentDepartmentsCatalogListingsCreateInput>>;
};

/** The `catalog` to be created by this mutation. */
export type FkDepartmentDepartmentsCatalogListingsListingsCatalogCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly cover?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly catalogFormat?: Maybe<Scalars['String']>;
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly leadCharge?: Maybe<Scalars['Float']>;
  readonly linkCharge?: Maybe<Scalars['Float']>;
  readonly digitalFee?: Maybe<Scalars['Float']>;
  readonly listingFee?: Maybe<Scalars['Float']>;
  readonly dateExpired?: Maybe<Scalars['Datetime']>;
  readonly trackingCode?: Maybe<Scalars['String']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly showCategoryLinks?: Maybe<Scalars['Boolean']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Datetime']>;
  readonly offerCopy?: Maybe<Scalars['String']>;
  readonly introCopy?: Maybe<Scalars['String']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  readonly blurb?: Maybe<Scalars['String']>;
  readonly featureCopy?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly keywords?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly longDescription?: Maybe<Scalars['String']>;
  readonly class?: Maybe<Scalars['String']>;
  readonly searchAppendCode?: Maybe<Scalars['String']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly primaryDepartment?: Maybe<Scalars['UUID']>;
  readonly isDigital?: Maybe<Scalars['Boolean']>;
  readonly merchantId?: Maybe<Scalars['BigInt']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly autoActivate?: Maybe<Scalars['Boolean']>;
  readonly groupHubspotId?: Maybe<Scalars['String']>;
  readonly customSubdomain?: Maybe<Scalars['String']>;
  readonly text2Disclaimer?: Maybe<Scalars['String']>;
  readonly activeVersion?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly collectFullAddress?: Maybe<Scalars['Boolean']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly freeShipping?: Maybe<Scalars['Boolean']>;
  readonly mailOrder?: Maybe<Scalars['Boolean']>;
  readonly collectLeadQr?: Maybe<Scalars['Boolean']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly hideAds?: Maybe<Scalars['Boolean']>;
  readonly accountToAccount?: Maybe<FkCatalogAccountInput>;
  readonly departmentToPrimaryDepartment?: Maybe<FkCatalogPrimaryDepartmentInput>;
  readonly versionToActiveVersion?: Maybe<FkCatalogActiveVersionInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInverseInput>;
  readonly versionsUsingOid?: Maybe<FkVersionCatalogInverseInput>;
  readonly offersUsingOid?: Maybe<FkOfferCatalogInverseInput>;
  readonly categoriesUsingOid?: Maybe<FkCategoryCatalogInverseInput>;
  readonly feedsUsingOid?: Maybe<FkFeedCatalogInverseInput>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCatalogInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCatalogInverseInput>;
  readonly productsUsingOid?: Maybe<FkProductCatalogInverseInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCatalogInverseInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingCatalogInverseInput>;
  readonly categoryByCatalogsUsingOid?: Maybe<FakePublicCategoryByCatalogForeignKey0InverseInput>;
};

/** The `departmentDepartmentsCatalogListing` to be created by this mutation. */
export type FkDepartmentDepartmentsCatalogListingsListingsDepartmentDepartmentsCatalogListingsCreateInput = {
  readonly departments?: Maybe<Scalars['UUID']>;
  readonly oid: Scalars['UUID'];
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly catalogToListings?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInput>;
  readonly departmentToDepartments?: Maybe<FkDepartmentDepartmentsCatalogListingsDepartmentsInput>;
};

/** Input for the nested mutation of `catalog` in the `DepartmentDepartmentsCatalogListingInput` mutation. */
export type FkDepartmentDepartmentsCatalogListingsListingsInput = {
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly connectByOid?: Maybe<CatalogPkCatalogConnect>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly connectById?: Maybe<CatalogCatalogByIdConnect>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<CatalogPkCatalogDelete>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly deleteById?: Maybe<CatalogCatalogByIdDelete>;
  /** The primary key(s) and patch data for `catalog` for the far side of the relationship. */
  readonly updateByOid?: Maybe<CatalogOnDepartmentDepartmentsCatalogListingForFkDepartmentDepartmentsCatalogListingsListingsUsingPkCatalogUpdate>;
  /** The primary key(s) and patch data for `catalog` for the far side of the relationship. */
  readonly updateById?: Maybe<CatalogOnDepartmentDepartmentsCatalogListingForFkDepartmentDepartmentsCatalogListingsListingsUsingCatalogByIdUpdate>;
  /** A `CatalogInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsCatalogCreateInput>;
};

/** Input for the nested mutation of `departmentDepartmentsCatalogListing` in the `CatalogInput` mutation. */
export type FkDepartmentDepartmentsCatalogListingsListingsInverseInput = {
  /** Flag indicating whether all other `departmentDepartmentsCatalogListing` records that match this relationship should be removed. */
  readonly deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `departmentDepartmentsCatalogListing` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ReadonlyArray<DepartmentDepartmentsCatalogListingPkDepartmentDepartmentsCatalogListingsConnect>>;
  /** The primary key(s) for `departmentDepartmentsCatalogListing` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ReadonlyArray<DepartmentDepartmentsCatalogListingPkDepartmentDepartmentsCatalogListingsDelete>>;
  /** The primary key(s) and patch data for `departmentDepartmentsCatalogListing` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ReadonlyArray<DepartmentDepartmentsCatalogListingOnDepartmentDepartmentsCatalogListingForFkDepartmentDepartmentsCatalogListingsListingsUsingPkDepartmentDepartmentsCatalogListingsUpdate>>;
  /** A `DepartmentDepartmentsCatalogListingInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FkDepartmentDepartmentsCatalogListingsListingsDepartmentDepartmentsCatalogListingsCreateInput>>;
};

/** The `department` to be created by this mutation. */
export type FkDepartmentParentDepartmentDepartmentCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly departmentId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metakeys?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly searchText?: Maybe<Scalars['String']>;
  readonly advice?: Maybe<Scalars['String']>;
  readonly hiwId?: Maybe<Scalars['Int']>;
  readonly metaTitleArticle?: Maybe<Scalars['String']>;
  readonly metaKeysArticle?: Maybe<Scalars['String']>;
  readonly metaDescrArticle?: Maybe<Scalars['String']>;
  readonly metaKeysProduct?: Maybe<Scalars['String']>;
  readonly articleDept?: Maybe<Scalars['Int']>;
  readonly deptType?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly showArticlesByDepartment?: Maybe<Scalars['Boolean']>;
  readonly showArticlesByTag?: Maybe<Scalars['String']>;
  readonly showOffers?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly departmentToParentDepartment?: Maybe<FkDepartmentParentDepartmentInput>;
  readonly catalogsUsingOid?: Maybe<FkCatalogPrimaryDepartmentInverseInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsDepartmentsInverseInput>;
  readonly navigationItemsUsingOid?: Maybe<FkNavigationItemDepartmentInverseInput>;
};

/** Input for the nested mutation of `department` in the `DepartmentInput` mutation. */
export type FkDepartmentParentDepartmentInput = {
  /** The primary key(s) for `department` for the far side of the relationship. */
  readonly connectByOid?: Maybe<DepartmentPkDepartmentConnect>;
  /** The primary key(s) for `department` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<DepartmentPkDepartmentDelete>;
  /** The primary key(s) and patch data for `department` for the far side of the relationship. */
  readonly updateByOid?: Maybe<DepartmentOnDepartmentForFkDepartmentParentDepartmentUsingPkDepartmentUpdate>;
  /** A `DepartmentInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FkDepartmentParentDepartmentDepartmentCreateInput>;
};

/** The `catalog` to be created by this mutation. */
export type FkFeedCatalogCatalogCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly cover?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly catalogFormat?: Maybe<Scalars['String']>;
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly leadCharge?: Maybe<Scalars['Float']>;
  readonly linkCharge?: Maybe<Scalars['Float']>;
  readonly digitalFee?: Maybe<Scalars['Float']>;
  readonly listingFee?: Maybe<Scalars['Float']>;
  readonly dateExpired?: Maybe<Scalars['Datetime']>;
  readonly trackingCode?: Maybe<Scalars['String']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly showCategoryLinks?: Maybe<Scalars['Boolean']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Datetime']>;
  readonly offerCopy?: Maybe<Scalars['String']>;
  readonly introCopy?: Maybe<Scalars['String']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  readonly blurb?: Maybe<Scalars['String']>;
  readonly featureCopy?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly keywords?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly longDescription?: Maybe<Scalars['String']>;
  readonly class?: Maybe<Scalars['String']>;
  readonly searchAppendCode?: Maybe<Scalars['String']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly primaryDepartment?: Maybe<Scalars['UUID']>;
  readonly isDigital?: Maybe<Scalars['Boolean']>;
  readonly merchantId?: Maybe<Scalars['BigInt']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly autoActivate?: Maybe<Scalars['Boolean']>;
  readonly groupHubspotId?: Maybe<Scalars['String']>;
  readonly customSubdomain?: Maybe<Scalars['String']>;
  readonly text2Disclaimer?: Maybe<Scalars['String']>;
  readonly activeVersion?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly collectFullAddress?: Maybe<Scalars['Boolean']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly freeShipping?: Maybe<Scalars['Boolean']>;
  readonly mailOrder?: Maybe<Scalars['Boolean']>;
  readonly collectLeadQr?: Maybe<Scalars['Boolean']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly hideAds?: Maybe<Scalars['Boolean']>;
  readonly accountToAccount?: Maybe<FkCatalogAccountInput>;
  readonly departmentToPrimaryDepartment?: Maybe<FkCatalogPrimaryDepartmentInput>;
  readonly versionToActiveVersion?: Maybe<FkCatalogActiveVersionInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInverseInput>;
  readonly versionsUsingOid?: Maybe<FkVersionCatalogInverseInput>;
  readonly offersUsingOid?: Maybe<FkOfferCatalogInverseInput>;
  readonly categoriesUsingOid?: Maybe<FkCategoryCatalogInverseInput>;
  readonly feedsUsingOid?: Maybe<FkFeedCatalogInverseInput>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCatalogInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCatalogInverseInput>;
  readonly productsUsingOid?: Maybe<FkProductCatalogInverseInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCatalogInverseInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingCatalogInverseInput>;
  readonly categoryByCatalogsUsingOid?: Maybe<FakePublicCategoryByCatalogForeignKey0InverseInput>;
};

/** The `feed` to be created by this mutation. */
export type FkFeedCatalogFeedCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly feedType?: Maybe<Scalars['String']>;
  readonly feedMap?: Maybe<Scalars['String']>;
  readonly productMap?: Maybe<Scalars['JSON']>;
  readonly dateModified?: Maybe<Scalars['Datetime']>;
  readonly autoUpdate?: Maybe<Scalars['Boolean']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly catalogToCatalog?: Maybe<FkFeedCatalogInput>;
  readonly feedHistoriesUsingOid?: Maybe<FkFeedHistoryFeedInverseInput>;
};

/** Input for the nested mutation of `catalog` in the `FeedInput` mutation. */
export type FkFeedCatalogInput = {
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly connectByOid?: Maybe<CatalogPkCatalogConnect>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly connectById?: Maybe<CatalogCatalogByIdConnect>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<CatalogPkCatalogDelete>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly deleteById?: Maybe<CatalogCatalogByIdDelete>;
  /** The primary key(s) and patch data for `catalog` for the far side of the relationship. */
  readonly updateByOid?: Maybe<CatalogOnFeedForFkFeedCatalogUsingPkCatalogUpdate>;
  /** The primary key(s) and patch data for `catalog` for the far side of the relationship. */
  readonly updateById?: Maybe<CatalogOnFeedForFkFeedCatalogUsingCatalogByIdUpdate>;
  /** A `CatalogInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FkFeedCatalogCatalogCreateInput>;
};

/** Input for the nested mutation of `feed` in the `CatalogInput` mutation. */
export type FkFeedCatalogInverseInput = {
  /** Flag indicating whether all other `feed` records that match this relationship should be removed. */
  readonly deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `feed` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ReadonlyArray<FeedPkFeedConnect>>;
  /** The primary key(s) for `feed` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ReadonlyArray<FeedPkFeedDelete>>;
  /** The primary key(s) and patch data for `feed` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ReadonlyArray<FeedOnFeedForFkFeedCatalogUsingPkFeedUpdate>>;
  /** A `FeedInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FkFeedCatalogFeedCreateInput>>;
};

/** The `feed` to be created by this mutation. */
export type FkFeedHistoryFeedFeedCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly feedType?: Maybe<Scalars['String']>;
  readonly feedMap?: Maybe<Scalars['String']>;
  readonly productMap?: Maybe<Scalars['JSON']>;
  readonly dateModified?: Maybe<Scalars['Datetime']>;
  readonly autoUpdate?: Maybe<Scalars['Boolean']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly catalogToCatalog?: Maybe<FkFeedCatalogInput>;
  readonly feedHistoriesUsingOid?: Maybe<FkFeedHistoryFeedInverseInput>;
};

/** The `feedHistory` to be created by this mutation. */
export type FkFeedHistoryFeedFeedHistoryCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly preUpdateStats?: Maybe<Scalars['JSON']>;
  readonly postUpdateStats?: Maybe<Scalars['JSON']>;
  readonly feedPath?: Maybe<Scalars['String']>;
  readonly feedJson?: Maybe<Scalars['JSON']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly feedToFeed?: Maybe<FkFeedHistoryFeedInput>;
};

/** Input for the nested mutation of `feed` in the `FeedHistoryInput` mutation. */
export type FkFeedHistoryFeedInput = {
  /** The primary key(s) for `feed` for the far side of the relationship. */
  readonly connectByOid?: Maybe<FeedPkFeedConnect>;
  /** The primary key(s) for `feed` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<FeedPkFeedDelete>;
  /** The primary key(s) and patch data for `feed` for the far side of the relationship. */
  readonly updateByOid?: Maybe<FeedOnFeedHistoryForFkFeedHistoryFeedUsingPkFeedUpdate>;
  /** A `FeedInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FkFeedHistoryFeedFeedCreateInput>;
};

/** Input for the nested mutation of `feedHistory` in the `FeedInput` mutation. */
export type FkFeedHistoryFeedInverseInput = {
  /** Flag indicating whether all other `feedHistory` records that match this relationship should be removed. */
  readonly deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `feedHistory` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ReadonlyArray<FeedHistoryPkFeedHistoryConnect>>;
  /** The primary key(s) for `feedHistory` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ReadonlyArray<FeedHistoryPkFeedHistoryDelete>>;
  /** The primary key(s) and patch data for `feedHistory` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ReadonlyArray<FeedHistoryOnFeedHistoryForFkFeedHistoryFeedUsingPkFeedHistoryUpdate>>;
  /** A `FeedHistoryInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FkFeedHistoryFeedFeedHistoryCreateInput>>;
};

/** The `account` to be created by this mutation. */
export type FkLeadAccountAccountCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly customSmsNumber?: Maybe<Scalars['String']>;
  readonly logo?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly login?: Maybe<Scalars['String']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly defaultTrackingCode?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['Datetime']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['BigInt']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly catalogsUsingOid?: Maybe<FkCatalogAccountInverseInput>;
  readonly contactsUsingOid?: Maybe<FkContactParentInverseInput>;
  readonly campaignsUsingOid?: Maybe<FkCampaignAccountInverseInput>;
  readonly leadsUsingOid?: Maybe<FkLeadAccountInverseInput>;
  readonly authorizedUserAuthorizedUsersAccountPartnerAccessesUsingOid?: Maybe<FkAuthorizedUserAuthorizedUsersAccountPartnerAccess4E22214EInverseInput>;
};

/** Input for the nested mutation of `account` in the `LeadInput` mutation. */
export type FkLeadAccountInput = {
  /** The primary key(s) for `account` for the far side of the relationship. */
  readonly connectByOid?: Maybe<AccountPkAccountConnect>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<AccountPkAccountDelete>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  readonly updateByOid?: Maybe<AccountOnLeadForFkLeadAccountUsingPkAccountUpdate>;
  /** A `AccountInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FkLeadAccountAccountCreateInput>;
};

/** Input for the nested mutation of `lead` in the `AccountInput` mutation. */
export type FkLeadAccountInverseInput = {
  /** Flag indicating whether all other `lead` records that match this relationship should be removed. */
  readonly deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ReadonlyArray<LeadPkLeadConnect>>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ReadonlyArray<LeadPkLeadDelete>>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ReadonlyArray<LeadOnLeadForFkLeadAccountUsingPkLeadUpdate>>;
  /** A `LeadInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FkLeadAccountLeadCreateInput>>;
};

/** The `lead` to be created by this mutation. */
export type FkLeadAccountLeadCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly company?: Maybe<Scalars['String']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly dateCollected?: Maybe<Scalars['Datetime']>;
  readonly profile?: Maybe<Scalars['UUID']>;
  readonly catalogId?: Maybe<Scalars['Int']>;
  readonly versionId?: Maybe<Scalars['Int']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly accountToAccount?: Maybe<FkLeadAccountInput>;
  readonly profileToProfile?: Maybe<FkLeadProfileInput>;
  readonly campaignUsersUsingOid?: Maybe<FkCampaignUserLeadInverseInput>;
};

/** Input for the nested mutation of `profile` in the `LeadInput` mutation. */
export type FkLeadProfileInput = {
  /** The primary key(s) for `profile` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ProfilePkProfileConnect>;
  /** The primary key(s) for `profile` for the far side of the relationship. */
  readonly connectByEmail?: Maybe<ProfileIEmailProfileConnect>;
  /** The primary key(s) for `profile` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ProfilePkProfileDelete>;
  /** The primary key(s) for `profile` for the far side of the relationship. */
  readonly deleteByEmail?: Maybe<ProfileIEmailProfileDelete>;
  /** The primary key(s) and patch data for `profile` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ProfileOnLeadForFkLeadProfileUsingPkProfileUpdate>;
  /** The primary key(s) and patch data for `profile` for the far side of the relationship. */
  readonly updateByEmail?: Maybe<ProfileOnLeadForFkLeadProfileUsingIEmailProfileUpdate>;
  /** A `ProfileInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FkLeadProfileProfileCreateInput>;
};

/** Input for the nested mutation of `lead` in the `ProfileInput` mutation. */
export type FkLeadProfileInverseInput = {
  /** Flag indicating whether all other `lead` records that match this relationship should be removed. */
  readonly deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ReadonlyArray<LeadPkLeadConnect>>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ReadonlyArray<LeadPkLeadDelete>>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ReadonlyArray<LeadOnLeadForFkLeadProfileUsingPkLeadUpdate>>;
  /** A `LeadInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FkLeadProfileLeadCreateInput>>;
};

/** The `lead` to be created by this mutation. */
export type FkLeadProfileLeadCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly company?: Maybe<Scalars['String']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly dateCollected?: Maybe<Scalars['Datetime']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly catalogId?: Maybe<Scalars['Int']>;
  readonly versionId?: Maybe<Scalars['Int']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly accountToAccount?: Maybe<FkLeadAccountInput>;
  readonly profileToProfile?: Maybe<FkLeadProfileInput>;
  readonly campaignUsersUsingOid?: Maybe<FkCampaignUserLeadInverseInput>;
};

/** The `profile` to be created by this mutation. */
export type FkLeadProfileProfileCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly company?: Maybe<Scalars['String']>;
  readonly dateCreated?: Maybe<Scalars['Datetime']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCustomerInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCustomerInverseInput>;
  readonly leadsUsingOid?: Maybe<FkLeadProfileInverseInput>;
  readonly authorizedUserUsingEmail?: Maybe<FakePublicAuthorizedUserForeignKey0InverseInput>;
};

/** The `catalog` to be created by this mutation. */
export type FkLinkOutCatalogCatalogCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly cover?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly catalogFormat?: Maybe<Scalars['String']>;
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly leadCharge?: Maybe<Scalars['Float']>;
  readonly linkCharge?: Maybe<Scalars['Float']>;
  readonly digitalFee?: Maybe<Scalars['Float']>;
  readonly listingFee?: Maybe<Scalars['Float']>;
  readonly dateExpired?: Maybe<Scalars['Datetime']>;
  readonly trackingCode?: Maybe<Scalars['String']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly showCategoryLinks?: Maybe<Scalars['Boolean']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Datetime']>;
  readonly offerCopy?: Maybe<Scalars['String']>;
  readonly introCopy?: Maybe<Scalars['String']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  readonly blurb?: Maybe<Scalars['String']>;
  readonly featureCopy?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly keywords?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly longDescription?: Maybe<Scalars['String']>;
  readonly class?: Maybe<Scalars['String']>;
  readonly searchAppendCode?: Maybe<Scalars['String']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly primaryDepartment?: Maybe<Scalars['UUID']>;
  readonly isDigital?: Maybe<Scalars['Boolean']>;
  readonly merchantId?: Maybe<Scalars['BigInt']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly autoActivate?: Maybe<Scalars['Boolean']>;
  readonly groupHubspotId?: Maybe<Scalars['String']>;
  readonly customSubdomain?: Maybe<Scalars['String']>;
  readonly text2Disclaimer?: Maybe<Scalars['String']>;
  readonly activeVersion?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly collectFullAddress?: Maybe<Scalars['Boolean']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly freeShipping?: Maybe<Scalars['Boolean']>;
  readonly mailOrder?: Maybe<Scalars['Boolean']>;
  readonly collectLeadQr?: Maybe<Scalars['Boolean']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly hideAds?: Maybe<Scalars['Boolean']>;
  readonly accountToAccount?: Maybe<FkCatalogAccountInput>;
  readonly departmentToPrimaryDepartment?: Maybe<FkCatalogPrimaryDepartmentInput>;
  readonly versionToActiveVersion?: Maybe<FkCatalogActiveVersionInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInverseInput>;
  readonly versionsUsingOid?: Maybe<FkVersionCatalogInverseInput>;
  readonly offersUsingOid?: Maybe<FkOfferCatalogInverseInput>;
  readonly categoriesUsingOid?: Maybe<FkCategoryCatalogInverseInput>;
  readonly feedsUsingOid?: Maybe<FkFeedCatalogInverseInput>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCatalogInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCatalogInverseInput>;
  readonly productsUsingOid?: Maybe<FkProductCatalogInverseInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCatalogInverseInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingCatalogInverseInput>;
  readonly categoryByCatalogsUsingOid?: Maybe<FakePublicCategoryByCatalogForeignKey0InverseInput>;
};

/** Input for the nested mutation of `catalog` in the `LinkOutInput` mutation. */
export type FkLinkOutCatalogInput = {
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly connectByOid?: Maybe<CatalogPkCatalogConnect>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly connectById?: Maybe<CatalogCatalogByIdConnect>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<CatalogPkCatalogDelete>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly deleteById?: Maybe<CatalogCatalogByIdDelete>;
  /** The primary key(s) and patch data for `catalog` for the far side of the relationship. */
  readonly updateByOid?: Maybe<CatalogOnLinkOutForFkLinkOutCatalogUsingPkCatalogUpdate>;
  /** The primary key(s) and patch data for `catalog` for the far side of the relationship. */
  readonly updateById?: Maybe<CatalogOnLinkOutForFkLinkOutCatalogUsingCatalogByIdUpdate>;
  /** A `CatalogInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FkLinkOutCatalogCatalogCreateInput>;
};

/** Input for the nested mutation of `linkOut` in the `CatalogInput` mutation. */
export type FkLinkOutCatalogInverseInput = {
  /** Flag indicating whether all other `linkOut` records that match this relationship should be removed. */
  readonly deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `linkOut` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ReadonlyArray<LinkOutPkLinkOutConnect>>;
  /** The primary key(s) for `linkOut` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ReadonlyArray<LinkOutPkLinkOutDelete>>;
  /** The primary key(s) and patch data for `linkOut` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ReadonlyArray<LinkOutOnLinkOutForFkLinkOutCatalogUsingPkLinkOutUpdate>>;
  /** A `LinkOutInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FkLinkOutCatalogLinkOutCreateInput>>;
};

/** The `linkOut` to be created by this mutation. */
export type FkLinkOutCatalogLinkOutCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly linkTime?: Maybe<Scalars['Datetime']>;
  readonly link?: Maybe<Scalars['String']>;
  readonly charge?: Maybe<Scalars['Float']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly customerIp?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly profileToCustomer?: Maybe<FkLinkOutCustomerInput>;
  readonly catalogToCatalog?: Maybe<FkLinkOutCatalogInput>;
};

/** Input for the nested mutation of `profile` in the `LinkOutInput` mutation. */
export type FkLinkOutCustomerInput = {
  /** The primary key(s) for `profile` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ProfilePkProfileConnect>;
  /** The primary key(s) for `profile` for the far side of the relationship. */
  readonly connectByEmail?: Maybe<ProfileIEmailProfileConnect>;
  /** The primary key(s) for `profile` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ProfilePkProfileDelete>;
  /** The primary key(s) for `profile` for the far side of the relationship. */
  readonly deleteByEmail?: Maybe<ProfileIEmailProfileDelete>;
  /** The primary key(s) and patch data for `profile` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ProfileOnLinkOutForFkLinkOutCustomerUsingPkProfileUpdate>;
  /** The primary key(s) and patch data for `profile` for the far side of the relationship. */
  readonly updateByEmail?: Maybe<ProfileOnLinkOutForFkLinkOutCustomerUsingIEmailProfileUpdate>;
  /** A `ProfileInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FkLinkOutCustomerProfileCreateInput>;
};

/** Input for the nested mutation of `linkOut` in the `ProfileInput` mutation. */
export type FkLinkOutCustomerInverseInput = {
  /** Flag indicating whether all other `linkOut` records that match this relationship should be removed. */
  readonly deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `linkOut` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ReadonlyArray<LinkOutPkLinkOutConnect>>;
  /** The primary key(s) for `linkOut` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ReadonlyArray<LinkOutPkLinkOutDelete>>;
  /** The primary key(s) and patch data for `linkOut` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ReadonlyArray<LinkOutOnLinkOutForFkLinkOutCustomerUsingPkLinkOutUpdate>>;
  /** A `LinkOutInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FkLinkOutCustomerLinkOutCreateInput>>;
};

/** The `linkOut` to be created by this mutation. */
export type FkLinkOutCustomerLinkOutCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly linkTime?: Maybe<Scalars['Datetime']>;
  readonly link?: Maybe<Scalars['String']>;
  readonly charge?: Maybe<Scalars['Float']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly customerIp?: Maybe<Scalars['String']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly profileToCustomer?: Maybe<FkLinkOutCustomerInput>;
  readonly catalogToCatalog?: Maybe<FkLinkOutCatalogInput>;
};

/** The `profile` to be created by this mutation. */
export type FkLinkOutCustomerProfileCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly company?: Maybe<Scalars['String']>;
  readonly dateCreated?: Maybe<Scalars['Datetime']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCustomerInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCustomerInverseInput>;
  readonly leadsUsingOid?: Maybe<FkLeadProfileInverseInput>;
  readonly authorizedUserUsingEmail?: Maybe<FakePublicAuthorizedUserForeignKey0InverseInput>;
};

/** The `department` to be created by this mutation. */
export type FkNavigationItemDepartmentDepartmentCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly departmentId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metakeys?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly searchText?: Maybe<Scalars['String']>;
  readonly advice?: Maybe<Scalars['String']>;
  readonly hiwId?: Maybe<Scalars['Int']>;
  readonly metaTitleArticle?: Maybe<Scalars['String']>;
  readonly metaKeysArticle?: Maybe<Scalars['String']>;
  readonly metaDescrArticle?: Maybe<Scalars['String']>;
  readonly metaKeysProduct?: Maybe<Scalars['String']>;
  readonly articleDept?: Maybe<Scalars['Int']>;
  readonly parentDepartment?: Maybe<Scalars['UUID']>;
  readonly deptType?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly showArticlesByDepartment?: Maybe<Scalars['Boolean']>;
  readonly showArticlesByTag?: Maybe<Scalars['String']>;
  readonly showOffers?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly departmentToParentDepartment?: Maybe<FkDepartmentParentDepartmentInput>;
  readonly catalogsUsingOid?: Maybe<FkCatalogPrimaryDepartmentInverseInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsDepartmentsInverseInput>;
  readonly navigationItemsUsingOid?: Maybe<FkNavigationItemDepartmentInverseInput>;
};

/** Input for the nested mutation of `department` in the `NavigationItemInput` mutation. */
export type FkNavigationItemDepartmentInput = {
  /** The primary key(s) for `department` for the far side of the relationship. */
  readonly connectByOid?: Maybe<DepartmentPkDepartmentConnect>;
  /** The primary key(s) for `department` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<DepartmentPkDepartmentDelete>;
  /** The primary key(s) and patch data for `department` for the far side of the relationship. */
  readonly updateByOid?: Maybe<DepartmentOnNavigationItemForFkNavigationItemDepartmentUsingPkDepartmentUpdate>;
  /** A `DepartmentInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FkNavigationItemDepartmentDepartmentCreateInput>;
};

/** Input for the nested mutation of `navigationItem` in the `DepartmentInput` mutation. */
export type FkNavigationItemDepartmentInverseInput = {
  /** Flag indicating whether all other `navigationItem` records that match this relationship should be removed. */
  readonly deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `navigationItem` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ReadonlyArray<NavigationItemPkNavigationItemConnect>>;
  /** The primary key(s) for `navigationItem` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ReadonlyArray<NavigationItemPkNavigationItemDelete>>;
  /** The primary key(s) and patch data for `navigationItem` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ReadonlyArray<NavigationItemOnNavigationItemForFkNavigationItemDepartmentUsingPkNavigationItemUpdate>>;
  /** A `NavigationItemInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FkNavigationItemDepartmentNavigationItemCreateInput>>;
};

/** The `navigationItem` to be created by this mutation. */
export type FkNavigationItemDepartmentNavigationItemCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly orderIndex?: Maybe<Scalars['Int']>;
  readonly groupText?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly departmentToDepartment?: Maybe<FkNavigationItemDepartmentInput>;
};

/** The `catalog` to be created by this mutation. */
export type FkOfferCatalogCatalogCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly cover?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly catalogFormat?: Maybe<Scalars['String']>;
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly leadCharge?: Maybe<Scalars['Float']>;
  readonly linkCharge?: Maybe<Scalars['Float']>;
  readonly digitalFee?: Maybe<Scalars['Float']>;
  readonly listingFee?: Maybe<Scalars['Float']>;
  readonly dateExpired?: Maybe<Scalars['Datetime']>;
  readonly trackingCode?: Maybe<Scalars['String']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly showCategoryLinks?: Maybe<Scalars['Boolean']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Datetime']>;
  readonly offerCopy?: Maybe<Scalars['String']>;
  readonly introCopy?: Maybe<Scalars['String']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  readonly blurb?: Maybe<Scalars['String']>;
  readonly featureCopy?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly keywords?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly longDescription?: Maybe<Scalars['String']>;
  readonly class?: Maybe<Scalars['String']>;
  readonly searchAppendCode?: Maybe<Scalars['String']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly primaryDepartment?: Maybe<Scalars['UUID']>;
  readonly isDigital?: Maybe<Scalars['Boolean']>;
  readonly merchantId?: Maybe<Scalars['BigInt']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly autoActivate?: Maybe<Scalars['Boolean']>;
  readonly groupHubspotId?: Maybe<Scalars['String']>;
  readonly customSubdomain?: Maybe<Scalars['String']>;
  readonly text2Disclaimer?: Maybe<Scalars['String']>;
  readonly activeVersion?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly collectFullAddress?: Maybe<Scalars['Boolean']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly freeShipping?: Maybe<Scalars['Boolean']>;
  readonly mailOrder?: Maybe<Scalars['Boolean']>;
  readonly collectLeadQr?: Maybe<Scalars['Boolean']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly hideAds?: Maybe<Scalars['Boolean']>;
  readonly accountToAccount?: Maybe<FkCatalogAccountInput>;
  readonly departmentToPrimaryDepartment?: Maybe<FkCatalogPrimaryDepartmentInput>;
  readonly versionToActiveVersion?: Maybe<FkCatalogActiveVersionInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInverseInput>;
  readonly versionsUsingOid?: Maybe<FkVersionCatalogInverseInput>;
  readonly offersUsingOid?: Maybe<FkOfferCatalogInverseInput>;
  readonly categoriesUsingOid?: Maybe<FkCategoryCatalogInverseInput>;
  readonly feedsUsingOid?: Maybe<FkFeedCatalogInverseInput>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCatalogInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCatalogInverseInput>;
  readonly productsUsingOid?: Maybe<FkProductCatalogInverseInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCatalogInverseInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingCatalogInverseInput>;
  readonly categoryByCatalogsUsingOid?: Maybe<FakePublicCategoryByCatalogForeignKey0InverseInput>;
};

/** Input for the nested mutation of `catalog` in the `OfferInput` mutation. */
export type FkOfferCatalogInput = {
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly connectByOid?: Maybe<CatalogPkCatalogConnect>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly connectById?: Maybe<CatalogCatalogByIdConnect>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<CatalogPkCatalogDelete>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly deleteById?: Maybe<CatalogCatalogByIdDelete>;
  /** The primary key(s) and patch data for `catalog` for the far side of the relationship. */
  readonly updateByOid?: Maybe<CatalogOnOfferForFkOfferCatalogUsingPkCatalogUpdate>;
  /** The primary key(s) and patch data for `catalog` for the far side of the relationship. */
  readonly updateById?: Maybe<CatalogOnOfferForFkOfferCatalogUsingCatalogByIdUpdate>;
  /** A `CatalogInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FkOfferCatalogCatalogCreateInput>;
};

/** Input for the nested mutation of `offer` in the `CatalogInput` mutation. */
export type FkOfferCatalogInverseInput = {
  /** Flag indicating whether all other `offer` records that match this relationship should be removed. */
  readonly deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `offer` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ReadonlyArray<OfferPkOfferConnect>>;
  /** The primary key(s) for `offer` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ReadonlyArray<OfferPkOfferDelete>>;
  /** The primary key(s) and patch data for `offer` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ReadonlyArray<OfferOnOfferForFkOfferCatalogUsingPkOfferUpdate>>;
  /** A `OfferInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FkOfferCatalogOfferCreateInput>>;
};

/** The `offer` to be created by this mutation. */
export type FkOfferCatalogOfferCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly image?: Maybe<Scalars['String']>;
  readonly discountInPercent?: Maybe<Scalars['Float']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly certificate?: Maybe<Scalars['String']>;
  readonly expiryDate?: Maybe<Scalars['Datetime']>;
  readonly value?: Maybe<Scalars['String']>;
  readonly disclaimer?: Maybe<Scalars['String']>;
  readonly burst?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly paid?: Maybe<Scalars['Boolean']>;
  readonly catalogToCatalog?: Maybe<FkOfferCatalogInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingOfferInverseInput>;
};

/** Input for the nested mutation of `order` in the `OrderProductInput` mutation. */
export type FkOrderProductOrderInput = {
  /** The primary key(s) for `order` for the far side of the relationship. */
  readonly connectByOid?: Maybe<OrderPkOrderConnect>;
  /** The primary key(s) for `order` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<OrderPkOrderDelete>;
  /** The primary key(s) and patch data for `order` for the far side of the relationship. */
  readonly updateByOid?: Maybe<OrderOnOrderProductForFkOrderProductOrderUsingPkOrderUpdate>;
  /** A `OrderInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FkOrderProductOrderOrderCreateInput>;
};

/** Input for the nested mutation of `orderProduct` in the `OrderInput` mutation. */
export type FkOrderProductOrderInverseInput = {
  /** Flag indicating whether all other `orderProduct` records that match this relationship should be removed. */
  readonly deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `orderProduct` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ReadonlyArray<OrderProductPkOrderProductConnect>>;
  /** The primary key(s) for `orderProduct` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ReadonlyArray<OrderProductPkOrderProductDelete>>;
  /** The primary key(s) and patch data for `orderProduct` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ReadonlyArray<OrderProductOnOrderProductForFkOrderProductOrderUsingPkOrderProductUpdate>>;
  /** A `OrderProductInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FkOrderProductOrderOrderProductCreateInput>>;
};

/** The `order` to be created by this mutation. */
export type FkOrderProductOrderOrderCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly deliveryAddress?: Maybe<Scalars['String']>;
  readonly subTotal?: Maybe<Scalars['Float']>;
  readonly date?: Maybe<Scalars['Datetime']>;
  readonly amount?: Maybe<Scalars['Float']>;
  readonly deliveryFee?: Maybe<Scalars['Float']>;
  readonly deliveryTime?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly orderProductsUsingOid?: Maybe<FkOrderProductOrderInverseInput>;
};

/** The `orderProduct` to be created by this mutation. */
export type FkOrderProductOrderOrderProductCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly image?: Maybe<Scalars['String']>;
  readonly total?: Maybe<Scalars['Float']>;
  readonly price?: Maybe<Scalars['Float']>;
  readonly quantity?: Maybe<Scalars['Float']>;
  readonly weight?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly orderToOrder?: Maybe<FkOrderProductOrderInput>;
};

/** Input for the nested mutation of `version` in the `PageInput` mutation. */
export type FkPageVersionInput = {
  /** The primary key(s) for `version` for the far side of the relationship. */
  readonly connectByOid?: Maybe<VersionPkVersionConnect>;
  /** The primary key(s) for `version` for the far side of the relationship. */
  readonly connectById?: Maybe<VersionVersionByIdConnect>;
  /** The primary key(s) for `version` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<VersionPkVersionDelete>;
  /** The primary key(s) for `version` for the far side of the relationship. */
  readonly deleteById?: Maybe<VersionVersionByIdDelete>;
  /** The primary key(s) and patch data for `version` for the far side of the relationship. */
  readonly updateByOid?: Maybe<VersionOnPageForFkPageVersionUsingPkVersionUpdate>;
  /** The primary key(s) and patch data for `version` for the far side of the relationship. */
  readonly updateById?: Maybe<VersionOnPageForFkPageVersionUsingVersionByIdUpdate>;
  /** A `VersionInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FkPageVersionVersionCreateInput>;
};

/** Input for the nested mutation of `page` in the `VersionInput` mutation. */
export type FkPageVersionInverseInput = {
  /** Flag indicating whether all other `page` records that match this relationship should be removed. */
  readonly deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `page` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ReadonlyArray<PagePkPageConnect>>;
  /** The primary key(s) for `page` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ReadonlyArray<PagePkPageDelete>>;
  /** The primary key(s) and patch data for `page` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ReadonlyArray<PageOnPageForFkPageVersionUsingPkPageUpdate>>;
  /** A `PageInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FkPageVersionPageCreateInput>>;
};

/** The `page` to be created by this mutation. */
export type FkPageVersionPageCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly imageMeta?: Maybe<Scalars['String']>;
  readonly imageSource?: Maybe<Scalars['String']>;
  readonly pageType?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly versionToVersion?: Maybe<FkPageVersionInput>;
};

/** The `version` to be created by this mutation. */
export type FkPageVersionVersionCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly coverUrl?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly coverMeta?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
  readonly dateCreated?: Maybe<Scalars['Datetime']>;
  readonly dateModified?: Maybe<Scalars['Datetime']>;
  readonly versionNo?: Maybe<Scalars['Int']>;
  readonly autoProcess?: Maybe<Scalars['Boolean']>;
  readonly contactUrl?: Maybe<Scalars['String']>;
  readonly enableCategoryCatalogs?: Maybe<Scalars['Boolean']>;
  readonly language?: Maybe<Scalars['String']>;
  readonly currencySymbol?: Maybe<Scalars['String']>;
  readonly leadZapEmail?: Maybe<Scalars['String']>;
  readonly agentId?: Maybe<Scalars['String']>;
  readonly shortName?: Maybe<Scalars['String']>;
  readonly isFtp?: Maybe<Scalars['Boolean']>;
  readonly catalogCloneId?: Maybe<Scalars['BigInt']>;
  readonly feedAlertNotifcation?: Maybe<Scalars['Datetime']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly order?: Maybe<Scalars['JSON']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly catalogToCatalog?: Maybe<FkVersionCatalogInput>;
  readonly catalogsUsingOid?: Maybe<FkCatalogActiveVersionInverseInput>;
  readonly pagesUsingOid?: Maybe<FkPageVersionInverseInput>;
};

/** The `catalog` to be created by this mutation. */
export type FkProductCatalogCatalogCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly cover?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly catalogFormat?: Maybe<Scalars['String']>;
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly leadCharge?: Maybe<Scalars['Float']>;
  readonly linkCharge?: Maybe<Scalars['Float']>;
  readonly digitalFee?: Maybe<Scalars['Float']>;
  readonly listingFee?: Maybe<Scalars['Float']>;
  readonly dateExpired?: Maybe<Scalars['Datetime']>;
  readonly trackingCode?: Maybe<Scalars['String']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly showCategoryLinks?: Maybe<Scalars['Boolean']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Datetime']>;
  readonly offerCopy?: Maybe<Scalars['String']>;
  readonly introCopy?: Maybe<Scalars['String']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  readonly blurb?: Maybe<Scalars['String']>;
  readonly featureCopy?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly keywords?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly longDescription?: Maybe<Scalars['String']>;
  readonly class?: Maybe<Scalars['String']>;
  readonly searchAppendCode?: Maybe<Scalars['String']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly primaryDepartment?: Maybe<Scalars['UUID']>;
  readonly isDigital?: Maybe<Scalars['Boolean']>;
  readonly merchantId?: Maybe<Scalars['BigInt']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly autoActivate?: Maybe<Scalars['Boolean']>;
  readonly groupHubspotId?: Maybe<Scalars['String']>;
  readonly customSubdomain?: Maybe<Scalars['String']>;
  readonly text2Disclaimer?: Maybe<Scalars['String']>;
  readonly activeVersion?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly collectFullAddress?: Maybe<Scalars['Boolean']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly freeShipping?: Maybe<Scalars['Boolean']>;
  readonly mailOrder?: Maybe<Scalars['Boolean']>;
  readonly collectLeadQr?: Maybe<Scalars['Boolean']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly hideAds?: Maybe<Scalars['Boolean']>;
  readonly accountToAccount?: Maybe<FkCatalogAccountInput>;
  readonly departmentToPrimaryDepartment?: Maybe<FkCatalogPrimaryDepartmentInput>;
  readonly versionToActiveVersion?: Maybe<FkCatalogActiveVersionInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInverseInput>;
  readonly versionsUsingOid?: Maybe<FkVersionCatalogInverseInput>;
  readonly offersUsingOid?: Maybe<FkOfferCatalogInverseInput>;
  readonly categoriesUsingOid?: Maybe<FkCategoryCatalogInverseInput>;
  readonly feedsUsingOid?: Maybe<FkFeedCatalogInverseInput>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCatalogInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCatalogInverseInput>;
  readonly productsUsingOid?: Maybe<FkProductCatalogInverseInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCatalogInverseInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingCatalogInverseInput>;
  readonly categoryByCatalogsUsingOid?: Maybe<FakePublicCategoryByCatalogForeignKey0InverseInput>;
};

/** Input for the nested mutation of `catalog` in the `ProductInput` mutation. */
export type FkProductCatalogInput = {
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly connectByOid?: Maybe<CatalogPkCatalogConnect>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly connectById?: Maybe<CatalogCatalogByIdConnect>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<CatalogPkCatalogDelete>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly deleteById?: Maybe<CatalogCatalogByIdDelete>;
  /** The primary key(s) and patch data for `catalog` for the far side of the relationship. */
  readonly updateByOid?: Maybe<CatalogOnProductForFkProductCatalogUsingPkCatalogUpdate>;
  /** The primary key(s) and patch data for `catalog` for the far side of the relationship. */
  readonly updateById?: Maybe<CatalogOnProductForFkProductCatalogUsingCatalogByIdUpdate>;
  /** A `CatalogInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FkProductCatalogCatalogCreateInput>;
};

/** Input for the nested mutation of `product` in the `CatalogInput` mutation. */
export type FkProductCatalogInverseInput = {
  /** Flag indicating whether all other `product` records that match this relationship should be removed. */
  readonly deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `product` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ReadonlyArray<ProductPkProductConnect>>;
  /** The primary key(s) for `product` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ReadonlyArray<ProductPkProductDelete>>;
  /** The primary key(s) and patch data for `product` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ReadonlyArray<ProductOnProductForFkProductCatalogUsingPkProductUpdate>>;
  /** A `ProductInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FkProductCatalogProductCreateInput>>;
};

/** The `product` to be created by this mutation. */
export type FkProductCatalogProductCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly brand?: Maybe<Scalars['String']>;
  readonly color?: Maybe<Scalars['String']>;
  readonly sku?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
  readonly tags?: Maybe<Scalars['String']>;
  readonly price?: Maybe<Scalars['Float']>;
  readonly dateEntered?: Maybe<Scalars['Datetime']>;
  readonly srcOriginal?: Maybe<Scalars['String']>;
  readonly additionalImages?: Maybe<Scalars['JSON']>;
  readonly additionalFields?: Maybe<Scalars['JSON']>;
  readonly changeHistory?: Maybe<Scalars['JSON']>;
  readonly dateModified?: Maybe<Scalars['Datetime']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly subCategory?: Maybe<Scalars['String']>;
  readonly salePrice?: Maybe<Scalars['Float']>;
  readonly category?: Maybe<Scalars['String']>;
  readonly expired?: Maybe<Scalars['Boolean']>;
  readonly src?: Maybe<Scalars['String']>;
  readonly rotated?: Maybe<Scalars['Boolean']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly catalogToCatalog?: Maybe<FkProductCatalogInput>;
  readonly categoryByCatalogToCategoryAndCatalog?: Maybe<FakePublicProductForeignKey0Input>;
};

/** The `category` to be created by this mutation. */
export type FkSubCategoryCategoryCategoryCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly featured?: Maybe<Scalars['Int']>;
  readonly image?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly linkoutUrl?: Maybe<Scalars['String']>;
  readonly productsPerPage?: Maybe<Scalars['Int']>;
  readonly imageOrientation?: Maybe<Scalars['Boolean']>;
  readonly productBorder?: Maybe<Scalars['Boolean']>;
  readonly animated?: Maybe<Scalars['Boolean']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly rankOrder?: Maybe<Scalars['Int']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly catalogToCatalog?: Maybe<FkCategoryCatalogInput>;
  readonly subCategoriesUsingOid?: Maybe<FkSubCategoryCategoryInverseInput>;
  readonly subProductsUsingNameAndCatalog?: Maybe<FakePublicSubProductsForeignKey0InverseInput>;
};

/** Input for the nested mutation of `category` in the `SubCategoryInput` mutation. */
export type FkSubCategoryCategoryInput = {
  /** The primary key(s) for `category` for the far side of the relationship. */
  readonly connectByOid?: Maybe<CategoryPkCategoryConnect>;
  /** The primary key(s) for `category` for the far side of the relationship. */
  readonly connectById?: Maybe<CategoryCategoryByIdConnect>;
  /** The primary key(s) for `category` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<CategoryPkCategoryDelete>;
  /** The primary key(s) for `category` for the far side of the relationship. */
  readonly deleteById?: Maybe<CategoryCategoryByIdDelete>;
  /** The primary key(s) and patch data for `category` for the far side of the relationship. */
  readonly updateByOid?: Maybe<CategoryOnSubCategoryForFkSubCategoryCategoryUsingPkCategoryUpdate>;
  /** The primary key(s) and patch data for `category` for the far side of the relationship. */
  readonly updateById?: Maybe<CategoryOnSubCategoryForFkSubCategoryCategoryUsingCategoryByIdUpdate>;
  /** A `CategoryInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FkSubCategoryCategoryCategoryCreateInput>;
};

/** Input for the nested mutation of `subCategory` in the `CategoryInput` mutation. */
export type FkSubCategoryCategoryInverseInput = {
  /** Flag indicating whether all other `subCategory` records that match this relationship should be removed. */
  readonly deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `subCategory` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ReadonlyArray<SubCategoryPkSubCategoryConnect>>;
  /** The primary key(s) for `subCategory` for the far side of the relationship. */
  readonly connectById?: Maybe<ReadonlyArray<SubCategorySubCategoryByIdConnect>>;
  /** The primary key(s) for `subCategory` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ReadonlyArray<SubCategoryPkSubCategoryDelete>>;
  /** The primary key(s) for `subCategory` for the far side of the relationship. */
  readonly deleteById?: Maybe<ReadonlyArray<SubCategorySubCategoryByIdDelete>>;
  /** The primary key(s) and patch data for `subCategory` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ReadonlyArray<SubCategoryOnSubCategoryForFkSubCategoryCategoryUsingPkSubCategoryUpdate>>;
  /** The primary key(s) and patch data for `subCategory` for the far side of the relationship. */
  readonly updateById?: Maybe<ReadonlyArray<SubCategoryOnSubCategoryForFkSubCategoryCategoryUsingSubCategoryByIdUpdate>>;
  /** A `SubCategoryInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FkSubCategoryCategorySubCategoryCreateInput>>;
};

/** The `subCategory` to be created by this mutation. */
export type FkSubCategoryCategorySubCategoryCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly featured?: Maybe<Scalars['Int']>;
  readonly image?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly linkoutUrl?: Maybe<Scalars['String']>;
  readonly productsPerPage?: Maybe<Scalars['Int']>;
  readonly imageOrientation?: Maybe<Scalars['Boolean']>;
  readonly productBorder?: Maybe<Scalars['Boolean']>;
  readonly animated?: Maybe<Scalars['Boolean']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly categoryToCategory?: Maybe<FkSubCategoryCategoryInput>;
};

/** The `catalog` to be created by this mutation. */
export type FkSubscriptionCatalogCatalogCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly cover?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly catalogFormat?: Maybe<Scalars['String']>;
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly leadCharge?: Maybe<Scalars['Float']>;
  readonly linkCharge?: Maybe<Scalars['Float']>;
  readonly digitalFee?: Maybe<Scalars['Float']>;
  readonly listingFee?: Maybe<Scalars['Float']>;
  readonly dateExpired?: Maybe<Scalars['Datetime']>;
  readonly trackingCode?: Maybe<Scalars['String']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly showCategoryLinks?: Maybe<Scalars['Boolean']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Datetime']>;
  readonly offerCopy?: Maybe<Scalars['String']>;
  readonly introCopy?: Maybe<Scalars['String']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  readonly blurb?: Maybe<Scalars['String']>;
  readonly featureCopy?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly keywords?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly longDescription?: Maybe<Scalars['String']>;
  readonly class?: Maybe<Scalars['String']>;
  readonly searchAppendCode?: Maybe<Scalars['String']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly primaryDepartment?: Maybe<Scalars['UUID']>;
  readonly isDigital?: Maybe<Scalars['Boolean']>;
  readonly merchantId?: Maybe<Scalars['BigInt']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly autoActivate?: Maybe<Scalars['Boolean']>;
  readonly groupHubspotId?: Maybe<Scalars['String']>;
  readonly customSubdomain?: Maybe<Scalars['String']>;
  readonly text2Disclaimer?: Maybe<Scalars['String']>;
  readonly activeVersion?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly collectFullAddress?: Maybe<Scalars['Boolean']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly freeShipping?: Maybe<Scalars['Boolean']>;
  readonly mailOrder?: Maybe<Scalars['Boolean']>;
  readonly collectLeadQr?: Maybe<Scalars['Boolean']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly hideAds?: Maybe<Scalars['Boolean']>;
  readonly accountToAccount?: Maybe<FkCatalogAccountInput>;
  readonly departmentToPrimaryDepartment?: Maybe<FkCatalogPrimaryDepartmentInput>;
  readonly versionToActiveVersion?: Maybe<FkCatalogActiveVersionInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInverseInput>;
  readonly versionsUsingOid?: Maybe<FkVersionCatalogInverseInput>;
  readonly offersUsingOid?: Maybe<FkOfferCatalogInverseInput>;
  readonly categoriesUsingOid?: Maybe<FkCategoryCatalogInverseInput>;
  readonly feedsUsingOid?: Maybe<FkFeedCatalogInverseInput>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCatalogInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCatalogInverseInput>;
  readonly productsUsingOid?: Maybe<FkProductCatalogInverseInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCatalogInverseInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingCatalogInverseInput>;
  readonly categoryByCatalogsUsingOid?: Maybe<FakePublicCategoryByCatalogForeignKey0InverseInput>;
};

/** Input for the nested mutation of `catalog` in the `SubscriptionInput` mutation. */
export type FkSubscriptionCatalogInput = {
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly connectByOid?: Maybe<CatalogPkCatalogConnect>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly connectById?: Maybe<CatalogCatalogByIdConnect>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<CatalogPkCatalogDelete>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly deleteById?: Maybe<CatalogCatalogByIdDelete>;
  /** The primary key(s) and patch data for `catalog` for the far side of the relationship. */
  readonly updateByOid?: Maybe<CatalogOnSubscriptionForFkSubscriptionCatalogUsingPkCatalogUpdate>;
  /** The primary key(s) and patch data for `catalog` for the far side of the relationship. */
  readonly updateById?: Maybe<CatalogOnSubscriptionForFkSubscriptionCatalogUsingCatalogByIdUpdate>;
  /** A `CatalogInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FkSubscriptionCatalogCatalogCreateInput>;
};

/** Input for the nested mutation of `subscription` in the `CatalogInput` mutation. */
export type FkSubscriptionCatalogInverseInput = {
  /** Flag indicating whether all other `subscription` records that match this relationship should be removed. */
  readonly deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `subscription` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ReadonlyArray<SubscriptionPkSubscriptionConnect>>;
  /** The primary key(s) for `subscription` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ReadonlyArray<SubscriptionPkSubscriptionDelete>>;
  /** The primary key(s) and patch data for `subscription` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ReadonlyArray<SubscriptionOnSubscriptionForFkSubscriptionCatalogUsingPkSubscriptionUpdate>>;
  /** A `SubscriptionInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FkSubscriptionCatalogSubscriptionCreateInput>>;
};

/** The `subscription` to be created by this mutation. */
export type FkSubscriptionCatalogSubscriptionCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['UUID']>;
  readonly date?: Maybe<Scalars['Datetime']>;
  readonly charge?: Maybe<Scalars['Float']>;
  readonly listingId?: Maybe<Scalars['Int']>;
  readonly exportStatus?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly profileToCustomer?: Maybe<FkSubscriptionCustomerInput>;
  readonly catalogToCatalog?: Maybe<FkSubscriptionCatalogInput>;
};

/** Input for the nested mutation of `profile` in the `SubscriptionInput` mutation. */
export type FkSubscriptionCustomerInput = {
  /** The primary key(s) for `profile` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ProfilePkProfileConnect>;
  /** The primary key(s) for `profile` for the far side of the relationship. */
  readonly connectByEmail?: Maybe<ProfileIEmailProfileConnect>;
  /** The primary key(s) for `profile` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ProfilePkProfileDelete>;
  /** The primary key(s) for `profile` for the far side of the relationship. */
  readonly deleteByEmail?: Maybe<ProfileIEmailProfileDelete>;
  /** The primary key(s) and patch data for `profile` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ProfileOnSubscriptionForFkSubscriptionCustomerUsingPkProfileUpdate>;
  /** The primary key(s) and patch data for `profile` for the far side of the relationship. */
  readonly updateByEmail?: Maybe<ProfileOnSubscriptionForFkSubscriptionCustomerUsingIEmailProfileUpdate>;
  /** A `ProfileInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FkSubscriptionCustomerProfileCreateInput>;
};

/** Input for the nested mutation of `subscription` in the `ProfileInput` mutation. */
export type FkSubscriptionCustomerInverseInput = {
  /** Flag indicating whether all other `subscription` records that match this relationship should be removed. */
  readonly deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `subscription` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ReadonlyArray<SubscriptionPkSubscriptionConnect>>;
  /** The primary key(s) for `subscription` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ReadonlyArray<SubscriptionPkSubscriptionDelete>>;
  /** The primary key(s) and patch data for `subscription` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ReadonlyArray<SubscriptionOnSubscriptionForFkSubscriptionCustomerUsingPkSubscriptionUpdate>>;
  /** A `SubscriptionInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FkSubscriptionCustomerSubscriptionCreateInput>>;
};

/** The `profile` to be created by this mutation. */
export type FkSubscriptionCustomerProfileCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly company?: Maybe<Scalars['String']>;
  readonly dateCreated?: Maybe<Scalars['Datetime']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCustomerInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCustomerInverseInput>;
  readonly leadsUsingOid?: Maybe<FkLeadProfileInverseInput>;
  readonly authorizedUserUsingEmail?: Maybe<FakePublicAuthorizedUserForeignKey0InverseInput>;
};

/** The `subscription` to be created by this mutation. */
export type FkSubscriptionCustomerSubscriptionCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly date?: Maybe<Scalars['Datetime']>;
  readonly charge?: Maybe<Scalars['Float']>;
  readonly listingId?: Maybe<Scalars['Int']>;
  readonly exportStatus?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly profileToCustomer?: Maybe<FkSubscriptionCustomerInput>;
  readonly catalogToCatalog?: Maybe<FkSubscriptionCatalogInput>;
};

/** The `catalog` to be created by this mutation. */
export type FkVersionCatalogCatalogCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly cover?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly catalogFormat?: Maybe<Scalars['String']>;
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly leadCharge?: Maybe<Scalars['Float']>;
  readonly linkCharge?: Maybe<Scalars['Float']>;
  readonly digitalFee?: Maybe<Scalars['Float']>;
  readonly listingFee?: Maybe<Scalars['Float']>;
  readonly dateExpired?: Maybe<Scalars['Datetime']>;
  readonly trackingCode?: Maybe<Scalars['String']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly showCategoryLinks?: Maybe<Scalars['Boolean']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Datetime']>;
  readonly offerCopy?: Maybe<Scalars['String']>;
  readonly introCopy?: Maybe<Scalars['String']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  readonly blurb?: Maybe<Scalars['String']>;
  readonly featureCopy?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly keywords?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly longDescription?: Maybe<Scalars['String']>;
  readonly class?: Maybe<Scalars['String']>;
  readonly searchAppendCode?: Maybe<Scalars['String']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly primaryDepartment?: Maybe<Scalars['UUID']>;
  readonly isDigital?: Maybe<Scalars['Boolean']>;
  readonly merchantId?: Maybe<Scalars['BigInt']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly autoActivate?: Maybe<Scalars['Boolean']>;
  readonly groupHubspotId?: Maybe<Scalars['String']>;
  readonly customSubdomain?: Maybe<Scalars['String']>;
  readonly text2Disclaimer?: Maybe<Scalars['String']>;
  readonly activeVersion?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly collectFullAddress?: Maybe<Scalars['Boolean']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly freeShipping?: Maybe<Scalars['Boolean']>;
  readonly mailOrder?: Maybe<Scalars['Boolean']>;
  readonly collectLeadQr?: Maybe<Scalars['Boolean']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly hideAds?: Maybe<Scalars['Boolean']>;
  readonly accountToAccount?: Maybe<FkCatalogAccountInput>;
  readonly departmentToPrimaryDepartment?: Maybe<FkCatalogPrimaryDepartmentInput>;
  readonly versionToActiveVersion?: Maybe<FkCatalogActiveVersionInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInverseInput>;
  readonly versionsUsingOid?: Maybe<FkVersionCatalogInverseInput>;
  readonly offersUsingOid?: Maybe<FkOfferCatalogInverseInput>;
  readonly categoriesUsingOid?: Maybe<FkCategoryCatalogInverseInput>;
  readonly feedsUsingOid?: Maybe<FkFeedCatalogInverseInput>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCatalogInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCatalogInverseInput>;
  readonly productsUsingOid?: Maybe<FkProductCatalogInverseInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCatalogInverseInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingCatalogInverseInput>;
  readonly categoryByCatalogsUsingOid?: Maybe<FakePublicCategoryByCatalogForeignKey0InverseInput>;
};

/** Input for the nested mutation of `catalog` in the `VersionInput` mutation. */
export type FkVersionCatalogInput = {
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly connectByOid?: Maybe<CatalogPkCatalogConnect>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly connectById?: Maybe<CatalogCatalogByIdConnect>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<CatalogPkCatalogDelete>;
  /** The primary key(s) for `catalog` for the far side of the relationship. */
  readonly deleteById?: Maybe<CatalogCatalogByIdDelete>;
  /** The primary key(s) and patch data for `catalog` for the far side of the relationship. */
  readonly updateByOid?: Maybe<CatalogOnVersionForFkVersionCatalogUsingPkCatalogUpdate>;
  /** The primary key(s) and patch data for `catalog` for the far side of the relationship. */
  readonly updateById?: Maybe<CatalogOnVersionForFkVersionCatalogUsingCatalogByIdUpdate>;
  /** A `CatalogInput` object that will be created and connected to this object. */
  readonly create?: Maybe<FkVersionCatalogCatalogCreateInput>;
};

/** Input for the nested mutation of `version` in the `CatalogInput` mutation. */
export type FkVersionCatalogInverseInput = {
  /** Flag indicating whether all other `version` records that match this relationship should be removed. */
  readonly deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `version` for the far side of the relationship. */
  readonly connectByOid?: Maybe<ReadonlyArray<VersionPkVersionConnect>>;
  /** The primary key(s) for `version` for the far side of the relationship. */
  readonly connectById?: Maybe<ReadonlyArray<VersionVersionByIdConnect>>;
  /** The primary key(s) for `version` for the far side of the relationship. */
  readonly deleteByOid?: Maybe<ReadonlyArray<VersionPkVersionDelete>>;
  /** The primary key(s) for `version` for the far side of the relationship. */
  readonly deleteById?: Maybe<ReadonlyArray<VersionVersionByIdDelete>>;
  /** The primary key(s) and patch data for `version` for the far side of the relationship. */
  readonly updateByOid?: Maybe<ReadonlyArray<VersionOnVersionForFkVersionCatalogUsingPkVersionUpdate>>;
  /** The primary key(s) and patch data for `version` for the far side of the relationship. */
  readonly updateById?: Maybe<ReadonlyArray<VersionOnVersionForFkVersionCatalogUsingVersionByIdUpdate>>;
  /** A `VersionInput` object that will be created and connected to this object. */
  readonly create?: Maybe<ReadonlyArray<FkVersionCatalogVersionCreateInput>>;
};

/** The `version` to be created by this mutation. */
export type FkVersionCatalogVersionCreateInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly coverUrl?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly coverMeta?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
  readonly dateCreated?: Maybe<Scalars['Datetime']>;
  readonly dateModified?: Maybe<Scalars['Datetime']>;
  readonly versionNo?: Maybe<Scalars['Int']>;
  readonly autoProcess?: Maybe<Scalars['Boolean']>;
  readonly contactUrl?: Maybe<Scalars['String']>;
  readonly enableCategoryCatalogs?: Maybe<Scalars['Boolean']>;
  readonly language?: Maybe<Scalars['String']>;
  readonly currencySymbol?: Maybe<Scalars['String']>;
  readonly leadZapEmail?: Maybe<Scalars['String']>;
  readonly agentId?: Maybe<Scalars['String']>;
  readonly shortName?: Maybe<Scalars['String']>;
  readonly isFtp?: Maybe<Scalars['Boolean']>;
  readonly catalogCloneId?: Maybe<Scalars['BigInt']>;
  readonly feedAlertNotifcation?: Maybe<Scalars['Datetime']>;
  readonly order?: Maybe<Scalars['JSON']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly catalogToCatalog?: Maybe<FkVersionCatalogInput>;
  readonly catalogsUsingOid?: Maybe<FkCatalogActiveVersionInverseInput>;
  readonly pagesUsingOid?: Maybe<FkPageVersionInverseInput>;
};

/** A filter to be used against Float fields. All fields are combined with a logical ‘and.’ */
export type FloatFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  readonly isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  readonly equalTo?: Maybe<Scalars['Float']>;
  /** Not equal to the specified value. */
  readonly notEqualTo?: Maybe<Scalars['Float']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  readonly distinctFrom?: Maybe<Scalars['Float']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  readonly notDistinctFrom?: Maybe<Scalars['Float']>;
  /** Included in the specified list. */
  readonly in?: Maybe<ReadonlyArray<Scalars['Float']>>;
  /** Not included in the specified list. */
  readonly notIn?: Maybe<ReadonlyArray<Scalars['Float']>>;
  /** Less than the specified value. */
  readonly lessThan?: Maybe<Scalars['Float']>;
  /** Less than or equal to the specified value. */
  readonly lessThanOrEqualTo?: Maybe<Scalars['Float']>;
  /** Greater than the specified value. */
  readonly greaterThan?: Maybe<Scalars['Float']>;
  /** Greater than or equal to the specified value. */
  readonly greaterThanOrEqualTo?: Maybe<Scalars['Float']>;
};

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  readonly isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  readonly equalTo?: Maybe<Scalars['Int']>;
  /** Not equal to the specified value. */
  readonly notEqualTo?: Maybe<Scalars['Int']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  readonly distinctFrom?: Maybe<Scalars['Int']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  readonly notDistinctFrom?: Maybe<Scalars['Int']>;
  /** Included in the specified list. */
  readonly in?: Maybe<ReadonlyArray<Scalars['Int']>>;
  /** Not included in the specified list. */
  readonly notIn?: Maybe<ReadonlyArray<Scalars['Int']>>;
  /** Less than the specified value. */
  readonly lessThan?: Maybe<Scalars['Int']>;
  /** Less than or equal to the specified value. */
  readonly lessThanOrEqualTo?: Maybe<Scalars['Int']>;
  /** Greater than the specified value. */
  readonly greaterThan?: Maybe<Scalars['Int']>;
  /** Greater than or equal to the specified value. */
  readonly greaterThanOrEqualTo?: Maybe<Scalars['Int']>;
};


export type Lead = {
  readonly __typename?: 'Lead';
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly company?: Maybe<Scalars['String']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly dateCollected?: Maybe<Scalars['Datetime']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly profile?: Maybe<Scalars['UUID']>;
  readonly catalogId?: Maybe<Scalars['Int']>;
  readonly versionId?: Maybe<Scalars['Int']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  /** Reads a single `Account` that is related to this `Lead`. */
  readonly accountByAccount?: Maybe<Account>;
  /** Reads a single `Profile` that is related to this `Lead`. */
  readonly profileByProfile?: Maybe<Profile>;
  /** Reads and enables pagination through a set of `CampaignUser`. */
  readonly campaignUsersByLeadConnection: CampaignUsersConnection;
  /** Reads and enables pagination through a set of `CampaignUser`. */
  readonly campaignUsersByLead: ReadonlyArray<CampaignUser>;
  /** Reads and enables pagination through a set of `CampaignGroup`. */
  readonly campaignGroupsByCampaignUserLeadAndCampaignGroup: LeadCampaignGroupsByCampaignUserLeadAndCampaignGroupManyToManyConnection;
  /** Reads and enables pagination through a set of `CampaignGroup`. */
  readonly campaignGroupsByCampaignUserLeadAndCampaignGroupList: ReadonlyArray<CampaignGroup>;
};


export type LeadCampaignUsersByLeadConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CampaignUsersOrderBy>>;
  condition?: Maybe<CampaignUserCondition>;
  filter?: Maybe<CampaignUserFilter>;
};


export type LeadCampaignUsersByLeadArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CampaignUsersOrderBy>>;
  condition?: Maybe<CampaignUserCondition>;
  filter?: Maybe<CampaignUserFilter>;
};


export type LeadCampaignGroupsByCampaignUserLeadAndCampaignGroupArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CampaignGroupsOrderBy>>;
  condition?: Maybe<CampaignGroupCondition>;
  filter?: Maybe<CampaignGroupFilter>;
};


export type LeadCampaignGroupsByCampaignUserLeadAndCampaignGroupListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CampaignGroupsOrderBy>>;
  condition?: Maybe<CampaignGroupCondition>;
  filter?: Maybe<CampaignGroupFilter>;
};

/** A connection to a list of `CampaignGroup` values, with data from `CampaignUser`. */
export type LeadCampaignGroupsByCampaignUserLeadAndCampaignGroupManyToManyConnection = {
  readonly __typename?: 'LeadCampaignGroupsByCampaignUserLeadAndCampaignGroupManyToManyConnection';
  /** A list of `CampaignGroup` objects. */
  readonly nodes: ReadonlyArray<CampaignGroup>;
  /** A list of edges which contains the `CampaignGroup`, info from the `CampaignUser`, and the cursor to aid in pagination. */
  readonly edges: ReadonlyArray<LeadCampaignGroupsByCampaignUserLeadAndCampaignGroupManyToManyEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `CampaignGroup` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `CampaignGroup` edge in the connection, with data from `CampaignUser`. */
export type LeadCampaignGroupsByCampaignUserLeadAndCampaignGroupManyToManyEdge = {
  readonly __typename?: 'LeadCampaignGroupsByCampaignUserLeadAndCampaignGroupManyToManyEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `CampaignGroup` at the end of the edge. */
  readonly node: CampaignGroup;
  /** Reads and enables pagination through a set of `CampaignUser`. */
  readonly campaignUsersByCampaignGroupConnection: CampaignUsersConnection;
  /** Reads and enables pagination through a set of `CampaignUser`. */
  readonly campaignUsersByCampaignGroup: ReadonlyArray<CampaignUser>;
};


/** A `CampaignGroup` edge in the connection, with data from `CampaignUser`. */
export type LeadCampaignGroupsByCampaignUserLeadAndCampaignGroupManyToManyEdgeCampaignUsersByCampaignGroupConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CampaignUsersOrderBy>>;
  condition?: Maybe<CampaignUserCondition>;
  filter?: Maybe<CampaignUserFilter>;
};


/** A `CampaignGroup` edge in the connection, with data from `CampaignUser`. */
export type LeadCampaignGroupsByCampaignUserLeadAndCampaignGroupManyToManyEdgeCampaignUsersByCampaignGroupArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CampaignUsersOrderBy>>;
  condition?: Maybe<CampaignUserCondition>;
  filter?: Maybe<CampaignUserFilter>;
};

/** A condition to be used against `Lead` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type LeadCondition = {
  /** Checks for equality with the object’s `oid` field. */
  readonly oid?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `options` field. */
  readonly options?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `imageUrl` field. */
  readonly imageUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `email` field. */
  readonly email?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `phone` field. */
  readonly phone?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `street` field. */
  readonly street?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `city` field. */
  readonly city?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `state` field. */
  readonly state?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `zipPostal` field. */
  readonly zipPostal?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `country` field. */
  readonly country?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `title` field. */
  readonly title?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `firstName` field. */
  readonly firstName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `lastName` field. */
  readonly lastName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `company` field. */
  readonly company?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `comments` field. */
  readonly comments?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `dateCollected` field. */
  readonly dateCollected?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `account` field. */
  readonly account?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `profile` field. */
  readonly profile?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `catalogId` field. */
  readonly catalogId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `versionId` field. */
  readonly versionId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `Lead` object types. All fields are combined with a logical ‘and.’ */
export type LeadFilter = {
  /** Filter by the object’s `oid` field. */
  readonly oid?: Maybe<UuidFilter>;
  /** Filter by the object’s `imageUrl` field. */
  readonly imageUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `email` field. */
  readonly email?: Maybe<StringFilter>;
  /** Filter by the object’s `phone` field. */
  readonly phone?: Maybe<StringFilter>;
  /** Filter by the object’s `street` field. */
  readonly street?: Maybe<StringFilter>;
  /** Filter by the object’s `city` field. */
  readonly city?: Maybe<StringFilter>;
  /** Filter by the object’s `state` field. */
  readonly state?: Maybe<StringFilter>;
  /** Filter by the object’s `zipPostal` field. */
  readonly zipPostal?: Maybe<StringFilter>;
  /** Filter by the object’s `country` field. */
  readonly country?: Maybe<StringFilter>;
  /** Filter by the object’s `title` field. */
  readonly title?: Maybe<StringFilter>;
  /** Filter by the object’s `firstName` field. */
  readonly firstName?: Maybe<StringFilter>;
  /** Filter by the object’s `lastName` field. */
  readonly lastName?: Maybe<StringFilter>;
  /** Filter by the object’s `company` field. */
  readonly company?: Maybe<StringFilter>;
  /** Filter by the object’s `comments` field. */
  readonly comments?: Maybe<StringFilter>;
  /** Filter by the object’s `dateCollected` field. */
  readonly dateCollected?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `account` field. */
  readonly account?: Maybe<UuidFilter>;
  /** Filter by the object’s `profile` field. */
  readonly profile?: Maybe<UuidFilter>;
  /** Filter by the object’s `catalogId` field. */
  readonly catalogId?: Maybe<IntFilter>;
  /** Filter by the object’s `versionId` field. */
  readonly versionId?: Maybe<IntFilter>;
  /** Filter by the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<IntFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<ReadonlyArray<LeadFilter>>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<ReadonlyArray<LeadFilter>>;
  /** Negates the expression. */
  readonly not?: Maybe<LeadFilter>;
};

/** An input for mutations affecting `Lead` */
export type LeadInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly company?: Maybe<Scalars['String']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly dateCollected?: Maybe<Scalars['Datetime']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly profile?: Maybe<Scalars['UUID']>;
  readonly catalogId?: Maybe<Scalars['Int']>;
  readonly versionId?: Maybe<Scalars['Int']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly accountToAccount?: Maybe<FkLeadAccountInput>;
  readonly profileToProfile?: Maybe<FkLeadProfileInput>;
  readonly campaignUsersUsingOid?: Maybe<FkCampaignUserLeadInverseInput>;
};

/** The fields on `lead` to look up the row to update. */
export type LeadOnCampaignUserForFkCampaignUserLeadUsingPkLeadUpdate = {
  /** An object where the defined keys will be set on the `lead` being updated. */
  readonly patch: UpdateLeadOnCampaignUserForFkCampaignUserLeadPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `lead` to look up the row to update. */
export type LeadOnLeadForFkLeadAccountUsingPkLeadUpdate = {
  /** An object where the defined keys will be set on the `lead` being updated. */
  readonly patch: UpdateLeadOnLeadForFkLeadAccountPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `lead` to look up the row to update. */
export type LeadOnLeadForFkLeadProfileUsingPkLeadUpdate = {
  /** An object where the defined keys will be set on the `lead` being updated. */
  readonly patch: UpdateLeadOnLeadForFkLeadProfilePatch;
  readonly oid: Scalars['UUID'];
};

/** Represents an update to a `Lead`. Fields that are set will be updated. */
export type LeadPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly company?: Maybe<Scalars['String']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly dateCollected?: Maybe<Scalars['Datetime']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly profile?: Maybe<Scalars['UUID']>;
  readonly catalogId?: Maybe<Scalars['Int']>;
  readonly versionId?: Maybe<Scalars['Int']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly accountToAccount?: Maybe<FkLeadAccountInput>;
  readonly profileToProfile?: Maybe<FkLeadProfileInput>;
  readonly campaignUsersUsingOid?: Maybe<FkCampaignUserLeadInverseInput>;
};

/** The fields on `lead` to look up the row to connect. */
export type LeadPkLeadConnect = {
  readonly oid: Scalars['UUID'];
};

/** The fields on `lead` to look up the row to delete. */
export type LeadPkLeadDelete = {
  readonly oid: Scalars['UUID'];
};

/** A connection to a list of `Lead` values. */
export type LeadsConnection = {
  readonly __typename?: 'LeadsConnection';
  /** A list of `Lead` objects. */
  readonly nodes: ReadonlyArray<Lead>;
  /** A list of edges which contains the `Lead` and cursor to aid in pagination. */
  readonly edges: ReadonlyArray<LeadsEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Lead` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Lead` edge in the connection. */
export type LeadsEdge = {
  readonly __typename?: 'LeadsEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Lead` at the end of the edge. */
  readonly node: Lead;
};

/** Methods to use when ordering `Lead`. */
export enum LeadsOrderBy {
  Natural = 'NATURAL',
  OidAsc = 'OID_ASC',
  OidDesc = 'OID_DESC',
  OptionsAsc = 'OPTIONS_ASC',
  OptionsDesc = 'OPTIONS_DESC',
  ImageUrlAsc = 'IMAGE_URL_ASC',
  ImageUrlDesc = 'IMAGE_URL_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  PhoneAsc = 'PHONE_ASC',
  PhoneDesc = 'PHONE_DESC',
  StreetAsc = 'STREET_ASC',
  StreetDesc = 'STREET_DESC',
  CityAsc = 'CITY_ASC',
  CityDesc = 'CITY_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  ZipPostalAsc = 'ZIP_POSTAL_ASC',
  ZipPostalDesc = 'ZIP_POSTAL_DESC',
  CountryAsc = 'COUNTRY_ASC',
  CountryDesc = 'COUNTRY_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  CompanyAsc = 'COMPANY_ASC',
  CompanyDesc = 'COMPANY_DESC',
  CommentsAsc = 'COMMENTS_ASC',
  CommentsDesc = 'COMMENTS_DESC',
  DateCollectedAsc = 'DATE_COLLECTED_ASC',
  DateCollectedDesc = 'DATE_COLLECTED_DESC',
  AccountAsc = 'ACCOUNT_ASC',
  AccountDesc = 'ACCOUNT_DESC',
  ProfileAsc = 'PROFILE_ASC',
  ProfileDesc = 'PROFILE_DESC',
  CatalogIdAsc = 'CATALOG_ID_ASC',
  CatalogIdDesc = 'CATALOG_ID_DESC',
  VersionIdAsc = 'VERSION_ID_ASC',
  VersionIdDesc = 'VERSION_ID_DESC',
  OptimisticLockFieldAsc = 'OPTIMISTIC_LOCK_FIELD_ASC',
  OptimisticLockFieldDesc = 'OPTIMISTIC_LOCK_FIELD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type LinkOut = {
  readonly __typename?: 'LinkOut';
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly linkTime?: Maybe<Scalars['Datetime']>;
  readonly link?: Maybe<Scalars['String']>;
  readonly charge?: Maybe<Scalars['Float']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly customerIp?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['UUID']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  /** Reads a single `Profile` that is related to this `LinkOut`. */
  readonly profileByCustomer?: Maybe<Profile>;
  /** Reads a single `Catalog` that is related to this `LinkOut`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
};

/** A condition to be used against `LinkOut` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type LinkOutCondition = {
  /** Checks for equality with the object’s `oid` field. */
  readonly oid?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `options` field. */
  readonly options?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `linkTime` field. */
  readonly linkTime?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `link` field. */
  readonly link?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `charge` field. */
  readonly charge?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `type` field. */
  readonly type?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `customerIp` field. */
  readonly customerIp?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `customer` field. */
  readonly customer?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `catalog` field. */
  readonly catalog?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `LinkOut` object types. All fields are combined with a logical ‘and.’ */
export type LinkOutFilter = {
  /** Filter by the object’s `oid` field. */
  readonly oid?: Maybe<UuidFilter>;
  /** Filter by the object’s `linkTime` field. */
  readonly linkTime?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `link` field. */
  readonly link?: Maybe<StringFilter>;
  /** Filter by the object’s `charge` field. */
  readonly charge?: Maybe<FloatFilter>;
  /** Filter by the object’s `type` field. */
  readonly type?: Maybe<StringFilter>;
  /** Filter by the object’s `customerIp` field. */
  readonly customerIp?: Maybe<StringFilter>;
  /** Filter by the object’s `customer` field. */
  readonly customer?: Maybe<UuidFilter>;
  /** Filter by the object’s `catalog` field. */
  readonly catalog?: Maybe<UuidFilter>;
  /** Filter by the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<IntFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<ReadonlyArray<LinkOutFilter>>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<ReadonlyArray<LinkOutFilter>>;
  /** Negates the expression. */
  readonly not?: Maybe<LinkOutFilter>;
};

/** An input for mutations affecting `LinkOut` */
export type LinkOutInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly linkTime?: Maybe<Scalars['Datetime']>;
  readonly link?: Maybe<Scalars['String']>;
  readonly charge?: Maybe<Scalars['Float']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly customerIp?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['UUID']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly profileToCustomer?: Maybe<FkLinkOutCustomerInput>;
  readonly catalogToCatalog?: Maybe<FkLinkOutCatalogInput>;
};

/** The fields on `linkOut` to look up the row to update. */
export type LinkOutOnLinkOutForFkLinkOutCatalogUsingPkLinkOutUpdate = {
  /** An object where the defined keys will be set on the `linkOut` being updated. */
  readonly patch: UpdateLinkOutOnLinkOutForFkLinkOutCatalogPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `linkOut` to look up the row to update. */
export type LinkOutOnLinkOutForFkLinkOutCustomerUsingPkLinkOutUpdate = {
  /** An object where the defined keys will be set on the `linkOut` being updated. */
  readonly patch: UpdateLinkOutOnLinkOutForFkLinkOutCustomerPatch;
  readonly oid: Scalars['UUID'];
};

/** Represents an update to a `LinkOut`. Fields that are set will be updated. */
export type LinkOutPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly linkTime?: Maybe<Scalars['Datetime']>;
  readonly link?: Maybe<Scalars['String']>;
  readonly charge?: Maybe<Scalars['Float']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly customerIp?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['UUID']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly profileToCustomer?: Maybe<FkLinkOutCustomerInput>;
  readonly catalogToCatalog?: Maybe<FkLinkOutCatalogInput>;
};

/** The fields on `linkOut` to look up the row to connect. */
export type LinkOutPkLinkOutConnect = {
  readonly oid: Scalars['UUID'];
};

/** The fields on `linkOut` to look up the row to delete. */
export type LinkOutPkLinkOutDelete = {
  readonly oid: Scalars['UUID'];
};

/** A connection to a list of `LinkOut` values. */
export type LinkOutsConnection = {
  readonly __typename?: 'LinkOutsConnection';
  /** A list of `LinkOut` objects. */
  readonly nodes: ReadonlyArray<LinkOut>;
  /** A list of edges which contains the `LinkOut` and cursor to aid in pagination. */
  readonly edges: ReadonlyArray<LinkOutsEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `LinkOut` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `LinkOut` edge in the connection. */
export type LinkOutsEdge = {
  readonly __typename?: 'LinkOutsEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `LinkOut` at the end of the edge. */
  readonly node: LinkOut;
};

/** Methods to use when ordering `LinkOut`. */
export enum LinkOutsOrderBy {
  Natural = 'NATURAL',
  OidAsc = 'OID_ASC',
  OidDesc = 'OID_DESC',
  OptionsAsc = 'OPTIONS_ASC',
  OptionsDesc = 'OPTIONS_DESC',
  LinkTimeAsc = 'LINK_TIME_ASC',
  LinkTimeDesc = 'LINK_TIME_DESC',
  LinkAsc = 'LINK_ASC',
  LinkDesc = 'LINK_DESC',
  ChargeAsc = 'CHARGE_ASC',
  ChargeDesc = 'CHARGE_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  CustomerIpAsc = 'CUSTOMER_IP_ASC',
  CustomerIpDesc = 'CUSTOMER_IP_DESC',
  CustomerAsc = 'CUSTOMER_ASC',
  CustomerDesc = 'CUSTOMER_DESC',
  CatalogAsc = 'CATALOG_ASC',
  CatalogDesc = 'CATALOG_DESC',
  OptimisticLockFieldAsc = 'OPTIMISTIC_LOCK_FIELD_ASC',
  OptimisticLockFieldDesc = 'OPTIMISTIC_LOCK_FIELD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  readonly __typename?: 'Mutation';
  /** Creates a single `Account`. */
  readonly createAccount?: Maybe<CreateAccountPayload>;
  /** Creates a single `AuthorizedUser`. */
  readonly createAuthorizedUser?: Maybe<CreateAuthorizedUserPayload>;
  /** Creates a single `AuthorizedUserAuthorizedUsersAccountPartnerAccess`. */
  readonly createAuthorizedUserAuthorizedUsersAccountPartnerAccess?: Maybe<CreateAuthorizedUserAuthorizedUsersAccountPartnerAccessPayload>;
  /** Creates a single `Campaign`. */
  readonly createCampaign?: Maybe<CreateCampaignPayload>;
  /** Creates a single `CampaignGroup`. */
  readonly createCampaignGroup?: Maybe<CreateCampaignGroupPayload>;
  /** Creates a single `CampaignUser`. */
  readonly createCampaignUser?: Maybe<CreateCampaignUserPayload>;
  /** Creates a single `Carousel`. */
  readonly createCarousel?: Maybe<CreateCarouselPayload>;
  /** Creates a single `CarouselListing`. */
  readonly createCarouselListing?: Maybe<CreateCarouselListingPayload>;
  /** Creates a single `Catalog`. */
  readonly createCatalog?: Maybe<CreateCatalogPayload>;
  /** Creates a single `Category`. */
  readonly createCategory?: Maybe<CreateCategoryPayload>;
  /** Creates a single `Contact`. */
  readonly createContact?: Maybe<CreateContactPayload>;
  /** Creates a single `Department`. */
  readonly createDepartment?: Maybe<CreateDepartmentPayload>;
  /** Creates a single `DepartmentDepartmentsCatalogListing`. */
  readonly createDepartmentDepartmentsCatalogListing?: Maybe<CreateDepartmentDepartmentsCatalogListingPayload>;
  /** Creates a single `Feed`. */
  readonly createFeed?: Maybe<CreateFeedPayload>;
  /** Creates a single `FeedHistory`. */
  readonly createFeedHistory?: Maybe<CreateFeedHistoryPayload>;
  /** Creates a single `Lead`. */
  readonly createLead?: Maybe<CreateLeadPayload>;
  /** Creates a single `LinkOut`. */
  readonly createLinkOut?: Maybe<CreateLinkOutPayload>;
  /** Creates a single `NavigationItem`. */
  readonly createNavigationItem?: Maybe<CreateNavigationItemPayload>;
  /** Creates a single `Offer`. */
  readonly createOffer?: Maybe<CreateOfferPayload>;
  /** Creates a single `Order`. */
  readonly createOrder?: Maybe<CreateOrderPayload>;
  /** Creates a single `OrderProduct`. */
  readonly createOrderProduct?: Maybe<CreateOrderProductPayload>;
  /** Creates a single `Page`. */
  readonly createPage?: Maybe<CreatePagePayload>;
  /** Creates a single `Product`. */
  readonly createProduct?: Maybe<CreateProductPayload>;
  /** Creates a single `Profile`. */
  readonly createProfile?: Maybe<CreateProfilePayload>;
  /** Creates a single `SubCategory`. */
  readonly createSubCategory?: Maybe<CreateSubCategoryPayload>;
  /** Creates a single `Subscription`. */
  readonly createSubscription?: Maybe<CreateSubscriptionPayload>;
  /** Creates a single `Version`. */
  readonly createVersion?: Maybe<CreateVersionPayload>;
  /** Creates a single `CatalogSearchLive`. */
  readonly createCatalogSearchLive?: Maybe<CreateCatalogSearchLivePayload>;
  /** Updates a single `Account` using a unique key and a patch. */
  readonly updateAccount?: Maybe<UpdateAccountPayload>;
  /** Updates a single `AuthorizedUser` using a unique key and a patch. */
  readonly updateAuthorizedUser?: Maybe<UpdateAuthorizedUserPayload>;
  /** Updates a single `AuthorizedUser` using a unique key and a patch. */
  readonly updateAuthorizedUserByEmail?: Maybe<UpdateAuthorizedUserPayload>;
  /** Updates a single `AuthorizedUserAuthorizedUsersAccountPartnerAccess` using a unique key and a patch. */
  readonly updateAuthorizedUserAuthorizedUsersAccountPartnerAccess?: Maybe<UpdateAuthorizedUserAuthorizedUsersAccountPartnerAccessPayload>;
  /** Updates a single `Campaign` using a unique key and a patch. */
  readonly updateCampaign?: Maybe<UpdateCampaignPayload>;
  /** Updates a single `CampaignGroup` using a unique key and a patch. */
  readonly updateCampaignGroup?: Maybe<UpdateCampaignGroupPayload>;
  /** Updates a single `CampaignUser` using a unique key and a patch. */
  readonly updateCampaignUser?: Maybe<UpdateCampaignUserPayload>;
  /** Updates a single `Carousel` using a unique key and a patch. */
  readonly updateCarousel?: Maybe<UpdateCarouselPayload>;
  /** Updates a single `CarouselListing` using a unique key and a patch. */
  readonly updateCarouselListing?: Maybe<UpdateCarouselListingPayload>;
  /** Updates a single `Catalog` using a unique key and a patch. */
  readonly updateCatalog?: Maybe<UpdateCatalogPayload>;
  /** Updates a single `Catalog` using a unique key and a patch. */
  readonly updateCatalogById?: Maybe<UpdateCatalogPayload>;
  /** Updates a single `Category` using a unique key and a patch. */
  readonly updateCategory?: Maybe<UpdateCategoryPayload>;
  /** Updates a single `Category` using a unique key and a patch. */
  readonly updateCategoryById?: Maybe<UpdateCategoryPayload>;
  /** Updates a single `Contact` using a unique key and a patch. */
  readonly updateContact?: Maybe<UpdateContactPayload>;
  /** Updates a single `Department` using a unique key and a patch. */
  readonly updateDepartment?: Maybe<UpdateDepartmentPayload>;
  /** Updates a single `DepartmentDepartmentsCatalogListing` using a unique key and a patch. */
  readonly updateDepartmentDepartmentsCatalogListing?: Maybe<UpdateDepartmentDepartmentsCatalogListingPayload>;
  /** Updates a single `Feed` using a unique key and a patch. */
  readonly updateFeed?: Maybe<UpdateFeedPayload>;
  /** Updates a single `FeedHistory` using a unique key and a patch. */
  readonly updateFeedHistory?: Maybe<UpdateFeedHistoryPayload>;
  /** Updates a single `Lead` using a unique key and a patch. */
  readonly updateLead?: Maybe<UpdateLeadPayload>;
  /** Updates a single `LinkOut` using a unique key and a patch. */
  readonly updateLinkOut?: Maybe<UpdateLinkOutPayload>;
  /** Updates a single `NavigationItem` using a unique key and a patch. */
  readonly updateNavigationItem?: Maybe<UpdateNavigationItemPayload>;
  /** Updates a single `Offer` using a unique key and a patch. */
  readonly updateOffer?: Maybe<UpdateOfferPayload>;
  /** Updates a single `Order` using a unique key and a patch. */
  readonly updateOrder?: Maybe<UpdateOrderPayload>;
  /** Updates a single `OrderProduct` using a unique key and a patch. */
  readonly updateOrderProduct?: Maybe<UpdateOrderProductPayload>;
  /** Updates a single `Page` using a unique key and a patch. */
  readonly updatePage?: Maybe<UpdatePagePayload>;
  /** Updates a single `Product` using a unique key and a patch. */
  readonly updateProduct?: Maybe<UpdateProductPayload>;
  /** Updates a single `Profile` using a unique key and a patch. */
  readonly updateProfile?: Maybe<UpdateProfilePayload>;
  /** Updates a single `Profile` using a unique key and a patch. */
  readonly updateProfileByEmail?: Maybe<UpdateProfilePayload>;
  /** Updates a single `SubCategory` using a unique key and a patch. */
  readonly updateSubCategory?: Maybe<UpdateSubCategoryPayload>;
  /** Updates a single `SubCategory` using a unique key and a patch. */
  readonly updateSubCategoryById?: Maybe<UpdateSubCategoryPayload>;
  /** Updates a single `Subscription` using a unique key and a patch. */
  readonly updateSubscription?: Maybe<UpdateSubscriptionPayload>;
  /** Updates a single `Version` using a unique key and a patch. */
  readonly updateVersion?: Maybe<UpdateVersionPayload>;
  /** Updates a single `Version` using a unique key and a patch. */
  readonly updateVersionById?: Maybe<UpdateVersionPayload>;
  /** Deletes a single `Account` using a unique key. */
  readonly deleteAccount?: Maybe<DeleteAccountPayload>;
  /** Deletes a single `AuthorizedUser` using a unique key. */
  readonly deleteAuthorizedUser?: Maybe<DeleteAuthorizedUserPayload>;
  /** Deletes a single `AuthorizedUser` using a unique key. */
  readonly deleteAuthorizedUserByEmail?: Maybe<DeleteAuthorizedUserPayload>;
  /** Deletes a single `AuthorizedUserAuthorizedUsersAccountPartnerAccess` using a unique key. */
  readonly deleteAuthorizedUserAuthorizedUsersAccountPartnerAccess?: Maybe<DeleteAuthorizedUserAuthorizedUsersAccountPartnerAccessPayload>;
  /** Deletes a single `Campaign` using a unique key. */
  readonly deleteCampaign?: Maybe<DeleteCampaignPayload>;
  /** Deletes a single `CampaignGroup` using a unique key. */
  readonly deleteCampaignGroup?: Maybe<DeleteCampaignGroupPayload>;
  /** Deletes a single `CampaignUser` using a unique key. */
  readonly deleteCampaignUser?: Maybe<DeleteCampaignUserPayload>;
  /** Deletes a single `Carousel` using a unique key. */
  readonly deleteCarousel?: Maybe<DeleteCarouselPayload>;
  /** Deletes a single `CarouselListing` using a unique key. */
  readonly deleteCarouselListing?: Maybe<DeleteCarouselListingPayload>;
  /** Deletes a single `Catalog` using a unique key. */
  readonly deleteCatalog?: Maybe<DeleteCatalogPayload>;
  /** Deletes a single `Catalog` using a unique key. */
  readonly deleteCatalogById?: Maybe<DeleteCatalogPayload>;
  /** Deletes a single `Category` using a unique key. */
  readonly deleteCategory?: Maybe<DeleteCategoryPayload>;
  /** Deletes a single `Category` using a unique key. */
  readonly deleteCategoryById?: Maybe<DeleteCategoryPayload>;
  /** Deletes a single `Contact` using a unique key. */
  readonly deleteContact?: Maybe<DeleteContactPayload>;
  /** Deletes a single `Department` using a unique key. */
  readonly deleteDepartment?: Maybe<DeleteDepartmentPayload>;
  /** Deletes a single `DepartmentDepartmentsCatalogListing` using a unique key. */
  readonly deleteDepartmentDepartmentsCatalogListing?: Maybe<DeleteDepartmentDepartmentsCatalogListingPayload>;
  /** Deletes a single `Feed` using a unique key. */
  readonly deleteFeed?: Maybe<DeleteFeedPayload>;
  /** Deletes a single `FeedHistory` using a unique key. */
  readonly deleteFeedHistory?: Maybe<DeleteFeedHistoryPayload>;
  /** Deletes a single `Lead` using a unique key. */
  readonly deleteLead?: Maybe<DeleteLeadPayload>;
  /** Deletes a single `LinkOut` using a unique key. */
  readonly deleteLinkOut?: Maybe<DeleteLinkOutPayload>;
  /** Deletes a single `NavigationItem` using a unique key. */
  readonly deleteNavigationItem?: Maybe<DeleteNavigationItemPayload>;
  /** Deletes a single `Offer` using a unique key. */
  readonly deleteOffer?: Maybe<DeleteOfferPayload>;
  /** Deletes a single `Order` using a unique key. */
  readonly deleteOrder?: Maybe<DeleteOrderPayload>;
  /** Deletes a single `OrderProduct` using a unique key. */
  readonly deleteOrderProduct?: Maybe<DeleteOrderProductPayload>;
  /** Deletes a single `Page` using a unique key. */
  readonly deletePage?: Maybe<DeletePagePayload>;
  /** Deletes a single `Product` using a unique key. */
  readonly deleteProduct?: Maybe<DeleteProductPayload>;
  /** Deletes a single `Profile` using a unique key. */
  readonly deleteProfile?: Maybe<DeleteProfilePayload>;
  /** Deletes a single `Profile` using a unique key. */
  readonly deleteProfileByEmail?: Maybe<DeleteProfilePayload>;
  /** Deletes a single `SubCategory` using a unique key. */
  readonly deleteSubCategory?: Maybe<DeleteSubCategoryPayload>;
  /** Deletes a single `SubCategory` using a unique key. */
  readonly deleteSubCategoryById?: Maybe<DeleteSubCategoryPayload>;
  /** Deletes a single `Subscription` using a unique key. */
  readonly deleteSubscription?: Maybe<DeleteSubscriptionPayload>;
  /** Deletes a single `Version` using a unique key. */
  readonly deleteVersion?: Maybe<DeleteVersionPayload>;
  /** Deletes a single `Version` using a unique key. */
  readonly deleteVersionById?: Maybe<DeleteVersionPayload>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAuthorizedUserArgs = {
  input: CreateAuthorizedUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAuthorizedUserAuthorizedUsersAccountPartnerAccessArgs = {
  input: CreateAuthorizedUserAuthorizedUsersAccountPartnerAccessInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCampaignArgs = {
  input: CreateCampaignInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCampaignGroupArgs = {
  input: CreateCampaignGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCampaignUserArgs = {
  input: CreateCampaignUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCarouselArgs = {
  input: CreateCarouselInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCarouselListingArgs = {
  input: CreateCarouselListingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCatalogArgs = {
  input: CreateCatalogInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCategoryArgs = {
  input: CreateCategoryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateContactArgs = {
  input: CreateContactInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDepartmentArgs = {
  input: CreateDepartmentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDepartmentDepartmentsCatalogListingArgs = {
  input: CreateDepartmentDepartmentsCatalogListingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFeedArgs = {
  input: CreateFeedInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFeedHistoryArgs = {
  input: CreateFeedHistoryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLeadArgs = {
  input: CreateLeadInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLinkOutArgs = {
  input: CreateLinkOutInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateNavigationItemArgs = {
  input: CreateNavigationItemInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateOfferArgs = {
  input: CreateOfferInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateOrderArgs = {
  input: CreateOrderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateOrderProductArgs = {
  input: CreateOrderProductInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePageArgs = {
  input: CreatePageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProductArgs = {
  input: CreateProductInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProfileArgs = {
  input: CreateProfileInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSubCategoryArgs = {
  input: CreateSubCategoryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSubscriptionArgs = {
  input: CreateSubscriptionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateVersionArgs = {
  input: CreateVersionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCatalogSearchLiveArgs = {
  input: CreateCatalogSearchLiveInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAccountArgs = {
  input: UpdateAccountInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAuthorizedUserArgs = {
  input: UpdateAuthorizedUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAuthorizedUserByEmailArgs = {
  input: UpdateAuthorizedUserByEmailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAuthorizedUserAuthorizedUsersAccountPartnerAccessArgs = {
  input: UpdateAuthorizedUserAuthorizedUsersAccountPartnerAccessInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCampaignArgs = {
  input: UpdateCampaignInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCampaignGroupArgs = {
  input: UpdateCampaignGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCampaignUserArgs = {
  input: UpdateCampaignUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCarouselArgs = {
  input: UpdateCarouselInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCarouselListingArgs = {
  input: UpdateCarouselListingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCatalogArgs = {
  input: UpdateCatalogInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCatalogByIdArgs = {
  input: UpdateCatalogByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCategoryArgs = {
  input: UpdateCategoryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCategoryByIdArgs = {
  input: UpdateCategoryByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateContactArgs = {
  input: UpdateContactInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDepartmentArgs = {
  input: UpdateDepartmentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDepartmentDepartmentsCatalogListingArgs = {
  input: UpdateDepartmentDepartmentsCatalogListingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFeedArgs = {
  input: UpdateFeedInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFeedHistoryArgs = {
  input: UpdateFeedHistoryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLeadArgs = {
  input: UpdateLeadInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLinkOutArgs = {
  input: UpdateLinkOutInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNavigationItemArgs = {
  input: UpdateNavigationItemInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOfferArgs = {
  input: UpdateOfferInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrderArgs = {
  input: UpdateOrderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrderProductArgs = {
  input: UpdateOrderProductInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePageArgs = {
  input: UpdatePageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProductArgs = {
  input: UpdateProductInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProfileByEmailArgs = {
  input: UpdateProfileByEmailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSubCategoryArgs = {
  input: UpdateSubCategoryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSubCategoryByIdArgs = {
  input: UpdateSubCategoryByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSubscriptionArgs = {
  input: UpdateSubscriptionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVersionArgs = {
  input: UpdateVersionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVersionByIdArgs = {
  input: UpdateVersionByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAccountArgs = {
  input: DeleteAccountInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAuthorizedUserArgs = {
  input: DeleteAuthorizedUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAuthorizedUserByEmailArgs = {
  input: DeleteAuthorizedUserByEmailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAuthorizedUserAuthorizedUsersAccountPartnerAccessArgs = {
  input: DeleteAuthorizedUserAuthorizedUsersAccountPartnerAccessInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCampaignArgs = {
  input: DeleteCampaignInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCampaignGroupArgs = {
  input: DeleteCampaignGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCampaignUserArgs = {
  input: DeleteCampaignUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCarouselArgs = {
  input: DeleteCarouselInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCarouselListingArgs = {
  input: DeleteCarouselListingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCatalogArgs = {
  input: DeleteCatalogInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCatalogByIdArgs = {
  input: DeleteCatalogByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCategoryArgs = {
  input: DeleteCategoryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCategoryByIdArgs = {
  input: DeleteCategoryByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteContactArgs = {
  input: DeleteContactInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDepartmentArgs = {
  input: DeleteDepartmentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDepartmentDepartmentsCatalogListingArgs = {
  input: DeleteDepartmentDepartmentsCatalogListingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFeedArgs = {
  input: DeleteFeedInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFeedHistoryArgs = {
  input: DeleteFeedHistoryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLeadArgs = {
  input: DeleteLeadInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLinkOutArgs = {
  input: DeleteLinkOutInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNavigationItemArgs = {
  input: DeleteNavigationItemInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOfferArgs = {
  input: DeleteOfferInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOrderArgs = {
  input: DeleteOrderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOrderProductArgs = {
  input: DeleteOrderProductInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePageArgs = {
  input: DeletePageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProductArgs = {
  input: DeleteProductInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProfileArgs = {
  input: DeleteProfileInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProfileByEmailArgs = {
  input: DeleteProfileByEmailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSubCategoryArgs = {
  input: DeleteSubCategoryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSubCategoryByIdArgs = {
  input: DeleteSubCategoryByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSubscriptionArgs = {
  input: DeleteSubscriptionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteVersionArgs = {
  input: DeleteVersionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteVersionByIdArgs = {
  input: DeleteVersionByIdInput;
};

export type NavigationItem = {
  readonly __typename?: 'NavigationItem';
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly orderIndex?: Maybe<Scalars['Int']>;
  readonly groupText?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
  readonly department?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  /** Reads a single `Department` that is related to this `NavigationItem`. */
  readonly departmentByDepartment?: Maybe<Department>;
};

/** A condition to be used against `NavigationItem` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type NavigationItemCondition = {
  /** Checks for equality with the object’s `oid` field. */
  readonly oid?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `options` field. */
  readonly options?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `orderIndex` field. */
  readonly orderIndex?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `groupText` field. */
  readonly groupText?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `title` field. */
  readonly title?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `url` field. */
  readonly url?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `department` field. */
  readonly department?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `NavigationItem` object types. All fields are combined with a logical ‘and.’ */
export type NavigationItemFilter = {
  /** Filter by the object’s `oid` field. */
  readonly oid?: Maybe<UuidFilter>;
  /** Filter by the object’s `orderIndex` field. */
  readonly orderIndex?: Maybe<IntFilter>;
  /** Filter by the object’s `groupText` field. */
  readonly groupText?: Maybe<StringFilter>;
  /** Filter by the object’s `title` field. */
  readonly title?: Maybe<StringFilter>;
  /** Filter by the object’s `url` field. */
  readonly url?: Maybe<StringFilter>;
  /** Filter by the object’s `department` field. */
  readonly department?: Maybe<UuidFilter>;
  /** Filter by the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<IntFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<ReadonlyArray<NavigationItemFilter>>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<ReadonlyArray<NavigationItemFilter>>;
  /** Negates the expression. */
  readonly not?: Maybe<NavigationItemFilter>;
};

/** An input for mutations affecting `NavigationItem` */
export type NavigationItemInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly orderIndex?: Maybe<Scalars['Int']>;
  readonly groupText?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
  readonly department?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly departmentToDepartment?: Maybe<FkNavigationItemDepartmentInput>;
};

/** The fields on `navigationItem` to look up the row to update. */
export type NavigationItemOnNavigationItemForFkNavigationItemDepartmentUsingPkNavigationItemUpdate = {
  /** An object where the defined keys will be set on the `navigationItem` being updated. */
  readonly patch: UpdateNavigationItemOnNavigationItemForFkNavigationItemDepartmentPatch;
  readonly oid: Scalars['UUID'];
};

/** Represents an update to a `NavigationItem`. Fields that are set will be updated. */
export type NavigationItemPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly orderIndex?: Maybe<Scalars['Int']>;
  readonly groupText?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
  readonly department?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly departmentToDepartment?: Maybe<FkNavigationItemDepartmentInput>;
};

/** The fields on `navigationItem` to look up the row to connect. */
export type NavigationItemPkNavigationItemConnect = {
  readonly oid: Scalars['UUID'];
};

/** The fields on `navigationItem` to look up the row to delete. */
export type NavigationItemPkNavigationItemDelete = {
  readonly oid: Scalars['UUID'];
};

/** A connection to a list of `NavigationItem` values. */
export type NavigationItemsConnection = {
  readonly __typename?: 'NavigationItemsConnection';
  /** A list of `NavigationItem` objects. */
  readonly nodes: ReadonlyArray<NavigationItem>;
  /** A list of edges which contains the `NavigationItem` and cursor to aid in pagination. */
  readonly edges: ReadonlyArray<NavigationItemsEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `NavigationItem` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `NavigationItem` edge in the connection. */
export type NavigationItemsEdge = {
  readonly __typename?: 'NavigationItemsEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `NavigationItem` at the end of the edge. */
  readonly node: NavigationItem;
};

/** Methods to use when ordering `NavigationItem`. */
export enum NavigationItemsOrderBy {
  Natural = 'NATURAL',
  OidAsc = 'OID_ASC',
  OidDesc = 'OID_DESC',
  OptionsAsc = 'OPTIONS_ASC',
  OptionsDesc = 'OPTIONS_DESC',
  OrderIndexAsc = 'ORDER_INDEX_ASC',
  OrderIndexDesc = 'ORDER_INDEX_DESC',
  GroupTextAsc = 'GROUP_TEXT_ASC',
  GroupTextDesc = 'GROUP_TEXT_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  UrlAsc = 'URL_ASC',
  UrlDesc = 'URL_DESC',
  DepartmentAsc = 'DEPARTMENT_ASC',
  DepartmentDesc = 'DEPARTMENT_DESC',
  OptimisticLockFieldAsc = 'OPTIMISTIC_LOCK_FIELD_ASC',
  OptimisticLockFieldDesc = 'OPTIMISTIC_LOCK_FIELD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type Offer = {
  readonly __typename?: 'Offer';
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly image?: Maybe<Scalars['String']>;
  readonly discountInPercent?: Maybe<Scalars['Float']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly certificate?: Maybe<Scalars['String']>;
  readonly expiryDate?: Maybe<Scalars['Datetime']>;
  readonly value?: Maybe<Scalars['String']>;
  readonly disclaimer?: Maybe<Scalars['String']>;
  readonly burst?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly paid?: Maybe<Scalars['Boolean']>;
  /** Reads a single `Catalog` that is related to this `Offer`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
  /** Reads and enables pagination through a set of `CarouselListing`. */
  readonly carouselListingsByOfferConnection: CarouselListingsConnection;
  /** Reads and enables pagination through a set of `CarouselListing`. */
  readonly carouselListingsByOffer: ReadonlyArray<CarouselListing>;
  /** Reads and enables pagination through a set of `Carousel`. */
  readonly carouselsByCarouselListingOfferAndCarousel: OfferCarouselsByCarouselListingOfferAndCarouselManyToManyConnection;
  /** Reads and enables pagination through a set of `Carousel`. */
  readonly carouselsByCarouselListingOfferAndCarouselList: ReadonlyArray<Carousel>;
  /** Reads and enables pagination through a set of `Catalog`. */
  readonly catalogsByCarouselListingOfferAndCatalog: OfferCatalogsByCarouselListingOfferAndCatalogManyToManyConnection;
  /** Reads and enables pagination through a set of `Catalog`. */
  readonly catalogsByCarouselListingOfferAndCatalogList: ReadonlyArray<Catalog>;
};


export type OfferCarouselListingsByOfferConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CarouselListingsOrderBy>>;
  condition?: Maybe<CarouselListingCondition>;
  filter?: Maybe<CarouselListingFilter>;
};


export type OfferCarouselListingsByOfferArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CarouselListingsOrderBy>>;
  condition?: Maybe<CarouselListingCondition>;
  filter?: Maybe<CarouselListingFilter>;
};


export type OfferCarouselsByCarouselListingOfferAndCarouselArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CarouselsOrderBy>>;
  condition?: Maybe<CarouselCondition>;
  filter?: Maybe<CarouselFilter>;
};


export type OfferCarouselsByCarouselListingOfferAndCarouselListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CarouselsOrderBy>>;
  condition?: Maybe<CarouselCondition>;
  filter?: Maybe<CarouselFilter>;
};


export type OfferCatalogsByCarouselListingOfferAndCatalogArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CatalogsOrderBy>>;
  condition?: Maybe<CatalogCondition>;
  filter?: Maybe<CatalogFilter>;
};


export type OfferCatalogsByCarouselListingOfferAndCatalogListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CatalogsOrderBy>>;
  condition?: Maybe<CatalogCondition>;
  filter?: Maybe<CatalogFilter>;
};

/** A connection to a list of `Carousel` values, with data from `CarouselListing`. */
export type OfferCarouselsByCarouselListingOfferAndCarouselManyToManyConnection = {
  readonly __typename?: 'OfferCarouselsByCarouselListingOfferAndCarouselManyToManyConnection';
  /** A list of `Carousel` objects. */
  readonly nodes: ReadonlyArray<Carousel>;
  /** A list of edges which contains the `Carousel`, info from the `CarouselListing`, and the cursor to aid in pagination. */
  readonly edges: ReadonlyArray<OfferCarouselsByCarouselListingOfferAndCarouselManyToManyEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Carousel` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Carousel` edge in the connection, with data from `CarouselListing`. */
export type OfferCarouselsByCarouselListingOfferAndCarouselManyToManyEdge = {
  readonly __typename?: 'OfferCarouselsByCarouselListingOfferAndCarouselManyToManyEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Carousel` at the end of the edge. */
  readonly node: Carousel;
  /** Reads and enables pagination through a set of `CarouselListing`. */
  readonly carouselListingsByCarouselConnection: CarouselListingsConnection;
  /** Reads and enables pagination through a set of `CarouselListing`. */
  readonly carouselListingsByCarousel: ReadonlyArray<CarouselListing>;
};


/** A `Carousel` edge in the connection, with data from `CarouselListing`. */
export type OfferCarouselsByCarouselListingOfferAndCarouselManyToManyEdgeCarouselListingsByCarouselConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CarouselListingsOrderBy>>;
  condition?: Maybe<CarouselListingCondition>;
  filter?: Maybe<CarouselListingFilter>;
};


/** A `Carousel` edge in the connection, with data from `CarouselListing`. */
export type OfferCarouselsByCarouselListingOfferAndCarouselManyToManyEdgeCarouselListingsByCarouselArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CarouselListingsOrderBy>>;
  condition?: Maybe<CarouselListingCondition>;
  filter?: Maybe<CarouselListingFilter>;
};

/** A connection to a list of `Catalog` values, with data from `CarouselListing`. */
export type OfferCatalogsByCarouselListingOfferAndCatalogManyToManyConnection = {
  readonly __typename?: 'OfferCatalogsByCarouselListingOfferAndCatalogManyToManyConnection';
  /** A list of `Catalog` objects. */
  readonly nodes: ReadonlyArray<Catalog>;
  /** A list of edges which contains the `Catalog`, info from the `CarouselListing`, and the cursor to aid in pagination. */
  readonly edges: ReadonlyArray<OfferCatalogsByCarouselListingOfferAndCatalogManyToManyEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Catalog` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Catalog` edge in the connection, with data from `CarouselListing`. */
export type OfferCatalogsByCarouselListingOfferAndCatalogManyToManyEdge = {
  readonly __typename?: 'OfferCatalogsByCarouselListingOfferAndCatalogManyToManyEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Catalog` at the end of the edge. */
  readonly node: Catalog;
  /** Reads and enables pagination through a set of `CarouselListing`. */
  readonly carouselListingsByCatalogConnection: CarouselListingsConnection;
  /** Reads and enables pagination through a set of `CarouselListing`. */
  readonly carouselListingsByCatalog: ReadonlyArray<CarouselListing>;
};


/** A `Catalog` edge in the connection, with data from `CarouselListing`. */
export type OfferCatalogsByCarouselListingOfferAndCatalogManyToManyEdgeCarouselListingsByCatalogConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CarouselListingsOrderBy>>;
  condition?: Maybe<CarouselListingCondition>;
  filter?: Maybe<CarouselListingFilter>;
};


/** A `Catalog` edge in the connection, with data from `CarouselListing`. */
export type OfferCatalogsByCarouselListingOfferAndCatalogManyToManyEdgeCarouselListingsByCatalogArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CarouselListingsOrderBy>>;
  condition?: Maybe<CarouselListingCondition>;
  filter?: Maybe<CarouselListingFilter>;
};

/** A condition to be used against `Offer` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type OfferCondition = {
  /** Checks for equality with the object’s `oid` field. */
  readonly oid?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `options` field. */
  readonly options?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `catalog` field. */
  readonly catalog?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `code` field. */
  readonly code?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `image` field. */
  readonly image?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `discountInPercent` field. */
  readonly discountInPercent?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `name` field. */
  readonly name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `certificate` field. */
  readonly certificate?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `expiryDate` field. */
  readonly expiryDate?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `value` field. */
  readonly value?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `disclaimer` field. */
  readonly disclaimer?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `burst` field. */
  readonly burst?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `active` field. */
  readonly active?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `paid` field. */
  readonly paid?: Maybe<Scalars['Boolean']>;
};

/** A filter to be used against `Offer` object types. All fields are combined with a logical ‘and.’ */
export type OfferFilter = {
  /** Filter by the object’s `oid` field. */
  readonly oid?: Maybe<UuidFilter>;
  /** Filter by the object’s `catalog` field. */
  readonly catalog?: Maybe<UuidFilter>;
  /** Filter by the object’s `code` field. */
  readonly code?: Maybe<StringFilter>;
  /** Filter by the object’s `image` field. */
  readonly image?: Maybe<StringFilter>;
  /** Filter by the object’s `discountInPercent` field. */
  readonly discountInPercent?: Maybe<FloatFilter>;
  /** Filter by the object’s `name` field. */
  readonly name?: Maybe<StringFilter>;
  /** Filter by the object’s `certificate` field. */
  readonly certificate?: Maybe<StringFilter>;
  /** Filter by the object’s `expiryDate` field. */
  readonly expiryDate?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `value` field. */
  readonly value?: Maybe<StringFilter>;
  /** Filter by the object’s `disclaimer` field. */
  readonly disclaimer?: Maybe<StringFilter>;
  /** Filter by the object’s `burst` field. */
  readonly burst?: Maybe<StringFilter>;
  /** Filter by the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<IntFilter>;
  /** Filter by the object’s `active` field. */
  readonly active?: Maybe<BooleanFilter>;
  /** Filter by the object’s `paid` field. */
  readonly paid?: Maybe<BooleanFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<ReadonlyArray<OfferFilter>>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<ReadonlyArray<OfferFilter>>;
  /** Negates the expression. */
  readonly not?: Maybe<OfferFilter>;
};

/** An input for mutations affecting `Offer` */
export type OfferInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly image?: Maybe<Scalars['String']>;
  readonly discountInPercent?: Maybe<Scalars['Float']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly certificate?: Maybe<Scalars['String']>;
  readonly expiryDate?: Maybe<Scalars['Datetime']>;
  readonly value?: Maybe<Scalars['String']>;
  readonly disclaimer?: Maybe<Scalars['String']>;
  readonly burst?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly paid?: Maybe<Scalars['Boolean']>;
  readonly catalogToCatalog?: Maybe<FkOfferCatalogInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingOfferInverseInput>;
};

/** The fields on `offer` to look up the row to update. */
export type OfferOnCarouselListingForFkCarouselListingOfferUsingPkOfferUpdate = {
  /** An object where the defined keys will be set on the `offer` being updated. */
  readonly patch: UpdateOfferOnCarouselListingForFkCarouselListingOfferPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `offer` to look up the row to update. */
export type OfferOnOfferForFkOfferCatalogUsingPkOfferUpdate = {
  /** An object where the defined keys will be set on the `offer` being updated. */
  readonly patch: UpdateOfferOnOfferForFkOfferCatalogPatch;
  readonly oid: Scalars['UUID'];
};

/** Represents an update to a `Offer`. Fields that are set will be updated. */
export type OfferPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly image?: Maybe<Scalars['String']>;
  readonly discountInPercent?: Maybe<Scalars['Float']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly certificate?: Maybe<Scalars['String']>;
  readonly expiryDate?: Maybe<Scalars['Datetime']>;
  readonly value?: Maybe<Scalars['String']>;
  readonly disclaimer?: Maybe<Scalars['String']>;
  readonly burst?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly paid?: Maybe<Scalars['Boolean']>;
  readonly catalogToCatalog?: Maybe<FkOfferCatalogInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingOfferInverseInput>;
};

/** The fields on `offer` to look up the row to connect. */
export type OfferPkOfferConnect = {
  readonly oid: Scalars['UUID'];
};

/** The fields on `offer` to look up the row to delete. */
export type OfferPkOfferDelete = {
  readonly oid: Scalars['UUID'];
};

/** A connection to a list of `Offer` values. */
export type OffersConnection = {
  readonly __typename?: 'OffersConnection';
  /** A list of `Offer` objects. */
  readonly nodes: ReadonlyArray<Offer>;
  /** A list of edges which contains the `Offer` and cursor to aid in pagination. */
  readonly edges: ReadonlyArray<OffersEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Offer` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Offer` edge in the connection. */
export type OffersEdge = {
  readonly __typename?: 'OffersEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Offer` at the end of the edge. */
  readonly node: Offer;
};

/** Methods to use when ordering `Offer`. */
export enum OffersOrderBy {
  Natural = 'NATURAL',
  OidAsc = 'OID_ASC',
  OidDesc = 'OID_DESC',
  OptionsAsc = 'OPTIONS_ASC',
  OptionsDesc = 'OPTIONS_DESC',
  CatalogAsc = 'CATALOG_ASC',
  CatalogDesc = 'CATALOG_DESC',
  CodeAsc = 'CODE_ASC',
  CodeDesc = 'CODE_DESC',
  ImageAsc = 'IMAGE_ASC',
  ImageDesc = 'IMAGE_DESC',
  DiscountInPercentAsc = 'DISCOUNT_IN_PERCENT_ASC',
  DiscountInPercentDesc = 'DISCOUNT_IN_PERCENT_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  CertificateAsc = 'CERTIFICATE_ASC',
  CertificateDesc = 'CERTIFICATE_DESC',
  ExpiryDateAsc = 'EXPIRY_DATE_ASC',
  ExpiryDateDesc = 'EXPIRY_DATE_DESC',
  ValueAsc = 'VALUE_ASC',
  ValueDesc = 'VALUE_DESC',
  DisclaimerAsc = 'DISCLAIMER_ASC',
  DisclaimerDesc = 'DISCLAIMER_DESC',
  BurstAsc = 'BURST_ASC',
  BurstDesc = 'BURST_DESC',
  OptimisticLockFieldAsc = 'OPTIMISTIC_LOCK_FIELD_ASC',
  OptimisticLockFieldDesc = 'OPTIMISTIC_LOCK_FIELD_DESC',
  ActiveAsc = 'ACTIVE_ASC',
  ActiveDesc = 'ACTIVE_DESC',
  PaidAsc = 'PAID_ASC',
  PaidDesc = 'PAID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type Order = {
  readonly __typename?: 'Order';
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly deliveryAddress?: Maybe<Scalars['String']>;
  readonly subTotal?: Maybe<Scalars['Float']>;
  readonly date?: Maybe<Scalars['Datetime']>;
  readonly amount?: Maybe<Scalars['Float']>;
  readonly deliveryFee?: Maybe<Scalars['Float']>;
  readonly deliveryTime?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  /** Reads and enables pagination through a set of `OrderProduct`. */
  readonly orderProductsByOrderConnection: OrderProductsConnection;
  /** Reads and enables pagination through a set of `OrderProduct`. */
  readonly orderProductsByOrder: ReadonlyArray<OrderProduct>;
};


export type OrderOrderProductsByOrderConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<OrderProductsOrderBy>>;
  condition?: Maybe<OrderProductCondition>;
  filter?: Maybe<OrderProductFilter>;
};


export type OrderOrderProductsByOrderArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<OrderProductsOrderBy>>;
  condition?: Maybe<OrderProductCondition>;
  filter?: Maybe<OrderProductFilter>;
};

/** A condition to be used against `Order` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type OrderCondition = {
  /** Checks for equality with the object’s `oid` field. */
  readonly oid?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `options` field. */
  readonly options?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `status` field. */
  readonly status?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `deliveryAddress` field. */
  readonly deliveryAddress?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `subTotal` field. */
  readonly subTotal?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `date` field. */
  readonly date?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `amount` field. */
  readonly amount?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `deliveryFee` field. */
  readonly deliveryFee?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `deliveryTime` field. */
  readonly deliveryTime?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `Order` object types. All fields are combined with a logical ‘and.’ */
export type OrderFilter = {
  /** Filter by the object’s `oid` field. */
  readonly oid?: Maybe<UuidFilter>;
  /** Filter by the object’s `status` field. */
  readonly status?: Maybe<StringFilter>;
  /** Filter by the object’s `deliveryAddress` field. */
  readonly deliveryAddress?: Maybe<StringFilter>;
  /** Filter by the object’s `subTotal` field. */
  readonly subTotal?: Maybe<FloatFilter>;
  /** Filter by the object’s `date` field. */
  readonly date?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `amount` field. */
  readonly amount?: Maybe<FloatFilter>;
  /** Filter by the object’s `deliveryFee` field. */
  readonly deliveryFee?: Maybe<FloatFilter>;
  /** Filter by the object’s `deliveryTime` field. */
  readonly deliveryTime?: Maybe<StringFilter>;
  /** Filter by the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<IntFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<ReadonlyArray<OrderFilter>>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<ReadonlyArray<OrderFilter>>;
  /** Negates the expression. */
  readonly not?: Maybe<OrderFilter>;
};

/** An input for mutations affecting `Order` */
export type OrderInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly deliveryAddress?: Maybe<Scalars['String']>;
  readonly subTotal?: Maybe<Scalars['Float']>;
  readonly date?: Maybe<Scalars['Datetime']>;
  readonly amount?: Maybe<Scalars['Float']>;
  readonly deliveryFee?: Maybe<Scalars['Float']>;
  readonly deliveryTime?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly orderProductsUsingOid?: Maybe<FkOrderProductOrderInverseInput>;
};

/** The fields on `order` to look up the row to update. */
export type OrderOnOrderProductForFkOrderProductOrderUsingPkOrderUpdate = {
  /** An object where the defined keys will be set on the `order` being updated. */
  readonly patch: UpdateOrderOnOrderProductForFkOrderProductOrderPatch;
  readonly oid: Scalars['UUID'];
};

/** Represents an update to a `Order`. Fields that are set will be updated. */
export type OrderPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly deliveryAddress?: Maybe<Scalars['String']>;
  readonly subTotal?: Maybe<Scalars['Float']>;
  readonly date?: Maybe<Scalars['Datetime']>;
  readonly amount?: Maybe<Scalars['Float']>;
  readonly deliveryFee?: Maybe<Scalars['Float']>;
  readonly deliveryTime?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly orderProductsUsingOid?: Maybe<FkOrderProductOrderInverseInput>;
};

/** The fields on `order` to look up the row to connect. */
export type OrderPkOrderConnect = {
  readonly oid: Scalars['UUID'];
};

/** The fields on `order` to look up the row to delete. */
export type OrderPkOrderDelete = {
  readonly oid: Scalars['UUID'];
};

export type OrderProduct = {
  readonly __typename?: 'OrderProduct';
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly order?: Maybe<Scalars['UUID']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly image?: Maybe<Scalars['String']>;
  readonly total?: Maybe<Scalars['Float']>;
  readonly price?: Maybe<Scalars['Float']>;
  readonly quantity?: Maybe<Scalars['Float']>;
  readonly weight?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  /** Reads a single `Order` that is related to this `OrderProduct`. */
  readonly orderByOrder?: Maybe<Order>;
};

/** A condition to be used against `OrderProduct` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type OrderProductCondition = {
  /** Checks for equality with the object’s `oid` field. */
  readonly oid?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `options` field. */
  readonly options?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `order` field. */
  readonly order?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `title` field. */
  readonly title?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `image` field. */
  readonly image?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `total` field. */
  readonly total?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `price` field. */
  readonly price?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `quantity` field. */
  readonly quantity?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `weight` field. */
  readonly weight?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `OrderProduct` object types. All fields are combined with a logical ‘and.’ */
export type OrderProductFilter = {
  /** Filter by the object’s `oid` field. */
  readonly oid?: Maybe<UuidFilter>;
  /** Filter by the object’s `order` field. */
  readonly order?: Maybe<UuidFilter>;
  /** Filter by the object’s `title` field. */
  readonly title?: Maybe<StringFilter>;
  /** Filter by the object’s `image` field. */
  readonly image?: Maybe<StringFilter>;
  /** Filter by the object’s `total` field. */
  readonly total?: Maybe<FloatFilter>;
  /** Filter by the object’s `price` field. */
  readonly price?: Maybe<FloatFilter>;
  /** Filter by the object’s `quantity` field. */
  readonly quantity?: Maybe<FloatFilter>;
  /** Filter by the object’s `weight` field. */
  readonly weight?: Maybe<StringFilter>;
  /** Filter by the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<IntFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<ReadonlyArray<OrderProductFilter>>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<ReadonlyArray<OrderProductFilter>>;
  /** Negates the expression. */
  readonly not?: Maybe<OrderProductFilter>;
};

/** An input for mutations affecting `OrderProduct` */
export type OrderProductInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly order?: Maybe<Scalars['UUID']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly image?: Maybe<Scalars['String']>;
  readonly total?: Maybe<Scalars['Float']>;
  readonly price?: Maybe<Scalars['Float']>;
  readonly quantity?: Maybe<Scalars['Float']>;
  readonly weight?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly orderToOrder?: Maybe<FkOrderProductOrderInput>;
};

/** The fields on `orderProduct` to look up the row to update. */
export type OrderProductOnOrderProductForFkOrderProductOrderUsingPkOrderProductUpdate = {
  /** An object where the defined keys will be set on the `orderProduct` being updated. */
  readonly patch: UpdateOrderProductOnOrderProductForFkOrderProductOrderPatch;
  readonly oid: Scalars['UUID'];
};

/** Represents an update to a `OrderProduct`. Fields that are set will be updated. */
export type OrderProductPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly order?: Maybe<Scalars['UUID']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly image?: Maybe<Scalars['String']>;
  readonly total?: Maybe<Scalars['Float']>;
  readonly price?: Maybe<Scalars['Float']>;
  readonly quantity?: Maybe<Scalars['Float']>;
  readonly weight?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly orderToOrder?: Maybe<FkOrderProductOrderInput>;
};

/** The fields on `orderProduct` to look up the row to connect. */
export type OrderProductPkOrderProductConnect = {
  readonly oid: Scalars['UUID'];
};

/** The fields on `orderProduct` to look up the row to delete. */
export type OrderProductPkOrderProductDelete = {
  readonly oid: Scalars['UUID'];
};

/** A connection to a list of `OrderProduct` values. */
export type OrderProductsConnection = {
  readonly __typename?: 'OrderProductsConnection';
  /** A list of `OrderProduct` objects. */
  readonly nodes: ReadonlyArray<OrderProduct>;
  /** A list of edges which contains the `OrderProduct` and cursor to aid in pagination. */
  readonly edges: ReadonlyArray<OrderProductsEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `OrderProduct` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `OrderProduct` edge in the connection. */
export type OrderProductsEdge = {
  readonly __typename?: 'OrderProductsEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `OrderProduct` at the end of the edge. */
  readonly node: OrderProduct;
};

/** Methods to use when ordering `OrderProduct`. */
export enum OrderProductsOrderBy {
  Natural = 'NATURAL',
  OidAsc = 'OID_ASC',
  OidDesc = 'OID_DESC',
  OptionsAsc = 'OPTIONS_ASC',
  OptionsDesc = 'OPTIONS_DESC',
  OrderAsc = 'ORDER_ASC',
  OrderDesc = 'ORDER_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  ImageAsc = 'IMAGE_ASC',
  ImageDesc = 'IMAGE_DESC',
  TotalAsc = 'TOTAL_ASC',
  TotalDesc = 'TOTAL_DESC',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  QuantityAsc = 'QUANTITY_ASC',
  QuantityDesc = 'QUANTITY_DESC',
  WeightAsc = 'WEIGHT_ASC',
  WeightDesc = 'WEIGHT_DESC',
  OptimisticLockFieldAsc = 'OPTIMISTIC_LOCK_FIELD_ASC',
  OptimisticLockFieldDesc = 'OPTIMISTIC_LOCK_FIELD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A connection to a list of `Order` values. */
export type OrdersConnection = {
  readonly __typename?: 'OrdersConnection';
  /** A list of `Order` objects. */
  readonly nodes: ReadonlyArray<Order>;
  /** A list of edges which contains the `Order` and cursor to aid in pagination. */
  readonly edges: ReadonlyArray<OrdersEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Order` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Order` edge in the connection. */
export type OrdersEdge = {
  readonly __typename?: 'OrdersEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Order` at the end of the edge. */
  readonly node: Order;
};

/** Methods to use when ordering `Order`. */
export enum OrdersOrderBy {
  Natural = 'NATURAL',
  OidAsc = 'OID_ASC',
  OidDesc = 'OID_DESC',
  OptionsAsc = 'OPTIONS_ASC',
  OptionsDesc = 'OPTIONS_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  DeliveryAddressAsc = 'DELIVERY_ADDRESS_ASC',
  DeliveryAddressDesc = 'DELIVERY_ADDRESS_DESC',
  SubTotalAsc = 'SUB_TOTAL_ASC',
  SubTotalDesc = 'SUB_TOTAL_DESC',
  DateAsc = 'DATE_ASC',
  DateDesc = 'DATE_DESC',
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  DeliveryFeeAsc = 'DELIVERY_FEE_ASC',
  DeliveryFeeDesc = 'DELIVERY_FEE_DESC',
  DeliveryTimeAsc = 'DELIVERY_TIME_ASC',
  DeliveryTimeDesc = 'DELIVERY_TIME_DESC',
  OptimisticLockFieldAsc = 'OPTIMISTIC_LOCK_FIELD_ASC',
  OptimisticLockFieldDesc = 'OPTIMISTIC_LOCK_FIELD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type Page = {
  readonly __typename?: 'Page';
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly version?: Maybe<Scalars['UUID']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly imageMeta?: Maybe<Scalars['String']>;
  readonly imageSource?: Maybe<Scalars['String']>;
  readonly pageType?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  /** Reads a single `Version` that is related to this `Page`. */
  readonly versionByVersion?: Maybe<Version>;
};

/** A condition to be used against `Page` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type PageCondition = {
  /** Checks for equality with the object’s `oid` field. */
  readonly oid?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `options` field. */
  readonly options?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `version` field. */
  readonly version?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `imageUrl` field. */
  readonly imageUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `imageMeta` field. */
  readonly imageMeta?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `imageSource` field. */
  readonly imageSource?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `pageType` field. */
  readonly pageType?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `Page` object types. All fields are combined with a logical ‘and.’ */
export type PageFilter = {
  /** Filter by the object’s `oid` field. */
  readonly oid?: Maybe<UuidFilter>;
  /** Filter by the object’s `version` field. */
  readonly version?: Maybe<UuidFilter>;
  /** Filter by the object’s `imageUrl` field. */
  readonly imageUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `imageMeta` field. */
  readonly imageMeta?: Maybe<StringFilter>;
  /** Filter by the object’s `imageSource` field. */
  readonly imageSource?: Maybe<StringFilter>;
  /** Filter by the object’s `pageType` field. */
  readonly pageType?: Maybe<StringFilter>;
  /** Filter by the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<IntFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<ReadonlyArray<PageFilter>>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<ReadonlyArray<PageFilter>>;
  /** Negates the expression. */
  readonly not?: Maybe<PageFilter>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  readonly __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  readonly hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  readonly hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  readonly startCursor?: Maybe<Scalars['Cursor']>;
  /** When paginating forwards, the cursor to continue. */
  readonly endCursor?: Maybe<Scalars['Cursor']>;
};

/** An input for mutations affecting `Page` */
export type PageInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly version?: Maybe<Scalars['UUID']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly imageMeta?: Maybe<Scalars['String']>;
  readonly imageSource?: Maybe<Scalars['String']>;
  readonly pageType?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly versionToVersion?: Maybe<FkPageVersionInput>;
};

/** The fields on `page` to look up the row to update. */
export type PageOnPageForFkPageVersionUsingPkPageUpdate = {
  /** An object where the defined keys will be set on the `page` being updated. */
  readonly patch: UpdatePageOnPageForFkPageVersionPatch;
  readonly oid: Scalars['UUID'];
};

/** Represents an update to a `Page`. Fields that are set will be updated. */
export type PagePatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly version?: Maybe<Scalars['UUID']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly imageMeta?: Maybe<Scalars['String']>;
  readonly imageSource?: Maybe<Scalars['String']>;
  readonly pageType?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly versionToVersion?: Maybe<FkPageVersionInput>;
};

/** The fields on `page` to look up the row to connect. */
export type PagePkPageConnect = {
  readonly oid: Scalars['UUID'];
};

/** The fields on `page` to look up the row to delete. */
export type PagePkPageDelete = {
  readonly oid: Scalars['UUID'];
};

/** A connection to a list of `Page` values. */
export type PagesConnection = {
  readonly __typename?: 'PagesConnection';
  /** A list of `Page` objects. */
  readonly nodes: ReadonlyArray<Page>;
  /** A list of edges which contains the `Page` and cursor to aid in pagination. */
  readonly edges: ReadonlyArray<PagesEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Page` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Page` edge in the connection. */
export type PagesEdge = {
  readonly __typename?: 'PagesEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Page` at the end of the edge. */
  readonly node: Page;
};

/** Methods to use when ordering `Page`. */
export enum PagesOrderBy {
  Natural = 'NATURAL',
  OidAsc = 'OID_ASC',
  OidDesc = 'OID_DESC',
  OptionsAsc = 'OPTIONS_ASC',
  OptionsDesc = 'OPTIONS_DESC',
  VersionAsc = 'VERSION_ASC',
  VersionDesc = 'VERSION_DESC',
  ImageUrlAsc = 'IMAGE_URL_ASC',
  ImageUrlDesc = 'IMAGE_URL_DESC',
  ImageMetaAsc = 'IMAGE_META_ASC',
  ImageMetaDesc = 'IMAGE_META_DESC',
  ImageSourceAsc = 'IMAGE_SOURCE_ASC',
  ImageSourceDesc = 'IMAGE_SOURCE_DESC',
  PageTypeAsc = 'PAGE_TYPE_ASC',
  PageTypeDesc = 'PAGE_TYPE_DESC',
  OptimisticLockFieldAsc = 'OPTIMISTIC_LOCK_FIELD_ASC',
  OptimisticLockFieldDesc = 'OPTIMISTIC_LOCK_FIELD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type Product = {
  readonly __typename?: 'Product';
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly brand?: Maybe<Scalars['String']>;
  readonly color?: Maybe<Scalars['String']>;
  readonly sku?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
  readonly tags?: Maybe<Scalars['String']>;
  readonly price?: Maybe<Scalars['Float']>;
  readonly dateEntered?: Maybe<Scalars['Datetime']>;
  readonly srcOriginal?: Maybe<Scalars['String']>;
  readonly additionalImages?: Maybe<Scalars['JSON']>;
  readonly additionalFields?: Maybe<Scalars['JSON']>;
  readonly changeHistory?: Maybe<Scalars['JSON']>;
  readonly dateModified?: Maybe<Scalars['Datetime']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly subCategory?: Maybe<Scalars['String']>;
  readonly salePrice?: Maybe<Scalars['Float']>;
  readonly category?: Maybe<Scalars['String']>;
  readonly expired?: Maybe<Scalars['Boolean']>;
  readonly src?: Maybe<Scalars['String']>;
  readonly rotated?: Maybe<Scalars['Boolean']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  /** Reads a single `Catalog` that is related to this `Product`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
  /** Reads a single `CategoryByCatalog` that is related to this `Product`. */
  readonly categoryByCatalogByCategoryAndCatalog?: Maybe<CategoryByCatalog>;
};

/** A condition to be used against `Product` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ProductCondition = {
  /** Checks for equality with the object’s `oid` field. */
  readonly oid?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `options` field. */
  readonly options?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `catalog` field. */
  readonly catalog?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `title` field. */
  readonly title?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `description` field. */
  readonly description?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `brand` field. */
  readonly brand?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `color` field. */
  readonly color?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `sku` field. */
  readonly sku?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `url` field. */
  readonly url?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `tags` field. */
  readonly tags?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `price` field. */
  readonly price?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `dateEntered` field. */
  readonly dateEntered?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `srcOriginal` field. */
  readonly srcOriginal?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `additionalImages` field. */
  readonly additionalImages?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `additionalFields` field. */
  readonly additionalFields?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `changeHistory` field. */
  readonly changeHistory?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `dateModified` field. */
  readonly dateModified?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `status` field. */
  readonly status?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `subCategory` field. */
  readonly subCategory?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `salePrice` field. */
  readonly salePrice?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `category` field. */
  readonly category?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `expired` field. */
  readonly expired?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `src` field. */
  readonly src?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `rotated` field. */
  readonly rotated?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `Product` object types. All fields are combined with a logical ‘and.’ */
export type ProductFilter = {
  /** Filter by the object’s `oid` field. */
  readonly oid?: Maybe<UuidFilter>;
  /** Filter by the object’s `catalog` field. */
  readonly catalog?: Maybe<UuidFilter>;
  /** Filter by the object’s `title` field. */
  readonly title?: Maybe<StringFilter>;
  /** Filter by the object’s `description` field. */
  readonly description?: Maybe<StringFilter>;
  /** Filter by the object’s `brand` field. */
  readonly brand?: Maybe<StringFilter>;
  /** Filter by the object’s `color` field. */
  readonly color?: Maybe<StringFilter>;
  /** Filter by the object’s `sku` field. */
  readonly sku?: Maybe<StringFilter>;
  /** Filter by the object’s `url` field. */
  readonly url?: Maybe<StringFilter>;
  /** Filter by the object’s `tags` field. */
  readonly tags?: Maybe<StringFilter>;
  /** Filter by the object’s `price` field. */
  readonly price?: Maybe<FloatFilter>;
  /** Filter by the object’s `dateEntered` field. */
  readonly dateEntered?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `srcOriginal` field. */
  readonly srcOriginal?: Maybe<StringFilter>;
  /** Filter by the object’s `dateModified` field. */
  readonly dateModified?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `status` field. */
  readonly status?: Maybe<StringFilter>;
  /** Filter by the object’s `subCategory` field. */
  readonly subCategory?: Maybe<StringFilter>;
  /** Filter by the object’s `salePrice` field. */
  readonly salePrice?: Maybe<FloatFilter>;
  /** Filter by the object’s `category` field. */
  readonly category?: Maybe<StringFilter>;
  /** Filter by the object’s `expired` field. */
  readonly expired?: Maybe<BooleanFilter>;
  /** Filter by the object’s `src` field. */
  readonly src?: Maybe<StringFilter>;
  /** Filter by the object’s `rotated` field. */
  readonly rotated?: Maybe<BooleanFilter>;
  /** Filter by the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<IntFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<ReadonlyArray<ProductFilter>>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<ReadonlyArray<ProductFilter>>;
  /** Negates the expression. */
  readonly not?: Maybe<ProductFilter>;
};

/** An input for mutations affecting `Product` */
export type ProductInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly brand?: Maybe<Scalars['String']>;
  readonly color?: Maybe<Scalars['String']>;
  readonly sku?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
  readonly tags?: Maybe<Scalars['String']>;
  readonly price?: Maybe<Scalars['Float']>;
  readonly dateEntered?: Maybe<Scalars['Datetime']>;
  readonly srcOriginal?: Maybe<Scalars['String']>;
  readonly additionalImages?: Maybe<Scalars['JSON']>;
  readonly additionalFields?: Maybe<Scalars['JSON']>;
  readonly changeHistory?: Maybe<Scalars['JSON']>;
  readonly dateModified?: Maybe<Scalars['Datetime']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly subCategory?: Maybe<Scalars['String']>;
  readonly salePrice?: Maybe<Scalars['Float']>;
  readonly category?: Maybe<Scalars['String']>;
  readonly expired?: Maybe<Scalars['Boolean']>;
  readonly src?: Maybe<Scalars['String']>;
  readonly rotated?: Maybe<Scalars['Boolean']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly catalogToCatalog?: Maybe<FkProductCatalogInput>;
  readonly categoryByCatalogToCategoryAndCatalog?: Maybe<FakePublicProductForeignKey0Input>;
};

/** The fields on `product` to look up the row to update. */
export type ProductOnProductForFakePublicProductForeignKey0UsingPkProductUpdate = {
  /** An object where the defined keys will be set on the `product` being updated. */
  readonly patch: UpdateProductOnProductForFakePublicProductForeignKey0Patch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `product` to look up the row to update. */
export type ProductOnProductForFkProductCatalogUsingPkProductUpdate = {
  /** An object where the defined keys will be set on the `product` being updated. */
  readonly patch: UpdateProductOnProductForFkProductCatalogPatch;
  readonly oid: Scalars['UUID'];
};

/** Represents an update to a `Product`. Fields that are set will be updated. */
export type ProductPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly brand?: Maybe<Scalars['String']>;
  readonly color?: Maybe<Scalars['String']>;
  readonly sku?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
  readonly tags?: Maybe<Scalars['String']>;
  readonly price?: Maybe<Scalars['Float']>;
  readonly dateEntered?: Maybe<Scalars['Datetime']>;
  readonly srcOriginal?: Maybe<Scalars['String']>;
  readonly additionalImages?: Maybe<Scalars['JSON']>;
  readonly additionalFields?: Maybe<Scalars['JSON']>;
  readonly changeHistory?: Maybe<Scalars['JSON']>;
  readonly dateModified?: Maybe<Scalars['Datetime']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly subCategory?: Maybe<Scalars['String']>;
  readonly salePrice?: Maybe<Scalars['Float']>;
  readonly category?: Maybe<Scalars['String']>;
  readonly expired?: Maybe<Scalars['Boolean']>;
  readonly src?: Maybe<Scalars['String']>;
  readonly rotated?: Maybe<Scalars['Boolean']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly catalogToCatalog?: Maybe<FkProductCatalogInput>;
  readonly categoryByCatalogToCategoryAndCatalog?: Maybe<FakePublicProductForeignKey0Input>;
};

/** The fields on `product` to look up the row to connect. */
export type ProductPkProductConnect = {
  readonly oid: Scalars['UUID'];
};

/** The fields on `product` to look up the row to delete. */
export type ProductPkProductDelete = {
  readonly oid: Scalars['UUID'];
};

export type ProductsByVersion = {
  readonly __typename?: 'ProductsByVersion';
  readonly versionId?: Maybe<Scalars['Int']>;
  readonly versionOid?: Maybe<Scalars['UUID']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly sku?: Maybe<Scalars['String']>;
  readonly main?: Maybe<Scalars['String']>;
  readonly sub?: Maybe<Scalars['String']>;
  /** Reads a single `Version` that is related to this `ProductsByVersion`. */
  readonly versionByVersionOid?: Maybe<Version>;
};

/** A condition to be used against `ProductsByVersion` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ProductsByVersionCondition = {
  /** Checks for equality with the object’s `versionId` field. */
  readonly versionId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `versionOid` field. */
  readonly versionOid?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `catalog` field. */
  readonly catalog?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `sku` field. */
  readonly sku?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `main` field. */
  readonly main?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `sub` field. */
  readonly sub?: Maybe<Scalars['String']>;
};

/** A filter to be used against `ProductsByVersion` object types. All fields are combined with a logical ‘and.’ */
export type ProductsByVersionFilter = {
  /** Filter by the object’s `versionId` field. */
  readonly versionId?: Maybe<IntFilter>;
  /** Filter by the object’s `versionOid` field. */
  readonly versionOid?: Maybe<UuidFilter>;
  /** Filter by the object’s `catalog` field. */
  readonly catalog?: Maybe<UuidFilter>;
  /** Filter by the object’s `sku` field. */
  readonly sku?: Maybe<StringFilter>;
  /** Filter by the object’s `main` field. */
  readonly main?: Maybe<StringFilter>;
  /** Filter by the object’s `sub` field. */
  readonly sub?: Maybe<StringFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<ReadonlyArray<ProductsByVersionFilter>>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<ReadonlyArray<ProductsByVersionFilter>>;
  /** Negates the expression. */
  readonly not?: Maybe<ProductsByVersionFilter>;
};

/** A connection to a list of `ProductsByVersion` values. */
export type ProductsByVersionsConnection = {
  readonly __typename?: 'ProductsByVersionsConnection';
  /** A list of `ProductsByVersion` objects. */
  readonly nodes: ReadonlyArray<ProductsByVersion>;
  /** A list of edges which contains the `ProductsByVersion` and cursor to aid in pagination. */
  readonly edges: ReadonlyArray<ProductsByVersionsEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `ProductsByVersion` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `ProductsByVersion` edge in the connection. */
export type ProductsByVersionsEdge = {
  readonly __typename?: 'ProductsByVersionsEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `ProductsByVersion` at the end of the edge. */
  readonly node: ProductsByVersion;
};

/** Methods to use when ordering `ProductsByVersion`. */
export enum ProductsByVersionsOrderBy {
  Natural = 'NATURAL',
  VersionIdAsc = 'VERSION_ID_ASC',
  VersionIdDesc = 'VERSION_ID_DESC',
  VersionOidAsc = 'VERSION_OID_ASC',
  VersionOidDesc = 'VERSION_OID_DESC',
  CatalogAsc = 'CATALOG_ASC',
  CatalogDesc = 'CATALOG_DESC',
  SkuAsc = 'SKU_ASC',
  SkuDesc = 'SKU_DESC',
  MainAsc = 'MAIN_ASC',
  MainDesc = 'MAIN_DESC',
  SubAsc = 'SUB_ASC',
  SubDesc = 'SUB_DESC'
}

/** A connection to a list of `Product` values. */
export type ProductsConnection = {
  readonly __typename?: 'ProductsConnection';
  /** A list of `Product` objects. */
  readonly nodes: ReadonlyArray<Product>;
  /** A list of edges which contains the `Product` and cursor to aid in pagination. */
  readonly edges: ReadonlyArray<ProductsEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Product` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Product` edge in the connection. */
export type ProductsEdge = {
  readonly __typename?: 'ProductsEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Product` at the end of the edge. */
  readonly node: Product;
};

/** Methods to use when ordering `Product`. */
export enum ProductsOrderBy {
  Natural = 'NATURAL',
  OidAsc = 'OID_ASC',
  OidDesc = 'OID_DESC',
  OptionsAsc = 'OPTIONS_ASC',
  OptionsDesc = 'OPTIONS_DESC',
  CatalogAsc = 'CATALOG_ASC',
  CatalogDesc = 'CATALOG_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  BrandAsc = 'BRAND_ASC',
  BrandDesc = 'BRAND_DESC',
  ColorAsc = 'COLOR_ASC',
  ColorDesc = 'COLOR_DESC',
  SkuAsc = 'SKU_ASC',
  SkuDesc = 'SKU_DESC',
  UrlAsc = 'URL_ASC',
  UrlDesc = 'URL_DESC',
  TagsAsc = 'TAGS_ASC',
  TagsDesc = 'TAGS_DESC',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  DateEnteredAsc = 'DATE_ENTERED_ASC',
  DateEnteredDesc = 'DATE_ENTERED_DESC',
  SrcOriginalAsc = 'SRC_ORIGINAL_ASC',
  SrcOriginalDesc = 'SRC_ORIGINAL_DESC',
  AdditionalImagesAsc = 'ADDITIONAL_IMAGES_ASC',
  AdditionalImagesDesc = 'ADDITIONAL_IMAGES_DESC',
  AdditionalFieldsAsc = 'ADDITIONAL_FIELDS_ASC',
  AdditionalFieldsDesc = 'ADDITIONAL_FIELDS_DESC',
  ChangeHistoryAsc = 'CHANGE_HISTORY_ASC',
  ChangeHistoryDesc = 'CHANGE_HISTORY_DESC',
  DateModifiedAsc = 'DATE_MODIFIED_ASC',
  DateModifiedDesc = 'DATE_MODIFIED_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  SubCategoryAsc = 'SUB_CATEGORY_ASC',
  SubCategoryDesc = 'SUB_CATEGORY_DESC',
  SalePriceAsc = 'SALE_PRICE_ASC',
  SalePriceDesc = 'SALE_PRICE_DESC',
  CategoryAsc = 'CATEGORY_ASC',
  CategoryDesc = 'CATEGORY_DESC',
  ExpiredAsc = 'EXPIRED_ASC',
  ExpiredDesc = 'EXPIRED_DESC',
  SrcAsc = 'SRC_ASC',
  SrcDesc = 'SRC_DESC',
  RotatedAsc = 'ROTATED_ASC',
  RotatedDesc = 'ROTATED_DESC',
  OptimisticLockFieldAsc = 'OPTIMISTIC_LOCK_FIELD_ASC',
  OptimisticLockFieldDesc = 'OPTIMISTIC_LOCK_FIELD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type Profile = {
  readonly __typename?: 'Profile';
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly company?: Maybe<Scalars['String']>;
  readonly dateCreated?: Maybe<Scalars['Datetime']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  /** Reads and enables pagination through a set of `LinkOut`. */
  readonly linkOutsByCustomerConnection: LinkOutsConnection;
  /** Reads and enables pagination through a set of `LinkOut`. */
  readonly linkOutsByCustomer: ReadonlyArray<LinkOut>;
  /** Reads and enables pagination through a set of `Subscription`. */
  readonly subscriptionsByCustomerConnection: SubscriptionsConnection;
  /** Reads and enables pagination through a set of `Subscription`. */
  readonly subscriptionsByCustomer: ReadonlyArray<Subscription>;
  /** Reads and enables pagination through a set of `Lead`. */
  readonly leadsByProfileConnection: LeadsConnection;
  /** Reads and enables pagination through a set of `Lead`. */
  readonly leadsByProfile: ReadonlyArray<Lead>;
  /** Reads a single `AuthorizedUser` that is related to this `Profile`. */
  readonly authorizedUserByEmail?: Maybe<AuthorizedUser>;
  /** Reads and enables pagination through a set of `Catalog`. */
  readonly catalogsByLinkOutCustomerAndCatalog: ProfileCatalogsByLinkOutCustomerAndCatalogManyToManyConnection;
  /** Reads and enables pagination through a set of `Catalog`. */
  readonly catalogsByLinkOutCustomerAndCatalogList: ReadonlyArray<Catalog>;
  /** Reads and enables pagination through a set of `Catalog`. */
  readonly catalogsBySubscriptionCustomerAndCatalog: ProfileCatalogsBySubscriptionCustomerAndCatalogManyToManyConnection;
  /** Reads and enables pagination through a set of `Catalog`. */
  readonly catalogsBySubscriptionCustomerAndCatalogList: ReadonlyArray<Catalog>;
  /** Reads and enables pagination through a set of `Account`. */
  readonly accountsByLeadProfileAndAccount: ProfileAccountsByLeadProfileAndAccountManyToManyConnection;
  /** Reads and enables pagination through a set of `Account`. */
  readonly accountsByLeadProfileAndAccountList: ReadonlyArray<Account>;
};


export type ProfileLinkOutsByCustomerConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<LinkOutsOrderBy>>;
  condition?: Maybe<LinkOutCondition>;
  filter?: Maybe<LinkOutFilter>;
};


export type ProfileLinkOutsByCustomerArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<LinkOutsOrderBy>>;
  condition?: Maybe<LinkOutCondition>;
  filter?: Maybe<LinkOutFilter>;
};


export type ProfileSubscriptionsByCustomerConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<SubscriptionsOrderBy>>;
  condition?: Maybe<SubscriptionCondition>;
  filter?: Maybe<SubscriptionFilter>;
};


export type ProfileSubscriptionsByCustomerArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<SubscriptionsOrderBy>>;
  condition?: Maybe<SubscriptionCondition>;
  filter?: Maybe<SubscriptionFilter>;
};


export type ProfileLeadsByProfileConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};


export type ProfileLeadsByProfileArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};


export type ProfileCatalogsByLinkOutCustomerAndCatalogArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CatalogsOrderBy>>;
  condition?: Maybe<CatalogCondition>;
  filter?: Maybe<CatalogFilter>;
};


export type ProfileCatalogsByLinkOutCustomerAndCatalogListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CatalogsOrderBy>>;
  condition?: Maybe<CatalogCondition>;
  filter?: Maybe<CatalogFilter>;
};


export type ProfileCatalogsBySubscriptionCustomerAndCatalogArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CatalogsOrderBy>>;
  condition?: Maybe<CatalogCondition>;
  filter?: Maybe<CatalogFilter>;
};


export type ProfileCatalogsBySubscriptionCustomerAndCatalogListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CatalogsOrderBy>>;
  condition?: Maybe<CatalogCondition>;
  filter?: Maybe<CatalogFilter>;
};


export type ProfileAccountsByLeadProfileAndAccountArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<AccountsOrderBy>>;
  condition?: Maybe<AccountCondition>;
  filter?: Maybe<AccountFilter>;
};


export type ProfileAccountsByLeadProfileAndAccountListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<AccountsOrderBy>>;
  condition?: Maybe<AccountCondition>;
  filter?: Maybe<AccountFilter>;
};

/** A connection to a list of `Account` values, with data from `Lead`. */
export type ProfileAccountsByLeadProfileAndAccountManyToManyConnection = {
  readonly __typename?: 'ProfileAccountsByLeadProfileAndAccountManyToManyConnection';
  /** A list of `Account` objects. */
  readonly nodes: ReadonlyArray<Account>;
  /** A list of edges which contains the `Account`, info from the `Lead`, and the cursor to aid in pagination. */
  readonly edges: ReadonlyArray<ProfileAccountsByLeadProfileAndAccountManyToManyEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Account` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Account` edge in the connection, with data from `Lead`. */
export type ProfileAccountsByLeadProfileAndAccountManyToManyEdge = {
  readonly __typename?: 'ProfileAccountsByLeadProfileAndAccountManyToManyEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Account` at the end of the edge. */
  readonly node: Account;
  /** Reads and enables pagination through a set of `Lead`. */
  readonly leadsByAccountConnection: LeadsConnection;
  /** Reads and enables pagination through a set of `Lead`. */
  readonly leadsByAccount: ReadonlyArray<Lead>;
};


/** A `Account` edge in the connection, with data from `Lead`. */
export type ProfileAccountsByLeadProfileAndAccountManyToManyEdgeLeadsByAccountConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};


/** A `Account` edge in the connection, with data from `Lead`. */
export type ProfileAccountsByLeadProfileAndAccountManyToManyEdgeLeadsByAccountArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};

/** A connection to a list of `Catalog` values, with data from `LinkOut`. */
export type ProfileCatalogsByLinkOutCustomerAndCatalogManyToManyConnection = {
  readonly __typename?: 'ProfileCatalogsByLinkOutCustomerAndCatalogManyToManyConnection';
  /** A list of `Catalog` objects. */
  readonly nodes: ReadonlyArray<Catalog>;
  /** A list of edges which contains the `Catalog`, info from the `LinkOut`, and the cursor to aid in pagination. */
  readonly edges: ReadonlyArray<ProfileCatalogsByLinkOutCustomerAndCatalogManyToManyEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Catalog` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Catalog` edge in the connection, with data from `LinkOut`. */
export type ProfileCatalogsByLinkOutCustomerAndCatalogManyToManyEdge = {
  readonly __typename?: 'ProfileCatalogsByLinkOutCustomerAndCatalogManyToManyEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Catalog` at the end of the edge. */
  readonly node: Catalog;
  /** Reads and enables pagination through a set of `LinkOut`. */
  readonly linkOutsByCatalogConnection: LinkOutsConnection;
  /** Reads and enables pagination through a set of `LinkOut`. */
  readonly linkOutsByCatalog: ReadonlyArray<LinkOut>;
};


/** A `Catalog` edge in the connection, with data from `LinkOut`. */
export type ProfileCatalogsByLinkOutCustomerAndCatalogManyToManyEdgeLinkOutsByCatalogConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<LinkOutsOrderBy>>;
  condition?: Maybe<LinkOutCondition>;
  filter?: Maybe<LinkOutFilter>;
};


/** A `Catalog` edge in the connection, with data from `LinkOut`. */
export type ProfileCatalogsByLinkOutCustomerAndCatalogManyToManyEdgeLinkOutsByCatalogArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<LinkOutsOrderBy>>;
  condition?: Maybe<LinkOutCondition>;
  filter?: Maybe<LinkOutFilter>;
};

/** A connection to a list of `Catalog` values, with data from `Subscription`. */
export type ProfileCatalogsBySubscriptionCustomerAndCatalogManyToManyConnection = {
  readonly __typename?: 'ProfileCatalogsBySubscriptionCustomerAndCatalogManyToManyConnection';
  /** A list of `Catalog` objects. */
  readonly nodes: ReadonlyArray<Catalog>;
  /** A list of edges which contains the `Catalog`, info from the `Subscription`, and the cursor to aid in pagination. */
  readonly edges: ReadonlyArray<ProfileCatalogsBySubscriptionCustomerAndCatalogManyToManyEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Catalog` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Catalog` edge in the connection, with data from `Subscription`. */
export type ProfileCatalogsBySubscriptionCustomerAndCatalogManyToManyEdge = {
  readonly __typename?: 'ProfileCatalogsBySubscriptionCustomerAndCatalogManyToManyEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Catalog` at the end of the edge. */
  readonly node: Catalog;
  /** Reads and enables pagination through a set of `Subscription`. */
  readonly subscriptionsByCatalogConnection: SubscriptionsConnection;
  /** Reads and enables pagination through a set of `Subscription`. */
  readonly subscriptionsByCatalog: ReadonlyArray<Subscription>;
};


/** A `Catalog` edge in the connection, with data from `Subscription`. */
export type ProfileCatalogsBySubscriptionCustomerAndCatalogManyToManyEdgeSubscriptionsByCatalogConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<SubscriptionsOrderBy>>;
  condition?: Maybe<SubscriptionCondition>;
  filter?: Maybe<SubscriptionFilter>;
};


/** A `Catalog` edge in the connection, with data from `Subscription`. */
export type ProfileCatalogsBySubscriptionCustomerAndCatalogManyToManyEdgeSubscriptionsByCatalogArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<SubscriptionsOrderBy>>;
  condition?: Maybe<SubscriptionCondition>;
  filter?: Maybe<SubscriptionFilter>;
};

/** A condition to be used against `Profile` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ProfileCondition = {
  /** Checks for equality with the object’s `oid` field. */
  readonly oid?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `options` field. */
  readonly options?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `imageUrl` field. */
  readonly imageUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `email` field. */
  readonly email?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `phone` field. */
  readonly phone?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `street` field. */
  readonly street?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `city` field. */
  readonly city?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `state` field. */
  readonly state?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `zipPostal` field. */
  readonly zipPostal?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `country` field. */
  readonly country?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `title` field. */
  readonly title?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `firstName` field. */
  readonly firstName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `lastName` field. */
  readonly lastName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `company` field. */
  readonly company?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `dateCreated` field. */
  readonly dateCreated?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `Profile` object types. All fields are combined with a logical ‘and.’ */
export type ProfileFilter = {
  /** Filter by the object’s `oid` field. */
  readonly oid?: Maybe<UuidFilter>;
  /** Filter by the object’s `imageUrl` field. */
  readonly imageUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `email` field. */
  readonly email?: Maybe<StringFilter>;
  /** Filter by the object’s `phone` field. */
  readonly phone?: Maybe<StringFilter>;
  /** Filter by the object’s `street` field. */
  readonly street?: Maybe<StringFilter>;
  /** Filter by the object’s `city` field. */
  readonly city?: Maybe<StringFilter>;
  /** Filter by the object’s `state` field. */
  readonly state?: Maybe<StringFilter>;
  /** Filter by the object’s `zipPostal` field. */
  readonly zipPostal?: Maybe<StringFilter>;
  /** Filter by the object’s `country` field. */
  readonly country?: Maybe<StringFilter>;
  /** Filter by the object’s `title` field. */
  readonly title?: Maybe<StringFilter>;
  /** Filter by the object’s `firstName` field. */
  readonly firstName?: Maybe<StringFilter>;
  /** Filter by the object’s `lastName` field. */
  readonly lastName?: Maybe<StringFilter>;
  /** Filter by the object’s `company` field. */
  readonly company?: Maybe<StringFilter>;
  /** Filter by the object’s `dateCreated` field. */
  readonly dateCreated?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<IntFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<ReadonlyArray<ProfileFilter>>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<ReadonlyArray<ProfileFilter>>;
  /** Negates the expression. */
  readonly not?: Maybe<ProfileFilter>;
};

/** The fields on `profile` to look up the row to connect. */
export type ProfileIEmailProfileConnect = {
  readonly email: Scalars['String'];
};

/** The fields on `profile` to look up the row to delete. */
export type ProfileIEmailProfileDelete = {
  readonly email: Scalars['String'];
};

/** An input for mutations affecting `Profile` */
export type ProfileInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly company?: Maybe<Scalars['String']>;
  readonly dateCreated?: Maybe<Scalars['Datetime']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCustomerInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCustomerInverseInput>;
  readonly leadsUsingOid?: Maybe<FkLeadProfileInverseInput>;
  readonly authorizedUserUsingEmail?: Maybe<FakePublicAuthorizedUserForeignKey0InverseInput>;
};

/** The fields on `profile` to look up the row to update. */
export type ProfileOnAuthorizedUserForFakePublicAuthorizedUserForeignKey0UsingIEmailProfileUpdate = {
  /** An object where the defined keys will be set on the `profile` being updated. */
  readonly patch: UpdateProfileOnAuthorizedUserForFakePublicAuthorizedUserForeignKey0Patch;
  readonly email: Scalars['String'];
};

/** The fields on `profile` to look up the row to update. */
export type ProfileOnAuthorizedUserForFakePublicAuthorizedUserForeignKey0UsingPkProfileUpdate = {
  /** An object where the defined keys will be set on the `profile` being updated. */
  readonly patch: UpdateProfileOnAuthorizedUserForFakePublicAuthorizedUserForeignKey0Patch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `profile` to look up the row to update. */
export type ProfileOnLeadForFkLeadProfileUsingIEmailProfileUpdate = {
  /** An object where the defined keys will be set on the `profile` being updated. */
  readonly patch: UpdateProfileOnLeadForFkLeadProfilePatch;
  readonly email: Scalars['String'];
};

/** The fields on `profile` to look up the row to update. */
export type ProfileOnLeadForFkLeadProfileUsingPkProfileUpdate = {
  /** An object where the defined keys will be set on the `profile` being updated. */
  readonly patch: UpdateProfileOnLeadForFkLeadProfilePatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `profile` to look up the row to update. */
export type ProfileOnLinkOutForFkLinkOutCustomerUsingIEmailProfileUpdate = {
  /** An object where the defined keys will be set on the `profile` being updated. */
  readonly patch: UpdateProfileOnLinkOutForFkLinkOutCustomerPatch;
  readonly email: Scalars['String'];
};

/** The fields on `profile` to look up the row to update. */
export type ProfileOnLinkOutForFkLinkOutCustomerUsingPkProfileUpdate = {
  /** An object where the defined keys will be set on the `profile` being updated. */
  readonly patch: UpdateProfileOnLinkOutForFkLinkOutCustomerPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `profile` to look up the row to update. */
export type ProfileOnSubscriptionForFkSubscriptionCustomerUsingIEmailProfileUpdate = {
  /** An object where the defined keys will be set on the `profile` being updated. */
  readonly patch: UpdateProfileOnSubscriptionForFkSubscriptionCustomerPatch;
  readonly email: Scalars['String'];
};

/** The fields on `profile` to look up the row to update. */
export type ProfileOnSubscriptionForFkSubscriptionCustomerUsingPkProfileUpdate = {
  /** An object where the defined keys will be set on the `profile` being updated. */
  readonly patch: UpdateProfileOnSubscriptionForFkSubscriptionCustomerPatch;
  readonly oid: Scalars['UUID'];
};

/** Represents an update to a `Profile`. Fields that are set will be updated. */
export type ProfilePatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly company?: Maybe<Scalars['String']>;
  readonly dateCreated?: Maybe<Scalars['Datetime']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCustomerInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCustomerInverseInput>;
  readonly leadsUsingOid?: Maybe<FkLeadProfileInverseInput>;
  readonly authorizedUserUsingEmail?: Maybe<FakePublicAuthorizedUserForeignKey0InverseInput>;
};

/** The fields on `profile` to look up the row to connect. */
export type ProfilePkProfileConnect = {
  readonly oid: Scalars['UUID'];
};

/** The fields on `profile` to look up the row to delete. */
export type ProfilePkProfileDelete = {
  readonly oid: Scalars['UUID'];
};

/** A connection to a list of `Profile` values. */
export type ProfilesConnection = {
  readonly __typename?: 'ProfilesConnection';
  /** A list of `Profile` objects. */
  readonly nodes: ReadonlyArray<Profile>;
  /** A list of edges which contains the `Profile` and cursor to aid in pagination. */
  readonly edges: ReadonlyArray<ProfilesEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Profile` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Profile` edge in the connection. */
export type ProfilesEdge = {
  readonly __typename?: 'ProfilesEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Profile` at the end of the edge. */
  readonly node: Profile;
};

/** Methods to use when ordering `Profile`. */
export enum ProfilesOrderBy {
  Natural = 'NATURAL',
  OidAsc = 'OID_ASC',
  OidDesc = 'OID_DESC',
  OptionsAsc = 'OPTIONS_ASC',
  OptionsDesc = 'OPTIONS_DESC',
  ImageUrlAsc = 'IMAGE_URL_ASC',
  ImageUrlDesc = 'IMAGE_URL_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  PhoneAsc = 'PHONE_ASC',
  PhoneDesc = 'PHONE_DESC',
  StreetAsc = 'STREET_ASC',
  StreetDesc = 'STREET_DESC',
  CityAsc = 'CITY_ASC',
  CityDesc = 'CITY_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  ZipPostalAsc = 'ZIP_POSTAL_ASC',
  ZipPostalDesc = 'ZIP_POSTAL_DESC',
  CountryAsc = 'COUNTRY_ASC',
  CountryDesc = 'COUNTRY_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  CompanyAsc = 'COMPANY_ASC',
  CompanyDesc = 'COMPANY_DESC',
  DateCreatedAsc = 'DATE_CREATED_ASC',
  DateCreatedDesc = 'DATE_CREATED_DESC',
  OptimisticLockFieldAsc = 'OPTIMISTIC_LOCK_FIELD_ASC',
  OptimisticLockFieldDesc = 'OPTIMISTIC_LOCK_FIELD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** The root query type which gives access points into the data universe. */
export type Query = {
  readonly __typename?: 'Query';
  /** Exposes the root query type nested one level down. This is helpful for Relay 1 which can only query top level fields if they are in a particular form. */
  readonly query: Query;
  /** Reads and enables pagination through a set of `Account`. */
  readonly accountsConnection?: Maybe<AccountsConnection>;
  /** Reads a set of `Account`. */
  readonly accounts?: Maybe<ReadonlyArray<Account>>;
  /** Reads and enables pagination through a set of `AuthorizedUser`. */
  readonly authorizedUsersConnection?: Maybe<AuthorizedUsersConnection>;
  /** Reads a set of `AuthorizedUser`. */
  readonly authorizedUsers?: Maybe<ReadonlyArray<AuthorizedUser>>;
  /** Reads and enables pagination through a set of `AuthorizedUserAuthorizedUsersAccountPartnerAccess`. */
  readonly authorizedUserAuthorizedUsersAccountPartnerAccessesConnection?: Maybe<AuthorizedUserAuthorizedUsersAccountPartnerAccessesConnection>;
  /** Reads a set of `AuthorizedUserAuthorizedUsersAccountPartnerAccess`. */
  readonly authorizedUserAuthorizedUsersAccountPartnerAccesses?: Maybe<ReadonlyArray<AuthorizedUserAuthorizedUsersAccountPartnerAccess>>;
  /** Reads and enables pagination through a set of `Campaign`. */
  readonly campaignsConnection?: Maybe<CampaignsConnection>;
  /** Reads a set of `Campaign`. */
  readonly campaigns?: Maybe<ReadonlyArray<Campaign>>;
  /** Reads and enables pagination through a set of `CampaignGroup`. */
  readonly campaignGroupsConnection?: Maybe<CampaignGroupsConnection>;
  /** Reads a set of `CampaignGroup`. */
  readonly campaignGroups?: Maybe<ReadonlyArray<CampaignGroup>>;
  /** Reads and enables pagination through a set of `CampaignUser`. */
  readonly campaignUsersConnection?: Maybe<CampaignUsersConnection>;
  /** Reads a set of `CampaignUser`. */
  readonly campaignUsers?: Maybe<ReadonlyArray<CampaignUser>>;
  /** Reads and enables pagination through a set of `Carousel`. */
  readonly carouselsConnection?: Maybe<CarouselsConnection>;
  /** Reads a set of `Carousel`. */
  readonly carousels?: Maybe<ReadonlyArray<Carousel>>;
  /** Reads and enables pagination through a set of `CarouselListing`. */
  readonly carouselListingsConnection?: Maybe<CarouselListingsConnection>;
  /** Reads a set of `CarouselListing`. */
  readonly carouselListings?: Maybe<ReadonlyArray<CarouselListing>>;
  /** Reads and enables pagination through a set of `Catalog`. */
  readonly catalogsConnection?: Maybe<CatalogsConnection>;
  /** Reads a set of `Catalog`. */
  readonly catalogs?: Maybe<ReadonlyArray<Catalog>>;
  /** Reads and enables pagination through a set of `Category`. */
  readonly categoriesConnection?: Maybe<CategoriesConnection>;
  /** Reads a set of `Category`. */
  readonly categories?: Maybe<ReadonlyArray<Category>>;
  /** Reads and enables pagination through a set of `CategoryByCatalog`. */
  readonly categoryByCatalogsConnection?: Maybe<CategoryByCatalogsConnection>;
  /** Reads a set of `CategoryByCatalog`. */
  readonly categoryByCatalogs?: Maybe<ReadonlyArray<CategoryByCatalog>>;
  /** Reads and enables pagination through a set of `Contact`. */
  readonly contactsConnection?: Maybe<ContactsConnection>;
  /** Reads a set of `Contact`. */
  readonly contacts?: Maybe<ReadonlyArray<Contact>>;
  /** Reads and enables pagination through a set of `Department`. */
  readonly departmentsConnection?: Maybe<DepartmentsConnection>;
  /** Reads a set of `Department`. */
  readonly departments?: Maybe<ReadonlyArray<Department>>;
  /** Reads and enables pagination through a set of `DepartmentDepartmentsCatalogListing`. */
  readonly departmentDepartmentsCatalogListingsConnection?: Maybe<DepartmentDepartmentsCatalogListingsConnection>;
  /** Reads a set of `DepartmentDepartmentsCatalogListing`. */
  readonly departmentDepartmentsCatalogListings?: Maybe<ReadonlyArray<DepartmentDepartmentsCatalogListing>>;
  /** Reads and enables pagination through a set of `Feed`. */
  readonly feedsConnection?: Maybe<FeedsConnection>;
  /** Reads a set of `Feed`. */
  readonly feeds?: Maybe<ReadonlyArray<Feed>>;
  /** Reads and enables pagination through a set of `FeedHistory`. */
  readonly feedHistoriesConnection?: Maybe<FeedHistoriesConnection>;
  /** Reads a set of `FeedHistory`. */
  readonly feedHistories?: Maybe<ReadonlyArray<FeedHistory>>;
  /** Reads and enables pagination through a set of `Lead`. */
  readonly leadsConnection?: Maybe<LeadsConnection>;
  /** Reads a set of `Lead`. */
  readonly leads?: Maybe<ReadonlyArray<Lead>>;
  /** Reads and enables pagination through a set of `LinkOut`. */
  readonly linkOutsConnection?: Maybe<LinkOutsConnection>;
  /** Reads a set of `LinkOut`. */
  readonly linkOuts?: Maybe<ReadonlyArray<LinkOut>>;
  /** Reads and enables pagination through a set of `NavigationItem`. */
  readonly navigationItemsConnection?: Maybe<NavigationItemsConnection>;
  /** Reads a set of `NavigationItem`. */
  readonly navigationItems?: Maybe<ReadonlyArray<NavigationItem>>;
  /** Reads and enables pagination through a set of `Offer`. */
  readonly offersConnection?: Maybe<OffersConnection>;
  /** Reads a set of `Offer`. */
  readonly offers?: Maybe<ReadonlyArray<Offer>>;
  /** Reads and enables pagination through a set of `Order`. */
  readonly ordersConnection?: Maybe<OrdersConnection>;
  /** Reads a set of `Order`. */
  readonly orders?: Maybe<ReadonlyArray<Order>>;
  /** Reads and enables pagination through a set of `OrderProduct`. */
  readonly orderProductsConnection?: Maybe<OrderProductsConnection>;
  /** Reads a set of `OrderProduct`. */
  readonly orderProducts?: Maybe<ReadonlyArray<OrderProduct>>;
  /** Reads and enables pagination through a set of `Page`. */
  readonly pagesConnection?: Maybe<PagesConnection>;
  /** Reads a set of `Page`. */
  readonly pages?: Maybe<ReadonlyArray<Page>>;
  /** Reads and enables pagination through a set of `Product`. */
  readonly productsConnection?: Maybe<ProductsConnection>;
  /** Reads a set of `Product`. */
  readonly products?: Maybe<ReadonlyArray<Product>>;
  /** Reads and enables pagination through a set of `ProductsByVersion`. */
  readonly productsByVersionsConnection?: Maybe<ProductsByVersionsConnection>;
  /** Reads a set of `ProductsByVersion`. */
  readonly productsByVersions?: Maybe<ReadonlyArray<ProductsByVersion>>;
  /** Reads and enables pagination through a set of `Profile`. */
  readonly profilesConnection?: Maybe<ProfilesConnection>;
  /** Reads a set of `Profile`. */
  readonly profiles?: Maybe<ReadonlyArray<Profile>>;
  /** Reads and enables pagination through a set of `SubCategory`. */
  readonly subCategoriesConnection?: Maybe<SubCategoriesConnection>;
  /** Reads a set of `SubCategory`. */
  readonly subCategories?: Maybe<ReadonlyArray<SubCategory>>;
  /** Reads and enables pagination through a set of `SubProduct`. */
  readonly subProductsConnection?: Maybe<SubProductsConnection>;
  /** Reads a set of `SubProduct`. */
  readonly subProducts?: Maybe<ReadonlyArray<SubProduct>>;
  /** Reads and enables pagination through a set of `Subscription`. */
  readonly subscriptionsConnection?: Maybe<SubscriptionsConnection>;
  /** Reads a set of `Subscription`. */
  readonly subscriptions?: Maybe<ReadonlyArray<Subscription>>;
  /** Reads and enables pagination through a set of `Version`. */
  readonly versionsConnection?: Maybe<VersionsConnection>;
  /** Reads a set of `Version`. */
  readonly versions?: Maybe<ReadonlyArray<Version>>;
  /** Reads and enables pagination through a set of `CatalogSearch`. */
  readonly catalogSearchesConnection?: Maybe<CatalogSearchesConnection>;
  /** Reads a set of `CatalogSearch`. */
  readonly catalogSearches?: Maybe<ReadonlyArray<CatalogSearch>>;
  /** Reads and enables pagination through a set of `CatalogSearchLive`. */
  readonly catalogSearchLivesConnection?: Maybe<CatalogSearchLivesConnection>;
  /** Reads a set of `CatalogSearchLive`. */
  readonly catalogSearchLives?: Maybe<ReadonlyArray<CatalogSearchLive>>;
  readonly account?: Maybe<Account>;
  readonly authorizedUser?: Maybe<AuthorizedUser>;
  readonly authorizedUserByEmail?: Maybe<AuthorizedUser>;
  readonly authorizedUserAuthorizedUsersAccountPartnerAccess?: Maybe<AuthorizedUserAuthorizedUsersAccountPartnerAccess>;
  readonly campaign?: Maybe<Campaign>;
  readonly campaignGroup?: Maybe<CampaignGroup>;
  readonly campaignUser?: Maybe<CampaignUser>;
  readonly carousel?: Maybe<Carousel>;
  readonly carouselListing?: Maybe<CarouselListing>;
  readonly catalog?: Maybe<Catalog>;
  readonly catalogById?: Maybe<Catalog>;
  readonly category?: Maybe<Category>;
  readonly categoryById?: Maybe<Category>;
  readonly contact?: Maybe<Contact>;
  readonly department?: Maybe<Department>;
  readonly departmentDepartmentsCatalogListing?: Maybe<DepartmentDepartmentsCatalogListing>;
  readonly feed?: Maybe<Feed>;
  readonly feedHistory?: Maybe<FeedHistory>;
  readonly lead?: Maybe<Lead>;
  readonly linkOut?: Maybe<LinkOut>;
  readonly navigationItem?: Maybe<NavigationItem>;
  readonly offer?: Maybe<Offer>;
  readonly order?: Maybe<Order>;
  readonly orderProduct?: Maybe<OrderProduct>;
  readonly page?: Maybe<Page>;
  readonly product?: Maybe<Product>;
  readonly profile?: Maybe<Profile>;
  readonly profileByEmail?: Maybe<Profile>;
  readonly subCategory?: Maybe<SubCategory>;
  readonly subCategoryById?: Maybe<SubCategory>;
  readonly subscription?: Maybe<Subscription>;
  readonly version?: Maybe<Version>;
  readonly versionById?: Maybe<Version>;
  readonly jsonIntext?: Maybe<Scalars['JSON']>;
  /** Reads and enables pagination through a set of `Catalog`. */
  readonly searchCatalogConnection: CatalogsConnection;
  /** Reads and enables pagination through a set of `Catalog`. */
  readonly searchCatalog?: Maybe<ReadonlyArray<Catalog>>;
  /** Reads and enables pagination through a set of `Catalog`. */
  readonly searchCatalogDeptConnection: CatalogsConnection;
  /** Reads and enables pagination through a set of `Catalog`. */
  readonly searchCatalogDept?: Maybe<ReadonlyArray<Catalog>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAccountsConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<AccountsOrderBy>>;
  condition?: Maybe<AccountCondition>;
  filter?: Maybe<AccountFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAccountsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<AccountsOrderBy>>;
  condition?: Maybe<AccountCondition>;
  filter?: Maybe<AccountFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAuthorizedUsersConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<AuthorizedUsersOrderBy>>;
  condition?: Maybe<AuthorizedUserCondition>;
  filter?: Maybe<AuthorizedUserFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAuthorizedUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<AuthorizedUsersOrderBy>>;
  condition?: Maybe<AuthorizedUserCondition>;
  filter?: Maybe<AuthorizedUserFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAuthorizedUserAuthorizedUsersAccountPartnerAccessesConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<AuthorizedUserAuthorizedUsersAccountPartnerAccessesOrderBy>>;
  condition?: Maybe<AuthorizedUserAuthorizedUsersAccountPartnerAccessCondition>;
  filter?: Maybe<AuthorizedUserAuthorizedUsersAccountPartnerAccessFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAuthorizedUserAuthorizedUsersAccountPartnerAccessesArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<AuthorizedUserAuthorizedUsersAccountPartnerAccessesOrderBy>>;
  condition?: Maybe<AuthorizedUserAuthorizedUsersAccountPartnerAccessCondition>;
  filter?: Maybe<AuthorizedUserAuthorizedUsersAccountPartnerAccessFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCampaignsConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CampaignsOrderBy>>;
  condition?: Maybe<CampaignCondition>;
  filter?: Maybe<CampaignFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCampaignsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CampaignsOrderBy>>;
  condition?: Maybe<CampaignCondition>;
  filter?: Maybe<CampaignFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCampaignGroupsConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CampaignGroupsOrderBy>>;
  condition?: Maybe<CampaignGroupCondition>;
  filter?: Maybe<CampaignGroupFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCampaignGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CampaignGroupsOrderBy>>;
  condition?: Maybe<CampaignGroupCondition>;
  filter?: Maybe<CampaignGroupFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCampaignUsersConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CampaignUsersOrderBy>>;
  condition?: Maybe<CampaignUserCondition>;
  filter?: Maybe<CampaignUserFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCampaignUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CampaignUsersOrderBy>>;
  condition?: Maybe<CampaignUserCondition>;
  filter?: Maybe<CampaignUserFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCarouselsConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CarouselsOrderBy>>;
  condition?: Maybe<CarouselCondition>;
  filter?: Maybe<CarouselFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCarouselsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CarouselsOrderBy>>;
  condition?: Maybe<CarouselCondition>;
  filter?: Maybe<CarouselFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCarouselListingsConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CarouselListingsOrderBy>>;
  condition?: Maybe<CarouselListingCondition>;
  filter?: Maybe<CarouselListingFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCarouselListingsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CarouselListingsOrderBy>>;
  condition?: Maybe<CarouselListingCondition>;
  filter?: Maybe<CarouselListingFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCatalogsConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CatalogsOrderBy>>;
  condition?: Maybe<CatalogCondition>;
  filter?: Maybe<CatalogFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCatalogsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CatalogsOrderBy>>;
  condition?: Maybe<CatalogCondition>;
  filter?: Maybe<CatalogFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCategoriesConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CategoriesOrderBy>>;
  condition?: Maybe<CategoryCondition>;
  filter?: Maybe<CategoryFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCategoriesArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CategoriesOrderBy>>;
  condition?: Maybe<CategoryCondition>;
  filter?: Maybe<CategoryFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCategoryByCatalogsConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CategoryByCatalogsOrderBy>>;
  condition?: Maybe<CategoryByCatalogCondition>;
  filter?: Maybe<CategoryByCatalogFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCategoryByCatalogsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CategoryByCatalogsOrderBy>>;
  condition?: Maybe<CategoryByCatalogCondition>;
  filter?: Maybe<CategoryByCatalogFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryContactsConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<ContactsOrderBy>>;
  condition?: Maybe<ContactCondition>;
  filter?: Maybe<ContactFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryContactsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<ContactsOrderBy>>;
  condition?: Maybe<ContactCondition>;
  filter?: Maybe<ContactFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDepartmentsConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<DepartmentsOrderBy>>;
  condition?: Maybe<DepartmentCondition>;
  filter?: Maybe<DepartmentFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDepartmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<DepartmentsOrderBy>>;
  condition?: Maybe<DepartmentCondition>;
  filter?: Maybe<DepartmentFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDepartmentDepartmentsCatalogListingsConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<DepartmentDepartmentsCatalogListingsOrderBy>>;
  condition?: Maybe<DepartmentDepartmentsCatalogListingCondition>;
  filter?: Maybe<DepartmentDepartmentsCatalogListingFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDepartmentDepartmentsCatalogListingsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<DepartmentDepartmentsCatalogListingsOrderBy>>;
  condition?: Maybe<DepartmentDepartmentsCatalogListingCondition>;
  filter?: Maybe<DepartmentDepartmentsCatalogListingFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFeedsConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<FeedsOrderBy>>;
  condition?: Maybe<FeedCondition>;
  filter?: Maybe<FeedFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFeedsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<FeedsOrderBy>>;
  condition?: Maybe<FeedCondition>;
  filter?: Maybe<FeedFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFeedHistoriesConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<FeedHistoriesOrderBy>>;
  condition?: Maybe<FeedHistoryCondition>;
  filter?: Maybe<FeedHistoryFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFeedHistoriesArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<FeedHistoriesOrderBy>>;
  condition?: Maybe<FeedHistoryCondition>;
  filter?: Maybe<FeedHistoryFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLeadsConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLeadsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLinkOutsConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<LinkOutsOrderBy>>;
  condition?: Maybe<LinkOutCondition>;
  filter?: Maybe<LinkOutFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLinkOutsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<LinkOutsOrderBy>>;
  condition?: Maybe<LinkOutCondition>;
  filter?: Maybe<LinkOutFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryNavigationItemsConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<NavigationItemsOrderBy>>;
  condition?: Maybe<NavigationItemCondition>;
  filter?: Maybe<NavigationItemFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryNavigationItemsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<NavigationItemsOrderBy>>;
  condition?: Maybe<NavigationItemCondition>;
  filter?: Maybe<NavigationItemFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryOffersConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<OffersOrderBy>>;
  condition?: Maybe<OfferCondition>;
  filter?: Maybe<OfferFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryOffersArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<OffersOrderBy>>;
  condition?: Maybe<OfferCondition>;
  filter?: Maybe<OfferFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryOrdersConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<OrdersOrderBy>>;
  condition?: Maybe<OrderCondition>;
  filter?: Maybe<OrderFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryOrdersArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<OrdersOrderBy>>;
  condition?: Maybe<OrderCondition>;
  filter?: Maybe<OrderFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryOrderProductsConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<OrderProductsOrderBy>>;
  condition?: Maybe<OrderProductCondition>;
  filter?: Maybe<OrderProductFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryOrderProductsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<OrderProductsOrderBy>>;
  condition?: Maybe<OrderProductCondition>;
  filter?: Maybe<OrderProductFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPagesConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<PagesOrderBy>>;
  condition?: Maybe<PageCondition>;
  filter?: Maybe<PageFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPagesArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<PagesOrderBy>>;
  condition?: Maybe<PageCondition>;
  filter?: Maybe<PageFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProductsConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<ProductsOrderBy>>;
  condition?: Maybe<ProductCondition>;
  filter?: Maybe<ProductFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProductsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<ProductsOrderBy>>;
  condition?: Maybe<ProductCondition>;
  filter?: Maybe<ProductFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProductsByVersionsConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<ProductsByVersionsOrderBy>>;
  condition?: Maybe<ProductsByVersionCondition>;
  filter?: Maybe<ProductsByVersionFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProductsByVersionsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<ProductsByVersionsOrderBy>>;
  condition?: Maybe<ProductsByVersionCondition>;
  filter?: Maybe<ProductsByVersionFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProfilesConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<ProfilesOrderBy>>;
  condition?: Maybe<ProfileCondition>;
  filter?: Maybe<ProfileFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProfilesArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<ProfilesOrderBy>>;
  condition?: Maybe<ProfileCondition>;
  filter?: Maybe<ProfileFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySubCategoriesConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<SubCategoriesOrderBy>>;
  condition?: Maybe<SubCategoryCondition>;
  filter?: Maybe<SubCategoryFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySubCategoriesArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<SubCategoriesOrderBy>>;
  condition?: Maybe<SubCategoryCondition>;
  filter?: Maybe<SubCategoryFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySubProductsConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<SubProductsOrderBy>>;
  condition?: Maybe<SubProductCondition>;
  filter?: Maybe<SubProductFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySubProductsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<SubProductsOrderBy>>;
  condition?: Maybe<SubProductCondition>;
  filter?: Maybe<SubProductFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySubscriptionsConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<SubscriptionsOrderBy>>;
  condition?: Maybe<SubscriptionCondition>;
  filter?: Maybe<SubscriptionFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySubscriptionsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<SubscriptionsOrderBy>>;
  condition?: Maybe<SubscriptionCondition>;
  filter?: Maybe<SubscriptionFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryVersionsConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<VersionsOrderBy>>;
  condition?: Maybe<VersionCondition>;
  filter?: Maybe<VersionFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryVersionsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<VersionsOrderBy>>;
  condition?: Maybe<VersionCondition>;
  filter?: Maybe<VersionFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCatalogSearchesConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CatalogSearchesOrderBy>>;
  condition?: Maybe<CatalogSearchCondition>;
  filter?: Maybe<CatalogSearchFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCatalogSearchesArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CatalogSearchesOrderBy>>;
  condition?: Maybe<CatalogSearchCondition>;
  filter?: Maybe<CatalogSearchFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCatalogSearchLivesConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CatalogSearchLivesOrderBy>>;
  condition?: Maybe<CatalogSearchLiveCondition>;
  filter?: Maybe<CatalogSearchLiveFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCatalogSearchLivesArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CatalogSearchLivesOrderBy>>;
  condition?: Maybe<CatalogSearchLiveCondition>;
  filter?: Maybe<CatalogSearchLiveFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAccountArgs = {
  oid: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAuthorizedUserArgs = {
  oid: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAuthorizedUserByEmailArgs = {
  email: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAuthorizedUserAuthorizedUsersAccountPartnerAccessArgs = {
  oid: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCampaignArgs = {
  oid: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCampaignGroupArgs = {
  oid: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCampaignUserArgs = {
  oid: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCarouselArgs = {
  oid: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCarouselListingArgs = {
  oid: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCatalogArgs = {
  oid: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCatalogByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCategoryArgs = {
  oid: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCategoryByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryContactArgs = {
  oid: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDepartmentArgs = {
  oid: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDepartmentDepartmentsCatalogListingArgs = {
  oid: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFeedArgs = {
  oid: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFeedHistoryArgs = {
  oid: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLeadArgs = {
  oid: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLinkOutArgs = {
  oid: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNavigationItemArgs = {
  oid: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOfferArgs = {
  oid: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOrderArgs = {
  oid: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOrderProductArgs = {
  oid: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPageArgs = {
  oid: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProductArgs = {
  oid: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProfileArgs = {
  oid: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProfileByEmailArgs = {
  email: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySubCategoryArgs = {
  oid: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySubCategoryByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySubscriptionArgs = {
  oid: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVersionArgs = {
  oid: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVersionByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryJsonIntextArgs = {
  arg0?: Maybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySearchCatalogConnectionArgs = {
  search?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<CatalogFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySearchCatalogArgs = {
  search?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filter?: Maybe<CatalogFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySearchCatalogDeptConnectionArgs = {
  search?: Maybe<Scalars['String']>;
  primarydepartment?: Maybe<Scalars['UUID']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<CatalogFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySearchCatalogDeptArgs = {
  search?: Maybe<Scalars['String']>;
  primarydepartment?: Maybe<Scalars['UUID']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filter?: Maybe<CatalogFilter>;
};

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  readonly isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  readonly equalTo?: Maybe<Scalars['String']>;
  /** Not equal to the specified value. */
  readonly notEqualTo?: Maybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  readonly distinctFrom?: Maybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  readonly notDistinctFrom?: Maybe<Scalars['String']>;
  /** Included in the specified list. */
  readonly in?: Maybe<ReadonlyArray<Scalars['String']>>;
  /** Not included in the specified list. */
  readonly notIn?: Maybe<ReadonlyArray<Scalars['String']>>;
  /** Less than the specified value. */
  readonly lessThan?: Maybe<Scalars['String']>;
  /** Less than or equal to the specified value. */
  readonly lessThanOrEqualTo?: Maybe<Scalars['String']>;
  /** Greater than the specified value. */
  readonly greaterThan?: Maybe<Scalars['String']>;
  /** Greater than or equal to the specified value. */
  readonly greaterThanOrEqualTo?: Maybe<Scalars['String']>;
  /** Contains the specified string (case-sensitive). */
  readonly includes?: Maybe<Scalars['String']>;
  /** Does not contain the specified string (case-sensitive). */
  readonly notIncludes?: Maybe<Scalars['String']>;
  /** Contains the specified string (case-insensitive). */
  readonly includesInsensitive?: Maybe<Scalars['String']>;
  /** Does not contain the specified string (case-insensitive). */
  readonly notIncludesInsensitive?: Maybe<Scalars['String']>;
  /** Starts with the specified string (case-sensitive). */
  readonly startsWith?: Maybe<Scalars['String']>;
  /** Does not start with the specified string (case-sensitive). */
  readonly notStartsWith?: Maybe<Scalars['String']>;
  /** Starts with the specified string (case-insensitive). */
  readonly startsWithInsensitive?: Maybe<Scalars['String']>;
  /** Does not start with the specified string (case-insensitive). */
  readonly notStartsWithInsensitive?: Maybe<Scalars['String']>;
  /** Ends with the specified string (case-sensitive). */
  readonly endsWith?: Maybe<Scalars['String']>;
  /** Does not end with the specified string (case-sensitive). */
  readonly notEndsWith?: Maybe<Scalars['String']>;
  /** Ends with the specified string (case-insensitive). */
  readonly endsWithInsensitive?: Maybe<Scalars['String']>;
  /** Does not end with the specified string (case-insensitive). */
  readonly notEndsWithInsensitive?: Maybe<Scalars['String']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  readonly like?: Maybe<Scalars['String']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  readonly notLike?: Maybe<Scalars['String']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  readonly likeInsensitive?: Maybe<Scalars['String']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  readonly notLikeInsensitive?: Maybe<Scalars['String']>;
  /** Equal to the specified value (case-insensitive). */
  readonly equalToInsensitive?: Maybe<Scalars['String']>;
  /** Not equal to the specified value (case-insensitive). */
  readonly notEqualToInsensitive?: Maybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  readonly distinctFromInsensitive?: Maybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  readonly notDistinctFromInsensitive?: Maybe<Scalars['String']>;
  /** Included in the specified list (case-insensitive). */
  readonly inInsensitive?: Maybe<ReadonlyArray<Scalars['String']>>;
  /** Not included in the specified list (case-insensitive). */
  readonly notInInsensitive?: Maybe<ReadonlyArray<Scalars['String']>>;
  /** Less than the specified value (case-insensitive). */
  readonly lessThanInsensitive?: Maybe<Scalars['String']>;
  /** Less than or equal to the specified value (case-insensitive). */
  readonly lessThanOrEqualToInsensitive?: Maybe<Scalars['String']>;
  /** Greater than the specified value (case-insensitive). */
  readonly greaterThanInsensitive?: Maybe<Scalars['String']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  readonly greaterThanOrEqualToInsensitive?: Maybe<Scalars['String']>;
};

/** A connection to a list of `SubCategory` values. */
export type SubCategoriesConnection = {
  readonly __typename?: 'SubCategoriesConnection';
  /** A list of `SubCategory` objects. */
  readonly nodes: ReadonlyArray<SubCategory>;
  /** A list of edges which contains the `SubCategory` and cursor to aid in pagination. */
  readonly edges: ReadonlyArray<SubCategoriesEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `SubCategory` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `SubCategory` edge in the connection. */
export type SubCategoriesEdge = {
  readonly __typename?: 'SubCategoriesEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `SubCategory` at the end of the edge. */
  readonly node: SubCategory;
};

/** Methods to use when ordering `SubCategory`. */
export enum SubCategoriesOrderBy {
  Natural = 'NATURAL',
  OidAsc = 'OID_ASC',
  OidDesc = 'OID_DESC',
  OptionsAsc = 'OPTIONS_ASC',
  OptionsDesc = 'OPTIONS_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  FeaturedAsc = 'FEATURED_ASC',
  FeaturedDesc = 'FEATURED_DESC',
  ImageAsc = 'IMAGE_ASC',
  ImageDesc = 'IMAGE_DESC',
  ActiveAsc = 'ACTIVE_ASC',
  ActiveDesc = 'ACTIVE_DESC',
  LinkoutUrlAsc = 'LINKOUT_URL_ASC',
  LinkoutUrlDesc = 'LINKOUT_URL_DESC',
  ProductsPerPageAsc = 'PRODUCTS_PER_PAGE_ASC',
  ProductsPerPageDesc = 'PRODUCTS_PER_PAGE_DESC',
  ImageOrientationAsc = 'IMAGE_ORIENTATION_ASC',
  ImageOrientationDesc = 'IMAGE_ORIENTATION_DESC',
  ProductBorderAsc = 'PRODUCT_BORDER_ASC',
  ProductBorderDesc = 'PRODUCT_BORDER_DESC',
  AnimatedAsc = 'ANIMATED_ASC',
  AnimatedDesc = 'ANIMATED_DESC',
  CategoryAsc = 'CATEGORY_ASC',
  CategoryDesc = 'CATEGORY_DESC',
  OptimisticLockFieldAsc = 'OPTIMISTIC_LOCK_FIELD_ASC',
  OptimisticLockFieldDesc = 'OPTIMISTIC_LOCK_FIELD_DESC',
  TargetUrlAsc = 'TARGET_URL_ASC',
  TargetUrlDesc = 'TARGET_URL_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type SubCategory = {
  readonly __typename?: 'SubCategory';
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly featured?: Maybe<Scalars['Int']>;
  readonly image?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly linkoutUrl?: Maybe<Scalars['String']>;
  readonly productsPerPage?: Maybe<Scalars['Int']>;
  readonly imageOrientation?: Maybe<Scalars['Boolean']>;
  readonly productBorder?: Maybe<Scalars['Boolean']>;
  readonly animated?: Maybe<Scalars['Boolean']>;
  readonly category?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  /** Reads a single `Category` that is related to this `SubCategory`. */
  readonly categoryByCategory?: Maybe<Category>;
};

/** A condition to be used against `SubCategory` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type SubCategoryCondition = {
  /** Checks for equality with the object’s `oid` field. */
  readonly oid?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `options` field. */
  readonly options?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `id` field. */
  readonly id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `slug` field. */
  readonly slug?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  readonly name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `featured` field. */
  readonly featured?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `image` field. */
  readonly image?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `active` field. */
  readonly active?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `linkoutUrl` field. */
  readonly linkoutUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `productsPerPage` field. */
  readonly productsPerPage?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `imageOrientation` field. */
  readonly imageOrientation?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `productBorder` field. */
  readonly productBorder?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `animated` field. */
  readonly animated?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `category` field. */
  readonly category?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `targetUrl` field. */
  readonly targetUrl?: Maybe<Scalars['String']>;
};

/** A filter to be used against `SubCategory` object types. All fields are combined with a logical ‘and.’ */
export type SubCategoryFilter = {
  /** Filter by the object’s `oid` field. */
  readonly oid?: Maybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  readonly id?: Maybe<IntFilter>;
  /** Filter by the object’s `slug` field. */
  readonly slug?: Maybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  readonly name?: Maybe<StringFilter>;
  /** Filter by the object’s `featured` field. */
  readonly featured?: Maybe<IntFilter>;
  /** Filter by the object’s `image` field. */
  readonly image?: Maybe<StringFilter>;
  /** Filter by the object’s `active` field. */
  readonly active?: Maybe<BooleanFilter>;
  /** Filter by the object’s `linkoutUrl` field. */
  readonly linkoutUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `productsPerPage` field. */
  readonly productsPerPage?: Maybe<IntFilter>;
  /** Filter by the object’s `imageOrientation` field. */
  readonly imageOrientation?: Maybe<BooleanFilter>;
  /** Filter by the object’s `productBorder` field. */
  readonly productBorder?: Maybe<BooleanFilter>;
  /** Filter by the object’s `animated` field. */
  readonly animated?: Maybe<BooleanFilter>;
  /** Filter by the object’s `category` field. */
  readonly category?: Maybe<UuidFilter>;
  /** Filter by the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<IntFilter>;
  /** Filter by the object’s `targetUrl` field. */
  readonly targetUrl?: Maybe<StringFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<ReadonlyArray<SubCategoryFilter>>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<ReadonlyArray<SubCategoryFilter>>;
  /** Negates the expression. */
  readonly not?: Maybe<SubCategoryFilter>;
};

/** An input for mutations affecting `SubCategory` */
export type SubCategoryInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly featured?: Maybe<Scalars['Int']>;
  readonly image?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly linkoutUrl?: Maybe<Scalars['String']>;
  readonly productsPerPage?: Maybe<Scalars['Int']>;
  readonly imageOrientation?: Maybe<Scalars['Boolean']>;
  readonly productBorder?: Maybe<Scalars['Boolean']>;
  readonly animated?: Maybe<Scalars['Boolean']>;
  readonly category?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly categoryToCategory?: Maybe<FkSubCategoryCategoryInput>;
};

/** The fields on `subCategory` to look up the row to update. */
export type SubCategoryOnSubCategoryForFkSubCategoryCategoryUsingPkSubCategoryUpdate = {
  /** An object where the defined keys will be set on the `subCategory` being updated. */
  readonly patch: UpdateSubCategoryOnSubCategoryForFkSubCategoryCategoryPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `subCategory` to look up the row to update. */
export type SubCategoryOnSubCategoryForFkSubCategoryCategoryUsingSubCategoryByIdUpdate = {
  /** An object where the defined keys will be set on the `subCategory` being updated. */
  readonly patch: UpdateSubCategoryOnSubCategoryForFkSubCategoryCategoryPatch;
  readonly id: Scalars['Int'];
};

/** Represents an update to a `SubCategory`. Fields that are set will be updated. */
export type SubCategoryPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly featured?: Maybe<Scalars['Int']>;
  readonly image?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly linkoutUrl?: Maybe<Scalars['String']>;
  readonly productsPerPage?: Maybe<Scalars['Int']>;
  readonly imageOrientation?: Maybe<Scalars['Boolean']>;
  readonly productBorder?: Maybe<Scalars['Boolean']>;
  readonly animated?: Maybe<Scalars['Boolean']>;
  readonly category?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly categoryToCategory?: Maybe<FkSubCategoryCategoryInput>;
};

/** The fields on `subCategory` to look up the row to connect. */
export type SubCategoryPkSubCategoryConnect = {
  readonly oid: Scalars['UUID'];
};

/** The fields on `subCategory` to look up the row to delete. */
export type SubCategoryPkSubCategoryDelete = {
  readonly oid: Scalars['UUID'];
};

/** The fields on `subCategory` to look up the row to connect. */
export type SubCategorySubCategoryByIdConnect = {
  readonly id: Scalars['Int'];
};

/** The fields on `subCategory` to look up the row to delete. */
export type SubCategorySubCategoryByIdDelete = {
  readonly id: Scalars['Int'];
};

export type SubProduct = {
  readonly __typename?: 'SubProduct';
  readonly main?: Maybe<Scalars['String']>;
  readonly sub?: Maybe<Scalars['String']>;
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly brand?: Maybe<Scalars['String']>;
  readonly color?: Maybe<Scalars['String']>;
  readonly sku?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
  readonly tags?: Maybe<Scalars['String']>;
  readonly price?: Maybe<Scalars['Float']>;
  readonly dateEntered?: Maybe<Scalars['Datetime']>;
  readonly srcOriginal?: Maybe<Scalars['String']>;
  readonly additionalImages?: Maybe<Scalars['JSON']>;
  readonly additionalFields?: Maybe<Scalars['JSON']>;
  readonly changeHistory?: Maybe<Scalars['JSON']>;
  readonly dateModified?: Maybe<Scalars['Datetime']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly subCategory?: Maybe<Scalars['String']>;
  readonly salePrice?: Maybe<Scalars['Float']>;
  readonly category?: Maybe<Scalars['String']>;
  readonly expired?: Maybe<Scalars['Boolean']>;
  readonly src?: Maybe<Scalars['String']>;
  readonly rotated?: Maybe<Scalars['Boolean']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  /** Reads a single `Category` that is related to this `SubProduct`. */
  readonly categoryByMainAndCatalog?: Maybe<Category>;
};

/** A condition to be used against `SubProduct` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type SubProductCondition = {
  /** Checks for equality with the object’s `main` field. */
  readonly main?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `sub` field. */
  readonly sub?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `oid` field. */
  readonly oid?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `options` field. */
  readonly options?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `catalog` field. */
  readonly catalog?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `title` field. */
  readonly title?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `description` field. */
  readonly description?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `brand` field. */
  readonly brand?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `color` field. */
  readonly color?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `sku` field. */
  readonly sku?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `url` field. */
  readonly url?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `tags` field. */
  readonly tags?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `price` field. */
  readonly price?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `dateEntered` field. */
  readonly dateEntered?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `srcOriginal` field. */
  readonly srcOriginal?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `additionalImages` field. */
  readonly additionalImages?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `additionalFields` field. */
  readonly additionalFields?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `changeHistory` field. */
  readonly changeHistory?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `dateModified` field. */
  readonly dateModified?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `status` field. */
  readonly status?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `subCategory` field. */
  readonly subCategory?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `salePrice` field. */
  readonly salePrice?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `category` field. */
  readonly category?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `expired` field. */
  readonly expired?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `src` field. */
  readonly src?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `rotated` field. */
  readonly rotated?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `SubProduct` object types. All fields are combined with a logical ‘and.’ */
export type SubProductFilter = {
  /** Filter by the object’s `main` field. */
  readonly main?: Maybe<StringFilter>;
  /** Filter by the object’s `sub` field. */
  readonly sub?: Maybe<StringFilter>;
  /** Filter by the object’s `oid` field. */
  readonly oid?: Maybe<UuidFilter>;
  /** Filter by the object’s `catalog` field. */
  readonly catalog?: Maybe<UuidFilter>;
  /** Filter by the object’s `title` field. */
  readonly title?: Maybe<StringFilter>;
  /** Filter by the object’s `description` field. */
  readonly description?: Maybe<StringFilter>;
  /** Filter by the object’s `brand` field. */
  readonly brand?: Maybe<StringFilter>;
  /** Filter by the object’s `color` field. */
  readonly color?: Maybe<StringFilter>;
  /** Filter by the object’s `sku` field. */
  readonly sku?: Maybe<StringFilter>;
  /** Filter by the object’s `url` field. */
  readonly url?: Maybe<StringFilter>;
  /** Filter by the object’s `tags` field. */
  readonly tags?: Maybe<StringFilter>;
  /** Filter by the object’s `price` field. */
  readonly price?: Maybe<FloatFilter>;
  /** Filter by the object’s `dateEntered` field. */
  readonly dateEntered?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `srcOriginal` field. */
  readonly srcOriginal?: Maybe<StringFilter>;
  /** Filter by the object’s `dateModified` field. */
  readonly dateModified?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `status` field. */
  readonly status?: Maybe<StringFilter>;
  /** Filter by the object’s `subCategory` field. */
  readonly subCategory?: Maybe<StringFilter>;
  /** Filter by the object’s `salePrice` field. */
  readonly salePrice?: Maybe<FloatFilter>;
  /** Filter by the object’s `category` field. */
  readonly category?: Maybe<StringFilter>;
  /** Filter by the object’s `expired` field. */
  readonly expired?: Maybe<BooleanFilter>;
  /** Filter by the object’s `src` field. */
  readonly src?: Maybe<StringFilter>;
  /** Filter by the object’s `rotated` field. */
  readonly rotated?: Maybe<BooleanFilter>;
  /** Filter by the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<IntFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<ReadonlyArray<SubProductFilter>>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<ReadonlyArray<SubProductFilter>>;
  /** Negates the expression. */
  readonly not?: Maybe<SubProductFilter>;
};

/** A connection to a list of `SubProduct` values. */
export type SubProductsConnection = {
  readonly __typename?: 'SubProductsConnection';
  /** A list of `SubProduct` objects. */
  readonly nodes: ReadonlyArray<SubProduct>;
  /** A list of edges which contains the `SubProduct` and cursor to aid in pagination. */
  readonly edges: ReadonlyArray<SubProductsEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `SubProduct` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `SubProduct` edge in the connection. */
export type SubProductsEdge = {
  readonly __typename?: 'SubProductsEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `SubProduct` at the end of the edge. */
  readonly node: SubProduct;
};

/** Methods to use when ordering `SubProduct`. */
export enum SubProductsOrderBy {
  Natural = 'NATURAL',
  MainAsc = 'MAIN_ASC',
  MainDesc = 'MAIN_DESC',
  SubAsc = 'SUB_ASC',
  SubDesc = 'SUB_DESC',
  OidAsc = 'OID_ASC',
  OidDesc = 'OID_DESC',
  OptionsAsc = 'OPTIONS_ASC',
  OptionsDesc = 'OPTIONS_DESC',
  CatalogAsc = 'CATALOG_ASC',
  CatalogDesc = 'CATALOG_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  BrandAsc = 'BRAND_ASC',
  BrandDesc = 'BRAND_DESC',
  ColorAsc = 'COLOR_ASC',
  ColorDesc = 'COLOR_DESC',
  SkuAsc = 'SKU_ASC',
  SkuDesc = 'SKU_DESC',
  UrlAsc = 'URL_ASC',
  UrlDesc = 'URL_DESC',
  TagsAsc = 'TAGS_ASC',
  TagsDesc = 'TAGS_DESC',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  DateEnteredAsc = 'DATE_ENTERED_ASC',
  DateEnteredDesc = 'DATE_ENTERED_DESC',
  SrcOriginalAsc = 'SRC_ORIGINAL_ASC',
  SrcOriginalDesc = 'SRC_ORIGINAL_DESC',
  AdditionalImagesAsc = 'ADDITIONAL_IMAGES_ASC',
  AdditionalImagesDesc = 'ADDITIONAL_IMAGES_DESC',
  AdditionalFieldsAsc = 'ADDITIONAL_FIELDS_ASC',
  AdditionalFieldsDesc = 'ADDITIONAL_FIELDS_DESC',
  ChangeHistoryAsc = 'CHANGE_HISTORY_ASC',
  ChangeHistoryDesc = 'CHANGE_HISTORY_DESC',
  DateModifiedAsc = 'DATE_MODIFIED_ASC',
  DateModifiedDesc = 'DATE_MODIFIED_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  SubCategoryAsc = 'SUB_CATEGORY_ASC',
  SubCategoryDesc = 'SUB_CATEGORY_DESC',
  SalePriceAsc = 'SALE_PRICE_ASC',
  SalePriceDesc = 'SALE_PRICE_DESC',
  CategoryAsc = 'CATEGORY_ASC',
  CategoryDesc = 'CATEGORY_DESC',
  ExpiredAsc = 'EXPIRED_ASC',
  ExpiredDesc = 'EXPIRED_DESC',
  SrcAsc = 'SRC_ASC',
  SrcDesc = 'SRC_DESC',
  RotatedAsc = 'ROTATED_ASC',
  RotatedDesc = 'ROTATED_DESC',
  OptimisticLockFieldAsc = 'OPTIMISTIC_LOCK_FIELD_ASC',
  OptimisticLockFieldDesc = 'OPTIMISTIC_LOCK_FIELD_DESC'
}

export type Subscription = {
  readonly __typename?: 'Subscription';
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['UUID']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly date?: Maybe<Scalars['Datetime']>;
  readonly charge?: Maybe<Scalars['Float']>;
  readonly listingId?: Maybe<Scalars['Int']>;
  readonly exportStatus?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  /** Reads a single `Profile` that is related to this `Subscription`. */
  readonly profileByCustomer?: Maybe<Profile>;
  /** Reads a single `Catalog` that is related to this `Subscription`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
};

/** A condition to be used against `Subscription` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type SubscriptionCondition = {
  /** Checks for equality with the object’s `oid` field. */
  readonly oid?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `options` field. */
  readonly options?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `id` field. */
  readonly id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `slug` field. */
  readonly slug?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `customer` field. */
  readonly customer?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `catalog` field. */
  readonly catalog?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `date` field. */
  readonly date?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `charge` field. */
  readonly charge?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `listingId` field. */
  readonly listingId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `exportStatus` field. */
  readonly exportStatus?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `targetUrl` field. */
  readonly targetUrl?: Maybe<Scalars['String']>;
};

/** A filter to be used against `Subscription` object types. All fields are combined with a logical ‘and.’ */
export type SubscriptionFilter = {
  /** Filter by the object’s `oid` field. */
  readonly oid?: Maybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  readonly id?: Maybe<IntFilter>;
  /** Filter by the object’s `slug` field. */
  readonly slug?: Maybe<StringFilter>;
  /** Filter by the object’s `customer` field. */
  readonly customer?: Maybe<UuidFilter>;
  /** Filter by the object’s `catalog` field. */
  readonly catalog?: Maybe<UuidFilter>;
  /** Filter by the object’s `date` field. */
  readonly date?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `charge` field. */
  readonly charge?: Maybe<FloatFilter>;
  /** Filter by the object’s `listingId` field. */
  readonly listingId?: Maybe<IntFilter>;
  /** Filter by the object’s `exportStatus` field. */
  readonly exportStatus?: Maybe<StringFilter>;
  /** Filter by the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<IntFilter>;
  /** Filter by the object’s `targetUrl` field. */
  readonly targetUrl?: Maybe<StringFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<ReadonlyArray<SubscriptionFilter>>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<ReadonlyArray<SubscriptionFilter>>;
  /** Negates the expression. */
  readonly not?: Maybe<SubscriptionFilter>;
};

/** An input for mutations affecting `Subscription` */
export type SubscriptionInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['UUID']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly date?: Maybe<Scalars['Datetime']>;
  readonly charge?: Maybe<Scalars['Float']>;
  readonly listingId?: Maybe<Scalars['Int']>;
  readonly exportStatus?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly profileToCustomer?: Maybe<FkSubscriptionCustomerInput>;
  readonly catalogToCatalog?: Maybe<FkSubscriptionCatalogInput>;
};

/** The fields on `subscription` to look up the row to update. */
export type SubscriptionOnSubscriptionForFkSubscriptionCatalogUsingPkSubscriptionUpdate = {
  /** An object where the defined keys will be set on the `subscription` being updated. */
  readonly patch: UpdateSubscriptionOnSubscriptionForFkSubscriptionCatalogPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `subscription` to look up the row to update. */
export type SubscriptionOnSubscriptionForFkSubscriptionCustomerUsingPkSubscriptionUpdate = {
  /** An object where the defined keys will be set on the `subscription` being updated. */
  readonly patch: UpdateSubscriptionOnSubscriptionForFkSubscriptionCustomerPatch;
  readonly oid: Scalars['UUID'];
};

/** Represents an update to a `Subscription`. Fields that are set will be updated. */
export type SubscriptionPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['UUID']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly date?: Maybe<Scalars['Datetime']>;
  readonly charge?: Maybe<Scalars['Float']>;
  readonly listingId?: Maybe<Scalars['Int']>;
  readonly exportStatus?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly profileToCustomer?: Maybe<FkSubscriptionCustomerInput>;
  readonly catalogToCatalog?: Maybe<FkSubscriptionCatalogInput>;
};

/** The fields on `subscription` to look up the row to connect. */
export type SubscriptionPkSubscriptionConnect = {
  readonly oid: Scalars['UUID'];
};

/** The fields on `subscription` to look up the row to delete. */
export type SubscriptionPkSubscriptionDelete = {
  readonly oid: Scalars['UUID'];
};

/** A connection to a list of `Subscription` values. */
export type SubscriptionsConnection = {
  readonly __typename?: 'SubscriptionsConnection';
  /** A list of `Subscription` objects. */
  readonly nodes: ReadonlyArray<Subscription>;
  /** A list of edges which contains the `Subscription` and cursor to aid in pagination. */
  readonly edges: ReadonlyArray<SubscriptionsEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Subscription` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Subscription` edge in the connection. */
export type SubscriptionsEdge = {
  readonly __typename?: 'SubscriptionsEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Subscription` at the end of the edge. */
  readonly node: Subscription;
};

/** Methods to use when ordering `Subscription`. */
export enum SubscriptionsOrderBy {
  Natural = 'NATURAL',
  OidAsc = 'OID_ASC',
  OidDesc = 'OID_DESC',
  OptionsAsc = 'OPTIONS_ASC',
  OptionsDesc = 'OPTIONS_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  CustomerAsc = 'CUSTOMER_ASC',
  CustomerDesc = 'CUSTOMER_DESC',
  CatalogAsc = 'CATALOG_ASC',
  CatalogDesc = 'CATALOG_DESC',
  DateAsc = 'DATE_ASC',
  DateDesc = 'DATE_DESC',
  ChargeAsc = 'CHARGE_ASC',
  ChargeDesc = 'CHARGE_DESC',
  ListingIdAsc = 'LISTING_ID_ASC',
  ListingIdDesc = 'LISTING_ID_DESC',
  ExportStatusAsc = 'EXPORT_STATUS_ASC',
  ExportStatusDesc = 'EXPORT_STATUS_DESC',
  OptimisticLockFieldAsc = 'OPTIMISTIC_LOCK_FIELD_ASC',
  OptimisticLockFieldDesc = 'OPTIMISTIC_LOCK_FIELD_DESC',
  TargetUrlAsc = 'TARGET_URL_ASC',
  TargetUrlDesc = 'TARGET_URL_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}


/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  readonly isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  readonly equalTo?: Maybe<Scalars['UUID']>;
  /** Not equal to the specified value. */
  readonly notEqualTo?: Maybe<Scalars['UUID']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  readonly distinctFrom?: Maybe<Scalars['UUID']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  readonly notDistinctFrom?: Maybe<Scalars['UUID']>;
  /** Included in the specified list. */
  readonly in?: Maybe<ReadonlyArray<Scalars['UUID']>>;
  /** Not included in the specified list. */
  readonly notIn?: Maybe<ReadonlyArray<Scalars['UUID']>>;
  /** Less than the specified value. */
  readonly lessThan?: Maybe<Scalars['UUID']>;
  /** Less than or equal to the specified value. */
  readonly lessThanOrEqualTo?: Maybe<Scalars['UUID']>;
  /** Greater than the specified value. */
  readonly greaterThan?: Maybe<Scalars['UUID']>;
  /** Greater than or equal to the specified value. */
  readonly greaterThanOrEqualTo?: Maybe<Scalars['UUID']>;
};

/** All input for the `updateAccount` mutation. */
export type UpdateAccountInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Account` being updated. */
  readonly patch: AccountPatch;
  readonly oid: Scalars['UUID'];
};

/** The output of our update `Account` mutation. */
export type UpdateAccountPayload = {
  readonly __typename?: 'UpdateAccountPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Account` that was updated by this mutation. */
  readonly account?: Maybe<Account>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** An edge for our `Account`. May be used by Relay 1. */
  readonly accountEdge?: Maybe<AccountsEdge>;
};


/** The output of our update `Account` mutation. */
export type UpdateAccountPayloadAccountEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<AccountsOrderBy>>;
};

/** All input for the `updateAuthorizedUserAuthorizedUsersAccountPartnerAccess` mutation. */
export type UpdateAuthorizedUserAuthorizedUsersAccountPartnerAccessInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `AuthorizedUserAuthorizedUsersAccountPartnerAccess` being updated. */
  readonly patch: AuthorizedUserAuthorizedUsersAccountPartnerAccessPatch;
  readonly oid: Scalars['UUID'];
};

/** The output of our update `AuthorizedUserAuthorizedUsersAccountPartnerAccess` mutation. */
export type UpdateAuthorizedUserAuthorizedUsersAccountPartnerAccessPayload = {
  readonly __typename?: 'UpdateAuthorizedUserAuthorizedUsersAccountPartnerAccessPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `AuthorizedUserAuthorizedUsersAccountPartnerAccess` that was updated by this mutation. */
  readonly authorizedUserAuthorizedUsersAccountPartnerAccess?: Maybe<AuthorizedUserAuthorizedUsersAccountPartnerAccess>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Account` that is related to this `AuthorizedUserAuthorizedUsersAccountPartnerAccess`. */
  readonly accountByPartnerAccess?: Maybe<Account>;
  /** Reads a single `AuthorizedUser` that is related to this `AuthorizedUserAuthorizedUsersAccountPartnerAccess`. */
  readonly authorizedUserByAuthorizedUsers?: Maybe<AuthorizedUser>;
  /** An edge for our `AuthorizedUserAuthorizedUsersAccountPartnerAccess`. May be used by Relay 1. */
  readonly authorizedUserAuthorizedUsersAccountPartnerAccessEdge?: Maybe<AuthorizedUserAuthorizedUsersAccountPartnerAccessesEdge>;
};


/** The output of our update `AuthorizedUserAuthorizedUsersAccountPartnerAccess` mutation. */
export type UpdateAuthorizedUserAuthorizedUsersAccountPartnerAccessPayloadAuthorizedUserAuthorizedUsersAccountPartnerAccessEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<AuthorizedUserAuthorizedUsersAccountPartnerAccessesOrderBy>>;
};

/** All input for the `updateAuthorizedUserByEmail` mutation. */
export type UpdateAuthorizedUserByEmailInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `AuthorizedUser` being updated. */
  readonly patch: AuthorizedUserPatch;
  readonly email: Scalars['String'];
};

/** All input for the `updateAuthorizedUser` mutation. */
export type UpdateAuthorizedUserInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `AuthorizedUser` being updated. */
  readonly patch: AuthorizedUserPatch;
  readonly oid: Scalars['UUID'];
};

/** The output of our update `AuthorizedUser` mutation. */
export type UpdateAuthorizedUserPayload = {
  readonly __typename?: 'UpdateAuthorizedUserPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `AuthorizedUser` that was updated by this mutation. */
  readonly authorizedUser?: Maybe<AuthorizedUser>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Profile` that is related to this `AuthorizedUser`. */
  readonly profileByEmail?: Maybe<Profile>;
  /** An edge for our `AuthorizedUser`. May be used by Relay 1. */
  readonly authorizedUserEdge?: Maybe<AuthorizedUsersEdge>;
};


/** The output of our update `AuthorizedUser` mutation. */
export type UpdateAuthorizedUserPayloadAuthorizedUserEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<AuthorizedUsersOrderBy>>;
};

/** All input for the `updateCampaignGroup` mutation. */
export type UpdateCampaignGroupInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CampaignGroup` being updated. */
  readonly patch: CampaignGroupPatch;
  readonly oid: Scalars['UUID'];
};

/** The output of our update `CampaignGroup` mutation. */
export type UpdateCampaignGroupPayload = {
  readonly __typename?: 'UpdateCampaignGroupPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `CampaignGroup` that was updated by this mutation. */
  readonly campaignGroup?: Maybe<CampaignGroup>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Campaign` that is related to this `CampaignGroup`. */
  readonly campaignByCampaign?: Maybe<Campaign>;
  /** Reads a single `Catalog` that is related to this `CampaignGroup`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
  /** An edge for our `CampaignGroup`. May be used by Relay 1. */
  readonly campaignGroupEdge?: Maybe<CampaignGroupsEdge>;
};


/** The output of our update `CampaignGroup` mutation. */
export type UpdateCampaignGroupPayloadCampaignGroupEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<CampaignGroupsOrderBy>>;
};

/** All input for the `updateCampaign` mutation. */
export type UpdateCampaignInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Campaign` being updated. */
  readonly patch: CampaignPatch;
  readonly oid: Scalars['UUID'];
};

/** The output of our update `Campaign` mutation. */
export type UpdateCampaignPayload = {
  readonly __typename?: 'UpdateCampaignPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Campaign` that was updated by this mutation. */
  readonly campaign?: Maybe<Campaign>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Account` that is related to this `Campaign`. */
  readonly accountByAccount?: Maybe<Account>;
  /** An edge for our `Campaign`. May be used by Relay 1. */
  readonly campaignEdge?: Maybe<CampaignsEdge>;
};


/** The output of our update `Campaign` mutation. */
export type UpdateCampaignPayloadCampaignEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<CampaignsOrderBy>>;
};

/** All input for the `updateCampaignUser` mutation. */
export type UpdateCampaignUserInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CampaignUser` being updated. */
  readonly patch: CampaignUserPatch;
  readonly oid: Scalars['UUID'];
};

/** The output of our update `CampaignUser` mutation. */
export type UpdateCampaignUserPayload = {
  readonly __typename?: 'UpdateCampaignUserPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `CampaignUser` that was updated by this mutation. */
  readonly campaignUser?: Maybe<CampaignUser>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `CampaignUser`. */
  readonly leadByLead?: Maybe<Lead>;
  /** Reads a single `CampaignGroup` that is related to this `CampaignUser`. */
  readonly campaignGroupByCampaignGroup?: Maybe<CampaignGroup>;
  /** An edge for our `CampaignUser`. May be used by Relay 1. */
  readonly campaignUserEdge?: Maybe<CampaignUsersEdge>;
};


/** The output of our update `CampaignUser` mutation. */
export type UpdateCampaignUserPayloadCampaignUserEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<CampaignUsersOrderBy>>;
};

/** All input for the `updateCarousel` mutation. */
export type UpdateCarouselInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Carousel` being updated. */
  readonly patch: CarouselPatch;
  readonly oid: Scalars['UUID'];
};

/** All input for the `updateCarouselListing` mutation. */
export type UpdateCarouselListingInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CarouselListing` being updated. */
  readonly patch: CarouselListingPatch;
  readonly oid: Scalars['UUID'];
};

/** The output of our update `CarouselListing` mutation. */
export type UpdateCarouselListingPayload = {
  readonly __typename?: 'UpdateCarouselListingPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `CarouselListing` that was updated by this mutation. */
  readonly carouselListing?: Maybe<CarouselListing>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Carousel` that is related to this `CarouselListing`. */
  readonly carouselByCarousel?: Maybe<Carousel>;
  /** Reads a single `Offer` that is related to this `CarouselListing`. */
  readonly offerByOffer?: Maybe<Offer>;
  /** Reads a single `Catalog` that is related to this `CarouselListing`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
  /** An edge for our `CarouselListing`. May be used by Relay 1. */
  readonly carouselListingEdge?: Maybe<CarouselListingsEdge>;
};


/** The output of our update `CarouselListing` mutation. */
export type UpdateCarouselListingPayloadCarouselListingEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<CarouselListingsOrderBy>>;
};

/** The output of our update `Carousel` mutation. */
export type UpdateCarouselPayload = {
  readonly __typename?: 'UpdateCarouselPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Carousel` that was updated by this mutation. */
  readonly carousel?: Maybe<Carousel>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** An edge for our `Carousel`. May be used by Relay 1. */
  readonly carouselEdge?: Maybe<CarouselsEdge>;
};


/** The output of our update `Carousel` mutation. */
export type UpdateCarouselPayloadCarouselEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<CarouselsOrderBy>>;
};

/** All input for the `updateCatalogById` mutation. */
export type UpdateCatalogByIdInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Catalog` being updated. */
  readonly patch: CatalogPatch;
  readonly id: Scalars['Int'];
};

/** All input for the `updateCatalog` mutation. */
export type UpdateCatalogInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Catalog` being updated. */
  readonly patch: CatalogPatch;
  readonly oid: Scalars['UUID'];
};

/** The output of our update `Catalog` mutation. */
export type UpdateCatalogPayload = {
  readonly __typename?: 'UpdateCatalogPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Catalog` that was updated by this mutation. */
  readonly catalog?: Maybe<Catalog>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Account` that is related to this `Catalog`. */
  readonly accountByAccount?: Maybe<Account>;
  /** Reads a single `Department` that is related to this `Catalog`. */
  readonly departmentByPrimaryDepartment?: Maybe<Department>;
  /** Reads a single `Version` that is related to this `Catalog`. */
  readonly versionByActiveVersion?: Maybe<Version>;
  /** An edge for our `Catalog`. May be used by Relay 1. */
  readonly catalogEdge?: Maybe<CatalogsEdge>;
};


/** The output of our update `Catalog` mutation. */
export type UpdateCatalogPayloadCatalogEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<CatalogsOrderBy>>;
};

/** All input for the `updateCategoryById` mutation. */
export type UpdateCategoryByIdInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Category` being updated. */
  readonly patch: CategoryPatch;
  readonly id: Scalars['Int'];
};

/** All input for the `updateCategory` mutation. */
export type UpdateCategoryInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Category` being updated. */
  readonly patch: CategoryPatch;
  readonly oid: Scalars['UUID'];
};

/** The output of our update `Category` mutation. */
export type UpdateCategoryPayload = {
  readonly __typename?: 'UpdateCategoryPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Category` that was updated by this mutation. */
  readonly category?: Maybe<Category>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Catalog` that is related to this `Category`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
  /** An edge for our `Category`. May be used by Relay 1. */
  readonly categoryEdge?: Maybe<CategoriesEdge>;
};


/** The output of our update `Category` mutation. */
export type UpdateCategoryPayloadCategoryEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<CategoriesOrderBy>>;
};

/** All input for the `updateContact` mutation. */
export type UpdateContactInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Contact` being updated. */
  readonly patch: ContactPatch;
  readonly oid: Scalars['UUID'];
};

/** The output of our update `Contact` mutation. */
export type UpdateContactPayload = {
  readonly __typename?: 'UpdateContactPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Contact` that was updated by this mutation. */
  readonly contact?: Maybe<Contact>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Account` that is related to this `Contact`. */
  readonly accountByParent?: Maybe<Account>;
  /** An edge for our `Contact`. May be used by Relay 1. */
  readonly contactEdge?: Maybe<ContactsEdge>;
};


/** The output of our update `Contact` mutation. */
export type UpdateContactPayloadContactEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<ContactsOrderBy>>;
};

/** All input for the `updateDepartmentDepartmentsCatalogListing` mutation. */
export type UpdateDepartmentDepartmentsCatalogListingInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `DepartmentDepartmentsCatalogListing` being updated. */
  readonly patch: DepartmentDepartmentsCatalogListingPatch;
  readonly oid: Scalars['UUID'];
};

/** The output of our update `DepartmentDepartmentsCatalogListing` mutation. */
export type UpdateDepartmentDepartmentsCatalogListingPayload = {
  readonly __typename?: 'UpdateDepartmentDepartmentsCatalogListingPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `DepartmentDepartmentsCatalogListing` that was updated by this mutation. */
  readonly departmentDepartmentsCatalogListing?: Maybe<DepartmentDepartmentsCatalogListing>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Catalog` that is related to this `DepartmentDepartmentsCatalogListing`. */
  readonly catalogByListings?: Maybe<Catalog>;
  /** Reads a single `Department` that is related to this `DepartmentDepartmentsCatalogListing`. */
  readonly departmentByDepartments?: Maybe<Department>;
  /** An edge for our `DepartmentDepartmentsCatalogListing`. May be used by Relay 1. */
  readonly departmentDepartmentsCatalogListingEdge?: Maybe<DepartmentDepartmentsCatalogListingsEdge>;
};


/** The output of our update `DepartmentDepartmentsCatalogListing` mutation. */
export type UpdateDepartmentDepartmentsCatalogListingPayloadDepartmentDepartmentsCatalogListingEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<DepartmentDepartmentsCatalogListingsOrderBy>>;
};

/** All input for the `updateDepartment` mutation. */
export type UpdateDepartmentInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Department` being updated. */
  readonly patch: DepartmentPatch;
  readonly oid: Scalars['UUID'];
};

/** The output of our update `Department` mutation. */
export type UpdateDepartmentPayload = {
  readonly __typename?: 'UpdateDepartmentPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Department` that was updated by this mutation. */
  readonly department?: Maybe<Department>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Department` that is related to this `Department`. */
  readonly departmentByParentDepartment?: Maybe<Department>;
  /** An edge for our `Department`. May be used by Relay 1. */
  readonly departmentEdge?: Maybe<DepartmentsEdge>;
};


/** The output of our update `Department` mutation. */
export type UpdateDepartmentPayloadDepartmentEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<DepartmentsOrderBy>>;
};

/** All input for the `updateFeedHistory` mutation. */
export type UpdateFeedHistoryInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `FeedHistory` being updated. */
  readonly patch: FeedHistoryPatch;
  readonly oid: Scalars['UUID'];
};

/** The output of our update `FeedHistory` mutation. */
export type UpdateFeedHistoryPayload = {
  readonly __typename?: 'UpdateFeedHistoryPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `FeedHistory` that was updated by this mutation. */
  readonly feedHistory?: Maybe<FeedHistory>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Feed` that is related to this `FeedHistory`. */
  readonly feedByFeed?: Maybe<Feed>;
  /** An edge for our `FeedHistory`. May be used by Relay 1. */
  readonly feedHistoryEdge?: Maybe<FeedHistoriesEdge>;
};


/** The output of our update `FeedHistory` mutation. */
export type UpdateFeedHistoryPayloadFeedHistoryEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<FeedHistoriesOrderBy>>;
};

/** All input for the `updateFeed` mutation. */
export type UpdateFeedInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Feed` being updated. */
  readonly patch: FeedPatch;
  readonly oid: Scalars['UUID'];
};

/** The output of our update `Feed` mutation. */
export type UpdateFeedPayload = {
  readonly __typename?: 'UpdateFeedPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Feed` that was updated by this mutation. */
  readonly feed?: Maybe<Feed>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Catalog` that is related to this `Feed`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
  /** An edge for our `Feed`. May be used by Relay 1. */
  readonly feedEdge?: Maybe<FeedsEdge>;
};


/** The output of our update `Feed` mutation. */
export type UpdateFeedPayloadFeedEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<FeedsOrderBy>>;
};

/** All input for the `updateLead` mutation. */
export type UpdateLeadInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Lead` being updated. */
  readonly patch: LeadPatch;
  readonly oid: Scalars['UUID'];
};

/** The output of our update `Lead` mutation. */
export type UpdateLeadPayload = {
  readonly __typename?: 'UpdateLeadPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Lead` that was updated by this mutation. */
  readonly lead?: Maybe<Lead>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Account` that is related to this `Lead`. */
  readonly accountByAccount?: Maybe<Account>;
  /** Reads a single `Profile` that is related to this `Lead`. */
  readonly profileByProfile?: Maybe<Profile>;
  /** An edge for our `Lead`. May be used by Relay 1. */
  readonly leadEdge?: Maybe<LeadsEdge>;
};


/** The output of our update `Lead` mutation. */
export type UpdateLeadPayloadLeadEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<LeadsOrderBy>>;
};

/** All input for the `updateLinkOut` mutation. */
export type UpdateLinkOutInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `LinkOut` being updated. */
  readonly patch: LinkOutPatch;
  readonly oid: Scalars['UUID'];
};

/** The output of our update `LinkOut` mutation. */
export type UpdateLinkOutPayload = {
  readonly __typename?: 'UpdateLinkOutPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `LinkOut` that was updated by this mutation. */
  readonly linkOut?: Maybe<LinkOut>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Profile` that is related to this `LinkOut`. */
  readonly profileByCustomer?: Maybe<Profile>;
  /** Reads a single `Catalog` that is related to this `LinkOut`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
  /** An edge for our `LinkOut`. May be used by Relay 1. */
  readonly linkOutEdge?: Maybe<LinkOutsEdge>;
};


/** The output of our update `LinkOut` mutation. */
export type UpdateLinkOutPayloadLinkOutEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<LinkOutsOrderBy>>;
};

/** All input for the `updateNavigationItem` mutation. */
export type UpdateNavigationItemInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `NavigationItem` being updated. */
  readonly patch: NavigationItemPatch;
  readonly oid: Scalars['UUID'];
};

/** The output of our update `NavigationItem` mutation. */
export type UpdateNavigationItemPayload = {
  readonly __typename?: 'UpdateNavigationItemPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `NavigationItem` that was updated by this mutation. */
  readonly navigationItem?: Maybe<NavigationItem>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Department` that is related to this `NavigationItem`. */
  readonly departmentByDepartment?: Maybe<Department>;
  /** An edge for our `NavigationItem`. May be used by Relay 1. */
  readonly navigationItemEdge?: Maybe<NavigationItemsEdge>;
};


/** The output of our update `NavigationItem` mutation. */
export type UpdateNavigationItemPayloadNavigationItemEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<NavigationItemsOrderBy>>;
};

/** All input for the `updateOffer` mutation. */
export type UpdateOfferInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Offer` being updated. */
  readonly patch: OfferPatch;
  readonly oid: Scalars['UUID'];
};

/** The output of our update `Offer` mutation. */
export type UpdateOfferPayload = {
  readonly __typename?: 'UpdateOfferPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Offer` that was updated by this mutation. */
  readonly offer?: Maybe<Offer>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Catalog` that is related to this `Offer`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
  /** An edge for our `Offer`. May be used by Relay 1. */
  readonly offerEdge?: Maybe<OffersEdge>;
};


/** The output of our update `Offer` mutation. */
export type UpdateOfferPayloadOfferEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<OffersOrderBy>>;
};

/** All input for the `updateOrder` mutation. */
export type UpdateOrderInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Order` being updated. */
  readonly patch: OrderPatch;
  readonly oid: Scalars['UUID'];
};

/** The output of our update `Order` mutation. */
export type UpdateOrderPayload = {
  readonly __typename?: 'UpdateOrderPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Order` that was updated by this mutation. */
  readonly order?: Maybe<Order>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** An edge for our `Order`. May be used by Relay 1. */
  readonly orderEdge?: Maybe<OrdersEdge>;
};


/** The output of our update `Order` mutation. */
export type UpdateOrderPayloadOrderEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<OrdersOrderBy>>;
};

/** All input for the `updateOrderProduct` mutation. */
export type UpdateOrderProductInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `OrderProduct` being updated. */
  readonly patch: OrderProductPatch;
  readonly oid: Scalars['UUID'];
};

/** The output of our update `OrderProduct` mutation. */
export type UpdateOrderProductPayload = {
  readonly __typename?: 'UpdateOrderProductPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `OrderProduct` that was updated by this mutation. */
  readonly orderProduct?: Maybe<OrderProduct>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Order` that is related to this `OrderProduct`. */
  readonly orderByOrder?: Maybe<Order>;
  /** An edge for our `OrderProduct`. May be used by Relay 1. */
  readonly orderProductEdge?: Maybe<OrderProductsEdge>;
};


/** The output of our update `OrderProduct` mutation. */
export type UpdateOrderProductPayloadOrderProductEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<OrderProductsOrderBy>>;
};

/** All input for the `updatePage` mutation. */
export type UpdatePageInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Page` being updated. */
  readonly patch: PagePatch;
  readonly oid: Scalars['UUID'];
};

/** The output of our update `Page` mutation. */
export type UpdatePagePayload = {
  readonly __typename?: 'UpdatePagePayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Page` that was updated by this mutation. */
  readonly page?: Maybe<Page>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Version` that is related to this `Page`. */
  readonly versionByVersion?: Maybe<Version>;
  /** An edge for our `Page`. May be used by Relay 1. */
  readonly pageEdge?: Maybe<PagesEdge>;
};


/** The output of our update `Page` mutation. */
export type UpdatePagePayloadPageEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<PagesOrderBy>>;
};

/** All input for the `updateProduct` mutation. */
export type UpdateProductInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Product` being updated. */
  readonly patch: ProductPatch;
  readonly oid: Scalars['UUID'];
};

/** The output of our update `Product` mutation. */
export type UpdateProductPayload = {
  readonly __typename?: 'UpdateProductPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Product` that was updated by this mutation. */
  readonly product?: Maybe<Product>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Catalog` that is related to this `Product`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
  /** Reads a single `CategoryByCatalog` that is related to this `Product`. */
  readonly categoryByCatalogByCategoryAndCatalog?: Maybe<CategoryByCatalog>;
  /** An edge for our `Product`. May be used by Relay 1. */
  readonly productEdge?: Maybe<ProductsEdge>;
};


/** The output of our update `Product` mutation. */
export type UpdateProductPayloadProductEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<ProductsOrderBy>>;
};

/** All input for the `updateProfileByEmail` mutation. */
export type UpdateProfileByEmailInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Profile` being updated. */
  readonly patch: ProfilePatch;
  readonly email: Scalars['String'];
};

/** All input for the `updateProfile` mutation. */
export type UpdateProfileInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Profile` being updated. */
  readonly patch: ProfilePatch;
  readonly oid: Scalars['UUID'];
};

/** The output of our update `Profile` mutation. */
export type UpdateProfilePayload = {
  readonly __typename?: 'UpdateProfilePayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Profile` that was updated by this mutation. */
  readonly profile?: Maybe<Profile>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** An edge for our `Profile`. May be used by Relay 1. */
  readonly profileEdge?: Maybe<ProfilesEdge>;
};


/** The output of our update `Profile` mutation. */
export type UpdateProfilePayloadProfileEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<ProfilesOrderBy>>;
};

/** All input for the `updateSubCategoryById` mutation. */
export type UpdateSubCategoryByIdInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `SubCategory` being updated. */
  readonly patch: SubCategoryPatch;
  readonly id: Scalars['Int'];
};

/** All input for the `updateSubCategory` mutation. */
export type UpdateSubCategoryInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `SubCategory` being updated. */
  readonly patch: SubCategoryPatch;
  readonly oid: Scalars['UUID'];
};

/** The output of our update `SubCategory` mutation. */
export type UpdateSubCategoryPayload = {
  readonly __typename?: 'UpdateSubCategoryPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `SubCategory` that was updated by this mutation. */
  readonly subCategory?: Maybe<SubCategory>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Category` that is related to this `SubCategory`. */
  readonly categoryByCategory?: Maybe<Category>;
  /** An edge for our `SubCategory`. May be used by Relay 1. */
  readonly subCategoryEdge?: Maybe<SubCategoriesEdge>;
};


/** The output of our update `SubCategory` mutation. */
export type UpdateSubCategoryPayloadSubCategoryEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<SubCategoriesOrderBy>>;
};

/** All input for the `updateSubscription` mutation. */
export type UpdateSubscriptionInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Subscription` being updated. */
  readonly patch: SubscriptionPatch;
  readonly oid: Scalars['UUID'];
};

/** The output of our update `Subscription` mutation. */
export type UpdateSubscriptionPayload = {
  readonly __typename?: 'UpdateSubscriptionPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Subscription` that was updated by this mutation. */
  readonly subscription?: Maybe<Subscription>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Profile` that is related to this `Subscription`. */
  readonly profileByCustomer?: Maybe<Profile>;
  /** Reads a single `Catalog` that is related to this `Subscription`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
  /** An edge for our `Subscription`. May be used by Relay 1. */
  readonly subscriptionEdge?: Maybe<SubscriptionsEdge>;
};


/** The output of our update `Subscription` mutation. */
export type UpdateSubscriptionPayloadSubscriptionEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<SubscriptionsOrderBy>>;
};

/** All input for the `updateVersionById` mutation. */
export type UpdateVersionByIdInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Version` being updated. */
  readonly patch: VersionPatch;
  readonly id: Scalars['Int'];
};

/** All input for the `updateVersion` mutation. */
export type UpdateVersionInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Version` being updated. */
  readonly patch: VersionPatch;
  readonly oid: Scalars['UUID'];
};

/** The output of our update `Version` mutation. */
export type UpdateVersionPayload = {
  readonly __typename?: 'UpdateVersionPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  readonly clientMutationId?: Maybe<Scalars['String']>;
  /** The `Version` that was updated by this mutation. */
  readonly version?: Maybe<Version>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  readonly query?: Maybe<Query>;
  /** Reads a single `Catalog` that is related to this `Version`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
  /** An edge for our `Version`. May be used by Relay 1. */
  readonly versionEdge?: Maybe<VersionsEdge>;
};


/** The output of our update `Version` mutation. */
export type UpdateVersionPayloadVersionEdgeArgs = {
  orderBy?: Maybe<ReadonlyArray<VersionsOrderBy>>;
};

export type Version = {
  readonly __typename?: 'Version';
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly coverUrl?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly coverMeta?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
  readonly dateCreated?: Maybe<Scalars['Datetime']>;
  readonly dateModified?: Maybe<Scalars['Datetime']>;
  readonly versionNo?: Maybe<Scalars['Int']>;
  readonly autoProcess?: Maybe<Scalars['Boolean']>;
  readonly contactUrl?: Maybe<Scalars['String']>;
  readonly enableCategoryCatalogs?: Maybe<Scalars['Boolean']>;
  readonly language?: Maybe<Scalars['String']>;
  readonly currencySymbol?: Maybe<Scalars['String']>;
  readonly leadZapEmail?: Maybe<Scalars['String']>;
  readonly agentId?: Maybe<Scalars['String']>;
  readonly shortName?: Maybe<Scalars['String']>;
  readonly isFtp?: Maybe<Scalars['Boolean']>;
  readonly catalogCloneId?: Maybe<Scalars['BigInt']>;
  readonly feedAlertNotifcation?: Maybe<Scalars['Datetime']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly order?: Maybe<Scalars['JSON']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  /** Reads a single `Catalog` that is related to this `Version`. */
  readonly catalogByCatalog?: Maybe<Catalog>;
  /** Reads and enables pagination through a set of `Catalog`. */
  readonly catalogsByActiveVersionConnection: CatalogsConnection;
  /** Reads and enables pagination through a set of `Catalog`. */
  readonly catalogsByActiveVersion: ReadonlyArray<Catalog>;
  /** Reads and enables pagination through a set of `Page`. */
  readonly pagesByVersionConnection: PagesConnection;
  /** Reads and enables pagination through a set of `Page`. */
  readonly pagesByVersion: ReadonlyArray<Page>;
  /** Reads and enables pagination through a set of `ProductsByVersion`. */
  readonly productsByVersionsByVersionOidConnection: ProductsByVersionsConnection;
  /** Reads and enables pagination through a set of `ProductsByVersion`. */
  readonly productsByVersionsByVersionOid: ReadonlyArray<ProductsByVersion>;
  /** Reads and enables pagination through a set of `Account`. */
  readonly accountsByCatalogActiveVersionAndAccount: VersionAccountsByCatalogActiveVersionAndAccountManyToManyConnection;
  /** Reads and enables pagination through a set of `Account`. */
  readonly accountsByCatalogActiveVersionAndAccountList: ReadonlyArray<Account>;
  /** Reads and enables pagination through a set of `Department`. */
  readonly departmentsByCatalogActiveVersionAndPrimaryDepartment: VersionDepartmentsByCatalogActiveVersionAndPrimaryDepartmentManyToManyConnection;
  /** Reads and enables pagination through a set of `Department`. */
  readonly departmentsByCatalogActiveVersionAndPrimaryDepartmentList: ReadonlyArray<Department>;
};


export type VersionCatalogsByActiveVersionConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CatalogsOrderBy>>;
  condition?: Maybe<CatalogCondition>;
  filter?: Maybe<CatalogFilter>;
};


export type VersionCatalogsByActiveVersionArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CatalogsOrderBy>>;
  condition?: Maybe<CatalogCondition>;
  filter?: Maybe<CatalogFilter>;
};


export type VersionPagesByVersionConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<PagesOrderBy>>;
  condition?: Maybe<PageCondition>;
  filter?: Maybe<PageFilter>;
};


export type VersionPagesByVersionArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<PagesOrderBy>>;
  condition?: Maybe<PageCondition>;
  filter?: Maybe<PageFilter>;
};


export type VersionProductsByVersionsByVersionOidConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<ProductsByVersionsOrderBy>>;
  condition?: Maybe<ProductsByVersionCondition>;
  filter?: Maybe<ProductsByVersionFilter>;
};


export type VersionProductsByVersionsByVersionOidArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<ProductsByVersionsOrderBy>>;
  condition?: Maybe<ProductsByVersionCondition>;
  filter?: Maybe<ProductsByVersionFilter>;
};


export type VersionAccountsByCatalogActiveVersionAndAccountArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<AccountsOrderBy>>;
  condition?: Maybe<AccountCondition>;
  filter?: Maybe<AccountFilter>;
};


export type VersionAccountsByCatalogActiveVersionAndAccountListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<AccountsOrderBy>>;
  condition?: Maybe<AccountCondition>;
  filter?: Maybe<AccountFilter>;
};


export type VersionDepartmentsByCatalogActiveVersionAndPrimaryDepartmentArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<DepartmentsOrderBy>>;
  condition?: Maybe<DepartmentCondition>;
  filter?: Maybe<DepartmentFilter>;
};


export type VersionDepartmentsByCatalogActiveVersionAndPrimaryDepartmentListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<DepartmentsOrderBy>>;
  condition?: Maybe<DepartmentCondition>;
  filter?: Maybe<DepartmentFilter>;
};

/** A connection to a list of `Account` values, with data from `Catalog`. */
export type VersionAccountsByCatalogActiveVersionAndAccountManyToManyConnection = {
  readonly __typename?: 'VersionAccountsByCatalogActiveVersionAndAccountManyToManyConnection';
  /** A list of `Account` objects. */
  readonly nodes: ReadonlyArray<Account>;
  /** A list of edges which contains the `Account`, info from the `Catalog`, and the cursor to aid in pagination. */
  readonly edges: ReadonlyArray<VersionAccountsByCatalogActiveVersionAndAccountManyToManyEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Account` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Account` edge in the connection, with data from `Catalog`. */
export type VersionAccountsByCatalogActiveVersionAndAccountManyToManyEdge = {
  readonly __typename?: 'VersionAccountsByCatalogActiveVersionAndAccountManyToManyEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Account` at the end of the edge. */
  readonly node: Account;
  /** Reads and enables pagination through a set of `Catalog`. */
  readonly catalogsByAccountConnection: CatalogsConnection;
  /** Reads and enables pagination through a set of `Catalog`. */
  readonly catalogsByAccount: ReadonlyArray<Catalog>;
};


/** A `Account` edge in the connection, with data from `Catalog`. */
export type VersionAccountsByCatalogActiveVersionAndAccountManyToManyEdgeCatalogsByAccountConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CatalogsOrderBy>>;
  condition?: Maybe<CatalogCondition>;
  filter?: Maybe<CatalogFilter>;
};


/** A `Account` edge in the connection, with data from `Catalog`. */
export type VersionAccountsByCatalogActiveVersionAndAccountManyToManyEdgeCatalogsByAccountArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CatalogsOrderBy>>;
  condition?: Maybe<CatalogCondition>;
  filter?: Maybe<CatalogFilter>;
};

/** A condition to be used against `Version` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type VersionCondition = {
  /** Checks for equality with the object’s `oid` field. */
  readonly oid?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `options` field. */
  readonly options?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `id` field. */
  readonly id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `slug` field. */
  readonly slug?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `coverUrl` field. */
  readonly coverUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `active` field. */
  readonly active?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `coverMeta` field. */
  readonly coverMeta?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  readonly name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `description` field. */
  readonly description?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `url` field. */
  readonly url?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `dateCreated` field. */
  readonly dateCreated?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `dateModified` field. */
  readonly dateModified?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `versionNo` field. */
  readonly versionNo?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `autoProcess` field. */
  readonly autoProcess?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `contactUrl` field. */
  readonly contactUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `enableCategoryCatalogs` field. */
  readonly enableCategoryCatalogs?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `language` field. */
  readonly language?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `currencySymbol` field. */
  readonly currencySymbol?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `leadZapEmail` field. */
  readonly leadZapEmail?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `agentId` field. */
  readonly agentId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `shortName` field. */
  readonly shortName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `isFtp` field. */
  readonly isFtp?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `catalogCloneId` field. */
  readonly catalogCloneId?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `feedAlertNotifcation` field. */
  readonly feedAlertNotifcation?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `catalog` field. */
  readonly catalog?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `order` field. */
  readonly order?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `targetUrl` field. */
  readonly targetUrl?: Maybe<Scalars['String']>;
};

/** A connection to a list of `Department` values, with data from `Catalog`. */
export type VersionDepartmentsByCatalogActiveVersionAndPrimaryDepartmentManyToManyConnection = {
  readonly __typename?: 'VersionDepartmentsByCatalogActiveVersionAndPrimaryDepartmentManyToManyConnection';
  /** A list of `Department` objects. */
  readonly nodes: ReadonlyArray<Department>;
  /** A list of edges which contains the `Department`, info from the `Catalog`, and the cursor to aid in pagination. */
  readonly edges: ReadonlyArray<VersionDepartmentsByCatalogActiveVersionAndPrimaryDepartmentManyToManyEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Department` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Department` edge in the connection, with data from `Catalog`. */
export type VersionDepartmentsByCatalogActiveVersionAndPrimaryDepartmentManyToManyEdge = {
  readonly __typename?: 'VersionDepartmentsByCatalogActiveVersionAndPrimaryDepartmentManyToManyEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Department` at the end of the edge. */
  readonly node: Department;
  /** Reads and enables pagination through a set of `Catalog`. */
  readonly catalogsByPrimaryDepartmentConnection: CatalogsConnection;
  /** Reads and enables pagination through a set of `Catalog`. */
  readonly catalogsByPrimaryDepartment: ReadonlyArray<Catalog>;
};


/** A `Department` edge in the connection, with data from `Catalog`. */
export type VersionDepartmentsByCatalogActiveVersionAndPrimaryDepartmentManyToManyEdgeCatalogsByPrimaryDepartmentConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<ReadonlyArray<CatalogsOrderBy>>;
  condition?: Maybe<CatalogCondition>;
  filter?: Maybe<CatalogFilter>;
};


/** A `Department` edge in the connection, with data from `Catalog`. */
export type VersionDepartmentsByCatalogActiveVersionAndPrimaryDepartmentManyToManyEdgeCatalogsByPrimaryDepartmentArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReadonlyArray<CatalogsOrderBy>>;
  condition?: Maybe<CatalogCondition>;
  filter?: Maybe<CatalogFilter>;
};

/** A filter to be used against `Version` object types. All fields are combined with a logical ‘and.’ */
export type VersionFilter = {
  /** Filter by the object’s `oid` field. */
  readonly oid?: Maybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  readonly id?: Maybe<IntFilter>;
  /** Filter by the object’s `slug` field. */
  readonly slug?: Maybe<StringFilter>;
  /** Filter by the object’s `coverUrl` field. */
  readonly coverUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `active` field. */
  readonly active?: Maybe<BooleanFilter>;
  /** Filter by the object’s `coverMeta` field. */
  readonly coverMeta?: Maybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  readonly name?: Maybe<StringFilter>;
  /** Filter by the object’s `description` field. */
  readonly description?: Maybe<StringFilter>;
  /** Filter by the object’s `url` field. */
  readonly url?: Maybe<StringFilter>;
  /** Filter by the object’s `dateCreated` field. */
  readonly dateCreated?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `dateModified` field. */
  readonly dateModified?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `versionNo` field. */
  readonly versionNo?: Maybe<IntFilter>;
  /** Filter by the object’s `autoProcess` field. */
  readonly autoProcess?: Maybe<BooleanFilter>;
  /** Filter by the object’s `contactUrl` field. */
  readonly contactUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `enableCategoryCatalogs` field. */
  readonly enableCategoryCatalogs?: Maybe<BooleanFilter>;
  /** Filter by the object’s `language` field. */
  readonly language?: Maybe<StringFilter>;
  /** Filter by the object’s `currencySymbol` field. */
  readonly currencySymbol?: Maybe<StringFilter>;
  /** Filter by the object’s `leadZapEmail` field. */
  readonly leadZapEmail?: Maybe<StringFilter>;
  /** Filter by the object’s `agentId` field. */
  readonly agentId?: Maybe<StringFilter>;
  /** Filter by the object’s `shortName` field. */
  readonly shortName?: Maybe<StringFilter>;
  /** Filter by the object’s `isFtp` field. */
  readonly isFtp?: Maybe<BooleanFilter>;
  /** Filter by the object’s `catalogCloneId` field. */
  readonly catalogCloneId?: Maybe<BigIntFilter>;
  /** Filter by the object’s `feedAlertNotifcation` field. */
  readonly feedAlertNotifcation?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `catalog` field. */
  readonly catalog?: Maybe<UuidFilter>;
  /** Filter by the object’s `optimisticLockField` field. */
  readonly optimisticLockField?: Maybe<IntFilter>;
  /** Filter by the object’s `targetUrl` field. */
  readonly targetUrl?: Maybe<StringFilter>;
  /** Checks for all expressions in this list. */
  readonly and?: Maybe<ReadonlyArray<VersionFilter>>;
  /** Checks for any expressions in this list. */
  readonly or?: Maybe<ReadonlyArray<VersionFilter>>;
  /** Negates the expression. */
  readonly not?: Maybe<VersionFilter>;
};

/** An input for mutations affecting `Version` */
export type VersionInput = {
  readonly oid: Scalars['UUID'];
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly coverUrl?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly coverMeta?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
  readonly dateCreated?: Maybe<Scalars['Datetime']>;
  readonly dateModified?: Maybe<Scalars['Datetime']>;
  readonly versionNo?: Maybe<Scalars['Int']>;
  readonly autoProcess?: Maybe<Scalars['Boolean']>;
  readonly contactUrl?: Maybe<Scalars['String']>;
  readonly enableCategoryCatalogs?: Maybe<Scalars['Boolean']>;
  readonly language?: Maybe<Scalars['String']>;
  readonly currencySymbol?: Maybe<Scalars['String']>;
  readonly leadZapEmail?: Maybe<Scalars['String']>;
  readonly agentId?: Maybe<Scalars['String']>;
  readonly shortName?: Maybe<Scalars['String']>;
  readonly isFtp?: Maybe<Scalars['Boolean']>;
  readonly catalogCloneId?: Maybe<Scalars['BigInt']>;
  readonly feedAlertNotifcation?: Maybe<Scalars['Datetime']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly order?: Maybe<Scalars['JSON']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly catalogToCatalog?: Maybe<FkVersionCatalogInput>;
  readonly catalogsUsingOid?: Maybe<FkCatalogActiveVersionInverseInput>;
  readonly pagesUsingOid?: Maybe<FkPageVersionInverseInput>;
};

/** The fields on `version` to look up the row to update. */
export type VersionOnCatalogForFkCatalogActiveVersionUsingPkVersionUpdate = {
  /** An object where the defined keys will be set on the `version` being updated. */
  readonly patch: UpdateVersionOnCatalogForFkCatalogActiveVersionPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `version` to look up the row to update. */
export type VersionOnCatalogForFkCatalogActiveVersionUsingVersionByIdUpdate = {
  /** An object where the defined keys will be set on the `version` being updated. */
  readonly patch: UpdateVersionOnCatalogForFkCatalogActiveVersionPatch;
  readonly id: Scalars['Int'];
};

/** The fields on `version` to look up the row to update. */
export type VersionOnPageForFkPageVersionUsingPkVersionUpdate = {
  /** An object where the defined keys will be set on the `version` being updated. */
  readonly patch: UpdateVersionOnPageForFkPageVersionPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `version` to look up the row to update. */
export type VersionOnPageForFkPageVersionUsingVersionByIdUpdate = {
  /** An object where the defined keys will be set on the `version` being updated. */
  readonly patch: UpdateVersionOnPageForFkPageVersionPatch;
  readonly id: Scalars['Int'];
};

/** The fields on `version` to look up the row to update. */
export type VersionOnVersionForFkVersionCatalogUsingPkVersionUpdate = {
  /** An object where the defined keys will be set on the `version` being updated. */
  readonly patch: UpdateVersionOnVersionForFkVersionCatalogPatch;
  readonly oid: Scalars['UUID'];
};

/** The fields on `version` to look up the row to update. */
export type VersionOnVersionForFkVersionCatalogUsingVersionByIdUpdate = {
  /** An object where the defined keys will be set on the `version` being updated. */
  readonly patch: UpdateVersionOnVersionForFkVersionCatalogPatch;
  readonly id: Scalars['Int'];
};

/** Represents an update to a `Version`. Fields that are set will be updated. */
export type VersionPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly coverUrl?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly coverMeta?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
  readonly dateCreated?: Maybe<Scalars['Datetime']>;
  readonly dateModified?: Maybe<Scalars['Datetime']>;
  readonly versionNo?: Maybe<Scalars['Int']>;
  readonly autoProcess?: Maybe<Scalars['Boolean']>;
  readonly contactUrl?: Maybe<Scalars['String']>;
  readonly enableCategoryCatalogs?: Maybe<Scalars['Boolean']>;
  readonly language?: Maybe<Scalars['String']>;
  readonly currencySymbol?: Maybe<Scalars['String']>;
  readonly leadZapEmail?: Maybe<Scalars['String']>;
  readonly agentId?: Maybe<Scalars['String']>;
  readonly shortName?: Maybe<Scalars['String']>;
  readonly isFtp?: Maybe<Scalars['Boolean']>;
  readonly catalogCloneId?: Maybe<Scalars['BigInt']>;
  readonly feedAlertNotifcation?: Maybe<Scalars['Datetime']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly order?: Maybe<Scalars['JSON']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly catalogToCatalog?: Maybe<FkVersionCatalogInput>;
  readonly catalogsUsingOid?: Maybe<FkCatalogActiveVersionInverseInput>;
  readonly pagesUsingOid?: Maybe<FkPageVersionInverseInput>;
};

/** The fields on `version` to look up the row to connect. */
export type VersionPkVersionConnect = {
  readonly oid: Scalars['UUID'];
};

/** The fields on `version` to look up the row to delete. */
export type VersionPkVersionDelete = {
  readonly oid: Scalars['UUID'];
};

/** The fields on `version` to look up the row to connect. */
export type VersionVersionByIdConnect = {
  readonly id: Scalars['Int'];
};

/** The fields on `version` to look up the row to delete. */
export type VersionVersionByIdDelete = {
  readonly id: Scalars['Int'];
};

/** A connection to a list of `Version` values. */
export type VersionsConnection = {
  readonly __typename?: 'VersionsConnection';
  /** A list of `Version` objects. */
  readonly nodes: ReadonlyArray<Version>;
  /** A list of edges which contains the `Version` and cursor to aid in pagination. */
  readonly edges: ReadonlyArray<VersionsEdge>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** The count of *all* `Version` you could get from the connection. */
  readonly totalCount: Scalars['Int'];
};

/** A `Version` edge in the connection. */
export type VersionsEdge = {
  readonly __typename?: 'VersionsEdge';
  /** A cursor for use in pagination. */
  readonly cursor?: Maybe<Scalars['Cursor']>;
  /** The `Version` at the end of the edge. */
  readonly node: Version;
};

/** Methods to use when ordering `Version`. */
export enum VersionsOrderBy {
  Natural = 'NATURAL',
  OidAsc = 'OID_ASC',
  OidDesc = 'OID_DESC',
  OptionsAsc = 'OPTIONS_ASC',
  OptionsDesc = 'OPTIONS_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  CoverUrlAsc = 'COVER_URL_ASC',
  CoverUrlDesc = 'COVER_URL_DESC',
  ActiveAsc = 'ACTIVE_ASC',
  ActiveDesc = 'ACTIVE_DESC',
  CoverMetaAsc = 'COVER_META_ASC',
  CoverMetaDesc = 'COVER_META_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  UrlAsc = 'URL_ASC',
  UrlDesc = 'URL_DESC',
  DateCreatedAsc = 'DATE_CREATED_ASC',
  DateCreatedDesc = 'DATE_CREATED_DESC',
  DateModifiedAsc = 'DATE_MODIFIED_ASC',
  DateModifiedDesc = 'DATE_MODIFIED_DESC',
  VersionNoAsc = 'VERSION_NO_ASC',
  VersionNoDesc = 'VERSION_NO_DESC',
  AutoProcessAsc = 'AUTO_PROCESS_ASC',
  AutoProcessDesc = 'AUTO_PROCESS_DESC',
  ContactUrlAsc = 'CONTACT_URL_ASC',
  ContactUrlDesc = 'CONTACT_URL_DESC',
  EnableCategoryCatalogsAsc = 'ENABLE_CATEGORY_CATALOGS_ASC',
  EnableCategoryCatalogsDesc = 'ENABLE_CATEGORY_CATALOGS_DESC',
  LanguageAsc = 'LANGUAGE_ASC',
  LanguageDesc = 'LANGUAGE_DESC',
  CurrencySymbolAsc = 'CURRENCY_SYMBOL_ASC',
  CurrencySymbolDesc = 'CURRENCY_SYMBOL_DESC',
  LeadZapEmailAsc = 'LEAD_ZAP_EMAIL_ASC',
  LeadZapEmailDesc = 'LEAD_ZAP_EMAIL_DESC',
  AgentIdAsc = 'AGENT_ID_ASC',
  AgentIdDesc = 'AGENT_ID_DESC',
  ShortNameAsc = 'SHORT_NAME_ASC',
  ShortNameDesc = 'SHORT_NAME_DESC',
  IsFtpAsc = 'IS_FTP_ASC',
  IsFtpDesc = 'IS_FTP_DESC',
  CatalogCloneIdAsc = 'CATALOG_CLONE_ID_ASC',
  CatalogCloneIdDesc = 'CATALOG_CLONE_ID_DESC',
  FeedAlertNotifcationAsc = 'FEED_ALERT_NOTIFCATION_ASC',
  FeedAlertNotifcationDesc = 'FEED_ALERT_NOTIFCATION_DESC',
  CatalogAsc = 'CATALOG_ASC',
  CatalogDesc = 'CATALOG_DESC',
  OrderAsc = 'ORDER_ASC',
  OrderDesc = 'ORDER_DESC',
  OptimisticLockFieldAsc = 'OPTIMISTIC_LOCK_FIELD_ASC',
  OptimisticLockFieldDesc = 'OPTIMISTIC_LOCK_FIELD_DESC',
  TargetUrlAsc = 'TARGET_URL_ASC',
  TargetUrlDesc = 'TARGET_URL_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** An object where the defined keys will be set on the `account` being updated. */
export type UpdateAccountOnAuthorizedUserAuthorizedUsersAccountPartnerAccessForFkAuthorizedUserAuthorizedUsersAccountPartnerAccess4E22214EPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly customSmsNumber?: Maybe<Scalars['String']>;
  readonly logo?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly login?: Maybe<Scalars['String']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly defaultTrackingCode?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['Datetime']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['BigInt']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly catalogsUsingOid?: Maybe<FkCatalogAccountInverseInput>;
  readonly contactsUsingOid?: Maybe<FkContactParentInverseInput>;
  readonly campaignsUsingOid?: Maybe<FkCampaignAccountInverseInput>;
  readonly leadsUsingOid?: Maybe<FkLeadAccountInverseInput>;
  readonly authorizedUserAuthorizedUsersAccountPartnerAccessesUsingOid?: Maybe<FkAuthorizedUserAuthorizedUsersAccountPartnerAccess4E22214EInverseInput>;
};

/** An object where the defined keys will be set on the `account` being updated. */
export type UpdateAccountOnCampaignForFkCampaignAccountPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly customSmsNumber?: Maybe<Scalars['String']>;
  readonly logo?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly login?: Maybe<Scalars['String']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly defaultTrackingCode?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['Datetime']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['BigInt']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly catalogsUsingOid?: Maybe<FkCatalogAccountInverseInput>;
  readonly contactsUsingOid?: Maybe<FkContactParentInverseInput>;
  readonly campaignsUsingOid?: Maybe<FkCampaignAccountInverseInput>;
  readonly leadsUsingOid?: Maybe<FkLeadAccountInverseInput>;
  readonly authorizedUserAuthorizedUsersAccountPartnerAccessesUsingOid?: Maybe<FkAuthorizedUserAuthorizedUsersAccountPartnerAccess4E22214EInverseInput>;
};

/** An object where the defined keys will be set on the `account` being updated. */
export type UpdateAccountOnCatalogForFkCatalogAccountPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly customSmsNumber?: Maybe<Scalars['String']>;
  readonly logo?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly login?: Maybe<Scalars['String']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly defaultTrackingCode?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['Datetime']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['BigInt']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly catalogsUsingOid?: Maybe<FkCatalogAccountInverseInput>;
  readonly contactsUsingOid?: Maybe<FkContactParentInverseInput>;
  readonly campaignsUsingOid?: Maybe<FkCampaignAccountInverseInput>;
  readonly leadsUsingOid?: Maybe<FkLeadAccountInverseInput>;
  readonly authorizedUserAuthorizedUsersAccountPartnerAccessesUsingOid?: Maybe<FkAuthorizedUserAuthorizedUsersAccountPartnerAccess4E22214EInverseInput>;
};

/** An object where the defined keys will be set on the `account` being updated. */
export type UpdateAccountOnContactForFkContactParentPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly customSmsNumber?: Maybe<Scalars['String']>;
  readonly logo?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly login?: Maybe<Scalars['String']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly defaultTrackingCode?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['Datetime']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['BigInt']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly catalogsUsingOid?: Maybe<FkCatalogAccountInverseInput>;
  readonly contactsUsingOid?: Maybe<FkContactParentInverseInput>;
  readonly campaignsUsingOid?: Maybe<FkCampaignAccountInverseInput>;
  readonly leadsUsingOid?: Maybe<FkLeadAccountInverseInput>;
  readonly authorizedUserAuthorizedUsersAccountPartnerAccessesUsingOid?: Maybe<FkAuthorizedUserAuthorizedUsersAccountPartnerAccess4E22214EInverseInput>;
};

/** An object where the defined keys will be set on the `account` being updated. */
export type UpdateAccountOnLeadForFkLeadAccountPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly customSmsNumber?: Maybe<Scalars['String']>;
  readonly logo?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly login?: Maybe<Scalars['String']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly defaultTrackingCode?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
  readonly createdOn?: Maybe<Scalars['Datetime']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['BigInt']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly catalogsUsingOid?: Maybe<FkCatalogAccountInverseInput>;
  readonly contactsUsingOid?: Maybe<FkContactParentInverseInput>;
  readonly campaignsUsingOid?: Maybe<FkCampaignAccountInverseInput>;
  readonly leadsUsingOid?: Maybe<FkLeadAccountInverseInput>;
  readonly authorizedUserAuthorizedUsersAccountPartnerAccessesUsingOid?: Maybe<FkAuthorizedUserAuthorizedUsersAccountPartnerAccess4E22214EInverseInput>;
};

/** An object where the defined keys will be set on the `authorizedUserAuthorizedUsersAccountPartnerAccess` being updated. */
export type UpdateAuthorizedUserAuthorizedUsersAccountPartnerAccessOnAuthorizedUserAuthorizedUsersAccountPartnerAccessForFkAuthorizedUserAuthorizedUsersAccountPartnerAccess4E22214EPatch = {
  readonly authorizedUsers?: Maybe<Scalars['UUID']>;
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly accountToPartnerAccess?: Maybe<FkAuthorizedUserAuthorizedUsersAccountPartnerAccess4E22214EInput>;
  readonly authorizedUserToAuthorizedUsers?: Maybe<FkAuthorizedUserAuthorizedUsersAccountPartnerAccessB343B2CfInput>;
};

/** An object where the defined keys will be set on the `authorizedUserAuthorizedUsersAccountPartnerAccess` being updated. */
export type UpdateAuthorizedUserAuthorizedUsersAccountPartnerAccessOnAuthorizedUserAuthorizedUsersAccountPartnerAccessForFkAuthorizedUserAuthorizedUsersAccountPartnerAccessB343B2CfPatch = {
  readonly partnerAccess?: Maybe<Scalars['UUID']>;
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly accountToPartnerAccess?: Maybe<FkAuthorizedUserAuthorizedUsersAccountPartnerAccess4E22214EInput>;
  readonly authorizedUserToAuthorizedUsers?: Maybe<FkAuthorizedUserAuthorizedUsersAccountPartnerAccessB343B2CfInput>;
};

/** An object where the defined keys will be set on the `authorizedUser` being updated. */
export type UpdateAuthorizedUserOnAuthorizedUserAuthorizedUsersAccountPartnerAccessForFkAuthorizedUserAuthorizedUsersAccountPartnerAccessB343B2CfPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly faceBookId?: Maybe<Scalars['String']>;
  readonly googleId?: Maybe<Scalars['String']>;
  readonly linkedInId?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly profileToEmail?: Maybe<FakePublicAuthorizedUserForeignKey0Input>;
  readonly authorizedUserAuthorizedUsersAccountPartnerAccessesUsingOid?: Maybe<FkAuthorizedUserAuthorizedUsersAccountPartnerAccessB343B2CfInverseInput>;
};

/** An object where the defined keys will be set on the `authorizedUser` being updated. */
export type UpdateAuthorizedUserOnAuthorizedUserForFakePublicAuthorizedUserForeignKey0Patch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly faceBookId?: Maybe<Scalars['String']>;
  readonly googleId?: Maybe<Scalars['String']>;
  readonly linkedInId?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly profileToEmail?: Maybe<FakePublicAuthorizedUserForeignKey0Input>;
  readonly authorizedUserAuthorizedUsersAccountPartnerAccessesUsingOid?: Maybe<FkAuthorizedUserAuthorizedUsersAccountPartnerAccessB343B2CfInverseInput>;
};

/** An object where the defined keys will be set on the `campaignGroup` being updated. */
export type UpdateCampaignGroupOnCampaignGroupForFkCampaignGroupCampaignPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly campaignToCampaign?: Maybe<FkCampaignGroupCampaignInput>;
  readonly catalogToCatalog?: Maybe<FkCampaignGroupCatalogInput>;
  readonly campaignUsersUsingOid?: Maybe<FkCampaignUserCampaignGroupInverseInput>;
};

/** An object where the defined keys will be set on the `campaignGroup` being updated. */
export type UpdateCampaignGroupOnCampaignGroupForFkCampaignGroupCatalogPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly campaign?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly campaignToCampaign?: Maybe<FkCampaignGroupCampaignInput>;
  readonly catalogToCatalog?: Maybe<FkCampaignGroupCatalogInput>;
  readonly campaignUsersUsingOid?: Maybe<FkCampaignUserCampaignGroupInverseInput>;
};

/** An object where the defined keys will be set on the `campaignGroup` being updated. */
export type UpdateCampaignGroupOnCampaignUserForFkCampaignUserCampaignGroupPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly campaign?: Maybe<Scalars['UUID']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly campaignToCampaign?: Maybe<FkCampaignGroupCampaignInput>;
  readonly catalogToCatalog?: Maybe<FkCampaignGroupCatalogInput>;
  readonly campaignUsersUsingOid?: Maybe<FkCampaignUserCampaignGroupInverseInput>;
};

/** An object where the defined keys will be set on the `campaign` being updated. */
export type UpdateCampaignOnCampaignForFkCampaignAccountPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly sender?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly distributionDate?: Maybe<Scalars['String']>;
  readonly quantity?: Maybe<Scalars['Int']>;
  readonly terms?: Maybe<Scalars['String']>;
  readonly rate?: Maybe<Scalars['Float']>;
  readonly totalCost?: Maybe<Scalars['String']>;
  readonly vendor?: Maybe<Scalars['String']>;
  readonly opens?: Maybe<Scalars['Int']>;
  readonly clicks?: Maybe<Scalars['Int']>;
  readonly dynalogViews?: Maybe<Scalars['Int']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly accountToAccount?: Maybe<FkCampaignAccountInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCampaignInverseInput>;
};

/** An object where the defined keys will be set on the `campaign` being updated. */
export type UpdateCampaignOnCampaignGroupForFkCampaignGroupCampaignPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly sender?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly distributionDate?: Maybe<Scalars['String']>;
  readonly quantity?: Maybe<Scalars['Int']>;
  readonly terms?: Maybe<Scalars['String']>;
  readonly rate?: Maybe<Scalars['Float']>;
  readonly totalCost?: Maybe<Scalars['String']>;
  readonly vendor?: Maybe<Scalars['String']>;
  readonly opens?: Maybe<Scalars['Int']>;
  readonly clicks?: Maybe<Scalars['Int']>;
  readonly dynalogViews?: Maybe<Scalars['Int']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly accountToAccount?: Maybe<FkCampaignAccountInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCampaignInverseInput>;
};

/** An object where the defined keys will be set on the `campaignUser` being updated. */
export type UpdateCampaignUserOnCampaignUserForFkCampaignUserCampaignGroupPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly lead?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly leadToLead?: Maybe<FkCampaignUserLeadInput>;
  readonly campaignGroupToCampaignGroup?: Maybe<FkCampaignUserCampaignGroupInput>;
};

/** An object where the defined keys will be set on the `campaignUser` being updated. */
export type UpdateCampaignUserOnCampaignUserForFkCampaignUserLeadPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly campaignGroup?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly leadToLead?: Maybe<FkCampaignUserLeadInput>;
  readonly campaignGroupToCampaignGroup?: Maybe<FkCampaignUserCampaignGroupInput>;
};

/** An object where the defined keys will be set on the `carouselListing` being updated. */
export type UpdateCarouselListingOnCarouselListingForFkCarouselListingCarouselPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly rankOrder?: Maybe<Scalars['Int']>;
  readonly offer?: Maybe<Scalars['UUID']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly carouselToCarousel?: Maybe<FkCarouselListingCarouselInput>;
  readonly offerToOffer?: Maybe<FkCarouselListingOfferInput>;
  readonly catalogToCatalog?: Maybe<FkCarouselListingCatalogInput>;
};

/** An object where the defined keys will be set on the `carouselListing` being updated. */
export type UpdateCarouselListingOnCarouselListingForFkCarouselListingCatalogPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly carousel?: Maybe<Scalars['UUID']>;
  readonly rankOrder?: Maybe<Scalars['Int']>;
  readonly offer?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly carouselToCarousel?: Maybe<FkCarouselListingCarouselInput>;
  readonly offerToOffer?: Maybe<FkCarouselListingOfferInput>;
  readonly catalogToCatalog?: Maybe<FkCarouselListingCatalogInput>;
};

/** An object where the defined keys will be set on the `carouselListing` being updated. */
export type UpdateCarouselListingOnCarouselListingForFkCarouselListingOfferPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly carousel?: Maybe<Scalars['UUID']>;
  readonly rankOrder?: Maybe<Scalars['Int']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly carouselToCarousel?: Maybe<FkCarouselListingCarouselInput>;
  readonly offerToOffer?: Maybe<FkCarouselListingOfferInput>;
  readonly catalogToCatalog?: Maybe<FkCarouselListingCatalogInput>;
};

/** An object where the defined keys will be set on the `carousel` being updated. */
export type UpdateCarouselOnCarouselListingForFkCarouselListingCarouselPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly showBySlug?: Maybe<Scalars['String']>;
  readonly showOnHomePage?: Maybe<Scalars['Boolean']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly rankOrder?: Maybe<Scalars['Int']>;
  readonly headLine?: Maybe<Scalars['String']>;
  readonly subText?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly showOffers?: Maybe<Scalars['Int']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaKeys?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingCarouselInverseInput>;
};

/** An object where the defined keys will be set on the `catalog` being updated. */
export type UpdateCatalogOnCampaignGroupForFkCampaignGroupCatalogPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly cover?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly catalogFormat?: Maybe<Scalars['String']>;
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly leadCharge?: Maybe<Scalars['Float']>;
  readonly linkCharge?: Maybe<Scalars['Float']>;
  readonly digitalFee?: Maybe<Scalars['Float']>;
  readonly listingFee?: Maybe<Scalars['Float']>;
  readonly dateExpired?: Maybe<Scalars['Datetime']>;
  readonly trackingCode?: Maybe<Scalars['String']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly showCategoryLinks?: Maybe<Scalars['Boolean']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Datetime']>;
  readonly offerCopy?: Maybe<Scalars['String']>;
  readonly introCopy?: Maybe<Scalars['String']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  readonly blurb?: Maybe<Scalars['String']>;
  readonly featureCopy?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly keywords?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly longDescription?: Maybe<Scalars['String']>;
  readonly class?: Maybe<Scalars['String']>;
  readonly searchAppendCode?: Maybe<Scalars['String']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly primaryDepartment?: Maybe<Scalars['UUID']>;
  readonly isDigital?: Maybe<Scalars['Boolean']>;
  readonly merchantId?: Maybe<Scalars['BigInt']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly autoActivate?: Maybe<Scalars['Boolean']>;
  readonly groupHubspotId?: Maybe<Scalars['String']>;
  readonly customSubdomain?: Maybe<Scalars['String']>;
  readonly text2Disclaimer?: Maybe<Scalars['String']>;
  readonly activeVersion?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly collectFullAddress?: Maybe<Scalars['Boolean']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly freeShipping?: Maybe<Scalars['Boolean']>;
  readonly mailOrder?: Maybe<Scalars['Boolean']>;
  readonly collectLeadQr?: Maybe<Scalars['Boolean']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly hideAds?: Maybe<Scalars['Boolean']>;
  readonly accountToAccount?: Maybe<FkCatalogAccountInput>;
  readonly departmentToPrimaryDepartment?: Maybe<FkCatalogPrimaryDepartmentInput>;
  readonly versionToActiveVersion?: Maybe<FkCatalogActiveVersionInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInverseInput>;
  readonly versionsUsingOid?: Maybe<FkVersionCatalogInverseInput>;
  readonly offersUsingOid?: Maybe<FkOfferCatalogInverseInput>;
  readonly categoriesUsingOid?: Maybe<FkCategoryCatalogInverseInput>;
  readonly feedsUsingOid?: Maybe<FkFeedCatalogInverseInput>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCatalogInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCatalogInverseInput>;
  readonly productsUsingOid?: Maybe<FkProductCatalogInverseInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCatalogInverseInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingCatalogInverseInput>;
  readonly categoryByCatalogsUsingOid?: Maybe<FakePublicCategoryByCatalogForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `catalog` being updated. */
export type UpdateCatalogOnCarouselListingForFkCarouselListingCatalogPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly cover?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly catalogFormat?: Maybe<Scalars['String']>;
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly leadCharge?: Maybe<Scalars['Float']>;
  readonly linkCharge?: Maybe<Scalars['Float']>;
  readonly digitalFee?: Maybe<Scalars['Float']>;
  readonly listingFee?: Maybe<Scalars['Float']>;
  readonly dateExpired?: Maybe<Scalars['Datetime']>;
  readonly trackingCode?: Maybe<Scalars['String']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly showCategoryLinks?: Maybe<Scalars['Boolean']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Datetime']>;
  readonly offerCopy?: Maybe<Scalars['String']>;
  readonly introCopy?: Maybe<Scalars['String']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  readonly blurb?: Maybe<Scalars['String']>;
  readonly featureCopy?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly keywords?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly longDescription?: Maybe<Scalars['String']>;
  readonly class?: Maybe<Scalars['String']>;
  readonly searchAppendCode?: Maybe<Scalars['String']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly primaryDepartment?: Maybe<Scalars['UUID']>;
  readonly isDigital?: Maybe<Scalars['Boolean']>;
  readonly merchantId?: Maybe<Scalars['BigInt']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly autoActivate?: Maybe<Scalars['Boolean']>;
  readonly groupHubspotId?: Maybe<Scalars['String']>;
  readonly customSubdomain?: Maybe<Scalars['String']>;
  readonly text2Disclaimer?: Maybe<Scalars['String']>;
  readonly activeVersion?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly collectFullAddress?: Maybe<Scalars['Boolean']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly freeShipping?: Maybe<Scalars['Boolean']>;
  readonly mailOrder?: Maybe<Scalars['Boolean']>;
  readonly collectLeadQr?: Maybe<Scalars['Boolean']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly hideAds?: Maybe<Scalars['Boolean']>;
  readonly accountToAccount?: Maybe<FkCatalogAccountInput>;
  readonly departmentToPrimaryDepartment?: Maybe<FkCatalogPrimaryDepartmentInput>;
  readonly versionToActiveVersion?: Maybe<FkCatalogActiveVersionInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInverseInput>;
  readonly versionsUsingOid?: Maybe<FkVersionCatalogInverseInput>;
  readonly offersUsingOid?: Maybe<FkOfferCatalogInverseInput>;
  readonly categoriesUsingOid?: Maybe<FkCategoryCatalogInverseInput>;
  readonly feedsUsingOid?: Maybe<FkFeedCatalogInverseInput>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCatalogInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCatalogInverseInput>;
  readonly productsUsingOid?: Maybe<FkProductCatalogInverseInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCatalogInverseInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingCatalogInverseInput>;
  readonly categoryByCatalogsUsingOid?: Maybe<FakePublicCategoryByCatalogForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `catalog` being updated. */
export type UpdateCatalogOnCatalogForFkCatalogAccountPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly cover?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly catalogFormat?: Maybe<Scalars['String']>;
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly leadCharge?: Maybe<Scalars['Float']>;
  readonly linkCharge?: Maybe<Scalars['Float']>;
  readonly digitalFee?: Maybe<Scalars['Float']>;
  readonly listingFee?: Maybe<Scalars['Float']>;
  readonly dateExpired?: Maybe<Scalars['Datetime']>;
  readonly trackingCode?: Maybe<Scalars['String']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly showCategoryLinks?: Maybe<Scalars['Boolean']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Datetime']>;
  readonly offerCopy?: Maybe<Scalars['String']>;
  readonly introCopy?: Maybe<Scalars['String']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  readonly blurb?: Maybe<Scalars['String']>;
  readonly featureCopy?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly keywords?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly longDescription?: Maybe<Scalars['String']>;
  readonly class?: Maybe<Scalars['String']>;
  readonly searchAppendCode?: Maybe<Scalars['String']>;
  readonly primaryDepartment?: Maybe<Scalars['UUID']>;
  readonly isDigital?: Maybe<Scalars['Boolean']>;
  readonly merchantId?: Maybe<Scalars['BigInt']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly autoActivate?: Maybe<Scalars['Boolean']>;
  readonly groupHubspotId?: Maybe<Scalars['String']>;
  readonly customSubdomain?: Maybe<Scalars['String']>;
  readonly text2Disclaimer?: Maybe<Scalars['String']>;
  readonly activeVersion?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly collectFullAddress?: Maybe<Scalars['Boolean']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly freeShipping?: Maybe<Scalars['Boolean']>;
  readonly mailOrder?: Maybe<Scalars['Boolean']>;
  readonly collectLeadQr?: Maybe<Scalars['Boolean']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly hideAds?: Maybe<Scalars['Boolean']>;
  readonly accountToAccount?: Maybe<FkCatalogAccountInput>;
  readonly departmentToPrimaryDepartment?: Maybe<FkCatalogPrimaryDepartmentInput>;
  readonly versionToActiveVersion?: Maybe<FkCatalogActiveVersionInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInverseInput>;
  readonly versionsUsingOid?: Maybe<FkVersionCatalogInverseInput>;
  readonly offersUsingOid?: Maybe<FkOfferCatalogInverseInput>;
  readonly categoriesUsingOid?: Maybe<FkCategoryCatalogInverseInput>;
  readonly feedsUsingOid?: Maybe<FkFeedCatalogInverseInput>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCatalogInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCatalogInverseInput>;
  readonly productsUsingOid?: Maybe<FkProductCatalogInverseInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCatalogInverseInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingCatalogInverseInput>;
  readonly categoryByCatalogsUsingOid?: Maybe<FakePublicCategoryByCatalogForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `catalog` being updated. */
export type UpdateCatalogOnCatalogForFkCatalogActiveVersionPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly cover?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly catalogFormat?: Maybe<Scalars['String']>;
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly leadCharge?: Maybe<Scalars['Float']>;
  readonly linkCharge?: Maybe<Scalars['Float']>;
  readonly digitalFee?: Maybe<Scalars['Float']>;
  readonly listingFee?: Maybe<Scalars['Float']>;
  readonly dateExpired?: Maybe<Scalars['Datetime']>;
  readonly trackingCode?: Maybe<Scalars['String']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly showCategoryLinks?: Maybe<Scalars['Boolean']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Datetime']>;
  readonly offerCopy?: Maybe<Scalars['String']>;
  readonly introCopy?: Maybe<Scalars['String']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  readonly blurb?: Maybe<Scalars['String']>;
  readonly featureCopy?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly keywords?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly longDescription?: Maybe<Scalars['String']>;
  readonly class?: Maybe<Scalars['String']>;
  readonly searchAppendCode?: Maybe<Scalars['String']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly primaryDepartment?: Maybe<Scalars['UUID']>;
  readonly isDigital?: Maybe<Scalars['Boolean']>;
  readonly merchantId?: Maybe<Scalars['BigInt']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly autoActivate?: Maybe<Scalars['Boolean']>;
  readonly groupHubspotId?: Maybe<Scalars['String']>;
  readonly customSubdomain?: Maybe<Scalars['String']>;
  readonly text2Disclaimer?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly collectFullAddress?: Maybe<Scalars['Boolean']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly freeShipping?: Maybe<Scalars['Boolean']>;
  readonly mailOrder?: Maybe<Scalars['Boolean']>;
  readonly collectLeadQr?: Maybe<Scalars['Boolean']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly hideAds?: Maybe<Scalars['Boolean']>;
  readonly accountToAccount?: Maybe<FkCatalogAccountInput>;
  readonly departmentToPrimaryDepartment?: Maybe<FkCatalogPrimaryDepartmentInput>;
  readonly versionToActiveVersion?: Maybe<FkCatalogActiveVersionInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInverseInput>;
  readonly versionsUsingOid?: Maybe<FkVersionCatalogInverseInput>;
  readonly offersUsingOid?: Maybe<FkOfferCatalogInverseInput>;
  readonly categoriesUsingOid?: Maybe<FkCategoryCatalogInverseInput>;
  readonly feedsUsingOid?: Maybe<FkFeedCatalogInverseInput>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCatalogInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCatalogInverseInput>;
  readonly productsUsingOid?: Maybe<FkProductCatalogInverseInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCatalogInverseInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingCatalogInverseInput>;
  readonly categoryByCatalogsUsingOid?: Maybe<FakePublicCategoryByCatalogForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `catalog` being updated. */
export type UpdateCatalogOnCatalogForFkCatalogPrimaryDepartmentPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly cover?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly catalogFormat?: Maybe<Scalars['String']>;
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly leadCharge?: Maybe<Scalars['Float']>;
  readonly linkCharge?: Maybe<Scalars['Float']>;
  readonly digitalFee?: Maybe<Scalars['Float']>;
  readonly listingFee?: Maybe<Scalars['Float']>;
  readonly dateExpired?: Maybe<Scalars['Datetime']>;
  readonly trackingCode?: Maybe<Scalars['String']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly showCategoryLinks?: Maybe<Scalars['Boolean']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Datetime']>;
  readonly offerCopy?: Maybe<Scalars['String']>;
  readonly introCopy?: Maybe<Scalars['String']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  readonly blurb?: Maybe<Scalars['String']>;
  readonly featureCopy?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly keywords?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly longDescription?: Maybe<Scalars['String']>;
  readonly class?: Maybe<Scalars['String']>;
  readonly searchAppendCode?: Maybe<Scalars['String']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly isDigital?: Maybe<Scalars['Boolean']>;
  readonly merchantId?: Maybe<Scalars['BigInt']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly autoActivate?: Maybe<Scalars['Boolean']>;
  readonly groupHubspotId?: Maybe<Scalars['String']>;
  readonly customSubdomain?: Maybe<Scalars['String']>;
  readonly text2Disclaimer?: Maybe<Scalars['String']>;
  readonly activeVersion?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly collectFullAddress?: Maybe<Scalars['Boolean']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly freeShipping?: Maybe<Scalars['Boolean']>;
  readonly mailOrder?: Maybe<Scalars['Boolean']>;
  readonly collectLeadQr?: Maybe<Scalars['Boolean']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly hideAds?: Maybe<Scalars['Boolean']>;
  readonly accountToAccount?: Maybe<FkCatalogAccountInput>;
  readonly departmentToPrimaryDepartment?: Maybe<FkCatalogPrimaryDepartmentInput>;
  readonly versionToActiveVersion?: Maybe<FkCatalogActiveVersionInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInverseInput>;
  readonly versionsUsingOid?: Maybe<FkVersionCatalogInverseInput>;
  readonly offersUsingOid?: Maybe<FkOfferCatalogInverseInput>;
  readonly categoriesUsingOid?: Maybe<FkCategoryCatalogInverseInput>;
  readonly feedsUsingOid?: Maybe<FkFeedCatalogInverseInput>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCatalogInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCatalogInverseInput>;
  readonly productsUsingOid?: Maybe<FkProductCatalogInverseInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCatalogInverseInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingCatalogInverseInput>;
  readonly categoryByCatalogsUsingOid?: Maybe<FakePublicCategoryByCatalogForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `catalog` being updated. */
export type UpdateCatalogOnCategoryByCatalogForFakePublicCategoryByCatalogForeignKey0Patch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly cover?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly catalogFormat?: Maybe<Scalars['String']>;
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly leadCharge?: Maybe<Scalars['Float']>;
  readonly linkCharge?: Maybe<Scalars['Float']>;
  readonly digitalFee?: Maybe<Scalars['Float']>;
  readonly listingFee?: Maybe<Scalars['Float']>;
  readonly dateExpired?: Maybe<Scalars['Datetime']>;
  readonly trackingCode?: Maybe<Scalars['String']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly showCategoryLinks?: Maybe<Scalars['Boolean']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Datetime']>;
  readonly offerCopy?: Maybe<Scalars['String']>;
  readonly introCopy?: Maybe<Scalars['String']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  readonly blurb?: Maybe<Scalars['String']>;
  readonly featureCopy?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly keywords?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly longDescription?: Maybe<Scalars['String']>;
  readonly class?: Maybe<Scalars['String']>;
  readonly searchAppendCode?: Maybe<Scalars['String']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly primaryDepartment?: Maybe<Scalars['UUID']>;
  readonly isDigital?: Maybe<Scalars['Boolean']>;
  readonly merchantId?: Maybe<Scalars['BigInt']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly autoActivate?: Maybe<Scalars['Boolean']>;
  readonly groupHubspotId?: Maybe<Scalars['String']>;
  readonly customSubdomain?: Maybe<Scalars['String']>;
  readonly text2Disclaimer?: Maybe<Scalars['String']>;
  readonly activeVersion?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly collectFullAddress?: Maybe<Scalars['Boolean']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly freeShipping?: Maybe<Scalars['Boolean']>;
  readonly mailOrder?: Maybe<Scalars['Boolean']>;
  readonly collectLeadQr?: Maybe<Scalars['Boolean']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly hideAds?: Maybe<Scalars['Boolean']>;
  readonly accountToAccount?: Maybe<FkCatalogAccountInput>;
  readonly departmentToPrimaryDepartment?: Maybe<FkCatalogPrimaryDepartmentInput>;
  readonly versionToActiveVersion?: Maybe<FkCatalogActiveVersionInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInverseInput>;
  readonly versionsUsingOid?: Maybe<FkVersionCatalogInverseInput>;
  readonly offersUsingOid?: Maybe<FkOfferCatalogInverseInput>;
  readonly categoriesUsingOid?: Maybe<FkCategoryCatalogInverseInput>;
  readonly feedsUsingOid?: Maybe<FkFeedCatalogInverseInput>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCatalogInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCatalogInverseInput>;
  readonly productsUsingOid?: Maybe<FkProductCatalogInverseInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCatalogInverseInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingCatalogInverseInput>;
  readonly categoryByCatalogsUsingOid?: Maybe<FakePublicCategoryByCatalogForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `catalog` being updated. */
export type UpdateCatalogOnCategoryForFkCategoryCatalogPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly cover?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly catalogFormat?: Maybe<Scalars['String']>;
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly leadCharge?: Maybe<Scalars['Float']>;
  readonly linkCharge?: Maybe<Scalars['Float']>;
  readonly digitalFee?: Maybe<Scalars['Float']>;
  readonly listingFee?: Maybe<Scalars['Float']>;
  readonly dateExpired?: Maybe<Scalars['Datetime']>;
  readonly trackingCode?: Maybe<Scalars['String']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly showCategoryLinks?: Maybe<Scalars['Boolean']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Datetime']>;
  readonly offerCopy?: Maybe<Scalars['String']>;
  readonly introCopy?: Maybe<Scalars['String']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  readonly blurb?: Maybe<Scalars['String']>;
  readonly featureCopy?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly keywords?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly longDescription?: Maybe<Scalars['String']>;
  readonly class?: Maybe<Scalars['String']>;
  readonly searchAppendCode?: Maybe<Scalars['String']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly primaryDepartment?: Maybe<Scalars['UUID']>;
  readonly isDigital?: Maybe<Scalars['Boolean']>;
  readonly merchantId?: Maybe<Scalars['BigInt']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly autoActivate?: Maybe<Scalars['Boolean']>;
  readonly groupHubspotId?: Maybe<Scalars['String']>;
  readonly customSubdomain?: Maybe<Scalars['String']>;
  readonly text2Disclaimer?: Maybe<Scalars['String']>;
  readonly activeVersion?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly collectFullAddress?: Maybe<Scalars['Boolean']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly freeShipping?: Maybe<Scalars['Boolean']>;
  readonly mailOrder?: Maybe<Scalars['Boolean']>;
  readonly collectLeadQr?: Maybe<Scalars['Boolean']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly hideAds?: Maybe<Scalars['Boolean']>;
  readonly accountToAccount?: Maybe<FkCatalogAccountInput>;
  readonly departmentToPrimaryDepartment?: Maybe<FkCatalogPrimaryDepartmentInput>;
  readonly versionToActiveVersion?: Maybe<FkCatalogActiveVersionInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInverseInput>;
  readonly versionsUsingOid?: Maybe<FkVersionCatalogInverseInput>;
  readonly offersUsingOid?: Maybe<FkOfferCatalogInverseInput>;
  readonly categoriesUsingOid?: Maybe<FkCategoryCatalogInverseInput>;
  readonly feedsUsingOid?: Maybe<FkFeedCatalogInverseInput>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCatalogInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCatalogInverseInput>;
  readonly productsUsingOid?: Maybe<FkProductCatalogInverseInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCatalogInverseInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingCatalogInverseInput>;
  readonly categoryByCatalogsUsingOid?: Maybe<FakePublicCategoryByCatalogForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `catalog` being updated. */
export type UpdateCatalogOnDepartmentDepartmentsCatalogListingForFkDepartmentDepartmentsCatalogListingsListingsPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly cover?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly catalogFormat?: Maybe<Scalars['String']>;
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly leadCharge?: Maybe<Scalars['Float']>;
  readonly linkCharge?: Maybe<Scalars['Float']>;
  readonly digitalFee?: Maybe<Scalars['Float']>;
  readonly listingFee?: Maybe<Scalars['Float']>;
  readonly dateExpired?: Maybe<Scalars['Datetime']>;
  readonly trackingCode?: Maybe<Scalars['String']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly showCategoryLinks?: Maybe<Scalars['Boolean']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Datetime']>;
  readonly offerCopy?: Maybe<Scalars['String']>;
  readonly introCopy?: Maybe<Scalars['String']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  readonly blurb?: Maybe<Scalars['String']>;
  readonly featureCopy?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly keywords?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly longDescription?: Maybe<Scalars['String']>;
  readonly class?: Maybe<Scalars['String']>;
  readonly searchAppendCode?: Maybe<Scalars['String']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly primaryDepartment?: Maybe<Scalars['UUID']>;
  readonly isDigital?: Maybe<Scalars['Boolean']>;
  readonly merchantId?: Maybe<Scalars['BigInt']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly autoActivate?: Maybe<Scalars['Boolean']>;
  readonly groupHubspotId?: Maybe<Scalars['String']>;
  readonly customSubdomain?: Maybe<Scalars['String']>;
  readonly text2Disclaimer?: Maybe<Scalars['String']>;
  readonly activeVersion?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly collectFullAddress?: Maybe<Scalars['Boolean']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly freeShipping?: Maybe<Scalars['Boolean']>;
  readonly mailOrder?: Maybe<Scalars['Boolean']>;
  readonly collectLeadQr?: Maybe<Scalars['Boolean']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly hideAds?: Maybe<Scalars['Boolean']>;
  readonly accountToAccount?: Maybe<FkCatalogAccountInput>;
  readonly departmentToPrimaryDepartment?: Maybe<FkCatalogPrimaryDepartmentInput>;
  readonly versionToActiveVersion?: Maybe<FkCatalogActiveVersionInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInverseInput>;
  readonly versionsUsingOid?: Maybe<FkVersionCatalogInverseInput>;
  readonly offersUsingOid?: Maybe<FkOfferCatalogInverseInput>;
  readonly categoriesUsingOid?: Maybe<FkCategoryCatalogInverseInput>;
  readonly feedsUsingOid?: Maybe<FkFeedCatalogInverseInput>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCatalogInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCatalogInverseInput>;
  readonly productsUsingOid?: Maybe<FkProductCatalogInverseInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCatalogInverseInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingCatalogInverseInput>;
  readonly categoryByCatalogsUsingOid?: Maybe<FakePublicCategoryByCatalogForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `catalog` being updated. */
export type UpdateCatalogOnFeedForFkFeedCatalogPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly cover?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly catalogFormat?: Maybe<Scalars['String']>;
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly leadCharge?: Maybe<Scalars['Float']>;
  readonly linkCharge?: Maybe<Scalars['Float']>;
  readonly digitalFee?: Maybe<Scalars['Float']>;
  readonly listingFee?: Maybe<Scalars['Float']>;
  readonly dateExpired?: Maybe<Scalars['Datetime']>;
  readonly trackingCode?: Maybe<Scalars['String']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly showCategoryLinks?: Maybe<Scalars['Boolean']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Datetime']>;
  readonly offerCopy?: Maybe<Scalars['String']>;
  readonly introCopy?: Maybe<Scalars['String']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  readonly blurb?: Maybe<Scalars['String']>;
  readonly featureCopy?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly keywords?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly longDescription?: Maybe<Scalars['String']>;
  readonly class?: Maybe<Scalars['String']>;
  readonly searchAppendCode?: Maybe<Scalars['String']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly primaryDepartment?: Maybe<Scalars['UUID']>;
  readonly isDigital?: Maybe<Scalars['Boolean']>;
  readonly merchantId?: Maybe<Scalars['BigInt']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly autoActivate?: Maybe<Scalars['Boolean']>;
  readonly groupHubspotId?: Maybe<Scalars['String']>;
  readonly customSubdomain?: Maybe<Scalars['String']>;
  readonly text2Disclaimer?: Maybe<Scalars['String']>;
  readonly activeVersion?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly collectFullAddress?: Maybe<Scalars['Boolean']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly freeShipping?: Maybe<Scalars['Boolean']>;
  readonly mailOrder?: Maybe<Scalars['Boolean']>;
  readonly collectLeadQr?: Maybe<Scalars['Boolean']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly hideAds?: Maybe<Scalars['Boolean']>;
  readonly accountToAccount?: Maybe<FkCatalogAccountInput>;
  readonly departmentToPrimaryDepartment?: Maybe<FkCatalogPrimaryDepartmentInput>;
  readonly versionToActiveVersion?: Maybe<FkCatalogActiveVersionInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInverseInput>;
  readonly versionsUsingOid?: Maybe<FkVersionCatalogInverseInput>;
  readonly offersUsingOid?: Maybe<FkOfferCatalogInverseInput>;
  readonly categoriesUsingOid?: Maybe<FkCategoryCatalogInverseInput>;
  readonly feedsUsingOid?: Maybe<FkFeedCatalogInverseInput>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCatalogInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCatalogInverseInput>;
  readonly productsUsingOid?: Maybe<FkProductCatalogInverseInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCatalogInverseInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingCatalogInverseInput>;
  readonly categoryByCatalogsUsingOid?: Maybe<FakePublicCategoryByCatalogForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `catalog` being updated. */
export type UpdateCatalogOnLinkOutForFkLinkOutCatalogPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly cover?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly catalogFormat?: Maybe<Scalars['String']>;
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly leadCharge?: Maybe<Scalars['Float']>;
  readonly linkCharge?: Maybe<Scalars['Float']>;
  readonly digitalFee?: Maybe<Scalars['Float']>;
  readonly listingFee?: Maybe<Scalars['Float']>;
  readonly dateExpired?: Maybe<Scalars['Datetime']>;
  readonly trackingCode?: Maybe<Scalars['String']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly showCategoryLinks?: Maybe<Scalars['Boolean']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Datetime']>;
  readonly offerCopy?: Maybe<Scalars['String']>;
  readonly introCopy?: Maybe<Scalars['String']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  readonly blurb?: Maybe<Scalars['String']>;
  readonly featureCopy?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly keywords?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly longDescription?: Maybe<Scalars['String']>;
  readonly class?: Maybe<Scalars['String']>;
  readonly searchAppendCode?: Maybe<Scalars['String']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly primaryDepartment?: Maybe<Scalars['UUID']>;
  readonly isDigital?: Maybe<Scalars['Boolean']>;
  readonly merchantId?: Maybe<Scalars['BigInt']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly autoActivate?: Maybe<Scalars['Boolean']>;
  readonly groupHubspotId?: Maybe<Scalars['String']>;
  readonly customSubdomain?: Maybe<Scalars['String']>;
  readonly text2Disclaimer?: Maybe<Scalars['String']>;
  readonly activeVersion?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly collectFullAddress?: Maybe<Scalars['Boolean']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly freeShipping?: Maybe<Scalars['Boolean']>;
  readonly mailOrder?: Maybe<Scalars['Boolean']>;
  readonly collectLeadQr?: Maybe<Scalars['Boolean']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly hideAds?: Maybe<Scalars['Boolean']>;
  readonly accountToAccount?: Maybe<FkCatalogAccountInput>;
  readonly departmentToPrimaryDepartment?: Maybe<FkCatalogPrimaryDepartmentInput>;
  readonly versionToActiveVersion?: Maybe<FkCatalogActiveVersionInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInverseInput>;
  readonly versionsUsingOid?: Maybe<FkVersionCatalogInverseInput>;
  readonly offersUsingOid?: Maybe<FkOfferCatalogInverseInput>;
  readonly categoriesUsingOid?: Maybe<FkCategoryCatalogInverseInput>;
  readonly feedsUsingOid?: Maybe<FkFeedCatalogInverseInput>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCatalogInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCatalogInverseInput>;
  readonly productsUsingOid?: Maybe<FkProductCatalogInverseInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCatalogInverseInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingCatalogInverseInput>;
  readonly categoryByCatalogsUsingOid?: Maybe<FakePublicCategoryByCatalogForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `catalog` being updated. */
export type UpdateCatalogOnOfferForFkOfferCatalogPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly cover?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly catalogFormat?: Maybe<Scalars['String']>;
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly leadCharge?: Maybe<Scalars['Float']>;
  readonly linkCharge?: Maybe<Scalars['Float']>;
  readonly digitalFee?: Maybe<Scalars['Float']>;
  readonly listingFee?: Maybe<Scalars['Float']>;
  readonly dateExpired?: Maybe<Scalars['Datetime']>;
  readonly trackingCode?: Maybe<Scalars['String']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly showCategoryLinks?: Maybe<Scalars['Boolean']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Datetime']>;
  readonly offerCopy?: Maybe<Scalars['String']>;
  readonly introCopy?: Maybe<Scalars['String']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  readonly blurb?: Maybe<Scalars['String']>;
  readonly featureCopy?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly keywords?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly longDescription?: Maybe<Scalars['String']>;
  readonly class?: Maybe<Scalars['String']>;
  readonly searchAppendCode?: Maybe<Scalars['String']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly primaryDepartment?: Maybe<Scalars['UUID']>;
  readonly isDigital?: Maybe<Scalars['Boolean']>;
  readonly merchantId?: Maybe<Scalars['BigInt']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly autoActivate?: Maybe<Scalars['Boolean']>;
  readonly groupHubspotId?: Maybe<Scalars['String']>;
  readonly customSubdomain?: Maybe<Scalars['String']>;
  readonly text2Disclaimer?: Maybe<Scalars['String']>;
  readonly activeVersion?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly collectFullAddress?: Maybe<Scalars['Boolean']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly freeShipping?: Maybe<Scalars['Boolean']>;
  readonly mailOrder?: Maybe<Scalars['Boolean']>;
  readonly collectLeadQr?: Maybe<Scalars['Boolean']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly hideAds?: Maybe<Scalars['Boolean']>;
  readonly accountToAccount?: Maybe<FkCatalogAccountInput>;
  readonly departmentToPrimaryDepartment?: Maybe<FkCatalogPrimaryDepartmentInput>;
  readonly versionToActiveVersion?: Maybe<FkCatalogActiveVersionInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInverseInput>;
  readonly versionsUsingOid?: Maybe<FkVersionCatalogInverseInput>;
  readonly offersUsingOid?: Maybe<FkOfferCatalogInverseInput>;
  readonly categoriesUsingOid?: Maybe<FkCategoryCatalogInverseInput>;
  readonly feedsUsingOid?: Maybe<FkFeedCatalogInverseInput>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCatalogInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCatalogInverseInput>;
  readonly productsUsingOid?: Maybe<FkProductCatalogInverseInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCatalogInverseInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingCatalogInverseInput>;
  readonly categoryByCatalogsUsingOid?: Maybe<FakePublicCategoryByCatalogForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `catalog` being updated. */
export type UpdateCatalogOnProductForFkProductCatalogPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly cover?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly catalogFormat?: Maybe<Scalars['String']>;
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly leadCharge?: Maybe<Scalars['Float']>;
  readonly linkCharge?: Maybe<Scalars['Float']>;
  readonly digitalFee?: Maybe<Scalars['Float']>;
  readonly listingFee?: Maybe<Scalars['Float']>;
  readonly dateExpired?: Maybe<Scalars['Datetime']>;
  readonly trackingCode?: Maybe<Scalars['String']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly showCategoryLinks?: Maybe<Scalars['Boolean']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Datetime']>;
  readonly offerCopy?: Maybe<Scalars['String']>;
  readonly introCopy?: Maybe<Scalars['String']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  readonly blurb?: Maybe<Scalars['String']>;
  readonly featureCopy?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly keywords?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly longDescription?: Maybe<Scalars['String']>;
  readonly class?: Maybe<Scalars['String']>;
  readonly searchAppendCode?: Maybe<Scalars['String']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly primaryDepartment?: Maybe<Scalars['UUID']>;
  readonly isDigital?: Maybe<Scalars['Boolean']>;
  readonly merchantId?: Maybe<Scalars['BigInt']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly autoActivate?: Maybe<Scalars['Boolean']>;
  readonly groupHubspotId?: Maybe<Scalars['String']>;
  readonly customSubdomain?: Maybe<Scalars['String']>;
  readonly text2Disclaimer?: Maybe<Scalars['String']>;
  readonly activeVersion?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly collectFullAddress?: Maybe<Scalars['Boolean']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly freeShipping?: Maybe<Scalars['Boolean']>;
  readonly mailOrder?: Maybe<Scalars['Boolean']>;
  readonly collectLeadQr?: Maybe<Scalars['Boolean']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly hideAds?: Maybe<Scalars['Boolean']>;
  readonly accountToAccount?: Maybe<FkCatalogAccountInput>;
  readonly departmentToPrimaryDepartment?: Maybe<FkCatalogPrimaryDepartmentInput>;
  readonly versionToActiveVersion?: Maybe<FkCatalogActiveVersionInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInverseInput>;
  readonly versionsUsingOid?: Maybe<FkVersionCatalogInverseInput>;
  readonly offersUsingOid?: Maybe<FkOfferCatalogInverseInput>;
  readonly categoriesUsingOid?: Maybe<FkCategoryCatalogInverseInput>;
  readonly feedsUsingOid?: Maybe<FkFeedCatalogInverseInput>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCatalogInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCatalogInverseInput>;
  readonly productsUsingOid?: Maybe<FkProductCatalogInverseInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCatalogInverseInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingCatalogInverseInput>;
  readonly categoryByCatalogsUsingOid?: Maybe<FakePublicCategoryByCatalogForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `catalog` being updated. */
export type UpdateCatalogOnSubscriptionForFkSubscriptionCatalogPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly cover?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly catalogFormat?: Maybe<Scalars['String']>;
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly leadCharge?: Maybe<Scalars['Float']>;
  readonly linkCharge?: Maybe<Scalars['Float']>;
  readonly digitalFee?: Maybe<Scalars['Float']>;
  readonly listingFee?: Maybe<Scalars['Float']>;
  readonly dateExpired?: Maybe<Scalars['Datetime']>;
  readonly trackingCode?: Maybe<Scalars['String']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly showCategoryLinks?: Maybe<Scalars['Boolean']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Datetime']>;
  readonly offerCopy?: Maybe<Scalars['String']>;
  readonly introCopy?: Maybe<Scalars['String']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  readonly blurb?: Maybe<Scalars['String']>;
  readonly featureCopy?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly keywords?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly longDescription?: Maybe<Scalars['String']>;
  readonly class?: Maybe<Scalars['String']>;
  readonly searchAppendCode?: Maybe<Scalars['String']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly primaryDepartment?: Maybe<Scalars['UUID']>;
  readonly isDigital?: Maybe<Scalars['Boolean']>;
  readonly merchantId?: Maybe<Scalars['BigInt']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly autoActivate?: Maybe<Scalars['Boolean']>;
  readonly groupHubspotId?: Maybe<Scalars['String']>;
  readonly customSubdomain?: Maybe<Scalars['String']>;
  readonly text2Disclaimer?: Maybe<Scalars['String']>;
  readonly activeVersion?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly collectFullAddress?: Maybe<Scalars['Boolean']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly freeShipping?: Maybe<Scalars['Boolean']>;
  readonly mailOrder?: Maybe<Scalars['Boolean']>;
  readonly collectLeadQr?: Maybe<Scalars['Boolean']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly hideAds?: Maybe<Scalars['Boolean']>;
  readonly accountToAccount?: Maybe<FkCatalogAccountInput>;
  readonly departmentToPrimaryDepartment?: Maybe<FkCatalogPrimaryDepartmentInput>;
  readonly versionToActiveVersion?: Maybe<FkCatalogActiveVersionInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInverseInput>;
  readonly versionsUsingOid?: Maybe<FkVersionCatalogInverseInput>;
  readonly offersUsingOid?: Maybe<FkOfferCatalogInverseInput>;
  readonly categoriesUsingOid?: Maybe<FkCategoryCatalogInverseInput>;
  readonly feedsUsingOid?: Maybe<FkFeedCatalogInverseInput>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCatalogInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCatalogInverseInput>;
  readonly productsUsingOid?: Maybe<FkProductCatalogInverseInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCatalogInverseInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingCatalogInverseInput>;
  readonly categoryByCatalogsUsingOid?: Maybe<FakePublicCategoryByCatalogForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `catalog` being updated. */
export type UpdateCatalogOnVersionForFkVersionCatalogPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly legacyId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly cover?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly catalogFormat?: Maybe<Scalars['String']>;
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly leadCharge?: Maybe<Scalars['Float']>;
  readonly linkCharge?: Maybe<Scalars['Float']>;
  readonly digitalFee?: Maybe<Scalars['Float']>;
  readonly listingFee?: Maybe<Scalars['Float']>;
  readonly dateExpired?: Maybe<Scalars['Datetime']>;
  readonly trackingCode?: Maybe<Scalars['String']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly showCategoryLinks?: Maybe<Scalars['Boolean']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Datetime']>;
  readonly offerCopy?: Maybe<Scalars['String']>;
  readonly introCopy?: Maybe<Scalars['String']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metaDescription?: Maybe<Scalars['String']>;
  readonly blurb?: Maybe<Scalars['String']>;
  readonly featureCopy?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly keywords?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly longDescription?: Maybe<Scalars['String']>;
  readonly class?: Maybe<Scalars['String']>;
  readonly searchAppendCode?: Maybe<Scalars['String']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly primaryDepartment?: Maybe<Scalars['UUID']>;
  readonly isDigital?: Maybe<Scalars['Boolean']>;
  readonly merchantId?: Maybe<Scalars['BigInt']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly autoActivate?: Maybe<Scalars['Boolean']>;
  readonly groupHubspotId?: Maybe<Scalars['String']>;
  readonly customSubdomain?: Maybe<Scalars['String']>;
  readonly text2Disclaimer?: Maybe<Scalars['String']>;
  readonly activeVersion?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly collectFullAddress?: Maybe<Scalars['Boolean']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly freeShipping?: Maybe<Scalars['Boolean']>;
  readonly mailOrder?: Maybe<Scalars['Boolean']>;
  readonly collectLeadQr?: Maybe<Scalars['Boolean']>;
  readonly homePageUrl?: Maybe<Scalars['String']>;
  readonly hideAds?: Maybe<Scalars['Boolean']>;
  readonly accountToAccount?: Maybe<FkCatalogAccountInput>;
  readonly departmentToPrimaryDepartment?: Maybe<FkCatalogPrimaryDepartmentInput>;
  readonly versionToActiveVersion?: Maybe<FkCatalogActiveVersionInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInverseInput>;
  readonly versionsUsingOid?: Maybe<FkVersionCatalogInverseInput>;
  readonly offersUsingOid?: Maybe<FkOfferCatalogInverseInput>;
  readonly categoriesUsingOid?: Maybe<FkCategoryCatalogInverseInput>;
  readonly feedsUsingOid?: Maybe<FkFeedCatalogInverseInput>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCatalogInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCatalogInverseInput>;
  readonly productsUsingOid?: Maybe<FkProductCatalogInverseInput>;
  readonly campaignGroupsUsingOid?: Maybe<FkCampaignGroupCatalogInverseInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingCatalogInverseInput>;
  readonly categoryByCatalogsUsingOid?: Maybe<FakePublicCategoryByCatalogForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `category` being updated. */
export type UpdateCategoryOnCategoryForFkCategoryCatalogPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly featured?: Maybe<Scalars['Int']>;
  readonly image?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly linkoutUrl?: Maybe<Scalars['String']>;
  readonly productsPerPage?: Maybe<Scalars['Int']>;
  readonly imageOrientation?: Maybe<Scalars['Boolean']>;
  readonly productBorder?: Maybe<Scalars['Boolean']>;
  readonly animated?: Maybe<Scalars['Boolean']>;
  readonly rankOrder?: Maybe<Scalars['Int']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly catalogToCatalog?: Maybe<FkCategoryCatalogInput>;
  readonly subCategoriesUsingOid?: Maybe<FkSubCategoryCategoryInverseInput>;
  readonly subProductsUsingNameAndCatalog?: Maybe<FakePublicSubProductsForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `category` being updated. */
export type UpdateCategoryOnSubCategoryForFkSubCategoryCategoryPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly featured?: Maybe<Scalars['Int']>;
  readonly image?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly linkoutUrl?: Maybe<Scalars['String']>;
  readonly productsPerPage?: Maybe<Scalars['Int']>;
  readonly imageOrientation?: Maybe<Scalars['Boolean']>;
  readonly productBorder?: Maybe<Scalars['Boolean']>;
  readonly animated?: Maybe<Scalars['Boolean']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly rankOrder?: Maybe<Scalars['Int']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly catalogToCatalog?: Maybe<FkCategoryCatalogInput>;
  readonly subCategoriesUsingOid?: Maybe<FkSubCategoryCategoryInverseInput>;
  readonly subProductsUsingNameAndCatalog?: Maybe<FakePublicSubProductsForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `category` being updated. */
export type UpdateCategoryOnSubProductForFakePublicSubProductsForeignKey0Patch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly featured?: Maybe<Scalars['Int']>;
  readonly image?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly linkoutUrl?: Maybe<Scalars['String']>;
  readonly productsPerPage?: Maybe<Scalars['Int']>;
  readonly imageOrientation?: Maybe<Scalars['Boolean']>;
  readonly productBorder?: Maybe<Scalars['Boolean']>;
  readonly animated?: Maybe<Scalars['Boolean']>;
  readonly rankOrder?: Maybe<Scalars['Int']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly catalogToCatalog?: Maybe<FkCategoryCatalogInput>;
  readonly subCategoriesUsingOid?: Maybe<FkSubCategoryCategoryInverseInput>;
  readonly subProductsUsingNameAndCatalog?: Maybe<FakePublicSubProductsForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `contact` being updated. */
export type UpdateContactOnContactForFkContactParentPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly company?: Maybe<Scalars['String']>;
  readonly appProfile?: Maybe<Scalars['Boolean']>;
  readonly dateCreated?: Maybe<Scalars['Datetime']>;
  readonly oldId?: Maybe<Scalars['Int']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly accountToParent?: Maybe<FkContactParentInput>;
};

/** An object where the defined keys will be set on the `departmentDepartmentsCatalogListing` being updated. */
export type UpdateDepartmentDepartmentsCatalogListingOnDepartmentDepartmentsCatalogListingForFkDepartmentDepartmentsCatalogListingsDepartmentsPatch = {
  readonly listings?: Maybe<Scalars['UUID']>;
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly catalogToListings?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInput>;
  readonly departmentToDepartments?: Maybe<FkDepartmentDepartmentsCatalogListingsDepartmentsInput>;
};

/** An object where the defined keys will be set on the `departmentDepartmentsCatalogListing` being updated. */
export type UpdateDepartmentDepartmentsCatalogListingOnDepartmentDepartmentsCatalogListingForFkDepartmentDepartmentsCatalogListingsListingsPatch = {
  readonly departments?: Maybe<Scalars['UUID']>;
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly catalogToListings?: Maybe<FkDepartmentDepartmentsCatalogListingsListingsInput>;
  readonly departmentToDepartments?: Maybe<FkDepartmentDepartmentsCatalogListingsDepartmentsInput>;
};

/** An object where the defined keys will be set on the `department` being updated. */
export type UpdateDepartmentOnCatalogForFkCatalogPrimaryDepartmentPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly departmentId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metakeys?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly searchText?: Maybe<Scalars['String']>;
  readonly advice?: Maybe<Scalars['String']>;
  readonly hiwId?: Maybe<Scalars['Int']>;
  readonly metaTitleArticle?: Maybe<Scalars['String']>;
  readonly metaKeysArticle?: Maybe<Scalars['String']>;
  readonly metaDescrArticle?: Maybe<Scalars['String']>;
  readonly metaKeysProduct?: Maybe<Scalars['String']>;
  readonly articleDept?: Maybe<Scalars['Int']>;
  readonly parentDepartment?: Maybe<Scalars['UUID']>;
  readonly deptType?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly showArticlesByDepartment?: Maybe<Scalars['Boolean']>;
  readonly showArticlesByTag?: Maybe<Scalars['String']>;
  readonly showOffers?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly departmentToParentDepartment?: Maybe<FkDepartmentParentDepartmentInput>;
  readonly catalogsUsingOid?: Maybe<FkCatalogPrimaryDepartmentInverseInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsDepartmentsInverseInput>;
  readonly navigationItemsUsingOid?: Maybe<FkNavigationItemDepartmentInverseInput>;
};

/** An object where the defined keys will be set on the `department` being updated. */
export type UpdateDepartmentOnDepartmentDepartmentsCatalogListingForFkDepartmentDepartmentsCatalogListingsDepartmentsPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly departmentId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metakeys?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly searchText?: Maybe<Scalars['String']>;
  readonly advice?: Maybe<Scalars['String']>;
  readonly hiwId?: Maybe<Scalars['Int']>;
  readonly metaTitleArticle?: Maybe<Scalars['String']>;
  readonly metaKeysArticle?: Maybe<Scalars['String']>;
  readonly metaDescrArticle?: Maybe<Scalars['String']>;
  readonly metaKeysProduct?: Maybe<Scalars['String']>;
  readonly articleDept?: Maybe<Scalars['Int']>;
  readonly parentDepartment?: Maybe<Scalars['UUID']>;
  readonly deptType?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly showArticlesByDepartment?: Maybe<Scalars['Boolean']>;
  readonly showArticlesByTag?: Maybe<Scalars['String']>;
  readonly showOffers?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly departmentToParentDepartment?: Maybe<FkDepartmentParentDepartmentInput>;
  readonly catalogsUsingOid?: Maybe<FkCatalogPrimaryDepartmentInverseInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsDepartmentsInverseInput>;
  readonly navigationItemsUsingOid?: Maybe<FkNavigationItemDepartmentInverseInput>;
};

/** An object where the defined keys will be set on the `department` being updated. */
export type UpdateDepartmentOnDepartmentForFkDepartmentParentDepartmentPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly departmentId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metakeys?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly searchText?: Maybe<Scalars['String']>;
  readonly advice?: Maybe<Scalars['String']>;
  readonly hiwId?: Maybe<Scalars['Int']>;
  readonly metaTitleArticle?: Maybe<Scalars['String']>;
  readonly metaKeysArticle?: Maybe<Scalars['String']>;
  readonly metaDescrArticle?: Maybe<Scalars['String']>;
  readonly metaKeysProduct?: Maybe<Scalars['String']>;
  readonly articleDept?: Maybe<Scalars['Int']>;
  readonly deptType?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly showArticlesByDepartment?: Maybe<Scalars['Boolean']>;
  readonly showArticlesByTag?: Maybe<Scalars['String']>;
  readonly showOffers?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly departmentToParentDepartment?: Maybe<FkDepartmentParentDepartmentInput>;
  readonly catalogsUsingOid?: Maybe<FkCatalogPrimaryDepartmentInverseInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsDepartmentsInverseInput>;
  readonly navigationItemsUsingOid?: Maybe<FkNavigationItemDepartmentInverseInput>;
};

/** An object where the defined keys will be set on the `department` being updated. */
export type UpdateDepartmentOnNavigationItemForFkNavigationItemDepartmentPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly departmentId?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly metaTitle?: Maybe<Scalars['String']>;
  readonly metakeys?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly altText?: Maybe<Scalars['String']>;
  readonly searchText?: Maybe<Scalars['String']>;
  readonly advice?: Maybe<Scalars['String']>;
  readonly hiwId?: Maybe<Scalars['Int']>;
  readonly metaTitleArticle?: Maybe<Scalars['String']>;
  readonly metaKeysArticle?: Maybe<Scalars['String']>;
  readonly metaDescrArticle?: Maybe<Scalars['String']>;
  readonly metaKeysProduct?: Maybe<Scalars['String']>;
  readonly articleDept?: Maybe<Scalars['Int']>;
  readonly parentDepartment?: Maybe<Scalars['UUID']>;
  readonly deptType?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly showArticlesByDepartment?: Maybe<Scalars['Boolean']>;
  readonly showArticlesByTag?: Maybe<Scalars['String']>;
  readonly showOffers?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly departmentToParentDepartment?: Maybe<FkDepartmentParentDepartmentInput>;
  readonly catalogsUsingOid?: Maybe<FkCatalogPrimaryDepartmentInverseInput>;
  readonly departmentDepartmentsCatalogListingsUsingOid?: Maybe<FkDepartmentDepartmentsCatalogListingsDepartmentsInverseInput>;
  readonly navigationItemsUsingOid?: Maybe<FkNavigationItemDepartmentInverseInput>;
};

/** An object where the defined keys will be set on the `feedHistory` being updated. */
export type UpdateFeedHistoryOnFeedHistoryForFkFeedHistoryFeedPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly preUpdateStats?: Maybe<Scalars['JSON']>;
  readonly postUpdateStats?: Maybe<Scalars['JSON']>;
  readonly feedPath?: Maybe<Scalars['String']>;
  readonly feedJson?: Maybe<Scalars['JSON']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly feedToFeed?: Maybe<FkFeedHistoryFeedInput>;
};

/** An object where the defined keys will be set on the `feed` being updated. */
export type UpdateFeedOnFeedForFkFeedCatalogPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly feedType?: Maybe<Scalars['String']>;
  readonly feedMap?: Maybe<Scalars['String']>;
  readonly productMap?: Maybe<Scalars['JSON']>;
  readonly dateModified?: Maybe<Scalars['Datetime']>;
  readonly autoUpdate?: Maybe<Scalars['Boolean']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly catalogToCatalog?: Maybe<FkFeedCatalogInput>;
  readonly feedHistoriesUsingOid?: Maybe<FkFeedHistoryFeedInverseInput>;
};

/** An object where the defined keys will be set on the `feed` being updated. */
export type UpdateFeedOnFeedHistoryForFkFeedHistoryFeedPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly feedType?: Maybe<Scalars['String']>;
  readonly feedMap?: Maybe<Scalars['String']>;
  readonly productMap?: Maybe<Scalars['JSON']>;
  readonly dateModified?: Maybe<Scalars['Datetime']>;
  readonly autoUpdate?: Maybe<Scalars['Boolean']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly catalogToCatalog?: Maybe<FkFeedCatalogInput>;
  readonly feedHistoriesUsingOid?: Maybe<FkFeedHistoryFeedInverseInput>;
};

/** An object where the defined keys will be set on the `lead` being updated. */
export type UpdateLeadOnCampaignUserForFkCampaignUserLeadPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly company?: Maybe<Scalars['String']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly dateCollected?: Maybe<Scalars['Datetime']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly profile?: Maybe<Scalars['UUID']>;
  readonly catalogId?: Maybe<Scalars['Int']>;
  readonly versionId?: Maybe<Scalars['Int']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly accountToAccount?: Maybe<FkLeadAccountInput>;
  readonly profileToProfile?: Maybe<FkLeadProfileInput>;
  readonly campaignUsersUsingOid?: Maybe<FkCampaignUserLeadInverseInput>;
};

/** An object where the defined keys will be set on the `lead` being updated. */
export type UpdateLeadOnLeadForFkLeadAccountPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly company?: Maybe<Scalars['String']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly dateCollected?: Maybe<Scalars['Datetime']>;
  readonly profile?: Maybe<Scalars['UUID']>;
  readonly catalogId?: Maybe<Scalars['Int']>;
  readonly versionId?: Maybe<Scalars['Int']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly accountToAccount?: Maybe<FkLeadAccountInput>;
  readonly profileToProfile?: Maybe<FkLeadProfileInput>;
  readonly campaignUsersUsingOid?: Maybe<FkCampaignUserLeadInverseInput>;
};

/** An object where the defined keys will be set on the `lead` being updated. */
export type UpdateLeadOnLeadForFkLeadProfilePatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly company?: Maybe<Scalars['String']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly dateCollected?: Maybe<Scalars['Datetime']>;
  readonly account?: Maybe<Scalars['UUID']>;
  readonly catalogId?: Maybe<Scalars['Int']>;
  readonly versionId?: Maybe<Scalars['Int']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly accountToAccount?: Maybe<FkLeadAccountInput>;
  readonly profileToProfile?: Maybe<FkLeadProfileInput>;
  readonly campaignUsersUsingOid?: Maybe<FkCampaignUserLeadInverseInput>;
};

/** An object where the defined keys will be set on the `linkOut` being updated. */
export type UpdateLinkOutOnLinkOutForFkLinkOutCatalogPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly linkTime?: Maybe<Scalars['Datetime']>;
  readonly link?: Maybe<Scalars['String']>;
  readonly charge?: Maybe<Scalars['Float']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly customerIp?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly profileToCustomer?: Maybe<FkLinkOutCustomerInput>;
  readonly catalogToCatalog?: Maybe<FkLinkOutCatalogInput>;
};

/** An object where the defined keys will be set on the `linkOut` being updated. */
export type UpdateLinkOutOnLinkOutForFkLinkOutCustomerPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly linkTime?: Maybe<Scalars['Datetime']>;
  readonly link?: Maybe<Scalars['String']>;
  readonly charge?: Maybe<Scalars['Float']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly customerIp?: Maybe<Scalars['String']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly profileToCustomer?: Maybe<FkLinkOutCustomerInput>;
  readonly catalogToCatalog?: Maybe<FkLinkOutCatalogInput>;
};

/** An object where the defined keys will be set on the `navigationItem` being updated. */
export type UpdateNavigationItemOnNavigationItemForFkNavigationItemDepartmentPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly orderIndex?: Maybe<Scalars['Int']>;
  readonly groupText?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly departmentToDepartment?: Maybe<FkNavigationItemDepartmentInput>;
};

/** An object where the defined keys will be set on the `offer` being updated. */
export type UpdateOfferOnCarouselListingForFkCarouselListingOfferPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly image?: Maybe<Scalars['String']>;
  readonly discountInPercent?: Maybe<Scalars['Float']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly certificate?: Maybe<Scalars['String']>;
  readonly expiryDate?: Maybe<Scalars['Datetime']>;
  readonly value?: Maybe<Scalars['String']>;
  readonly disclaimer?: Maybe<Scalars['String']>;
  readonly burst?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly paid?: Maybe<Scalars['Boolean']>;
  readonly catalogToCatalog?: Maybe<FkOfferCatalogInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingOfferInverseInput>;
};

/** An object where the defined keys will be set on the `offer` being updated. */
export type UpdateOfferOnOfferForFkOfferCatalogPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly image?: Maybe<Scalars['String']>;
  readonly discountInPercent?: Maybe<Scalars['Float']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly certificate?: Maybe<Scalars['String']>;
  readonly expiryDate?: Maybe<Scalars['Datetime']>;
  readonly value?: Maybe<Scalars['String']>;
  readonly disclaimer?: Maybe<Scalars['String']>;
  readonly burst?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly paid?: Maybe<Scalars['Boolean']>;
  readonly catalogToCatalog?: Maybe<FkOfferCatalogInput>;
  readonly carouselListingsUsingOid?: Maybe<FkCarouselListingOfferInverseInput>;
};

/** An object where the defined keys will be set on the `order` being updated. */
export type UpdateOrderOnOrderProductForFkOrderProductOrderPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly deliveryAddress?: Maybe<Scalars['String']>;
  readonly subTotal?: Maybe<Scalars['Float']>;
  readonly date?: Maybe<Scalars['Datetime']>;
  readonly amount?: Maybe<Scalars['Float']>;
  readonly deliveryFee?: Maybe<Scalars['Float']>;
  readonly deliveryTime?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly orderProductsUsingOid?: Maybe<FkOrderProductOrderInverseInput>;
};

/** An object where the defined keys will be set on the `orderProduct` being updated. */
export type UpdateOrderProductOnOrderProductForFkOrderProductOrderPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly image?: Maybe<Scalars['String']>;
  readonly total?: Maybe<Scalars['Float']>;
  readonly price?: Maybe<Scalars['Float']>;
  readonly quantity?: Maybe<Scalars['Float']>;
  readonly weight?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly orderToOrder?: Maybe<FkOrderProductOrderInput>;
};

/** An object where the defined keys will be set on the `page` being updated. */
export type UpdatePageOnPageForFkPageVersionPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly imageMeta?: Maybe<Scalars['String']>;
  readonly imageSource?: Maybe<Scalars['String']>;
  readonly pageType?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly versionToVersion?: Maybe<FkPageVersionInput>;
};

/** An object where the defined keys will be set on the `product` being updated. */
export type UpdateProductOnProductForFakePublicProductForeignKey0Patch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly brand?: Maybe<Scalars['String']>;
  readonly color?: Maybe<Scalars['String']>;
  readonly sku?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
  readonly tags?: Maybe<Scalars['String']>;
  readonly price?: Maybe<Scalars['Float']>;
  readonly dateEntered?: Maybe<Scalars['Datetime']>;
  readonly srcOriginal?: Maybe<Scalars['String']>;
  readonly additionalImages?: Maybe<Scalars['JSON']>;
  readonly additionalFields?: Maybe<Scalars['JSON']>;
  readonly changeHistory?: Maybe<Scalars['JSON']>;
  readonly dateModified?: Maybe<Scalars['Datetime']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly subCategory?: Maybe<Scalars['String']>;
  readonly salePrice?: Maybe<Scalars['Float']>;
  readonly expired?: Maybe<Scalars['Boolean']>;
  readonly src?: Maybe<Scalars['String']>;
  readonly rotated?: Maybe<Scalars['Boolean']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly catalogToCatalog?: Maybe<FkProductCatalogInput>;
  readonly categoryByCatalogToCategoryAndCatalog?: Maybe<FakePublicProductForeignKey0Input>;
};

/** An object where the defined keys will be set on the `product` being updated. */
export type UpdateProductOnProductForFkProductCatalogPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly brand?: Maybe<Scalars['String']>;
  readonly color?: Maybe<Scalars['String']>;
  readonly sku?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
  readonly tags?: Maybe<Scalars['String']>;
  readonly price?: Maybe<Scalars['Float']>;
  readonly dateEntered?: Maybe<Scalars['Datetime']>;
  readonly srcOriginal?: Maybe<Scalars['String']>;
  readonly additionalImages?: Maybe<Scalars['JSON']>;
  readonly additionalFields?: Maybe<Scalars['JSON']>;
  readonly changeHistory?: Maybe<Scalars['JSON']>;
  readonly dateModified?: Maybe<Scalars['Datetime']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly subCategory?: Maybe<Scalars['String']>;
  readonly salePrice?: Maybe<Scalars['Float']>;
  readonly category?: Maybe<Scalars['String']>;
  readonly expired?: Maybe<Scalars['Boolean']>;
  readonly src?: Maybe<Scalars['String']>;
  readonly rotated?: Maybe<Scalars['Boolean']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly catalogToCatalog?: Maybe<FkProductCatalogInput>;
  readonly categoryByCatalogToCategoryAndCatalog?: Maybe<FakePublicProductForeignKey0Input>;
};

/** An object where the defined keys will be set on the `profile` being updated. */
export type UpdateProfileOnAuthorizedUserForFakePublicAuthorizedUserForeignKey0Patch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly company?: Maybe<Scalars['String']>;
  readonly dateCreated?: Maybe<Scalars['Datetime']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCustomerInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCustomerInverseInput>;
  readonly leadsUsingOid?: Maybe<FkLeadProfileInverseInput>;
  readonly authorizedUserUsingEmail?: Maybe<FakePublicAuthorizedUserForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `profile` being updated. */
export type UpdateProfileOnLeadForFkLeadProfilePatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly company?: Maybe<Scalars['String']>;
  readonly dateCreated?: Maybe<Scalars['Datetime']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCustomerInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCustomerInverseInput>;
  readonly leadsUsingOid?: Maybe<FkLeadProfileInverseInput>;
  readonly authorizedUserUsingEmail?: Maybe<FakePublicAuthorizedUserForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `profile` being updated. */
export type UpdateProfileOnLinkOutForFkLinkOutCustomerPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly company?: Maybe<Scalars['String']>;
  readonly dateCreated?: Maybe<Scalars['Datetime']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCustomerInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCustomerInverseInput>;
  readonly leadsUsingOid?: Maybe<FkLeadProfileInverseInput>;
  readonly authorizedUserUsingEmail?: Maybe<FakePublicAuthorizedUserForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `profile` being updated. */
export type UpdateProfileOnSubscriptionForFkSubscriptionCustomerPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipPostal?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly company?: Maybe<Scalars['String']>;
  readonly dateCreated?: Maybe<Scalars['Datetime']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly linkOutsUsingOid?: Maybe<FkLinkOutCustomerInverseInput>;
  readonly subscriptionsUsingOid?: Maybe<FkSubscriptionCustomerInverseInput>;
  readonly leadsUsingOid?: Maybe<FkLeadProfileInverseInput>;
  readonly authorizedUserUsingEmail?: Maybe<FakePublicAuthorizedUserForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `subCategory` being updated. */
export type UpdateSubCategoryOnSubCategoryForFkSubCategoryCategoryPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly featured?: Maybe<Scalars['Int']>;
  readonly image?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly linkoutUrl?: Maybe<Scalars['String']>;
  readonly productsPerPage?: Maybe<Scalars['Int']>;
  readonly imageOrientation?: Maybe<Scalars['Boolean']>;
  readonly productBorder?: Maybe<Scalars['Boolean']>;
  readonly animated?: Maybe<Scalars['Boolean']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly categoryToCategory?: Maybe<FkSubCategoryCategoryInput>;
};

/** An object where the defined keys will be set on the `subscription` being updated. */
export type UpdateSubscriptionOnSubscriptionForFkSubscriptionCatalogPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly customer?: Maybe<Scalars['UUID']>;
  readonly date?: Maybe<Scalars['Datetime']>;
  readonly charge?: Maybe<Scalars['Float']>;
  readonly listingId?: Maybe<Scalars['Int']>;
  readonly exportStatus?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly profileToCustomer?: Maybe<FkSubscriptionCustomerInput>;
  readonly catalogToCatalog?: Maybe<FkSubscriptionCatalogInput>;
};

/** An object where the defined keys will be set on the `subscription` being updated. */
export type UpdateSubscriptionOnSubscriptionForFkSubscriptionCustomerPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly date?: Maybe<Scalars['Datetime']>;
  readonly charge?: Maybe<Scalars['Float']>;
  readonly listingId?: Maybe<Scalars['Int']>;
  readonly exportStatus?: Maybe<Scalars['String']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly profileToCustomer?: Maybe<FkSubscriptionCustomerInput>;
  readonly catalogToCatalog?: Maybe<FkSubscriptionCatalogInput>;
};

/** An object where the defined keys will be set on the `version` being updated. */
export type UpdateVersionOnCatalogForFkCatalogActiveVersionPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly coverUrl?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly coverMeta?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
  readonly dateCreated?: Maybe<Scalars['Datetime']>;
  readonly dateModified?: Maybe<Scalars['Datetime']>;
  readonly versionNo?: Maybe<Scalars['Int']>;
  readonly autoProcess?: Maybe<Scalars['Boolean']>;
  readonly contactUrl?: Maybe<Scalars['String']>;
  readonly enableCategoryCatalogs?: Maybe<Scalars['Boolean']>;
  readonly language?: Maybe<Scalars['String']>;
  readonly currencySymbol?: Maybe<Scalars['String']>;
  readonly leadZapEmail?: Maybe<Scalars['String']>;
  readonly agentId?: Maybe<Scalars['String']>;
  readonly shortName?: Maybe<Scalars['String']>;
  readonly isFtp?: Maybe<Scalars['Boolean']>;
  readonly catalogCloneId?: Maybe<Scalars['BigInt']>;
  readonly feedAlertNotifcation?: Maybe<Scalars['Datetime']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly order?: Maybe<Scalars['JSON']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly catalogToCatalog?: Maybe<FkVersionCatalogInput>;
  readonly catalogsUsingOid?: Maybe<FkCatalogActiveVersionInverseInput>;
  readonly pagesUsingOid?: Maybe<FkPageVersionInverseInput>;
};

/** An object where the defined keys will be set on the `version` being updated. */
export type UpdateVersionOnPageForFkPageVersionPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly coverUrl?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly coverMeta?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
  readonly dateCreated?: Maybe<Scalars['Datetime']>;
  readonly dateModified?: Maybe<Scalars['Datetime']>;
  readonly versionNo?: Maybe<Scalars['Int']>;
  readonly autoProcess?: Maybe<Scalars['Boolean']>;
  readonly contactUrl?: Maybe<Scalars['String']>;
  readonly enableCategoryCatalogs?: Maybe<Scalars['Boolean']>;
  readonly language?: Maybe<Scalars['String']>;
  readonly currencySymbol?: Maybe<Scalars['String']>;
  readonly leadZapEmail?: Maybe<Scalars['String']>;
  readonly agentId?: Maybe<Scalars['String']>;
  readonly shortName?: Maybe<Scalars['String']>;
  readonly isFtp?: Maybe<Scalars['Boolean']>;
  readonly catalogCloneId?: Maybe<Scalars['BigInt']>;
  readonly feedAlertNotifcation?: Maybe<Scalars['Datetime']>;
  readonly catalog?: Maybe<Scalars['UUID']>;
  readonly order?: Maybe<Scalars['JSON']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly catalogToCatalog?: Maybe<FkVersionCatalogInput>;
  readonly catalogsUsingOid?: Maybe<FkCatalogActiveVersionInverseInput>;
  readonly pagesUsingOid?: Maybe<FkPageVersionInverseInput>;
};

/** An object where the defined keys will be set on the `version` being updated. */
export type UpdateVersionOnVersionForFkVersionCatalogPatch = {
  readonly oid?: Maybe<Scalars['UUID']>;
  readonly options?: Maybe<Scalars['JSON']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly slug?: Maybe<Scalars['String']>;
  readonly coverUrl?: Maybe<Scalars['String']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly coverMeta?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
  readonly dateCreated?: Maybe<Scalars['Datetime']>;
  readonly dateModified?: Maybe<Scalars['Datetime']>;
  readonly versionNo?: Maybe<Scalars['Int']>;
  readonly autoProcess?: Maybe<Scalars['Boolean']>;
  readonly contactUrl?: Maybe<Scalars['String']>;
  readonly enableCategoryCatalogs?: Maybe<Scalars['Boolean']>;
  readonly language?: Maybe<Scalars['String']>;
  readonly currencySymbol?: Maybe<Scalars['String']>;
  readonly leadZapEmail?: Maybe<Scalars['String']>;
  readonly agentId?: Maybe<Scalars['String']>;
  readonly shortName?: Maybe<Scalars['String']>;
  readonly isFtp?: Maybe<Scalars['Boolean']>;
  readonly catalogCloneId?: Maybe<Scalars['BigInt']>;
  readonly feedAlertNotifcation?: Maybe<Scalars['Datetime']>;
  readonly order?: Maybe<Scalars['JSON']>;
  readonly optimisticLockField?: Maybe<Scalars['Int']>;
  readonly targetUrl?: Maybe<Scalars['String']>;
  readonly catalogToCatalog?: Maybe<FkVersionCatalogInput>;
  readonly catalogsUsingOid?: Maybe<FkCatalogActiveVersionInverseInput>;
  readonly pagesUsingOid?: Maybe<FkPageVersionInverseInput>;
};

export type CatalogFragment = (
  { readonly __typename?: 'Catalog' }
  & Pick<Catalog, 'id' | 'oid' | 'name' | 'title' | 'merchantId' | 'cover' | 'customSubdomain' | 'text2Disclaimer' | 'options' | 'description'>
);

export type LeadFragment = (
  { readonly __typename?: 'Lead' }
  & Pick<Lead, 'oid' | 'firstName' | 'lastName' | 'email' | 'phone' | 'catalogId' | 'versionId'>
  & { readonly accountByAccount?: Maybe<(
    { readonly __typename?: 'Account' }
    & Pick<Account, 'name'>
  )> }
);

export type ProductFragment = (
  { readonly __typename?: 'Product' }
  & Pick<Product, 'src' | 'additionalImages' | 'sku' | 'category' | 'tags' | 'title' | 'description' | 'url' | 'price' | 'salePrice' | 'options' | 'oid' | 'expired' | 'status'>
);

export type PageFragment = (
  { readonly __typename?: 'Page' }
  & Pick<Page, 'oid' | 'imageSource' | 'imageUrl' | 'pageType' | 'options'>
);

export type FeedFragment = (
  { readonly __typename?: 'Feed' }
  & Pick<Feed, 'oid' | 'feedType' | 'feedMap' | 'productMap' | 'autoUpdate' | 'options' | 'dateModified'>
  & { readonly catalog?: Maybe<(
    { readonly __typename?: 'Catalog' }
    & Pick<Catalog, 'id' | 'oid' | 'name'>
  )> }
);

export type VersionFragment = (
  { readonly __typename?: 'Version' }
  & Pick<Version, 'id' | 'oid' | 'name' | 'coverUrl' | 'enableCategoryCatalogs' | 'contactUrl' | 'description' | 'options' | 'versionNo' | 'order'>
);

export type CategoryFragment = (
  { readonly __typename?: 'Category' }
  & Pick<Category, 'id' | 'oid' | 'name' | 'image' | 'options'>
  & { readonly subcategories: ReadonlyArray<(
    { readonly __typename?: 'SubCategory' }
    & Pick<SubCategory, 'id' | 'name' | 'image'>
    & { readonly parent?: Maybe<(
      { readonly __typename?: 'Category' }
      & Pick<Category, 'name'>
    )> }
  )> }
);

export type AccountFragment = (
  { readonly __typename?: 'Account' }
  & Pick<Account, 'oid' | 'name' | 'email' | 'legacyId' | 'active' | 'logo'>
  & { readonly catalogs: ReadonlyArray<(
    { readonly __typename?: 'Catalog' }
    & { readonly activeVersion?: Maybe<(
      { readonly __typename?: 'Version' }
      & VersionFragment
    )> }
    & CatalogFragment
  )> }
);

export type ProfileFragment = (
  { readonly __typename?: 'Profile' }
  & Pick<Profile, 'oid' | 'email' | 'title' | 'firstName' | 'lastName' | 'phone' | 'street' | 'city' | 'state' | 'country' | 'zipPostal' | 'imageUrl' | 'company' | 'options'>
);

export type AccountQueryVariables = Exact<{
  oid: Scalars['UUID'];
}>;


export type AccountQuery = (
  { readonly __typename?: 'Query' }
  & { readonly account?: Maybe<(
    { readonly __typename?: 'Account' }
    & AccountFragment
  )> }
);

export type ProductBySkuQueryVariables = Exact<{
  sku: Scalars['String'];
  catalog: Scalars['UUID'];
}>;


export type ProductBySkuQuery = (
  { readonly __typename?: 'Query' }
  & { readonly products?: Maybe<ReadonlyArray<(
    { readonly __typename?: 'Product' }
    & ProductFragment
  )>> }
);

export type GetCatalogsListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCatalogsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly catalogs?: Maybe<ReadonlyArray<(
    { readonly __typename?: 'Catalog' }
    & CatalogFragment
  )>> }
);

export type FullCatalogByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type FullCatalogByIdQuery = (
  { readonly __typename?: 'Query' }
  & { readonly catalog?: Maybe<(
    { readonly __typename?: 'Catalog' }
    & { readonly products: ReadonlyArray<(
      { readonly __typename?: 'Product' }
      & ProductFragment
    )>, readonly categories: ReadonlyArray<(
      { readonly __typename?: 'Category' }
      & { readonly products: ReadonlyArray<(
        { readonly __typename?: 'SubProduct' }
        & Pick<SubProduct, 'sku' | 'sub' | 'main'>
      )> }
      & CategoryFragment
    )>, readonly version?: Maybe<(
      { readonly __typename?: 'Version' }
      & { readonly pages: ReadonlyArray<(
        { readonly __typename?: 'Page' }
        & PageFragment
      )> }
      & VersionFragment
    )>, readonly account?: Maybe<(
      { readonly __typename?: 'Account' }
      & Pick<Account, 'logo' | 'name'>
      & { readonly catalogs: ReadonlyArray<(
        { readonly __typename?: 'Catalog' }
        & Pick<Catalog, 'id' | 'oid' | 'merchantId'>
        & { readonly activeVersion?: Maybe<(
          { readonly __typename?: 'Version' }
          & Pick<Version, 'id' | 'oid' | 'name' | 'coverUrl'>
        )> }
      )> }
    )> }
    & CatalogFragment
  )> }
);

export type FullVersionByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type FullVersionByIdQuery = (
  { readonly __typename?: 'Query' }
  & { readonly version?: Maybe<(
    { readonly __typename?: 'Version' }
    & { readonly pages: ReadonlyArray<(
      { readonly __typename?: 'Page' }
      & PageFragment
    )>, readonly catalog?: Maybe<(
      { readonly __typename?: 'Catalog' }
      & { readonly products: ReadonlyArray<(
        { readonly __typename?: 'Product' }
        & ProductFragment
      )>, readonly categories: ReadonlyArray<(
        { readonly __typename?: 'Category' }
        & { readonly products: ReadonlyArray<(
          { readonly __typename?: 'SubProduct' }
          & Pick<SubProduct, 'sku' | 'sub' | 'main'>
        )> }
        & CategoryFragment
      )>, readonly account?: Maybe<(
        { readonly __typename?: 'Account' }
        & Pick<Account, 'logo' | 'name'>
        & { readonly catalogs: ReadonlyArray<(
          { readonly __typename?: 'Catalog' }
          & Pick<Catalog, 'id' | 'oid' | 'merchantId'>
          & { readonly activeVersion?: Maybe<(
            { readonly __typename?: 'Version' }
            & Pick<Version, 'id' | 'oid' | 'name' | 'coverUrl'>
          )> }
        )> }
      )> }
      & CatalogFragment
    )> }
    & VersionFragment
  )> }
);

export type ActiveAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type ActiveAccountsQuery = (
  { readonly __typename?: 'Query' }
  & { readonly accounts?: Maybe<ReadonlyArray<(
    { readonly __typename?: 'Account' }
    & Pick<Account, 'oid' | 'name' | 'email' | 'legacyId' | 'active' | 'logo'>
    & { readonly catalogs: ReadonlyArray<(
      { readonly __typename?: 'Catalog' }
      & { readonly activeVersion?: Maybe<(
        { readonly __typename?: 'Version' }
        & VersionFragment
      )>, readonly versions: ReadonlyArray<(
        { readonly __typename?: 'Version' }
        & VersionFragment
      )> }
      & CatalogFragment
    )> }
  )>> }
);

export type CatalogByOidQueryVariables = Exact<{
  oid: Scalars['UUID'];
}>;


export type CatalogByOidQuery = (
  { readonly __typename?: 'Query' }
  & { readonly catalog?: Maybe<(
    { readonly __typename?: 'Catalog' }
    & { readonly account?: Maybe<(
      { readonly __typename?: 'Account' }
      & Pick<Account, 'logo' | 'name' | 'active'>
    )>, readonly activeVersion?: Maybe<(
      { readonly __typename?: 'Version' }
      & VersionFragment
    )>, readonly versions: ReadonlyArray<(
      { readonly __typename?: 'Version' }
      & VersionFragment
    )> }
    & CatalogFragment
  )> }
);

export type CatalogByVersionIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CatalogByVersionIdQuery = (
  { readonly __typename?: 'Query' }
  & { readonly version?: Maybe<(
    { readonly __typename?: 'Version' }
    & { readonly catalog?: Maybe<(
      { readonly __typename?: 'Catalog' }
      & { readonly account?: Maybe<(
        { readonly __typename?: 'Account' }
        & Pick<Account, 'logo' | 'name' | 'active'>
      )> }
      & CatalogFragment
    )> }
    & VersionFragment
  )> }
);

export type CatalogByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CatalogByIdQuery = (
  { readonly __typename?: 'Query' }
  & { readonly catalog?: Maybe<(
    { readonly __typename?: 'Catalog' }
    & { readonly account?: Maybe<(
      { readonly __typename?: 'Account' }
      & Pick<Account, 'logo' | 'name' | 'active'>
    )>, readonly activeVersion?: Maybe<(
      { readonly __typename?: 'Version' }
      & VersionFragment
    )>, readonly versions: ReadonlyArray<(
      { readonly __typename?: 'Version' }
      & VersionFragment
    )> }
    & CatalogFragment
  )> }
);

export type AllAuthorizedUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type AllAuthorizedUsersQuery = (
  { readonly __typename?: 'Query' }
  & { readonly authorizedUsers?: Maybe<ReadonlyArray<(
    { readonly __typename?: 'AuthorizedUser' }
    & Pick<AuthorizedUser, 'oid' | 'email'>
    & { readonly accounts: (
      { readonly __typename?: 'AuthorizedUserAuthorizedUsersAccountPartnerAccessesConnection' }
      & Pick<AuthorizedUserAuthorizedUsersAccountPartnerAccessesConnection, 'totalCount'>
    ) }
  )>> }
);

export type UserByEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type UserByEmailQuery = (
  { readonly __typename?: 'Query' }
  & { readonly profile?: Maybe<(
    { readonly __typename?: 'Profile' }
    & ProfileFragment
  )>, readonly authorizedUserByEmail?: Maybe<(
    { readonly __typename?: 'AuthorizedUser' }
    & { readonly accounts: ReadonlyArray<(
      { readonly __typename?: 'Account' }
      & AccountFragment
    )> }
  )> }
);

export type UpdateProfileMutationVariables = Exact<{
  oid: Scalars['UUID'];
  profile: ProfilePatch;
}>;


export type UpdateProfileMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateProfile?: Maybe<(
    { readonly __typename?: 'UpdateProfilePayload' }
    & Pick<UpdateProfilePayload, 'clientMutationId'>
    & { readonly profile?: Maybe<(
      { readonly __typename?: 'Profile' }
      & ProfileFragment
    )> }
  )> }
);

export type CreateProfileMutationVariables = Exact<{
  profile: ProfileInput;
}>;


export type CreateProfileMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createProfile?: Maybe<(
    { readonly __typename?: 'CreateProfilePayload' }
    & Pick<CreateProfilePayload, 'clientMutationId'>
    & { readonly profile?: Maybe<(
      { readonly __typename?: 'Profile' }
      & ProfileFragment
    )> }
  )> }
);

export type VersionFullByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type VersionFullByIdQuery = (
  { readonly __typename?: 'Query' }
  & { readonly version?: Maybe<(
    { readonly __typename?: 'Version' }
    & { readonly products: ReadonlyArray<(
      { readonly __typename?: 'ProductsByVersion' }
      & Pick<ProductsByVersion, 'sku'>
    )>, readonly pages: ReadonlyArray<(
      { readonly __typename?: 'Page' }
      & PageFragment
    )>, readonly catalog?: Maybe<(
      { readonly __typename?: 'Catalog' }
      & { readonly products: ReadonlyArray<(
        { readonly __typename?: 'Product' }
        & ProductFragment
      )> }
      & CatalogFragment
    )> }
    & VersionFragment
  )> }
);

export type ProductsByVersionIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ProductsByVersionIdQuery = (
  { readonly __typename?: 'Query' }
  & { readonly version?: Maybe<(
    { readonly __typename?: 'Version' }
    & Pick<Version, 'id' | 'oid' | 'name'>
    & { readonly catalog?: Maybe<(
      { readonly __typename?: 'Catalog' }
      & Pick<Catalog, 'id' | 'oid'>
      & { readonly products: ReadonlyArray<(
        { readonly __typename?: 'Product' }
        & ProductFragment
      )> }
    )> }
  )> }
);

export type ActiveVersionByCatalogIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ActiveVersionByCatalogIdQuery = (
  { readonly __typename?: 'Query' }
  & { readonly catalog?: Maybe<(
    { readonly __typename?: 'Catalog' }
    & { readonly account?: Maybe<(
      { readonly __typename?: 'Account' }
      & Pick<Account, 'logo' | 'name' | 'active'>
    )>, readonly activeVersion?: Maybe<(
      { readonly __typename?: 'Version' }
      & VersionFragment
    )> }
    & CatalogFragment
  )> }
);

export type ProductsCatalogQueryVariables = Exact<{
  oid: Scalars['UUID'];
}>;


export type ProductsCatalogQuery = (
  { readonly __typename?: 'Query' }
  & { readonly catalog?: Maybe<(
    { readonly __typename?: 'Catalog' }
    & Pick<Catalog, 'id' | 'oid'>
    & { readonly products: ReadonlyArray<(
      { readonly __typename?: 'Product' }
      & ProductFragment
    )> }
  )> }
);

export type ProductsByCatalogIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ProductsByCatalogIdQuery = (
  { readonly __typename?: 'Query' }
  & { readonly catalog?: Maybe<(
    { readonly __typename?: 'Catalog' }
    & Pick<Catalog, 'id' | 'oid'>
    & { readonly products: ReadonlyArray<(
      { readonly __typename?: 'Product' }
      & ProductFragment
    )> }
  )> }
);

export type CategoriesByCatalogIdQueryVariables = Exact<{
  oid: Scalars['UUID'];
}>;


export type CategoriesByCatalogIdQuery = (
  { readonly __typename?: 'Query' }
  & { readonly catalog?: Maybe<(
    { readonly __typename?: 'Catalog' }
    & Pick<Catalog, 'id' | 'oid'>
    & { readonly categories: ReadonlyArray<(
      { readonly __typename?: 'Category' }
      & CategoryFragment
    )> }
  )> }
);

export type CatalogCategoriesQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CatalogCategoriesQuery = (
  { readonly __typename?: 'Query' }
  & { readonly catalog?: Maybe<(
    { readonly __typename?: 'Catalog' }
    & Pick<Catalog, 'id' | 'oid'>
    & { readonly categories: ReadonlyArray<(
      { readonly __typename?: 'Category' }
      & CategoryFragment
    )> }
  )> }
);

export type CategoriesByVersionIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CategoriesByVersionIdQuery = (
  { readonly __typename?: 'Query' }
  & { readonly version?: Maybe<(
    { readonly __typename?: 'Version' }
    & Pick<Version, 'id' | 'oid' | 'name'>
    & { readonly catalog?: Maybe<(
      { readonly __typename?: 'Catalog' }
      & Pick<Catalog, 'id' | 'oid'>
      & { readonly categories: ReadonlyArray<(
        { readonly __typename?: 'Category' }
        & CategoryFragment
      )> }
    )> }
  )> }
);

export type LeadsAccountQueryVariables = Exact<{
  account: Scalars['UUID'];
}>;


export type LeadsAccountQuery = (
  { readonly __typename?: 'Query' }
  & { readonly leads?: Maybe<ReadonlyArray<(
    { readonly __typename?: 'Lead' }
    & LeadFragment
  )>> }
);

export type UpdatePageMutationVariables = Exact<{
  id: Scalars['UUID'];
  payload: PagePatch;
}>;


export type UpdatePageMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updatePage?: Maybe<(
    { readonly __typename?: 'UpdatePagePayload' }
    & { readonly page?: Maybe<(
      { readonly __typename?: 'Page' }
      & PageFragment
    )> }
  )> }
);

export type CreateCatalogMutationVariables = Exact<{
  catalog: CatalogInput;
}>;


export type CreateCatalogMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createCatalog?: Maybe<(
    { readonly __typename?: 'CreateCatalogPayload' }
    & { readonly catalog?: Maybe<(
      { readonly __typename?: 'Catalog' }
      & { readonly account?: Maybe<(
        { readonly __typename?: 'Account' }
        & Pick<Account, 'logo' | 'name' | 'active'>
      )>, readonly activeVersion?: Maybe<(
        { readonly __typename?: 'Version' }
        & VersionFragment
      )>, readonly versions: ReadonlyArray<(
        { readonly __typename?: 'Version' }
        & VersionFragment
      )> }
      & CatalogFragment
    )> }
  )> }
);

export type UpdateCatalogMutationVariables = Exact<{
  catalog: CatalogPatch;
  oid: Scalars['UUID'];
}>;


export type UpdateCatalogMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateCatalog?: Maybe<(
    { readonly __typename?: 'UpdateCatalogPayload' }
    & { readonly catalog?: Maybe<(
      { readonly __typename?: 'Catalog' }
      & { readonly activeVersion?: Maybe<(
        { readonly __typename?: 'Version' }
        & VersionFragment
      )>, readonly versions: ReadonlyArray<(
        { readonly __typename?: 'Version' }
        & VersionFragment
      )> }
      & CatalogFragment
    )> }
  )> }
);

export type DeleteCatalogMutationVariables = Exact<{
  oid: Scalars['UUID'];
}>;


export type DeleteCatalogMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly deleteCatalog?: Maybe<(
    { readonly __typename?: 'DeleteCatalogPayload' }
    & { readonly catalog?: Maybe<(
      { readonly __typename?: 'Catalog' }
      & Pick<Catalog, 'oid' | 'id'>
    )> }
  )> }
);

export type CreateVersionMutationVariables = Exact<{
  version: VersionInput;
}>;


export type CreateVersionMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createVersion?: Maybe<(
    { readonly __typename?: 'CreateVersionPayload' }
    & { readonly version?: Maybe<(
      { readonly __typename?: 'Version' }
      & { readonly catalog?: Maybe<(
        { readonly __typename?: 'Catalog' }
        & CatalogFragment
      )> }
      & VersionFragment
    )> }
  )> }
);

export type UpdateVersionMutationVariables = Exact<{
  version: VersionPatch;
  oid: Scalars['UUID'];
}>;


export type UpdateVersionMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateVersion?: Maybe<(
    { readonly __typename?: 'UpdateVersionPayload' }
    & { readonly version?: Maybe<(
      { readonly __typename?: 'Version' }
      & { readonly catalog?: Maybe<(
        { readonly __typename?: 'Catalog' }
        & CatalogFragment
      )> }
      & VersionFragment
    )> }
  )> }
);

export type DeleteVersionMutationVariables = Exact<{
  oid: Scalars['UUID'];
}>;


export type DeleteVersionMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly deleteVersion?: Maybe<(
    { readonly __typename?: 'DeleteVersionPayload' }
    & Pick<DeleteVersionPayload, 'clientMutationId'>
    & { readonly catalog?: Maybe<(
      { readonly __typename?: 'Catalog' }
      & Pick<Catalog, 'oid' | 'id'>
    )> }
  )> }
);

export type CreatePageMutationVariables = Exact<{
  page: PageInput;
}>;


export type CreatePageMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createPage?: Maybe<(
    { readonly __typename?: 'CreatePagePayload' }
    & { readonly page?: Maybe<(
      { readonly __typename?: 'Page' }
      & PageFragment
    )> }
  )> }
);

export type DeletePageMutationVariables = Exact<{
  oid: Scalars['UUID'];
}>;


export type DeletePageMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly deletePage?: Maybe<(
    { readonly __typename?: 'DeletePagePayload' }
    & { readonly page?: Maybe<(
      { readonly __typename?: 'Page' }
      & Pick<Page, 'oid'>
    )> }
  )> }
);

export type CatalogFeedsQueryVariables = Exact<{ [key: string]: never; }>;


export type CatalogFeedsQuery = (
  { readonly __typename?: 'Query' }
  & { readonly feeds?: Maybe<ReadonlyArray<(
    { readonly __typename?: 'Feed' }
    & FeedFragment
  )>> }
);

export type CreateFeedMutationVariables = Exact<{
  feed: FeedInput;
}>;


export type CreateFeedMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createFeed?: Maybe<(
    { readonly __typename?: 'CreateFeedPayload' }
    & { readonly feed?: Maybe<(
      { readonly __typename?: 'Feed' }
      & FeedFragment
    )> }
  )> }
);

export type UpdateFeedMutationVariables = Exact<{
  feed: FeedPatch;
  oid: Scalars['UUID'];
}>;


export type UpdateFeedMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateFeed?: Maybe<(
    { readonly __typename?: 'UpdateFeedPayload' }
    & { readonly feed?: Maybe<(
      { readonly __typename?: 'Feed' }
      & FeedFragment
    )> }
  )> }
);

export type DeleteFeedMutationVariables = Exact<{
  oid: Scalars['UUID'];
}>;


export type DeleteFeedMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly deleteFeed?: Maybe<(
    { readonly __typename?: 'DeleteFeedPayload' }
    & { readonly feed?: Maybe<(
      { readonly __typename?: 'Feed' }
      & Pick<Feed, 'oid'>
    )> }
  )> }
);

export type CreateCategoryMutationVariables = Exact<{
  category: CategoryInput;
}>;


export type CreateCategoryMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createCategory?: Maybe<(
    { readonly __typename?: 'CreateCategoryPayload' }
    & { readonly category?: Maybe<(
      { readonly __typename?: 'Category' }
      & CategoryFragment
    )> }
  )> }
);

export type UpdateCategoryMutationVariables = Exact<{
  category: CategoryPatch;
  oid: Scalars['UUID'];
}>;


export type UpdateCategoryMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateCategory?: Maybe<(
    { readonly __typename?: 'UpdateCategoryPayload' }
    & { readonly category?: Maybe<(
      { readonly __typename?: 'Category' }
      & CategoryFragment
    )> }
  )> }
);

export type DeleteCategoryMutationVariables = Exact<{
  oid: Scalars['UUID'];
}>;


export type DeleteCategoryMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly deleteCategory?: Maybe<(
    { readonly __typename?: 'DeleteCategoryPayload' }
    & { readonly category?: Maybe<(
      { readonly __typename?: 'Category' }
      & Pick<Category, 'oid' | 'id'>
    )> }
  )> }
);

export type CreateProductMutationVariables = Exact<{
  product: ProductInput;
}>;


export type CreateProductMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createProduct?: Maybe<(
    { readonly __typename?: 'CreateProductPayload' }
    & { readonly product?: Maybe<(
      { readonly __typename?: 'Product' }
      & ProductFragment
    )> }
  )> }
);

export type UpdateProductMutationVariables = Exact<{
  product: ProductPatch;
  oid: Scalars['UUID'];
}>;


export type UpdateProductMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateProduct?: Maybe<(
    { readonly __typename?: 'UpdateProductPayload' }
    & { readonly product?: Maybe<(
      { readonly __typename?: 'Product' }
      & ProductFragment
    )> }
  )> }
);

export type DeleteProductMutationVariables = Exact<{
  oid: Scalars['UUID'];
}>;


export type DeleteProductMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly deleteProduct?: Maybe<(
    { readonly __typename?: 'DeleteProductPayload' }
    & { readonly product?: Maybe<(
      { readonly __typename?: 'Product' }
      & Pick<Product, 'oid'>
    )> }
  )> }
);

export const LeadFragmentDoc = gql`
    fragment Lead on Lead {
  oid
  firstName
  lastName
  email
  phone
  catalogId
  versionId
  accountByAccount {
    name
  }
}
    `;
export const ProductFragmentDoc = gql`
    fragment Product on Product {
  src
  additionalImages
  sku
  category
  tags
  title
  description
  tags
  url
  price
  salePrice
  options
  oid
  expired
  status
}
    `;
export const PageFragmentDoc = gql`
    fragment Page on Page {
  oid
  imageSource
  imageUrl
  pageType
  options
}
    `;
export const FeedFragmentDoc = gql`
    fragment Feed on Feed {
  oid
  feedType
  feedMap
  productMap
  autoUpdate
  options
  dateModified
  catalog: catalogByCatalog {
    id
    oid
    name
  }
}
    `;
export const CategoryFragmentDoc = gql`
    fragment Category on Category {
  id
  oid
  name
  image
  options
  subcategories: subCategoriesByCategory {
    id
    name
    image
    parent: categoryByCategory {
      name
    }
  }
}
    `;
export const CatalogFragmentDoc = gql`
    fragment Catalog on Catalog {
  id
  oid
  name
  title
  merchantId
  cover
  customSubdomain
  text2Disclaimer
  options
  description
}
    `;
export const VersionFragmentDoc = gql`
    fragment Version on Version {
  id
  oid
  name
  coverUrl
  enableCategoryCatalogs
  contactUrl
  description
  options
  versionNo
  order
}
    `;
export const AccountFragmentDoc = gql`
    fragment Account on Account {
  oid
  name
  email
  legacyId
  active
  logo
  catalogs: catalogsByAccount {
    ...Catalog
    activeVersion: versionByActiveVersion {
      ...Version
    }
  }
}
    ${CatalogFragmentDoc}
${VersionFragmentDoc}`;
export const ProfileFragmentDoc = gql`
    fragment Profile on Profile {
  oid
  email
  title
  firstName
  lastName
  phone
  street
  city
  state
  country
  zipPostal
  imageUrl
  company
  options
}
    `;
export const AccountDocument = gql`
    query Account($oid: UUID!) {
  account(oid: $oid) {
    ...Account
  }
}
    ${AccountFragmentDoc}`;

/**
 * __useAccountQuery__
 *
 * To run a query within a React component, call `useAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountQuery({
 *   variables: {
 *      oid: // value for 'oid'
 *   },
 * });
 */
export function useAccountQuery(baseOptions: Apollo.QueryHookOptions<AccountQuery, AccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountQuery, AccountQueryVariables>(AccountDocument, options);
      }
export function useAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountQuery, AccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountQuery, AccountQueryVariables>(AccountDocument, options);
        }
export type AccountQueryHookResult = ReturnType<typeof useAccountQuery>;
export type AccountLazyQueryHookResult = ReturnType<typeof useAccountLazyQuery>;
export type AccountQueryResult = Apollo.QueryResult<AccountQuery, AccountQueryVariables>;
export function refetchAccountQuery(variables?: AccountQueryVariables) {
      return { query: AccountDocument, variables: variables }
    }
export const ProductBySkuDocument = gql`
    query ProductBySku($sku: String!, $catalog: UUID!) {
  products(condition: {sku: $sku, catalog: $catalog}) {
    ...Product
  }
}
    ${ProductFragmentDoc}`;

/**
 * __useProductBySkuQuery__
 *
 * To run a query within a React component, call `useProductBySkuQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductBySkuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductBySkuQuery({
 *   variables: {
 *      sku: // value for 'sku'
 *      catalog: // value for 'catalog'
 *   },
 * });
 */
export function useProductBySkuQuery(baseOptions: Apollo.QueryHookOptions<ProductBySkuQuery, ProductBySkuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductBySkuQuery, ProductBySkuQueryVariables>(ProductBySkuDocument, options);
      }
export function useProductBySkuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductBySkuQuery, ProductBySkuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductBySkuQuery, ProductBySkuQueryVariables>(ProductBySkuDocument, options);
        }
export type ProductBySkuQueryHookResult = ReturnType<typeof useProductBySkuQuery>;
export type ProductBySkuLazyQueryHookResult = ReturnType<typeof useProductBySkuLazyQuery>;
export type ProductBySkuQueryResult = Apollo.QueryResult<ProductBySkuQuery, ProductBySkuQueryVariables>;
export function refetchProductBySkuQuery(variables?: ProductBySkuQueryVariables) {
      return { query: ProductBySkuDocument, variables: variables }
    }
export const GetCatalogsListDocument = gql`
    query GetCatalogsList {
  catalogs(
    condition: {isDigital: true, active: true}
    first: 100
    orderBy: TITLE_ASC
  ) {
    ...Catalog
  }
}
    ${CatalogFragmentDoc}`;

/**
 * __useGetCatalogsListQuery__
 *
 * To run a query within a React component, call `useGetCatalogsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCatalogsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCatalogsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCatalogsListQuery(baseOptions?: Apollo.QueryHookOptions<GetCatalogsListQuery, GetCatalogsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCatalogsListQuery, GetCatalogsListQueryVariables>(GetCatalogsListDocument, options);
      }
export function useGetCatalogsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCatalogsListQuery, GetCatalogsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCatalogsListQuery, GetCatalogsListQueryVariables>(GetCatalogsListDocument, options);
        }
export type GetCatalogsListQueryHookResult = ReturnType<typeof useGetCatalogsListQuery>;
export type GetCatalogsListLazyQueryHookResult = ReturnType<typeof useGetCatalogsListLazyQuery>;
export type GetCatalogsListQueryResult = Apollo.QueryResult<GetCatalogsListQuery, GetCatalogsListQueryVariables>;
export function refetchGetCatalogsListQuery(variables?: GetCatalogsListQueryVariables) {
      return { query: GetCatalogsListDocument, variables: variables }
    }
export const FullCatalogByIdDocument = gql`
    query FullCatalogById($id: Int!) {
  catalog: catalogById(id: $id) {
    ...Catalog
    products: productsByCatalog {
      ...Product
    }
    categories: categoriesByCatalog {
      ...Category
      products: subProductsByMainAndCatalog {
        sku
        sub
        main
      }
    }
    version: versionByActiveVersion {
      ...Version
      pages: pagesByVersion {
        ...Page
      }
    }
    account: accountByAccount {
      logo
      name
      catalogs: catalogsByAccount {
        id
        oid
        merchantId
        activeVersion: versionByActiveVersion {
          id
          oid
          name
          coverUrl
        }
      }
    }
  }
}
    ${CatalogFragmentDoc}
${ProductFragmentDoc}
${CategoryFragmentDoc}
${VersionFragmentDoc}
${PageFragmentDoc}`;

/**
 * __useFullCatalogByIdQuery__
 *
 * To run a query within a React component, call `useFullCatalogByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFullCatalogByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFullCatalogByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFullCatalogByIdQuery(baseOptions: Apollo.QueryHookOptions<FullCatalogByIdQuery, FullCatalogByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FullCatalogByIdQuery, FullCatalogByIdQueryVariables>(FullCatalogByIdDocument, options);
      }
export function useFullCatalogByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FullCatalogByIdQuery, FullCatalogByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FullCatalogByIdQuery, FullCatalogByIdQueryVariables>(FullCatalogByIdDocument, options);
        }
export type FullCatalogByIdQueryHookResult = ReturnType<typeof useFullCatalogByIdQuery>;
export type FullCatalogByIdLazyQueryHookResult = ReturnType<typeof useFullCatalogByIdLazyQuery>;
export type FullCatalogByIdQueryResult = Apollo.QueryResult<FullCatalogByIdQuery, FullCatalogByIdQueryVariables>;
export function refetchFullCatalogByIdQuery(variables?: FullCatalogByIdQueryVariables) {
      return { query: FullCatalogByIdDocument, variables: variables }
    }
export const FullVersionByIdDocument = gql`
    query FullVersionById($id: Int!) {
  version: versionById(id: $id) {
    ...Version
    pages: pagesByVersion {
      ...Page
    }
    catalog: catalogByCatalog {
      ...Catalog
      products: productsByCatalog {
        ...Product
      }
      categories: categoriesByCatalog {
        ...Category
        products: subProductsByMainAndCatalog {
          sku
          sub
          main
        }
      }
      account: accountByAccount {
        logo
        name
        catalogs: catalogsByAccount {
          id
          oid
          merchantId
          activeVersion: versionByActiveVersion {
            id
            oid
            name
            coverUrl
          }
        }
      }
    }
  }
}
    ${VersionFragmentDoc}
${PageFragmentDoc}
${CatalogFragmentDoc}
${ProductFragmentDoc}
${CategoryFragmentDoc}`;

/**
 * __useFullVersionByIdQuery__
 *
 * To run a query within a React component, call `useFullVersionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFullVersionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFullVersionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFullVersionByIdQuery(baseOptions: Apollo.QueryHookOptions<FullVersionByIdQuery, FullVersionByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FullVersionByIdQuery, FullVersionByIdQueryVariables>(FullVersionByIdDocument, options);
      }
export function useFullVersionByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FullVersionByIdQuery, FullVersionByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FullVersionByIdQuery, FullVersionByIdQueryVariables>(FullVersionByIdDocument, options);
        }
export type FullVersionByIdQueryHookResult = ReturnType<typeof useFullVersionByIdQuery>;
export type FullVersionByIdLazyQueryHookResult = ReturnType<typeof useFullVersionByIdLazyQuery>;
export type FullVersionByIdQueryResult = Apollo.QueryResult<FullVersionByIdQuery, FullVersionByIdQueryVariables>;
export function refetchFullVersionByIdQuery(variables?: FullVersionByIdQueryVariables) {
      return { query: FullVersionByIdDocument, variables: variables }
    }
export const ActiveAccountsDocument = gql`
    query ActiveAccounts {
  accounts(condition: {active: true}, orderBy: NAME_ASC) {
    oid
    name
    email
    legacyId
    active
    logo
    catalogs: catalogsByAccount {
      ...Catalog
      activeVersion: versionByActiveVersion {
        ...Version
      }
      versions: versionsByCatalog(orderBy: ID_DESC, offset: 0) {
        ...Version
      }
    }
  }
}
    ${CatalogFragmentDoc}
${VersionFragmentDoc}`;

/**
 * __useActiveAccountsQuery__
 *
 * To run a query within a React component, call `useActiveAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveAccountsQuery(baseOptions?: Apollo.QueryHookOptions<ActiveAccountsQuery, ActiveAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveAccountsQuery, ActiveAccountsQueryVariables>(ActiveAccountsDocument, options);
      }
export function useActiveAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveAccountsQuery, ActiveAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveAccountsQuery, ActiveAccountsQueryVariables>(ActiveAccountsDocument, options);
        }
export type ActiveAccountsQueryHookResult = ReturnType<typeof useActiveAccountsQuery>;
export type ActiveAccountsLazyQueryHookResult = ReturnType<typeof useActiveAccountsLazyQuery>;
export type ActiveAccountsQueryResult = Apollo.QueryResult<ActiveAccountsQuery, ActiveAccountsQueryVariables>;
export function refetchActiveAccountsQuery(variables?: ActiveAccountsQueryVariables) {
      return { query: ActiveAccountsDocument, variables: variables }
    }
export const CatalogByOidDocument = gql`
    query CatalogByOid($oid: UUID!) {
  catalog(oid: $oid) {
    ...Catalog
    account: accountByAccount {
      logo
      name
      active
    }
    activeVersion: versionByActiveVersion {
      ...Version
    }
    versions: versionsByCatalog(orderBy: ID_DESC, offset: 0) {
      ...Version
    }
  }
}
    ${CatalogFragmentDoc}
${VersionFragmentDoc}`;

/**
 * __useCatalogByOidQuery__
 *
 * To run a query within a React component, call `useCatalogByOidQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogByOidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogByOidQuery({
 *   variables: {
 *      oid: // value for 'oid'
 *   },
 * });
 */
export function useCatalogByOidQuery(baseOptions: Apollo.QueryHookOptions<CatalogByOidQuery, CatalogByOidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CatalogByOidQuery, CatalogByOidQueryVariables>(CatalogByOidDocument, options);
      }
export function useCatalogByOidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CatalogByOidQuery, CatalogByOidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CatalogByOidQuery, CatalogByOidQueryVariables>(CatalogByOidDocument, options);
        }
export type CatalogByOidQueryHookResult = ReturnType<typeof useCatalogByOidQuery>;
export type CatalogByOidLazyQueryHookResult = ReturnType<typeof useCatalogByOidLazyQuery>;
export type CatalogByOidQueryResult = Apollo.QueryResult<CatalogByOidQuery, CatalogByOidQueryVariables>;
export function refetchCatalogByOidQuery(variables?: CatalogByOidQueryVariables) {
      return { query: CatalogByOidDocument, variables: variables }
    }
export const CatalogByVersionIdDocument = gql`
    query CatalogByVersionId($id: Int!) {
  version: versionById(id: $id) {
    ...Version
    catalog: catalogByCatalog {
      ...Catalog
      account: accountByAccount {
        logo
        name
        active
      }
    }
  }
}
    ${VersionFragmentDoc}
${CatalogFragmentDoc}`;

/**
 * __useCatalogByVersionIdQuery__
 *
 * To run a query within a React component, call `useCatalogByVersionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogByVersionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogByVersionIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCatalogByVersionIdQuery(baseOptions: Apollo.QueryHookOptions<CatalogByVersionIdQuery, CatalogByVersionIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CatalogByVersionIdQuery, CatalogByVersionIdQueryVariables>(CatalogByVersionIdDocument, options);
      }
export function useCatalogByVersionIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CatalogByVersionIdQuery, CatalogByVersionIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CatalogByVersionIdQuery, CatalogByVersionIdQueryVariables>(CatalogByVersionIdDocument, options);
        }
export type CatalogByVersionIdQueryHookResult = ReturnType<typeof useCatalogByVersionIdQuery>;
export type CatalogByVersionIdLazyQueryHookResult = ReturnType<typeof useCatalogByVersionIdLazyQuery>;
export type CatalogByVersionIdQueryResult = Apollo.QueryResult<CatalogByVersionIdQuery, CatalogByVersionIdQueryVariables>;
export function refetchCatalogByVersionIdQuery(variables?: CatalogByVersionIdQueryVariables) {
      return { query: CatalogByVersionIdDocument, variables: variables }
    }
export const CatalogByIdDocument = gql`
    query CatalogById($id: Int!) {
  catalog: catalogById(id: $id) {
    ...Catalog
    account: accountByAccount {
      logo
      name
      active
    }
    activeVersion: versionByActiveVersion {
      ...Version
    }
    versions: versionsByCatalog(orderBy: ID_DESC, offset: 0) {
      ...Version
    }
  }
}
    ${CatalogFragmentDoc}
${VersionFragmentDoc}`;

/**
 * __useCatalogByIdQuery__
 *
 * To run a query within a React component, call `useCatalogByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCatalogByIdQuery(baseOptions: Apollo.QueryHookOptions<CatalogByIdQuery, CatalogByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CatalogByIdQuery, CatalogByIdQueryVariables>(CatalogByIdDocument, options);
      }
export function useCatalogByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CatalogByIdQuery, CatalogByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CatalogByIdQuery, CatalogByIdQueryVariables>(CatalogByIdDocument, options);
        }
export type CatalogByIdQueryHookResult = ReturnType<typeof useCatalogByIdQuery>;
export type CatalogByIdLazyQueryHookResult = ReturnType<typeof useCatalogByIdLazyQuery>;
export type CatalogByIdQueryResult = Apollo.QueryResult<CatalogByIdQuery, CatalogByIdQueryVariables>;
export function refetchCatalogByIdQuery(variables?: CatalogByIdQueryVariables) {
      return { query: CatalogByIdDocument, variables: variables }
    }
export const AllAuthorizedUsersDocument = gql`
    query AllAuthorizedUsers {
  authorizedUsers {
    oid
    email
    accounts: authorizedUserAuthorizedUsersAccountPartnerAccessesByAuthorizedUsersConnection {
      totalCount
    }
  }
}
    `;

/**
 * __useAllAuthorizedUsersQuery__
 *
 * To run a query within a React component, call `useAllAuthorizedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAuthorizedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAuthorizedUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllAuthorizedUsersQuery(baseOptions?: Apollo.QueryHookOptions<AllAuthorizedUsersQuery, AllAuthorizedUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllAuthorizedUsersQuery, AllAuthorizedUsersQueryVariables>(AllAuthorizedUsersDocument, options);
      }
export function useAllAuthorizedUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllAuthorizedUsersQuery, AllAuthorizedUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllAuthorizedUsersQuery, AllAuthorizedUsersQueryVariables>(AllAuthorizedUsersDocument, options);
        }
export type AllAuthorizedUsersQueryHookResult = ReturnType<typeof useAllAuthorizedUsersQuery>;
export type AllAuthorizedUsersLazyQueryHookResult = ReturnType<typeof useAllAuthorizedUsersLazyQuery>;
export type AllAuthorizedUsersQueryResult = Apollo.QueryResult<AllAuthorizedUsersQuery, AllAuthorizedUsersQueryVariables>;
export function refetchAllAuthorizedUsersQuery(variables?: AllAuthorizedUsersQueryVariables) {
      return { query: AllAuthorizedUsersDocument, variables: variables }
    }
export const UserByEmailDocument = gql`
    query UserByEmail($email: String!) {
  profile: profileByEmail(email: $email) {
    ...Profile
  }
  authorizedUserByEmail(email: $email) {
    accounts: accountsByAuthorizedUserAuthorizedUsersAccountPartnerAccessAuthorizedUsersAndPartnerAccessList {
      ...Account
    }
  }
}
    ${ProfileFragmentDoc}
${AccountFragmentDoc}`;

/**
 * __useUserByEmailQuery__
 *
 * To run a query within a React component, call `useUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUserByEmailQuery(baseOptions: Apollo.QueryHookOptions<UserByEmailQuery, UserByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserByEmailQuery, UserByEmailQueryVariables>(UserByEmailDocument, options);
      }
export function useUserByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserByEmailQuery, UserByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserByEmailQuery, UserByEmailQueryVariables>(UserByEmailDocument, options);
        }
export type UserByEmailQueryHookResult = ReturnType<typeof useUserByEmailQuery>;
export type UserByEmailLazyQueryHookResult = ReturnType<typeof useUserByEmailLazyQuery>;
export type UserByEmailQueryResult = Apollo.QueryResult<UserByEmailQuery, UserByEmailQueryVariables>;
export function refetchUserByEmailQuery(variables?: UserByEmailQueryVariables) {
      return { query: UserByEmailDocument, variables: variables }
    }
export const UpdateProfileDocument = gql`
    mutation updateProfile($oid: UUID!, $profile: ProfilePatch!) {
  updateProfile(input: {patch: $profile, oid: $oid}) {
    clientMutationId
    profile {
      ...Profile
    }
  }
}
    ${ProfileFragmentDoc}`;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      oid: // value for 'oid'
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const CreateProfileDocument = gql`
    mutation createProfile($profile: ProfileInput!) {
  createProfile(input: {profile: $profile}) {
    clientMutationId
    profile {
      ...Profile
    }
  }
}
    ${ProfileFragmentDoc}`;
export type CreateProfileMutationFn = Apollo.MutationFunction<CreateProfileMutation, CreateProfileMutationVariables>;

/**
 * __useCreateProfileMutation__
 *
 * To run a mutation, you first call `useCreateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProfileMutation, { data, loading, error }] = useCreateProfileMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useCreateProfileMutation(baseOptions?: Apollo.MutationHookOptions<CreateProfileMutation, CreateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProfileMutation, CreateProfileMutationVariables>(CreateProfileDocument, options);
      }
export type CreateProfileMutationHookResult = ReturnType<typeof useCreateProfileMutation>;
export type CreateProfileMutationResult = Apollo.MutationResult<CreateProfileMutation>;
export type CreateProfileMutationOptions = Apollo.BaseMutationOptions<CreateProfileMutation, CreateProfileMutationVariables>;
export const VersionFullByIdDocument = gql`
    query VersionFullById($id: Int!) {
  version: versionById(id: $id) {
    ...Version
    products: productsByVersionsByVersionOid {
      sku
    }
    pages: pagesByVersion {
      ...Page
    }
    catalog: catalogByCatalog {
      ...Catalog
      products: productsByCatalog {
        ...Product
      }
    }
  }
}
    ${VersionFragmentDoc}
${PageFragmentDoc}
${CatalogFragmentDoc}
${ProductFragmentDoc}`;

/**
 * __useVersionFullByIdQuery__
 *
 * To run a query within a React component, call `useVersionFullByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useVersionFullByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVersionFullByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVersionFullByIdQuery(baseOptions: Apollo.QueryHookOptions<VersionFullByIdQuery, VersionFullByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VersionFullByIdQuery, VersionFullByIdQueryVariables>(VersionFullByIdDocument, options);
      }
export function useVersionFullByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VersionFullByIdQuery, VersionFullByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VersionFullByIdQuery, VersionFullByIdQueryVariables>(VersionFullByIdDocument, options);
        }
export type VersionFullByIdQueryHookResult = ReturnType<typeof useVersionFullByIdQuery>;
export type VersionFullByIdLazyQueryHookResult = ReturnType<typeof useVersionFullByIdLazyQuery>;
export type VersionFullByIdQueryResult = Apollo.QueryResult<VersionFullByIdQuery, VersionFullByIdQueryVariables>;
export function refetchVersionFullByIdQuery(variables?: VersionFullByIdQueryVariables) {
      return { query: VersionFullByIdDocument, variables: variables }
    }
export const ProductsByVersionIdDocument = gql`
    query ProductsByVersionId($id: Int!) {
  version: versionById(id: $id) {
    id
    oid
    name
    catalog: catalogByCatalog {
      id
      oid
      products: productsByCatalog {
        ...Product
      }
    }
  }
}
    ${ProductFragmentDoc}`;

/**
 * __useProductsByVersionIdQuery__
 *
 * To run a query within a React component, call `useProductsByVersionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsByVersionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsByVersionIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductsByVersionIdQuery(baseOptions: Apollo.QueryHookOptions<ProductsByVersionIdQuery, ProductsByVersionIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsByVersionIdQuery, ProductsByVersionIdQueryVariables>(ProductsByVersionIdDocument, options);
      }
export function useProductsByVersionIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsByVersionIdQuery, ProductsByVersionIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsByVersionIdQuery, ProductsByVersionIdQueryVariables>(ProductsByVersionIdDocument, options);
        }
export type ProductsByVersionIdQueryHookResult = ReturnType<typeof useProductsByVersionIdQuery>;
export type ProductsByVersionIdLazyQueryHookResult = ReturnType<typeof useProductsByVersionIdLazyQuery>;
export type ProductsByVersionIdQueryResult = Apollo.QueryResult<ProductsByVersionIdQuery, ProductsByVersionIdQueryVariables>;
export function refetchProductsByVersionIdQuery(variables?: ProductsByVersionIdQueryVariables) {
      return { query: ProductsByVersionIdDocument, variables: variables }
    }
export const ActiveVersionByCatalogIdDocument = gql`
    query ActiveVersionByCatalogID($id: Int!) {
  catalog: catalogById(id: $id) {
    ...Catalog
    account: accountByAccount {
      logo
      name
      active
    }
    activeVersion: versionByActiveVersion {
      ...Version
    }
  }
}
    ${CatalogFragmentDoc}
${VersionFragmentDoc}`;

/**
 * __useActiveVersionByCatalogIdQuery__
 *
 * To run a query within a React component, call `useActiveVersionByCatalogIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveVersionByCatalogIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveVersionByCatalogIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActiveVersionByCatalogIdQuery(baseOptions: Apollo.QueryHookOptions<ActiveVersionByCatalogIdQuery, ActiveVersionByCatalogIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveVersionByCatalogIdQuery, ActiveVersionByCatalogIdQueryVariables>(ActiveVersionByCatalogIdDocument, options);
      }
export function useActiveVersionByCatalogIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveVersionByCatalogIdQuery, ActiveVersionByCatalogIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveVersionByCatalogIdQuery, ActiveVersionByCatalogIdQueryVariables>(ActiveVersionByCatalogIdDocument, options);
        }
export type ActiveVersionByCatalogIdQueryHookResult = ReturnType<typeof useActiveVersionByCatalogIdQuery>;
export type ActiveVersionByCatalogIdLazyQueryHookResult = ReturnType<typeof useActiveVersionByCatalogIdLazyQuery>;
export type ActiveVersionByCatalogIdQueryResult = Apollo.QueryResult<ActiveVersionByCatalogIdQuery, ActiveVersionByCatalogIdQueryVariables>;
export function refetchActiveVersionByCatalogIdQuery(variables?: ActiveVersionByCatalogIdQueryVariables) {
      return { query: ActiveVersionByCatalogIdDocument, variables: variables }
    }
export const ProductsCatalogDocument = gql`
    query ProductsCatalog($oid: UUID!) {
  catalog(oid: $oid) {
    id
    oid
    products: productsByCatalog {
      ...Product
    }
  }
}
    ${ProductFragmentDoc}`;

/**
 * __useProductsCatalogQuery__
 *
 * To run a query within a React component, call `useProductsCatalogQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsCatalogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsCatalogQuery({
 *   variables: {
 *      oid: // value for 'oid'
 *   },
 * });
 */
export function useProductsCatalogQuery(baseOptions: Apollo.QueryHookOptions<ProductsCatalogQuery, ProductsCatalogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsCatalogQuery, ProductsCatalogQueryVariables>(ProductsCatalogDocument, options);
      }
export function useProductsCatalogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsCatalogQuery, ProductsCatalogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsCatalogQuery, ProductsCatalogQueryVariables>(ProductsCatalogDocument, options);
        }
export type ProductsCatalogQueryHookResult = ReturnType<typeof useProductsCatalogQuery>;
export type ProductsCatalogLazyQueryHookResult = ReturnType<typeof useProductsCatalogLazyQuery>;
export type ProductsCatalogQueryResult = Apollo.QueryResult<ProductsCatalogQuery, ProductsCatalogQueryVariables>;
export function refetchProductsCatalogQuery(variables?: ProductsCatalogQueryVariables) {
      return { query: ProductsCatalogDocument, variables: variables }
    }
export const ProductsByCatalogIdDocument = gql`
    query ProductsByCatalogId($id: Int!) {
  catalog: catalogById(id: $id) {
    id
    oid
    products: productsByCatalog {
      ...Product
    }
  }
}
    ${ProductFragmentDoc}`;

/**
 * __useProductsByCatalogIdQuery__
 *
 * To run a query within a React component, call `useProductsByCatalogIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsByCatalogIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsByCatalogIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductsByCatalogIdQuery(baseOptions: Apollo.QueryHookOptions<ProductsByCatalogIdQuery, ProductsByCatalogIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsByCatalogIdQuery, ProductsByCatalogIdQueryVariables>(ProductsByCatalogIdDocument, options);
      }
export function useProductsByCatalogIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsByCatalogIdQuery, ProductsByCatalogIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsByCatalogIdQuery, ProductsByCatalogIdQueryVariables>(ProductsByCatalogIdDocument, options);
        }
export type ProductsByCatalogIdQueryHookResult = ReturnType<typeof useProductsByCatalogIdQuery>;
export type ProductsByCatalogIdLazyQueryHookResult = ReturnType<typeof useProductsByCatalogIdLazyQuery>;
export type ProductsByCatalogIdQueryResult = Apollo.QueryResult<ProductsByCatalogIdQuery, ProductsByCatalogIdQueryVariables>;
export function refetchProductsByCatalogIdQuery(variables?: ProductsByCatalogIdQueryVariables) {
      return { query: ProductsByCatalogIdDocument, variables: variables }
    }
export const CategoriesByCatalogIdDocument = gql`
    query CategoriesByCatalogId($oid: UUID!) {
  catalog(oid: $oid) {
    id
    oid
    categories: categoriesByCatalog {
      ...Category
    }
  }
}
    ${CategoryFragmentDoc}`;

/**
 * __useCategoriesByCatalogIdQuery__
 *
 * To run a query within a React component, call `useCategoriesByCatalogIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesByCatalogIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesByCatalogIdQuery({
 *   variables: {
 *      oid: // value for 'oid'
 *   },
 * });
 */
export function useCategoriesByCatalogIdQuery(baseOptions: Apollo.QueryHookOptions<CategoriesByCatalogIdQuery, CategoriesByCatalogIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesByCatalogIdQuery, CategoriesByCatalogIdQueryVariables>(CategoriesByCatalogIdDocument, options);
      }
export function useCategoriesByCatalogIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesByCatalogIdQuery, CategoriesByCatalogIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesByCatalogIdQuery, CategoriesByCatalogIdQueryVariables>(CategoriesByCatalogIdDocument, options);
        }
export type CategoriesByCatalogIdQueryHookResult = ReturnType<typeof useCategoriesByCatalogIdQuery>;
export type CategoriesByCatalogIdLazyQueryHookResult = ReturnType<typeof useCategoriesByCatalogIdLazyQuery>;
export type CategoriesByCatalogIdQueryResult = Apollo.QueryResult<CategoriesByCatalogIdQuery, CategoriesByCatalogIdQueryVariables>;
export function refetchCategoriesByCatalogIdQuery(variables?: CategoriesByCatalogIdQueryVariables) {
      return { query: CategoriesByCatalogIdDocument, variables: variables }
    }
export const CatalogCategoriesDocument = gql`
    query CatalogCategories($id: Int!) {
  catalog: catalogById(id: $id) {
    id
    oid
    categories: categoriesByCatalog {
      ...Category
    }
  }
}
    ${CategoryFragmentDoc}`;

/**
 * __useCatalogCategoriesQuery__
 *
 * To run a query within a React component, call `useCatalogCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogCategoriesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCatalogCategoriesQuery(baseOptions: Apollo.QueryHookOptions<CatalogCategoriesQuery, CatalogCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CatalogCategoriesQuery, CatalogCategoriesQueryVariables>(CatalogCategoriesDocument, options);
      }
export function useCatalogCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CatalogCategoriesQuery, CatalogCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CatalogCategoriesQuery, CatalogCategoriesQueryVariables>(CatalogCategoriesDocument, options);
        }
export type CatalogCategoriesQueryHookResult = ReturnType<typeof useCatalogCategoriesQuery>;
export type CatalogCategoriesLazyQueryHookResult = ReturnType<typeof useCatalogCategoriesLazyQuery>;
export type CatalogCategoriesQueryResult = Apollo.QueryResult<CatalogCategoriesQuery, CatalogCategoriesQueryVariables>;
export function refetchCatalogCategoriesQuery(variables?: CatalogCategoriesQueryVariables) {
      return { query: CatalogCategoriesDocument, variables: variables }
    }
export const CategoriesByVersionIdDocument = gql`
    query CategoriesByVersionId($id: Int!) {
  version: versionById(id: $id) {
    id
    oid
    name
    catalog: catalogByCatalog {
      id
      oid
      categories: categoriesByCatalog {
        ...Category
      }
    }
  }
}
    ${CategoryFragmentDoc}`;

/**
 * __useCategoriesByVersionIdQuery__
 *
 * To run a query within a React component, call `useCategoriesByVersionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesByVersionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesByVersionIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCategoriesByVersionIdQuery(baseOptions: Apollo.QueryHookOptions<CategoriesByVersionIdQuery, CategoriesByVersionIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesByVersionIdQuery, CategoriesByVersionIdQueryVariables>(CategoriesByVersionIdDocument, options);
      }
export function useCategoriesByVersionIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesByVersionIdQuery, CategoriesByVersionIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesByVersionIdQuery, CategoriesByVersionIdQueryVariables>(CategoriesByVersionIdDocument, options);
        }
export type CategoriesByVersionIdQueryHookResult = ReturnType<typeof useCategoriesByVersionIdQuery>;
export type CategoriesByVersionIdLazyQueryHookResult = ReturnType<typeof useCategoriesByVersionIdLazyQuery>;
export type CategoriesByVersionIdQueryResult = Apollo.QueryResult<CategoriesByVersionIdQuery, CategoriesByVersionIdQueryVariables>;
export function refetchCategoriesByVersionIdQuery(variables?: CategoriesByVersionIdQueryVariables) {
      return { query: CategoriesByVersionIdDocument, variables: variables }
    }
export const LeadsAccountDocument = gql`
    query LeadsAccount($account: UUID!) {
  leads(condition: {account: $account}) {
    ...Lead
  }
}
    ${LeadFragmentDoc}`;

/**
 * __useLeadsAccountQuery__
 *
 * To run a query within a React component, call `useLeadsAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeadsAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeadsAccountQuery({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useLeadsAccountQuery(baseOptions: Apollo.QueryHookOptions<LeadsAccountQuery, LeadsAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LeadsAccountQuery, LeadsAccountQueryVariables>(LeadsAccountDocument, options);
      }
export function useLeadsAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LeadsAccountQuery, LeadsAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LeadsAccountQuery, LeadsAccountQueryVariables>(LeadsAccountDocument, options);
        }
export type LeadsAccountQueryHookResult = ReturnType<typeof useLeadsAccountQuery>;
export type LeadsAccountLazyQueryHookResult = ReturnType<typeof useLeadsAccountLazyQuery>;
export type LeadsAccountQueryResult = Apollo.QueryResult<LeadsAccountQuery, LeadsAccountQueryVariables>;
export function refetchLeadsAccountQuery(variables?: LeadsAccountQueryVariables) {
      return { query: LeadsAccountDocument, variables: variables }
    }
export const UpdatePageDocument = gql`
    mutation UpdatePage($id: UUID!, $payload: PagePatch!) {
  updatePage(input: {patch: $payload, oid: $id}) {
    page {
      ...Page
    }
  }
}
    ${PageFragmentDoc}`;
export type UpdatePageMutationFn = Apollo.MutationFunction<UpdatePageMutation, UpdatePageMutationVariables>;

/**
 * __useUpdatePageMutation__
 *
 * To run a mutation, you first call `useUpdatePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePageMutation, { data, loading, error }] = useUpdatePageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdatePageMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePageMutation, UpdatePageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePageMutation, UpdatePageMutationVariables>(UpdatePageDocument, options);
      }
export type UpdatePageMutationHookResult = ReturnType<typeof useUpdatePageMutation>;
export type UpdatePageMutationResult = Apollo.MutationResult<UpdatePageMutation>;
export type UpdatePageMutationOptions = Apollo.BaseMutationOptions<UpdatePageMutation, UpdatePageMutationVariables>;
export const CreateCatalogDocument = gql`
    mutation CreateCatalog($catalog: CatalogInput!) {
  createCatalog(input: {catalog: $catalog}) {
    catalog {
      ...Catalog
      account: accountByAccount {
        logo
        name
        active
      }
      activeVersion: versionByActiveVersion {
        ...Version
      }
      versions: versionsByCatalog(orderBy: ID_DESC, offset: 0) {
        ...Version
      }
    }
  }
}
    ${CatalogFragmentDoc}
${VersionFragmentDoc}`;
export type CreateCatalogMutationFn = Apollo.MutationFunction<CreateCatalogMutation, CreateCatalogMutationVariables>;

/**
 * __useCreateCatalogMutation__
 *
 * To run a mutation, you first call `useCreateCatalogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCatalogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCatalogMutation, { data, loading, error }] = useCreateCatalogMutation({
 *   variables: {
 *      catalog: // value for 'catalog'
 *   },
 * });
 */
export function useCreateCatalogMutation(baseOptions?: Apollo.MutationHookOptions<CreateCatalogMutation, CreateCatalogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCatalogMutation, CreateCatalogMutationVariables>(CreateCatalogDocument, options);
      }
export type CreateCatalogMutationHookResult = ReturnType<typeof useCreateCatalogMutation>;
export type CreateCatalogMutationResult = Apollo.MutationResult<CreateCatalogMutation>;
export type CreateCatalogMutationOptions = Apollo.BaseMutationOptions<CreateCatalogMutation, CreateCatalogMutationVariables>;
export const UpdateCatalogDocument = gql`
    mutation UpdateCatalog($catalog: CatalogPatch!, $oid: UUID!) {
  updateCatalog(input: {patch: $catalog, oid: $oid}) {
    catalog {
      ...Catalog
      activeVersion: versionByActiveVersion {
        ...Version
      }
      versions: versionsByCatalog(orderBy: ID_DESC, offset: 0) {
        ...Version
      }
    }
  }
}
    ${CatalogFragmentDoc}
${VersionFragmentDoc}`;
export type UpdateCatalogMutationFn = Apollo.MutationFunction<UpdateCatalogMutation, UpdateCatalogMutationVariables>;

/**
 * __useUpdateCatalogMutation__
 *
 * To run a mutation, you first call `useUpdateCatalogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCatalogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCatalogMutation, { data, loading, error }] = useUpdateCatalogMutation({
 *   variables: {
 *      catalog: // value for 'catalog'
 *      oid: // value for 'oid'
 *   },
 * });
 */
export function useUpdateCatalogMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCatalogMutation, UpdateCatalogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCatalogMutation, UpdateCatalogMutationVariables>(UpdateCatalogDocument, options);
      }
export type UpdateCatalogMutationHookResult = ReturnType<typeof useUpdateCatalogMutation>;
export type UpdateCatalogMutationResult = Apollo.MutationResult<UpdateCatalogMutation>;
export type UpdateCatalogMutationOptions = Apollo.BaseMutationOptions<UpdateCatalogMutation, UpdateCatalogMutationVariables>;
export const DeleteCatalogDocument = gql`
    mutation DeleteCatalog($oid: UUID!) {
  deleteCatalog(input: {oid: $oid}) {
    catalog {
      oid
      id
    }
  }
}
    `;
export type DeleteCatalogMutationFn = Apollo.MutationFunction<DeleteCatalogMutation, DeleteCatalogMutationVariables>;

/**
 * __useDeleteCatalogMutation__
 *
 * To run a mutation, you first call `useDeleteCatalogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCatalogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCatalogMutation, { data, loading, error }] = useDeleteCatalogMutation({
 *   variables: {
 *      oid: // value for 'oid'
 *   },
 * });
 */
export function useDeleteCatalogMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCatalogMutation, DeleteCatalogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCatalogMutation, DeleteCatalogMutationVariables>(DeleteCatalogDocument, options);
      }
export type DeleteCatalogMutationHookResult = ReturnType<typeof useDeleteCatalogMutation>;
export type DeleteCatalogMutationResult = Apollo.MutationResult<DeleteCatalogMutation>;
export type DeleteCatalogMutationOptions = Apollo.BaseMutationOptions<DeleteCatalogMutation, DeleteCatalogMutationVariables>;
export const CreateVersionDocument = gql`
    mutation CreateVersion($version: VersionInput!) {
  createVersion(input: {version: $version}) {
    version {
      ...Version
      catalog: catalogByCatalog {
        ...Catalog
      }
    }
  }
}
    ${VersionFragmentDoc}
${CatalogFragmentDoc}`;
export type CreateVersionMutationFn = Apollo.MutationFunction<CreateVersionMutation, CreateVersionMutationVariables>;

/**
 * __useCreateVersionMutation__
 *
 * To run a mutation, you first call `useCreateVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVersionMutation, { data, loading, error }] = useCreateVersionMutation({
 *   variables: {
 *      version: // value for 'version'
 *   },
 * });
 */
export function useCreateVersionMutation(baseOptions?: Apollo.MutationHookOptions<CreateVersionMutation, CreateVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVersionMutation, CreateVersionMutationVariables>(CreateVersionDocument, options);
      }
export type CreateVersionMutationHookResult = ReturnType<typeof useCreateVersionMutation>;
export type CreateVersionMutationResult = Apollo.MutationResult<CreateVersionMutation>;
export type CreateVersionMutationOptions = Apollo.BaseMutationOptions<CreateVersionMutation, CreateVersionMutationVariables>;
export const UpdateVersionDocument = gql`
    mutation UpdateVersion($version: VersionPatch!, $oid: UUID!) {
  updateVersion(input: {patch: $version, oid: $oid}) {
    version {
      ...Version
      catalog: catalogByCatalog {
        ...Catalog
      }
    }
  }
}
    ${VersionFragmentDoc}
${CatalogFragmentDoc}`;
export type UpdateVersionMutationFn = Apollo.MutationFunction<UpdateVersionMutation, UpdateVersionMutationVariables>;

/**
 * __useUpdateVersionMutation__
 *
 * To run a mutation, you first call `useUpdateVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVersionMutation, { data, loading, error }] = useUpdateVersionMutation({
 *   variables: {
 *      version: // value for 'version'
 *      oid: // value for 'oid'
 *   },
 * });
 */
export function useUpdateVersionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVersionMutation, UpdateVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVersionMutation, UpdateVersionMutationVariables>(UpdateVersionDocument, options);
      }
export type UpdateVersionMutationHookResult = ReturnType<typeof useUpdateVersionMutation>;
export type UpdateVersionMutationResult = Apollo.MutationResult<UpdateVersionMutation>;
export type UpdateVersionMutationOptions = Apollo.BaseMutationOptions<UpdateVersionMutation, UpdateVersionMutationVariables>;
export const DeleteVersionDocument = gql`
    mutation DeleteVersion($oid: UUID!) {
  deleteVersion(input: {oid: $oid}) {
    clientMutationId
    catalog: catalogByCatalog {
      oid
      id
    }
  }
}
    `;
export type DeleteVersionMutationFn = Apollo.MutationFunction<DeleteVersionMutation, DeleteVersionMutationVariables>;

/**
 * __useDeleteVersionMutation__
 *
 * To run a mutation, you first call `useDeleteVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVersionMutation, { data, loading, error }] = useDeleteVersionMutation({
 *   variables: {
 *      oid: // value for 'oid'
 *   },
 * });
 */
export function useDeleteVersionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVersionMutation, DeleteVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVersionMutation, DeleteVersionMutationVariables>(DeleteVersionDocument, options);
      }
export type DeleteVersionMutationHookResult = ReturnType<typeof useDeleteVersionMutation>;
export type DeleteVersionMutationResult = Apollo.MutationResult<DeleteVersionMutation>;
export type DeleteVersionMutationOptions = Apollo.BaseMutationOptions<DeleteVersionMutation, DeleteVersionMutationVariables>;
export const CreatePageDocument = gql`
    mutation CreatePage($page: PageInput!) {
  createPage(input: {page: $page}) {
    page {
      ...Page
    }
  }
}
    ${PageFragmentDoc}`;
export type CreatePageMutationFn = Apollo.MutationFunction<CreatePageMutation, CreatePageMutationVariables>;

/**
 * __useCreatePageMutation__
 *
 * To run a mutation, you first call `useCreatePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPageMutation, { data, loading, error }] = useCreatePageMutation({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCreatePageMutation(baseOptions?: Apollo.MutationHookOptions<CreatePageMutation, CreatePageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePageMutation, CreatePageMutationVariables>(CreatePageDocument, options);
      }
export type CreatePageMutationHookResult = ReturnType<typeof useCreatePageMutation>;
export type CreatePageMutationResult = Apollo.MutationResult<CreatePageMutation>;
export type CreatePageMutationOptions = Apollo.BaseMutationOptions<CreatePageMutation, CreatePageMutationVariables>;
export const DeletePageDocument = gql`
    mutation DeletePage($oid: UUID!) {
  deletePage(input: {oid: $oid}) {
    page {
      oid
    }
  }
}
    `;
export type DeletePageMutationFn = Apollo.MutationFunction<DeletePageMutation, DeletePageMutationVariables>;

/**
 * __useDeletePageMutation__
 *
 * To run a mutation, you first call `useDeletePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePageMutation, { data, loading, error }] = useDeletePageMutation({
 *   variables: {
 *      oid: // value for 'oid'
 *   },
 * });
 */
export function useDeletePageMutation(baseOptions?: Apollo.MutationHookOptions<DeletePageMutation, DeletePageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePageMutation, DeletePageMutationVariables>(DeletePageDocument, options);
      }
export type DeletePageMutationHookResult = ReturnType<typeof useDeletePageMutation>;
export type DeletePageMutationResult = Apollo.MutationResult<DeletePageMutation>;
export type DeletePageMutationOptions = Apollo.BaseMutationOptions<DeletePageMutation, DeletePageMutationVariables>;
export const CatalogFeedsDocument = gql`
    query CatalogFeeds {
  feeds {
    ...Feed
  }
}
    ${FeedFragmentDoc}`;

/**
 * __useCatalogFeedsQuery__
 *
 * To run a query within a React component, call `useCatalogFeedsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogFeedsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogFeedsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCatalogFeedsQuery(baseOptions?: Apollo.QueryHookOptions<CatalogFeedsQuery, CatalogFeedsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CatalogFeedsQuery, CatalogFeedsQueryVariables>(CatalogFeedsDocument, options);
      }
export function useCatalogFeedsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CatalogFeedsQuery, CatalogFeedsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CatalogFeedsQuery, CatalogFeedsQueryVariables>(CatalogFeedsDocument, options);
        }
export type CatalogFeedsQueryHookResult = ReturnType<typeof useCatalogFeedsQuery>;
export type CatalogFeedsLazyQueryHookResult = ReturnType<typeof useCatalogFeedsLazyQuery>;
export type CatalogFeedsQueryResult = Apollo.QueryResult<CatalogFeedsQuery, CatalogFeedsQueryVariables>;
export function refetchCatalogFeedsQuery(variables?: CatalogFeedsQueryVariables) {
      return { query: CatalogFeedsDocument, variables: variables }
    }
export const CreateFeedDocument = gql`
    mutation CreateFeed($feed: FeedInput!) {
  createFeed(input: {feed: $feed}) {
    feed {
      ...Feed
    }
  }
}
    ${FeedFragmentDoc}`;
export type CreateFeedMutationFn = Apollo.MutationFunction<CreateFeedMutation, CreateFeedMutationVariables>;

/**
 * __useCreateFeedMutation__
 *
 * To run a mutation, you first call `useCreateFeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedMutation, { data, loading, error }] = useCreateFeedMutation({
 *   variables: {
 *      feed: // value for 'feed'
 *   },
 * });
 */
export function useCreateFeedMutation(baseOptions?: Apollo.MutationHookOptions<CreateFeedMutation, CreateFeedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFeedMutation, CreateFeedMutationVariables>(CreateFeedDocument, options);
      }
export type CreateFeedMutationHookResult = ReturnType<typeof useCreateFeedMutation>;
export type CreateFeedMutationResult = Apollo.MutationResult<CreateFeedMutation>;
export type CreateFeedMutationOptions = Apollo.BaseMutationOptions<CreateFeedMutation, CreateFeedMutationVariables>;
export const UpdateFeedDocument = gql`
    mutation UpdateFeed($feed: FeedPatch!, $oid: UUID!) {
  updateFeed(input: {patch: $feed, oid: $oid}) {
    feed {
      ...Feed
    }
  }
}
    ${FeedFragmentDoc}`;
export type UpdateFeedMutationFn = Apollo.MutationFunction<UpdateFeedMutation, UpdateFeedMutationVariables>;

/**
 * __useUpdateFeedMutation__
 *
 * To run a mutation, you first call `useUpdateFeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeedMutation, { data, loading, error }] = useUpdateFeedMutation({
 *   variables: {
 *      feed: // value for 'feed'
 *      oid: // value for 'oid'
 *   },
 * });
 */
export function useUpdateFeedMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFeedMutation, UpdateFeedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFeedMutation, UpdateFeedMutationVariables>(UpdateFeedDocument, options);
      }
export type UpdateFeedMutationHookResult = ReturnType<typeof useUpdateFeedMutation>;
export type UpdateFeedMutationResult = Apollo.MutationResult<UpdateFeedMutation>;
export type UpdateFeedMutationOptions = Apollo.BaseMutationOptions<UpdateFeedMutation, UpdateFeedMutationVariables>;
export const DeleteFeedDocument = gql`
    mutation DeleteFeed($oid: UUID!) {
  deleteFeed(input: {oid: $oid}) {
    feed {
      oid
    }
  }
}
    `;
export type DeleteFeedMutationFn = Apollo.MutationFunction<DeleteFeedMutation, DeleteFeedMutationVariables>;

/**
 * __useDeleteFeedMutation__
 *
 * To run a mutation, you first call `useDeleteFeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFeedMutation, { data, loading, error }] = useDeleteFeedMutation({
 *   variables: {
 *      oid: // value for 'oid'
 *   },
 * });
 */
export function useDeleteFeedMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFeedMutation, DeleteFeedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFeedMutation, DeleteFeedMutationVariables>(DeleteFeedDocument, options);
      }
export type DeleteFeedMutationHookResult = ReturnType<typeof useDeleteFeedMutation>;
export type DeleteFeedMutationResult = Apollo.MutationResult<DeleteFeedMutation>;
export type DeleteFeedMutationOptions = Apollo.BaseMutationOptions<DeleteFeedMutation, DeleteFeedMutationVariables>;
export const CreateCategoryDocument = gql`
    mutation CreateCategory($category: CategoryInput!) {
  createCategory(input: {category: $category}) {
    category {
      ...Category
    }
  }
}
    ${CategoryFragmentDoc}`;
export type CreateCategoryMutationFn = Apollo.MutationFunction<CreateCategoryMutation, CreateCategoryMutationVariables>;

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function useCreateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCategoryMutation, CreateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument, options);
      }
export type CreateCategoryMutationHookResult = ReturnType<typeof useCreateCategoryMutation>;
export type CreateCategoryMutationResult = Apollo.MutationResult<CreateCategoryMutation>;
export type CreateCategoryMutationOptions = Apollo.BaseMutationOptions<CreateCategoryMutation, CreateCategoryMutationVariables>;
export const UpdateCategoryDocument = gql`
    mutation UpdateCategory($category: CategoryPatch!, $oid: UUID!) {
  updateCategory(input: {patch: $category, oid: $oid}) {
    category {
      ...Category
    }
  }
}
    ${CategoryFragmentDoc}`;
export type UpdateCategoryMutationFn = Apollo.MutationFunction<UpdateCategoryMutation, UpdateCategoryMutationVariables>;

/**
 * __useUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryMutation, { data, loading, error }] = useUpdateCategoryMutation({
 *   variables: {
 *      category: // value for 'category'
 *      oid: // value for 'oid'
 *   },
 * });
 */
export function useUpdateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>(UpdateCategoryDocument, options);
      }
export type UpdateCategoryMutationHookResult = ReturnType<typeof useUpdateCategoryMutation>;
export type UpdateCategoryMutationResult = Apollo.MutationResult<UpdateCategoryMutation>;
export type UpdateCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>;
export const DeleteCategoryDocument = gql`
    mutation DeleteCategory($oid: UUID!) {
  deleteCategory(input: {oid: $oid}) {
    category {
      oid
      id
    }
  }
}
    `;
export type DeleteCategoryMutationFn = Apollo.MutationFunction<DeleteCategoryMutation, DeleteCategoryMutationVariables>;

/**
 * __useDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryMutation, { data, loading, error }] = useDeleteCategoryMutation({
 *   variables: {
 *      oid: // value for 'oid'
 *   },
 * });
 */
export function useDeleteCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCategoryMutation, DeleteCategoryMutationVariables>(DeleteCategoryDocument, options);
      }
export type DeleteCategoryMutationHookResult = ReturnType<typeof useDeleteCategoryMutation>;
export type DeleteCategoryMutationResult = Apollo.MutationResult<DeleteCategoryMutation>;
export type DeleteCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>;
export const CreateProductDocument = gql`
    mutation CreateProduct($product: ProductInput!) {
  createProduct(input: {product: $product}) {
    product {
      ...Product
    }
  }
}
    ${ProductFragmentDoc}`;
export type CreateProductMutationFn = Apollo.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      product: // value for 'product'
 *   },
 * });
 */
export function useCreateProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, options);
      }
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = Apollo.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<CreateProductMutation, CreateProductMutationVariables>;
export const UpdateProductDocument = gql`
    mutation UpdateProduct($product: ProductPatch!, $oid: UUID!) {
  updateProduct(input: {patch: $product, oid: $oid}) {
    product {
      ...Product
    }
  }
}
    ${ProductFragmentDoc}`;
export type UpdateProductMutationFn = Apollo.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      product: // value for 'product'
 *      oid: // value for 'oid'
 *   },
 * });
 */
export function useUpdateProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, options);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<UpdateProductMutation, UpdateProductMutationVariables>;
export const DeleteProductDocument = gql`
    mutation DeleteProduct($oid: UUID!) {
  deleteProduct(input: {oid: $oid}) {
    product {
      oid
    }
  }
}
    `;
export type DeleteProductMutationFn = Apollo.MutationFunction<DeleteProductMutation, DeleteProductMutationVariables>;

/**
 * __useDeleteProductMutation__
 *
 * To run a mutation, you first call `useDeleteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductMutation, { data, loading, error }] = useDeleteProductMutation({
 *   variables: {
 *      oid: // value for 'oid'
 *   },
 * });
 */
export function useDeleteProductMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductMutation, DeleteProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductMutation, DeleteProductMutationVariables>(DeleteProductDocument, options);
      }
export type DeleteProductMutationHookResult = ReturnType<typeof useDeleteProductMutation>;
export type DeleteProductMutationResult = Apollo.MutationResult<DeleteProductMutation>;
export type DeleteProductMutationOptions = Apollo.BaseMutationOptions<DeleteProductMutation, DeleteProductMutationVariables>;