import React, { useEffect, useState, useContext } from "react";
import { AuthContext, INITIAL_STATE } from "./auth.context";
import { ProfileContext } from "../profile/profile.context";

import { Auth } from "@aws-amplify/auth";
import { CognitoUserInterface } from "@aws-amplify/ui-components";
import { Hub } from "aws-amplify";
import { IoMdReturnRight } from "react-icons/io";
import { BsReverseBackspaceReverse } from "react-icons/bs";

export type ActionType =
  | { type: "SIGNIN"; payload?: AuthData }
  | { type: "LOGIN"; payload?: AuthData }
  | { type: "ORDER"; payload?: AuthData }
  | { type: "SIGNIN_SUCCESS" }
  | { type: "SIGN_OUT"; payload?: AuthData }
  | { type: "SIGNUP"; payload?: AuthData }
  | { type: "SIGNUP_VERIFY"; payload: AuthData }
  | { type: "FORGOTPASS"; payload: AuthData };

export type AuthData = {
  currentForm: string;
  isAuthenticated: boolean;
  useAuthentication: boolean;
  cognitoUser?: CognitoUserInterface;
};

export const AuthProvider: React.FunctionComponent = ({ children }) => {
  const [state, setState] = useState<AuthData>(INITIAL_STATE);
  const { dispatchProfile } = useContext(ProfileContext);
  const dispatch = (action: ActionType) => {
    setState(reduce(state, action));
  };
  function reduce(state: AuthData, action: ActionType): AuthData {
    // const history = useHistory();
    switch (action.type) {
      case "SIGNIN":
        return {
          ...state,
          currentForm: "signIn",
        };
      case "LOGIN":
        return {
          ...state,
          isAuthenticated: true,
          currentForm: "login",
        };
      case "ORDER":
        return {
          ...state,
          currentForm: "order",
        };
      case "SIGNIN_SUCCESS":
        //getUser();
        return {
          ...state,
          currentForm: "",
          isAuthenticated: true,
        };
      case "SIGN_OUT":
        //        Auth.signOut();
        //history.push('/');
        return {
          ...state,
          isAuthenticated: false,
          cognitoUser: null,
        };
      case "SIGNUP":
        return {
          ...state,
          currentForm: "signUp",
        };
      case "SIGNUP_VERIFY":
        return {
          ...state,
          currentForm: "signUp",
        };
      case "FORGOTPASS":
        return {
          ...state,
          currentForm: "forgotPass",
        };
      default:
        return state;
    }
  }

  const getUser = async () => {
    await Auth.currentAuthenticatedUser()
      .then((userData) => {
        // console.log("hub", userData);
        if (userData) {
          dispatch({ type: "SIGNIN_SUCCESS" });
          setState({
            ...state,
            isAuthenticated: true,
            cognitoUser: userData,
          });
          //dispatchProfile({ type: "UPDATE_PROFILE", payload: userData.attributes.email})
        } else
          setState({ ...state, isAuthenticated: false, cognitoUser: null });
      })
      .catch(() => {
        console.log("Not signed in");
      });
  };

  //Check income request from cognito
  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      console.log("hub", event);

      switch (event) {
        case "signIn":
          getUser();
          break;
        case "cognitoHostedUI":
          break;
        case "signOut":
          setState({ ...state, isAuthenticated: false, cognitoUser: null });
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
      }
    });
  }, []);

  // //check if new login requested
  // useEffect(() => {
  //   if (state && state.currentForm === "login" && !state.isAuthenticated) {

  //   }
  // }, [state?.currentForm]);

  //checkStartupAuth();
  if (state.cognitoUser === undefined && state.useAuthentication) {
    //  getUser();
  }

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
