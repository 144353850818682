import React from "react";
import { Img } from "react-image";
import placeholder from "assets/images/placeholder.jpg";
const Placeholder = () => <img src={placeholder} alt="product img loder" />;

export default function Image({
  url,
  alt = "placeholder",
  className,
  style,
}: {
  url?: string | [string];
  alt?: string;
  className?: string;
  style?: any;
}) {
  return (
    <Img
      draggable={false}
      style={style}
      src={url}
      alt={alt}
      loader={<Placeholder />}
      unloader={<Placeholder />}
      className={className}
    />
  );
}
