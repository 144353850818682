import { useContext, useEffect, useState } from "react";
import { v4 as uuidV4 } from "uuid";
import { ProfileContext, INITIAL_STATE } from "./profile.context";
import {
  AccountFragment,
  CatalogFragment,
  ProfileFragment,
  useCreateProfileMutation,
  UserByEmailQuery,
  useUpdateProfileMutation,
  useUserByEmailLazyQuery,
  useUserByEmailQuery,
  VersionFragment,
} from "api";
import { AuthContext } from "contexts/auth/auth.context";
import Auth from "@aws-amplify/auth";

export type ActionType =
  | { type: "LOAD_PROFILE"; payload: string }
  | { type: "CREATE_PROFILE"; payload: any } // Should we make this a ProfileFragment and manually build it from the form data?
  | { type: "UPDATE_PROFILE"; payload: any }
  | { type: "SET_ACCOUNT"; payload: AccountFragment }
  | { type: "SET_CATALOG"; payload: CatalogFragment }
  | { type: "SET_VERSION"; payload: VersionFragment };
// | {
//     type: "HANDLE_ON_INPUT_CHANGE";
//     payload: { field: "firstName" | "lastName" | "email"; value: string };
//   }
// | { type: "ADD_OR_UPDATE_CONTACT"; payload: Contact }
// | { type: "DELETE_CONTACT"; payload: string }
// | { type: "ADD_OR_UPDATE_ADDRESS"; payload: Address }
// | { type: "MISSING_ADDRESS"; payload?: null }
// | { type: "DELETE_ADDRESS"; payload: string }
// | { type: "ADD_CARD"; payload: string }
// | { type: "DELETE_CARD"; payload: string }
// | { type: "SET_PRIMARY_CONTACT"; payload: string }
// | { type: "SET_PRIMARY_ADDRESS"; payload: string }
// | { type: "SET_PRIMARY_SCHEDULE"; payload: string }
// | { type: "SET_PRIMARY_CARD"; payload: string }
// | { type: "SET_PICTURE"; payload: string }
// | { type: "REFRESH" };

export type ProfileData = {
  user?: UserByEmailQuery;
  account?: AccountFragment;
  catalog?: CatalogFragment;
  version?: VersionFragment;
};

export const ProfileProvider: React.FunctionComponent = ({ children }) => {
  const [profile, setProfile] = useState<ProfileData>({});
  const { state, dispatch } = useContext(AuthContext);
  const [getUser] = useUserByEmailLazyQuery({
    onCompleted: (d) => {
      // If user does not have a profile, redirect to signup form
      if (d.profile) {
        dispatch({ type: "SIGNIN_SUCCESS" });
        setProfile({ user: d });
      } else {
        // alert("We don't have a user profile, please create one");
        dispatch({ type: "SIGNUP" });
      }
    },
  });
  const [createProfile] = useCreateProfileMutation({
    onCompleted: (d) => {
      getUser({
        variables: {
          email: d.createProfile.profile.email,
        },
      });
    },
  });
  const [updateProfile] = useUpdateProfileMutation({
    onCompleted: (d) => {
      getUser({
        variables: {
          email: d.updateProfile.profile.email,
        },
      });
    },
  });

  useEffect(() => {
    if (state.cognitoUser) {
      if (profile.user.profile) {
        updateProfile({
          variables: {
            oid: profile.user.profile.oid,
            profile: {
              firstName: state.cognitoUser.attributes?.given_name,
              lastName: state.cognitoUser.attributes?.family_name,
              imageUrl: state.cognitoUser.attributes?.picture,
            },
          },
        });
      } else {
        alert("We don't have a user profile, please create one");
        dispatch({ type: "SIGNUP" });
      }
    }
  }, [state.cognitoUser]);

  const dispatchProfile = (action: ActionType) => {
    switch (action.type) {
      case "LOAD_PROFILE":
        getUser({
          variables: {
            email: action.payload,
          },
        });
        break;
      case "CREATE_PROFILE":
        createProfile({
          variables: {
            profile: {
              ...action.payload,
              oid: uuidV4(),
              dateCreated: new Date(),
            },
          },
        });
        break;
      case "UPDATE_PROFILE":
        updateProfile({
          variables: {
            oid: action.payload.oid,
            profile: {
              ...action.payload.profile,
            },
          },
        });
        break;
      case "SET_ACCOUNT":
        setProfile((profile) => ({ account: action.payload, ...profile }));
        break;
      case "SET_CATALOG":
        setProfile((profile) => ({ catalog: action.payload, ...profile }));
        break;
      case "SET_VERSION":
        setProfile((profile) => ({ version: action.payload, ...profile }));
        break;

      default:
        break;
    }
  };

  return (
    <ProfileContext.Provider value={{ profile, dispatchProfile }}>
      {children}
    </ProfileContext.Provider>
  );
};
