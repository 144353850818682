import { styled } from 'baseui';
import { Link } from "react-router-dom";

const Links = styled(Link, ({ $theme }) => ({
	
	textDecoration: 'none'
	
  }));


  export default Links;