import React, { useContext, useState } from "react";
import useRouter from "utils/useRouter";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import AuthHeader from "components/Header/AuthHeader/AuthHeader";
import AppWrapper, { ContentWrapper } from "./Layout.styled";
import { useThemeSwitcherCtx, THEME } from "contexts/theme/theme.provider";
import Login from "pages/login";
import { AuthContext } from "contexts/auth/auth.context";

const Layout: React.FunctionComponent<{ router?: any }> = ({ children }) => {
  const router = useRouter();
  const pathname = router.pathname;
  const { theme } = useThemeSwitcherCtx();
  let layoutBg = "#ffffff";

  if (theme === THEME.dark) {
    layoutBg = "#000000";
  }

  if (
    (theme === THEME.light && pathname === "/") ||
    (theme === THEME.light && pathname === "/chat")
  ) {
    layoutBg = "#fcfcfc";
  }

  if (
    (theme === THEME.dark && pathname === "/") ||
    (theme === THEME.dark && pathname === "/chat")
  ) {
    layoutBg = "#0c0c0c";
  }
  const { state, dispatch } = useContext(AuthContext);

  return (
    <AppWrapper className={theme} style={{ backgroundColor: layoutBg }}>
      {!state.isAuthenticated ? (
        <>
          <AuthHeader pathname={pathname} />
          <ContentWrapper>
            <Login />
          </ContentWrapper>
        </>
      ) : (
        <>
          <Header />
          <ContentWrapper>{children}</ContentWrapper>
          <Footer />
        </>
      )}
    </AppWrapper>
  );
};

export default Layout;
