export const DashboardData = {
  recentApps: [
    {
      id: 1,
      image: "https://s3.amazonaws.com/redqteam.com/inst/svg/todo.svg",
      name: "Todoist",
      description: "Organize your life. Then go enjoy it.",
    },
    {
      id: 2,
      image: "https://s3.amazonaws.com/redqteam.com/inst/svg/grammarly.svg",
      name: "Grammarly",
      description: "Clear, effective, mistake-free writing.",
    },
    {
      id: 3,
      image: "https://s3.amazonaws.com/redqteam.com/inst/svg/airtable.svg",
      name: "Airtable",
      description: "Realtime spreadsheet-database hybrid",
    },
    {
      id: 4,
      image: "https://s3.amazonaws.com/redqteam.com/inst/svg/telegram.svg",
      name: "Telegram 4.0",
      description: "The best messenger for every platform",
    },
  ],
  productViews: {
    categories: [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
    ],
    products: [
      160,
      155,
      141,
      167,
      122,
      143,
      113,
      123,
      120,
      120,
      120,
      120,
      160,
      155,
      141,
      167,
      122,
      143,
      113,
      123,
      130,
      140,
      120,
      170,
    ],
    views: [
      140,
      123,
      120,
      118,
      113,
      127,
      144,
      155,
      141,
      167,
      122,
      143,
      140,
      123,
      120,
      118,
      113,
      127,
      144,
      155,
      141,
      167,
      122,
      143,
    ],
  },
  productsBar: {
    labels: ["Apples", "Oranges", "Bananas"],
    products: [64, 70, 76],
  },
  cashFlow: {
    categories: [
      "2017-01-01",
      "2017-02-01",
      "2017-03-01",
      "2017-04-01",
      "2017-05-01",
      "2017-06-01",
      "2017-07-01",
      "2017-08-01",
      "2017-09-01",
      "2017-10-01",
      "2017-11-01",
      "2017-12-01",
      "2018-01-01",
      "2018-02-01",
      "2018-03-01",
      "2018-04-01",
      "2018-05-01",
      "2018-06-01",
      "2018-07-01",
      "2018-08-01",
      "2018-09-01",
      "2018-10-01",
      "2018-11-01",
      "2018-12-01",
      "2019-01-01",
      "2019-02-01",
      "2019-03-01",
      "2019-04-01",
      "2019-05-01",
      "2019-06-01",
      "2019-07-01",
      "2019-08-01",
      "2019-09-01",
      "2019-10-01",
      "2019-11-01",
      "2019-12-01",
    ],
    cash: [
      20.45,
      35.42,
      45.9,
      -10.42,
      -12.6,
      -18.1,
      -18.2,
      -14.16,
      -11.1,
      -16.09,
      10.34,
      30.88,
      53.07,
      25.8,
      20,
      17.37,
      18.1,
      13.57,
      15.75,
      17.1,
      19.8,
      -27.03,
      -54.4,
      -47.2,
      -43.3,
      -18.6,
      -48.6,
      -41.1,
      -39.6,
      -37.6,
      -29.4,
      -21.4,
      -20.4,
      15.75,
      17.1,
      19.8,
    ],
  },
};
